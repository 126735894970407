import { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'

import type { Application, Subscription, SubscriptionApp } from 'Store/AuthState'

import style from './ManageContentItem.module.scss'
import AppSettings from 'ApplicationSettings/ApplicationSettings'

export interface SubscriptionAppsProps {
  onCheckboxChange: (event: ChangeEvent<HTMLInputElement>, subscriptionApp: SubscriptionApp) => void
  subscription: Subscription
}

export default function SubscriptionApps({
  onCheckboxChange,
  subscription,
}: SubscriptionAppsProps) {
  function isClinicalApp(app: Application) {
    return app.id === AppSettings.AdviseAppId
  }

  function renderApp(app: Application, index: number) {
    return (
      <Grid item key={index} xs={12}>
        <FormControlLabel
          className={style.manageControlPanelItem}
          control={
            <Checkbox
              checked
              // checked={
              //   selectedSubscriptions?.filter(
              //     subApp =>
              //       subApp.subscriptionId === sub.id && subApp.applicationId === app.id
              //   ).length === 1
              // }
              disabled //TODO: Remove this when we have options to select multiple subscriptions
              onChange={event =>
                onCheckboxChange(event, { applicationId: app.id, subscriptionId: subscription.id })
              }
              sx={{ '&.Mui-disabled': { color: '#dadada' } }}
            />
          }
          key={index}
          label={<label className={style.checkLabel}>{app.name}</label>}
        />
      </Grid>
    )
  }

  return (
    <Grid container direction='row'>
      {/* For now we are just going to hide the subscription and consumer
        so all the user sees is a default checked clinical Experience. Not testing multi tenant clients */}
      {/* <Grid item xs={12}>
          {sub.name}
        </Grid> */}
      {subscription.applications.filter(isClinicalApp).map(renderApp)}
    </Grid>
  )
}
