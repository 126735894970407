import React, { createContext, useReducer, useContext } from 'react'
import enUS from 'Resources/LocalizationDictionary/en-us'
import esUS from 'Resources/LocalizationDictionary/es-us'

export type AppLocalization = {
  localizationCode: string
  localizationLibrary: any
}

const initialState: AppLocalization = {
  localizationCode: 'en-us',
  localizationLibrary: enUS,
}

const Context = createContext<{
  localizationState: AppLocalization
  localizationDispatch: React.Dispatch<any>
}>({ localizationState: initialState, localizationDispatch: () => null })

export function LocalizationStateProvider(props: any) {
  const [state, dispatch] = useReducer(localizationReducer, initialState)
  return (
    <Context.Provider value={{ localizationState: state, localizationDispatch: dispatch }}>
      {props.children}
    </Context.Provider>
  )
}

export function useLocalizationState() {
  return useContext(Context)
}

export const SET_LOCALIZATION = 'setlocalization'

export function localizationReducer(state: AppLocalization, action: any) {
  switch (action.type) {
    case SET_LOCALIZATION:
      if (state.localizationCode !== action.localizationCode) {
        let locLib = state.localizationLibrary
        switch (action.localizationCode) {
          case 'en-us':
            locLib = enUS
            break
          case 'es-us':
            locLib = esUS
            break
        }
        return {
          ...state,
          localizationCode: action.localizationCode,
          localizationLibrary: locLib,
        }
      }
      return state
    default:
      return state
  }
}
