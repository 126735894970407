import { Breadcrumbs, Typography } from '@mui/material'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import useSearchStore from 'Store/SearchState'
import { Link } from 'react-router-dom'
import style from './BreadCrumbs.module.scss'

export default function BreadCrumbs() {
  const browsePath = useSearchStore(state => state.browsePath)
  const updateBrowsePath = useSearchStore(state => state.updateBrowsePath)

  const browsePathSplit = browsePath?.split(' > ')
  const callingPageMenu = browsePathSplit![0]
  const topLevelConcept = browsePathSplit![1]
  const secondLevelConcept = browsePathSplit![2]
  const remainingConcepts = browsePathSplit!.slice(3)

  return (
    <Breadcrumbs aria-label='breadcrumb' className={style.browseBreadCrumbs}>
      <Link to={App_Routes.BROWSEALLINDEX}>{callingPageMenu}</Link>
      <Link to={`${App_Routes.BROWSEALLINDEX}#${topLevelConcept}`}>{topLevelConcept}</Link>
      {remainingConcepts.length > 0 ? (
        <Link
          to={App_Routes.BROWSE}
          onClick={() =>
            updateBrowsePath(`${callingPageMenu} > ${topLevelConcept} > ${secondLevelConcept}`)
          }
        >
          {secondLevelConcept}
        </Link>
      ) : (
        <Typography>{secondLevelConcept}</Typography>
      )}
      {remainingConcepts.map((rc, index) => {
        if (index >= remainingConcepts.length - 1) {
          return <Typography key={`typography-${rc}`}>{rc}</Typography>
        } else {
          return (
            <Link
              to={App_Routes.BROWSE}
              key={`link-${rc}`}
              onClick={() =>
                updateBrowsePath(
                  browsePathSplit?.slice(0, browsePathSplit.indexOf(rc) + 1).join(' > ') ?? ''
                )
              }
            >
              {rc}
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}
