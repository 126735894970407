import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { FormControl, IconButton, InputAdornment, Paper, Tooltip } from '@mui/material'
import { fetchFormattedContent } from 'Api/Content.api'
import { App_Routes, algoliaApplicationTags } from 'ApplicationSettings/ApplicationSettings'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import { AlgoliaState } from 'Store/AlgoliaState'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import useSearchStore from 'Store/SearchState'
import { AlgoliaHit, ConceptHit, HistoryHit, HistoryItem, normalizeHits } from 'Types/HitItem'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useConnector, Index, Configure } from 'react-instantsearch'
import connectAutocomplete, {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { useNavigate, useLocation } from 'react-router-dom'
import { actionKeys } from '../../common.util'
import { AutocompleteResults } from './AutoCompleteResults'
import style from './SearchWithAutocomplete.module.scss'
import 'instantsearch.css/themes/satellite.css'
import 'Styles/Overwrite.scss'

const recentSearches = createLocalStorageRecentSearchesPlugin({
  key: 'RECENT_SEARCH',
  limit: 3,
})

const autocompleteId = 'searchAutocompleteControl'
const ARTICLE_AUTOCOMPLETE_ID = 'autocomplete_articles'

type SearchWithAutocompleteProps = {
  className?: string
  algoliaState: AlgoliaState
}

export function useSearchWithAutocomplete(props?: AutocompleteConnectorParams) {
  return useConnector<AutocompleteConnectorParams, AutocompleteWidgetDescription>(
    connectAutocomplete,
    props
  )
}

export function SearchWithAutocomplete({ algoliaState }: SearchWithAutocompleteProps) {
  const searchTerm = useSearchStore(state => state.searchTerm)
  const updateSearchTerm = useSearchStore(state => state.updateSearchTerm)
  const { indices, refine } = useSearchWithAutocomplete()
  const { previewStateDispatch } = usePreviewState()

  const [searchText, setSearchText] = useState('')
  const [hasFocus, setFocus] = useState<boolean>(false)
  const [popperAnchorEl, setPopperAnchorEl] = useState<any>(null)

  const inputRef = useRef<HTMLInputElement>()
  const location = useLocation()
  const navigate = useNavigate()

  const wrapperId = 'search-bar-skinny'

  const getIndexById = (id: string) => {
    let result = {}
    for (var i = 0; i < indices.length; i++) {
      var item = indices[i]
      if ('indexId' in item) {
        if (item.indexId === id) {
          result = item
          break
        }
      }
    }
    return result
  }

  const [historyHits, setHistoryHits] = useState<HistoryHit[]>(
    (recentSearches.data?.getAll(searchText) as HistoryHit[]) ?? []
  )

  let autocompleteIndex = getIndexById(ARTICLE_AUTOCOMPLETE_ID)
  const normalizedHits =
    'hits' in autocompleteIndex
      ? normalizeHits<AlgoliaHit>(autocompleteIndex?.hits, ARTICLE_AUTOCOMPLETE_ID)
      : []

  let suggestionsIndex = getIndexById(algoliaState?.suggestions || '')
  const suggestionHits =
    'hits' in suggestionsIndex
      ? normalizeHits<ConceptHit>(suggestionsIndex?.hits, algoliaState?.suggestions || '')
      : []

  const isActivelySearching = popperAnchorEl != null && hasFocus && searchText.length > 0

  const addToSearchHistory = (item: HistoryItem) => {
    if (historyHits.filter(h => h.id === item.id).length > 0) return
    recentSearches.data?.addItem(item)
    setHistoryHits((recentSearches.data?.getAll(searchText) as HistoryHit[]) ?? [])
  }

  const removeFromSearchHistory = (item: HistoryItem) => {
    // Adding in a timeout to allow the click event to fire before the item is removed
    setTimeout(() => {
      recentSearches.data?.removeItem(item.id)
      setHistoryHits((recentSearches.data?.getAll(searchText) as HistoryHit[]) ?? [])
    }, 50)
  }

  const confirmSearch = () => {
    const desiredRoute = App_Routes.BROWSE
    addToSearchHistory({
      id: searchText,
      label: searchText,
    })
    refineSearch(searchText)
    setFocus(false)
    if (location.pathname !== desiredRoute) {
      navigate(desiredRoute, { replace: true })
    }
  }

  const testElementIsOutsideSearchStack = (target: HTMLElement | Element | null): boolean => {
    const $wrapper = document.getElementById(wrapperId)
    const $auto = document.getElementById(autocompleteId)
    let elementIsInsideWrapper = $wrapper && $wrapper.contains(target)
    let elementIsInsideAutocomplete = $auto && $auto.contains(target)
    return !elementIsInsideWrapper && !elementIsInsideAutocomplete
  }

  useEffect(() => {
    refine(searchText)
  }, [searchText, refine])

  useEffect(() => {
    if (searchTerm === '') setSearchText('')
  }, [searchTerm])

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (testElementIsOutsideSearchStack(e.target)) {
        setFocus(false)
      }
    }

    window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
    // eslint-disable-next-line
  }, [wrapperId, searchTerm, searchText, popperAnchorEl, history, location.pathname])

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(e.currentTarget.value)
    setHistoryHits((recentSearches.data?.getAll(e.currentTarget.value) as HistoryHit[]) ?? [])
  }

  const refineSearch = (label: string) => {
    if (label) {
      setSearchText(label)
      updateSearchTerm(label)
      refine(label)
    }
  }

  const handleInputKeyUp = (e: any) => {
    if (e.keyCode === actionKeys.Enter && !testElementIsOutsideSearchStack(e.target)) {
      e.preventDefault()
      confirmSearch()
      setFocus(false)
      updateSearchTerm(searchText)
      if (searchText.length > 0)
        addToSearchHistory({
          id: searchText,
          label: searchText,
        })
    } else if (
      searchText.length === 0 &&
      (e.keyCode === actionKeys.Backspace || e.keyCode === actionKeys.Delete)
    ) {
      focusInput()
    }
  }

  const focusInput = () => {
    inputRef?.current?.focus()
    setFocus(true)
  }

  const handleListItemClick = (item: any) => {
    if (item?.id) {
      //If an article item was selected
      //for inhouse content - show pdf
      if (item.inHouse) {
        const inHouseItem = {
          hwid: item.hwid,
          title: item.title,
          localization: item.localization,
        }
        previewStateDispatch({
          type: PreviewStateAction.SETPREVIEWCONTENT,
          data: inHouseItem,
        })
      } else {
        //for hwContent
        previewStateDispatch({ type: PreviewStateAction.SETPREVIEWCONTENT, data: item })
        fetchFormattedContent(item.id).then(content => {
          if (content) {
            previewStateDispatch({ type: PreviewStateAction.SETPREVIEWHTML, data: content })
          }
        })
        if (item.type === 'Video' || item.type === 'Image') {
          previewStateDispatch({
            type: PreviewStateAction.SETTOPICEMBEDTYPE,
            data: item.type,
          })
        }
      }

      previewStateDispatch({ type: PreviewStateAction.SETPREVIEWOPEN, data: true })
      setFocus(false)
    } else if (item) {
      addToSearchHistory({
        id: item,
        label: item,
      })
      refineSearch(item)
      setFocus(false)
    } //if a search term was selected do a search?
  }

  const handleFocus = (e: any) => {
    if (!popperAnchorEl) setPopperAnchorEl(e.target)
    setFocus(true)
  }

  const handleIconClick = () => {
    if (searchText.length > 0) {
      setSearchText('')
      updateSearchTerm('')
      focusInput()
    }
  }

  const algoliaTags = algoliaApplicationTags()

  return (
    <div role='search' className={style.autocomplete} id={wrapperId}>
      <FormControl className={style.searchForm}>
        <CmxTextField
          inputRef={inputRef}
          value={searchText}
          onChange={e => handleOnChange(e)}
          onKeyUp={e => handleInputKeyUp(e)}
          onFocus={handleFocus}
          onSelect={handleFocus}
          autoComplete='off'
          placeholder='Search'
          data-qa='SearchTextField'
          sx={{
            width: '100%',
            input: {
              '&::placeholder': {
                color: '#424242',
                opacity: 1,
              },
            },
            '.MuiOutlinedInput-root': {
              backgroundColor: '#FFF',
              borderRadius: '8px',
              borderBottomRightRadius: isActivelySearching ? '0' : '8px',
              borderBottomLeftRadius: isActivelySearching ? '0' : '8px',
              boxShadow: isActivelySearching ? 1 : 0,
              color: '#262626',
              '&.Mui-focused': {
                outline: isActivelySearching ? 'none' : '2px dotted #262626',
                outlineOffset: '1px',
              },
              '& fieldset': {
                borderRadius: '8px',
                borderBottomRightRadius: isActivelySearching ? '0' : '8px',
                borderBottomLeftRadius: isActivelySearching ? '0' : '8px',
                borderColor: '#FFF',
              },
              '&:hover fieldset': {
                borderColor: '#FFF',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FFF',
              },
            },
            '& .MuiSvgIcon-root': {
              color: 'unset',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  onClick={focusInput}
                  onFocus={handleFocus}
                  aria-label='Start Search'
                  className={style.searchIcon}
                  data-qa='SearchIcon'
                  tabIndex={-1}
                  sx={{ color: isActivelySearching ? '#727272' : '#929292' }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchText.length > 0 && (
              <InputAdornment position='end'>
                <Tooltip
                  title='Clear'
                  placement={'left'}
                  PopperProps={{
                    // Test Harness placement compatibility
                    disablePortal: true,
                  }}
                >
                  <IconButton
                    onClick={() => handleIconClick()}
                    onFocus={handleFocus}
                    aria-label='Clear Search'
                    className={style.closeIcon}
                    data-qa='ClearSearchIcon'
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <div
          role='tooltip'
          id={autocompleteId}
          className={classNames(style.autocompleteListPopper, {
            [style.autocompleteListPopperOpen]: isActivelySearching,
          })}
        >
          <Paper className={style.autocompleteListPaper} variant='elevation' elevation={1}>
            <Index indexName={'assets'} indexId={ARTICLE_AUTOCOMPLETE_ID}>
              <Configure
                hitsPerPage={3}
                distinct={true}
                facetingAfterDistinct={true}
                analytics={true}
                clickAnalytics={true}
                enablePersonalization={true}
                analyticsTags={algoliaTags}
                filters='content.localization:en-us AND NOT cmx.type:Topic AND NOT cmx.type:Image'
                page={0}
              />
              <AutocompleteResults
                results={normalizedHits}
                suggestions={suggestionHits}
                history={historyHits}
                removeFromSearchHistory={removeFromSearchHistory}
                handleListItemClick={handleListItemClick}
                searchTerm={searchText}
                onConfirmSearch={confirmSearch}
              />
            </Index>
          </Paper>
        </div>
      </FormControl>
    </div>
  )
}
