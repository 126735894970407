import Alert from '@mui/material/Alert'
import ErrorIcon from '@mui/icons-material/Error'

export default function PageError() {
  return (
    <Alert
      sx={{
        backgroundColor: '#FFF',
        padding: '20px 0 20px 35px',
        color: '#DD372F',
        '& .MuiAlert-message': {
          paddingTop: '10px',
        },
        '& .MuiAlert-icon': {
          marginRight: '5px',
          color: '#DD372F',
        },
      }}
      icon={<ErrorIcon fontSize='inherit' />}
      severity='error'
    >
      There was an error loading this page. Please try again, or contact your Healthwise Account
      Manager if the problem persists.
    </Alert>
  )
}
