import { create } from 'zustand'
import { ContentItemMetadata } from './Models/ContentItem'

export type ManageStore = {
  deleteOpen: boolean
  suppressItems: any[]
  suppressOpen: boolean
  isBulk: boolean
  fromBasket: boolean
  isRestore: boolean
  isHwContent: boolean
  fromManage: boolean
  fromSuppress: boolean
  suppressSuccessCount: number
  publishAction: string
  publishOpen: boolean
  publishItems: any[]
  hasEnglish: boolean
  contentItems: ContentItemMetadata[]
  refreshContentItem: boolean
  refreshData: boolean
}

export const initialState: ManageStore = {
  deleteOpen: false,
  suppressItems: [],
  suppressOpen: false,
  isBulk: false,
  fromBasket: false,
  isRestore: false,
  isHwContent: false,
  fromManage: false,
  fromSuppress: false,
  suppressSuccessCount: 0,
  publishAction: '',
  publishOpen: false,
  publishItems: [],
  hasEnglish: true,
  contentItems: [],
  refreshContentItem: false,
  refreshData: false,
}

type ManageStoreAction = {
  updateContentItems: (contentItems: ManageStore['contentItems']) => void
  updateDeleteOpen: (deleteOpen: ManageStore['deleteOpen']) => void
  updateFromBasket: (fromBasket: ManageStore['fromBasket']) => void
  updateFromManage: (fromManage: ManageStore['fromManage']) => void
  updateFromSuppress: (fromSuppress: ManageStore['fromSuppress']) => void
  updateHasEnglish: (hasEnglish: ManageStore['hasEnglish']) => void
  updateIsBulk: (isBulk: ManageStore['isBulk']) => void
  updateIsHwContent: (isHwContent: ManageStore['isHwContent']) => void
  updateIsRestore: (isRestore: ManageStore['isRestore']) => void
  updatePublishAction: (publishAction: ManageStore['publishAction']) => void
  updatePublishItems: (publishItems: ManageStore['publishItems']) => void
  updatePublishOpen: (publishOpen: ManageStore['publishOpen']) => void
  updateRefreshContentItem: (refreshContentItem: ManageStore['refreshContentItem']) => void
  updateRefreshData: (refreshData: ManageStore['refreshData']) => void
  updateSuppressItems: (suppressItems: ManageStore['suppressItems']) => void
  updateSuppressOpen: (suppressOpen: ManageStore['suppressOpen']) => void
  updateSuppressSuccessCount: (suppressSuccessCount: ManageStore['suppressSuccessCount']) => void
}

export const useManageStore = create<ManageStore & ManageStoreAction>()(set => ({
  ...initialState,
  updateDeleteOpen: deleteOpen => set(() => ({ deleteOpen: deleteOpen })),
  updateSuppressItems: suppressItems => set(() => ({ suppressItems: suppressItems })),
  updateSuppressOpen: suppressOpen => set(() => ({ suppressOpen: suppressOpen })),
  updateIsBulk: isBulk => set(() => ({ isBulk: isBulk })),
  updateFromBasket: fromBasket => set(() => ({ fromBasket: fromBasket })),
  updateIsRestore: isRestore => set(() => ({ isRestore: isRestore })),
  updateIsHwContent: isHwContent => set(() => ({ isHwContent: isHwContent })),
  updateFromManage: fromManage => set(() => ({ fromManage: fromManage })),
  updateFromSuppress: fromSuppress => set(() => ({ fromSuppress: fromSuppress })),
  updateSuppressSuccessCount: suppressSuccessCount =>
    set(() => ({ suppressSuccessCount: suppressSuccessCount })),
  updatePublishAction: publishAction => set(() => ({ publishAction: publishAction })),
  updatePublishOpen: publishOpen => set(() => ({ publishOpen: publishOpen })),
  updatePublishItems: publishItems => set(() => ({ publishItems: publishItems })),
  updateHasEnglish: hasEnglish => set(() => ({ hasEnglish: hasEnglish })),
  updateContentItems: contentItems => set(() => ({ contentItems: contentItems })),
  updateRefreshContentItem: refreshContentItem =>
    set(() => ({ refreshContentItem: refreshContentItem })),
  updateRefreshData: refreshData => set(() => ({ refreshData: refreshData })),
}))
