import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Modal } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import styles from './ContentUpload.module.scss'
import modalStyle from 'Styles/Modal.module.scss'

import FileUpload from '../FileUpload'
import FileDetails from '../FileDetails'
import Step from '../Step'
import { useImportState, ImportFile, FileUploadStatus } from 'Store/ImportState'
import CmxButton from 'Components/CmxButton'
import useLocalStorage from 'Store/Hooks/useLocalStorage'
import { SubmitContent, UpdateContent } from 'Api/Import.api'
import FinalizeUpload from '../FinalizeUpload/FinalizeUpload'

type ContentUploadProps = {
  open: boolean
  updateRefreshData: (flag: boolean) => void
}

export const NO_UPLOADED_FILES_ERROR = 'Please select file(s) to upload.'

const ContentUpload: FC<ContentUploadProps> = ({ open, updateRefreshData }) => {
  const { importState, ImportStateManager } = useImportState()
  const currentStep = importState.currentStep
  const [goNextStatus, setGoNextStatus] = useState('')
  const prevGoNextStatus = useRef<string>()
  const [fileDetails, setFileDetails] = useLocalStorage('fileImportDetails', [])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [confirmCancelImportOpen, setConfirmCancelImportOpen] = useState(false)
  const [uploadErrors, setUploadErrors] = useState(false)
  const uploadFileCount = GetImportFiles().filter(f => !f.removedFromUpload).length

  const updateFilesState = useCallback(() => {
    setSubmitAttempted(false)
    let files = JSON.parse(localStorage.getItem('fileImportDetails') ?? '') as ImportFile[]
    let filteredFiles = files.filter(file => !file.removedFromUpload)
    ImportStateManager.SetFiles(filteredFiles)
    setFileDetails(filteredFiles)
  }, [ImportStateManager, setFileDetails])

  const fileUploadComplete = useCallback(() => {
    const uploadedFiles: ImportFile[] = JSON.parse(
      localStorage.getItem('fileImportDetails') || '[]'
    )
    if (uploadedFiles?.filter((file: ImportFile) => !file.removedFromUpload).length > 0) {
      if (
        uploadedFiles.filter(
          (file: ImportFile) =>
            !file.removedFromUpload && file.uploadStatus !== FileUploadStatus.Complete
        ).length > 0
      ) {
        setGoNextStatus('Uploads in progress or issues exist with selected files.')
        return false
      }
      if (
        uploadedFiles.filter(
          (file: ImportFile) => !file.removedFromUpload && file.fileExists && !file.overwriteFile
        ).length > 0
      ) {
        setGoNextStatus('Please resolve errors before continuing.')
        return false
      }
    } else {
      setGoNextStatus(NO_UPLOADED_FILES_ERROR)
      return false
    }

    //Initialize local storage for next step. This is needed as we are creating forms as children
    setGoNextStatus('')
    setFileDetails(uploadedFiles.filter(f => !f.removedFromUpload))
    return true
  }, [setFileDetails])

  const fileDetailsComplete = useCallback(() => {
    setSubmitAttempted(true)
    let files = JSON.parse(localStorage.getItem('fileImportDetails') ?? '') as ImportFile[]
    if (files.filter(file => !file.canSubmit && !file.removedFromUpload).length > 0) {
      if (files.filter(file => file.validationInProgress).length > 0) {
        setGoNextStatus('File validation in progress.')
      }
      return false
    } else {
      updateFilesState()
      ImportStateManager.SetSubmitFiles(true)
      return true
    }
  }, [ImportStateManager, updateFilesState])

  const steps: any[] = useMemo(() => {
    return [
      {
        order: 1,
        title: 'Add Content',
        component: FileUpload,
        goNext: () => {
          return fileUploadComplete()
        },
      },
      {
        order: 2,
        title: 'Provide Details',
        component: FileDetails,
        saveState: updateFilesState,
        goNext: () => {
          return fileDetailsComplete()
        },
      },
      {
        order: 3,
        component: FinalizeUpload,
        goNext: () => {
          return true
        },
      },
    ]
  }, [fileDetailsComplete, fileUploadComplete, updateFilesState])

  const submitFile = useCallback(
    (index: number = 0) => {
      let file = importState.files[index]
      if (file.overwriteContentItem || file.overwriteFile) {
        UpdateContent(file)
          .then(data => {
            if (data?.status) {
              //Error occured. Show to user
              ImportStateManager.AddFinalizeError(file.filename)
            }
            ImportStateManager.SetFileFinalized(file.fileKey, true) //It will be finalized success or not
            //TODO: If there is an error capture it here and display on the progress screen
            if (importState.files.length > index + 1) {
              submitFile(index + 1)
            }
          })
          .catch(err => {
            const errMsg = 'UpdateContent Error: ' + err
            ImportStateManager.AddFinalizeError(errMsg)
          })
      } else {
        SubmitContent(file).then(data => {
          if (data?.status) {
            //Error occured. Show to user
            ImportStateManager.AddFinalizeError(file.filename)
          }
          ImportStateManager.SetFileFinalized(file.fileKey, true) //It will be finalized success or not
          //TODO: If there is an error capture it here and display on the progress screen
          if (importState.files.length > index + 1) {
            submitFile(index + 1)
          }
        })
      }
    },
    [ImportStateManager, importState.files]
  )

  useEffect(() => {
    prevGoNextStatus.current = goNextStatus
  }, [goNextStatus])

  useEffect(() => {
    //If there was a previous validation error then the user had tried to click next with errors.
    //Don't blow away the message on a re-redner. If the user didn't have errors (because they didn't click next) don't run 'canGoNext'
    if (prevGoNextStatus.current !== '') {
      let thisStep = steps.filter(step => step.order === currentStep)[0]
      thisStep.goNext() //Update any validation errors
    }
  }, [importState.files, importState.currentStep, steps, currentStep])

  useEffect(() => {
    if (importState.submitFiles) {
      submitFile()
      ImportStateManager.SetSubmitFiles(false)
    }
  }, [ImportStateManager, importState.submitFiles, submitFile])

  //allowing esc to close dialog for accessibility
  const KEY_NAME_ESC = 'Escape'
  const KEY_EVENT_TYPE = 'keyup'
  function useEscapeKey(onClose: any) {
    const handleEscKey = useCallback(
      (event: { key: string }) => {
        if (event.key === KEY_NAME_ESC) {
          onClose()
        }
      },
      [onClose]
    )

    useEffect(() => {
      document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

      return () => {
        document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
      }
    }, [handleEscKey])
  }
  useEscapeKey(onClose)

  function ConfirmModal() {
    return (
      <Modal open={confirmCancelImportOpen} keepMounted>
        <Grid
          container
          className={`${styles.modalConfirm} ${modalStyle.modalContent}`}
          direction='row'
          spacing={1}
        >
          <Grid item xs={12} container justifyContent='space-between' alignItems='flex-start'>
            <Grid item className={styles.modalConfirmTitle}>
              Cancel upload?
            </Grid>
          </Grid>
          <Grid item xs={12} className={styles.modalMessage}>
            Your upload is not complete. Would you like to cancel the upload?
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item container direction='row' xs={12} spacing='15px' justifyContent='flex-end'>
            <Grid item>
              <CmxButton onClick={() => closeImport()} data-testid='ImportCancelConfirm'>
                Cancel
              </CmxButton>
            </Grid>
            <Grid item>
              <CmxButton primary onClick={() => setConfirmCancelImportOpen(false)}>
                Continue Upload
              </CmxButton>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    )
  }

  function onNext() {
    let thisStep = steps.filter(step => step.order === currentStep)[0]
    if (thisStep.goNext()) {
      ImportStateManager.SetStep(currentStep + 1)
    }
  }

  function onBack() {
    let thisStep = steps.filter(step => step.order === currentStep)[0]
    if (thisStep.saveState) {
      thisStep.saveState()
    }
    ImportStateManager.SetStep(currentStep - 1)
  }

  function closeImport() {
    localStorage.removeItem('fileImportDetails')
    setSubmitAttempted(false)
    setUploadErrors(false)
    setConfirmCancelImportOpen(false)
    setGoNextStatus('')
    ImportStateManager.ResetImport()
  }

  function onClose() {
    if (GetImportFiles().filter(f => !f.removedFromUpload).length > 0)
      setConfirmCancelImportOpen(true)
    else closeImport()
  }

  function GetImportFiles(): ImportFile[] {
    return JSON.parse(localStorage.getItem('fileImportDetails') ?? '[]') as ImportFile[]
  }

  let fileDetailsErrors = 0
  if (fileDetails.length > 0) {
    fileDetails.forEach((file: ImportFile) => {
      fileDetailsErrors += file.errorCount
    })
  }

  return (
    <Modal open={open} role='dialog' aria-modal='true' aria-labelledby='uploadcontent'>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <h2 id='uploadcontent'>Upload Content</h2>
          <div className={styles.steps}>
            <Step
              {...steps[0]}
              done={steps[0].order < currentStep}
              selected={currentStep === 1}
              last={false}
            />
            <Step
              {...steps[1]}
              done={steps[1].order < currentStep}
              selected={currentStep === 2}
              last={true}
            />
          </div>
        </div>
        <div className={styles.separator}></div>
        {currentStep === 1 && (
          <FileUpload
            uploadErrors={uploadErrors}
            setUploadErrors={setUploadErrors}
            goNextStatus={goNextStatus}
            setGoNextStatus={setGoNextStatus}
          />
        )}
        {currentStep === 2 && (
          <FileDetails
            submitAttempted={submitAttempted}
            fileDetails={fileDetails}
            setFileDetails={setFileDetails}
            noUploadFiles={uploadFileCount === 0}
          />
        )}
        {currentStep === 3 && <FinalizeUpload updateRefreshData={updateRefreshData} />}
        {(!!goNextStatus || !!importState.uploadError) && (
          <div
            aria-label='Upload content error message'
            data-testid='UploadError'
            className={styles.errorText}
          >{`${goNextStatus} ${importState.uploadError}`}</div>
        )}
        {submitAttempted && fileDetailsErrors > 0 && (
          <div
            className={styles.errorText}
            aria-label='Details error message'
            data-testid='DetailsError'
          >
            {`Please resolve ${fileDetailsErrors} ${
              fileDetailsErrors > 1 ? 'errors' : 'error'
            } before continuing.`}
          </div>
        )}
        <div className={styles.buttonPanel}>
          {/* Added in this div to keep spacing consistent when Back button is not rendered */}
          <div>
            {1 < currentStep && currentStep < 3 && (
              <CmxButton
                variant='text'
                onClick={onBack}
                data-qa='ContentUploadPrevious'
                startIcon={<ArrowBackIcon />}
              >
                Back
              </CmxButton>
            )}
          </div>
          {currentStep < 3 && (
            <div className={styles.rightButtons}>
              <CmxButton
                onClick={onClose}
                data-qa='ContentUploadCancel'
                data-testid='ImportCancel'
                variant='contained'
              >
                Cancel
              </CmxButton>
              <CmxButton
                primary
                onClick={() => {
                  // If there aren't any files to upload, go back to the previous step
                  if (currentStep === 2 && uploadFileCount === 0) {
                    onBack()
                  } else {
                    onNext()
                  }
                }}
                data-qa='ContentUploadNext'
                endIcon={currentStep < 2 ? <ArrowForwardIcon /> : ''}
                data-testid='UploadNext'
              >
                {currentStep < 2 ? 'Next' : uploadFileCount > 0 ? 'Upload' : 'Add Content'}
              </CmxButton>
            </div>
          )}
        </div>
        <ConfirmModal />
      </div>
    </Modal>
  )
}

export default ContentUpload
