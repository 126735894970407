import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IdToken, useAuth0 } from '@auth0/auth0-react'
import { App_Routes } from '../../ApplicationSettings/ApplicationSettings'
import { useAuthState } from 'Store/AuthState'
import { getNewGuid } from 'common'

export const AuthHandler = () => {
  const { Auth0StateManager } = useAuthState()
  const location = useLocation()
  const auth0 = useAuth0()

  const [jwt, setJwt] = useState('')
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | undefined>()

  useEffect(() => {
    const resolveIdTokenClaims = async () => {
      const token = await auth0.getAccessTokenSilently()
      setJwt(token)
      const claims = await auth0.getIdTokenClaims()
      setIdTokenClaims(claims)
    }
    if (auth0?.isAuthenticated) {
      resolveIdTokenClaims()
    }
  }, [auth0])

  // if there is no variable list, it executes once and that's it
  useEffect(() => {
    if (jwt && idTokenClaims && auth0?.user) {
      Auth0StateManager.UpdateAuth(auth0.user, jwt, idTokenClaims)

      //create sessionId in sessionStorage, if it does not already exist
      if (!sessionStorage.getItem('sessionid')) {
        sessionStorage.setItem('sessionid', getNewGuid())
      }
    }
  }, [jwt, idTokenClaims, auth0?.user, Auth0StateManager])

  useEffect(() => {
    const logout = async () => {
      await auth0.logout()
    }

    // Direct comparison with === while maintaining org alias doesn't work here
    if (location.pathname.includes(App_Routes.LOGOUT)) {
      logout()
      sessionStorage.removeItem('features')
      sessionStorage.removeItem('userid')
      sessionStorage.removeItem('sessionid')
      sessionStorage.removeItem('initialLoad')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0, location.pathname])

  return null
}
