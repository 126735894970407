import {
  ContentItemMetadata,
  defaultContentItem,
  MetadataItem,
  MetadataItemAction,
  MetadataSource,
} from './Models/ContentItem'

function BuildMetadataItemsFromValues(category: string, values: any[]) {
  if (values) {
    return values.map((e: any): MetadataItem => {
      return {
        category: category,
        value: e.value,
        source: MetadataSource.Unknown,
        action: MetadataItemAction.NoAction,
        saved: true,
      }
    })
  }
  return []
}

export function BuildContentItemMetadataFromHit(hit: any): ContentItemMetadata {
  let content = hit.content
  let res = hit._highlightResult
  return {
    ...defaultContentItem,
    id: -1,
    hwid: content.id,
    title: content.title,
    localization: content.localization,
    description: content.description,
    contentType: content.docType,
    editable: false,
    metadata: {
      age: { minAge: content.minAge, maxAge: content.maxAge },
      description: content.description,
      gender: content.gender,
      metadataItems: [
        ...BuildMetadataItemsFromValues('keywords', res.concept?.keywords?.r1),
        ...BuildMetadataItemsFromValues('cpt', res.concept.codeSystems?.cpt?.r1),
        ...BuildMetadataItemsFromValues('icd10ca', res.concept.codeSystems?.icd10ca?.r1),
        ...BuildMetadataItemsFromValues('icd10cm', res.concept.codeSystems?.icd10cm?.r1),
        ...BuildMetadataItemsFromValues('loinc', res.concept.codeSystems?.loinc?.r1),
        ...BuildMetadataItemsFromValues('rxnorm', res.concept.codeSystems?.rxnorm?.r1),
        ...BuildMetadataItemsFromValues('snomedct', res.concept.codeSystems?.snomedct?.r1),
      ],

      keywords: res.concept?.keywords?.r1?.map((e: any) => e.value) ?? [],
      cpt: res.concept.codeSystems?.cpt?.r1?.map((e: any) => e.value) ?? [],
      icd10ca: res.concept.codeSystems?.icd10ca?.r1?.map((e: any) => e.value) ?? [],
      icd10cm: res.concept.codeSystems?.icd10cm?.r1?.map((e: any) => e.value) ?? [],
      loinc: res.concept.codeSystems?.loinc?.r1?.map((e: any) => e.value) ?? [],
      rxnorm: res.concept.codeSystems?.rxnorm?.r1?.map((e: any) => e.value) ?? [],
      snomedct: res.concept.codeSystems?.snomedct?.r1.map((e: any) => e.value) ?? [],
    },
    status: '',
    statuses: [],
    pdfUrl: '',
    canSubmit: false,
  }
}

export class ContentItemMetadataStorageManager {
  ContentItemMetadataLocalStorageKey = 'contentItemMetadata'

  AddItem(contentItemMetadata: ContentItemMetadata) {
    let cimItems = this.GetAll()
    window.localStorage.setItem(
      this.ContentItemMetadataLocalStorageKey,
      JSON.stringify([
        ...cimItems.filter(
          cim =>
            cim.hwid !== contentItemMetadata.hwid &&
            cim.localization !== contentItemMetadata.localization
        ),
        contentItemMetadata,
      ])
    )
  }

  GetAll() {
    return JSON.parse(
      localStorage.getItem(this.ContentItemMetadataLocalStorageKey) || '[]'
    ) as ContentItemMetadata[]
  }

  Get(hwid: string, localization: string): ContentItemMetadata {
    let cimItems = this.GetAll()
    return cimItems.filter(cim => cim.hwid === hwid && cim.localization === localization)[0]
  }
  RemoveItem(hwid: string, localization: string) {
    let cimItems = this.GetAll()
    window.localStorage.setItem(
      this.ContentItemMetadataLocalStorageKey,
      JSON.stringify([
        ...cimItems.filter(cim => cim.hwid !== hwid && cim.localization !== localization),
      ])
    )
  }
}
