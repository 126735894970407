import { createTheme } from '@mui/material/styles'
import { withStyles } from 'tss-react/mui'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export enum Color {
  Black = '#424242',
  White = '#ffffff',
}

export const theme: any = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#006272',
      light: '#0d8484',
    },
    secondary: {
      main: '#DD372F',
    },
    warning: {
      main: '#ff9800',
      light: '#FFF1D3',
    },
    error: {
      main: '#f44336',
      light: '#F9D7D5',
    },
    success: {
      main: '#4caf50',
      light: '#D3F3EB',
    },
  },
})

export const Accordion: any = withStyles(MuiAccordion, (_theme, _params, classes) => ({
  root: {
    background: 'none',
    backgroundColor: '#f5f4f6',
    border: 'none',
    boxShadow: 'none',
    paddingBottom: '18px',
    margin: 0,
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 0,
      backgroundColor: '#f5f4f6',
      boxShadow: 'none',
    },
  },
  expanded: {},
  focused: {},
}))

export const AccordionSummary = withStyles(MuiAccordionSummary, (_theme, _params, classes) => ({
  root: {
    [`&.${classes.expanded}`]: {
      minHeight: 30,
    },
    padding: 0,
    '&.MuiAccordionSummary-root': {
      '&.Mui-focusVisible': {
        outline: '2px dotted #424242',
        backgroundColor: '#f5f4f6',
      },
    },
  },
  content: {
    [`&.${classes.expanded}`]: {
      margin: '0',
    },
    backgroundColor: '#f5f4f6',
    boxShadow: 'none',
  },
  expanded: {},
  focused: {},
}))

export const AccordionDetails = withStyles(MuiAccordionDetails, {
  root: {
    padding: '2px',
  },
})
