import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import classNames from 'classnames'
import styles from './FacetItem.module.scss'

interface FacetItemProps {
  label: string
  tooltip: string
  title: string
  isRefined: boolean
  count: number
  onClick: (event: any) => void
}

const FacetItem = ({ label, tooltip, isRefined, count, onClick }: FacetItemProps) => {
  const dashedLabel = label.replaceAll(' ', '-')

  function getItem() {
    const disabled = count < 1 && !isRefined
    return (
      <FormControlLabel
        className={styles.item}
        control={
          <Checkbox
            checked={isRefined}
            size='medium'
            data-qa='FacetCheckbox'
            data-testid={`${dashedLabel}-FacetCheckbox`}
            className={classNames(
              styles.checkbox,
              isRefined && styles.activeCheckbox,
              disabled && styles.disabledCheckbox
            )}
            disabled={disabled}
            onClick={onClick}
            sx={{
              '&:has(:focus-visible)': { outline: '2px dotted #424242', outlineOffset: '-1px' },
            }}
          />
        }
        label={
          <span className={classNames(styles.label, disabled && styles.disabledLabel)}>
            {label}
          </span>
        }
      />
    )
  }

  return (
    <div data-testid={dashedLabel + '-FacetItem'}>
      {tooltip ? (
        <Tooltip placement='top-end' describeChild arrow title={tooltip} disableInteractive>
          {getItem()}
        </Tooltip>
      ) : (
        getItem()
      )}
    </div>
  )
}

export default FacetItem
