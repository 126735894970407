import { GetECode } from 'Api/Content.api'
import ApplicationSettings from 'ApplicationSettings/ApplicationSettings'
import TokenParser, { AdminOrgsSub } from 'Utilities/Auth0TokenParser'
import { GetCompassSubscription, getLicensing, getOrgName } from 'Utilities/OrgUtil'
import PermissionManager, { Feature } from 'Utilities/PermissionManager'
import { createContext, useContext, useEffect, useReducer } from 'react'

export const CallbackConfig = {
  frontDoorUri: ApplicationSettings.MyHealthwiseIssuer || ApplicationSettings.AUTH0_DOMAIN,
  auth0IssuerUri: ApplicationSettings.AUTH0_DOMAIN,
}

let orgInfo: OrgInfo

export const topicAssets = ['Topics', 'Videos', 'Image Topics']
export const articleAssets = ['Knowledgebase', 'NCI', 'CDC', 'FDB', 'Patient Instructions']

export class AppFeatureDictionary {
  public static CanUploadAndManage: string = 'CanUploadAndManage'
}

export type SubscriptionApp = {
  subscriptionId: string
  applicationId: string
}

export type Subscription = {
  id: string
  name: string
  type: string
  active: boolean
  applications: Application[]
}

export type AppFeature = {
  name: string
  isEnabled: boolean
}

export type Application = {
  id: string
  name: string
}

export type OrgInfo = {
  orgName: string
  subscriptions: Subscription[]
  appFeatures: AppFeature[]
}

let globalAccessToken: string
export function AccessToken(): string {
  return globalAccessToken
}

export function OrgName(): string {
  return orgInfo?.orgName
}

export function Subscriptions(): Subscription[] {
  return orgInfo?.subscriptions
}

export function FeatureEnabled(featureName: string): boolean {
  return orgInfo && orgInfo.appFeatures!.findIndex(e => e.name === featureName && e.isEnabled) > -1
}

export function orgHasTopics(licensing: any) {
  return licensing?.data.assets.some((a: { name: string }) => topicAssets.includes(a.name))
}

export function orgIsCompassOnly(licensing: any) {
  return !licensing?.data.assets.some((a: { name: string }) => articleAssets.includes(a.name))
}

const customAuthHandler = async (state: any) => {
  await state.loginWithRedirect({
    authorizationParams: {
      redirect_uri: CallbackConfig.frontDoorUri,
    },
  })
}

export type User = {
  created: string
  email: string
  givenName: string
  identityProviderId: string
  lastLogin: string
  familyName: string
  name: string
  organizationId: string
  referenceId: string
  roles: []
  status: string
  sub: string
}

export type AuthState = {
  token: any
  organizationId: string
  subscriptionId: string
  accessToken: string
  permissions: any
  loading: boolean
  user: any
  managementRoles: Array<string> | null
  features: Array<Feature>
  licensing: { data: any } | null
  orgRole?: string
  adminOrgs?: Array<AdminOrgsSub>
  organizationAlias: string
  hasError: boolean
  orgInfo?: OrgInfo
  customAuthHandler?: (state: any) => void
  ecode: string
}

export const initialState: AuthState = {
  token: '',
  organizationId: '',
  subscriptionId: '',
  accessToken: '',
  permissions: null,
  loading: true,
  user: null,
  managementRoles: [],
  features: [],
  licensing: null,
  orgRole: '',
  orgInfo: undefined,
  organizationAlias: '',
  hasError: false,
  customAuthHandler: customAuthHandler,
  ecode: '',
}

const AuthContext = createContext<{
  auth0State: AuthState
  auth0StateDispatch: React.Dispatch<any>
}>({ auth0State: initialState, auth0StateDispatch: () => null })

export enum AuthStateAction {
  UPDATEAUTH,
  SETHASERROR,
  SETORGINFO,
  SETLICENSING,
  SETECODE,
}
function reducer(state: AuthState, action: any): AuthState {
  switch (action.type) {
    case AuthStateAction.UPDATEAUTH:
      const { user, jwt, idTokenClaims } = action.data
      const token = idTokenClaims.__raw
      globalAccessToken = jwt
      if (token && token !== state.token && jwt !== state.accessToken) {
        let roles = TokenParser.getManagementRolesFromToken(jwt)
        let features = PermissionManager.GetFeaturesFromRoles(roles)
        return {
          ...state,
          token: token,
          organizationId: TokenParser.getOrgIdFromToken(jwt),
          subscriptionId: TokenParser.getSubscriptionIdFromToken(jwt),
          accessToken: jwt, // Added, previously when destructuring returned null
          permissions: TokenParser.parseJwt(jwt),
          loading: false,
          user: user,
          managementRoles: TokenParser.getManagementRolesFromToken(jwt),
          features: features,
          orgRole: TokenParser.getOrgRoleFromToken(jwt),
          adminOrgs: TokenParser.getAdminOrgsFromToken(jwt),
          organizationAlias: TokenParser.getOrganizationAlias(jwt),
          customAuthHandler,
        }
      }
      return state
    case AuthStateAction.SETORGINFO:
      orgInfo = action.orgInfo
      return { ...state, orgInfo: action.orgInfo }
    case AuthStateAction.SETHASERROR:
      return { ...state, hasError: action.hasError }
    case AuthStateAction.SETLICENSING:
      return { ...state, licensing: action.licensing }
    case AuthStateAction.SETECODE:
      return { ...state, ecode: action.ecode }
    default:
      return state
  }
}

export const Auth0StateProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  Auth0StateManager.Auth0StateDispatch = dispatch

  useEffect(() => {
    if (state.accessToken && state.organizationId) {
      getOrgName(state.organizationId, state.accessToken, dispatch)
        .then(orgInfo => dispatch({ type: AuthStateAction.SETORGINFO, orgInfo }))
        .catch(() => dispatch({ type: AuthStateAction.SETHASERROR, hasError: true }))
    }
  }, [state.accessToken, state.organizationId])

  useEffect(() => {
    if (state.orgInfo?.subscriptions && state.organizationId) {
      var compassSub = GetCompassSubscription(state.orgInfo?.subscriptions)
      if (compassSub.length > 0) {
        //getting ecode for org and compass subscription
        GetECode(compassSub[0].id, state.organizationId)
          .then(code => dispatch({ type: AuthStateAction.SETECODE, ecode: code }))
          .catch(() => dispatch({ type: AuthStateAction.SETHASERROR, hasError: true }))
      }
    }
    // eslint-disable-next-line
  }, [state.orgInfo, state.organizationId])

  useEffect(() => {
    if (state.accessToken && !state.licensing) {
      getLicensing(state.accessToken).then(licensing =>
        dispatch({ type: AuthStateAction.SETLICENSING, licensing })
      )
    }
    // eslint-disable-next-line
  }, [state.accessToken])

  useEffect(() => {
    if (state.organizationAlias) {
      window.localStorage.setItem('orgAlias', state.organizationAlias)
    }
  }, [state.organizationAlias])

  return (
    <AuthContext.Provider value={{ auth0State: state, auth0StateDispatch: dispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuthState() {
  const { auth0State, auth0StateDispatch } = useContext(AuthContext)
  return { authState: auth0State, auth0StateDispatch, Auth0StateManager }
}

export class Auth0StateManager {
  public static Auth0StateDispatch: React.Dispatch<any>

  public static UpdateAuth(user: any, jwt: string, idTokenClaims: any) {
    this.Auth0StateDispatch({
      type: AuthStateAction.UPDATEAUTH,
      data: { user, jwt, idTokenClaims },
    })
  }
}

export function HasFeature() {}
