import { useEffect, useState } from 'react'

import styles from './FileDetails.module.scss'

import { ImportFile } from 'Store/ImportState'
import FileDetailsForm from './FileDetailsForm'
import { buildLangDropdownObjects } from 'Api/Algolia.api'

export type FileDetailsProps = {
  submitAttempted: boolean
  fileDetails: ImportFile[]
  noUploadFiles: boolean
  setFileDetails: (e: ImportFile[]) => void
}

export default function FileDetails({
  submitAttempted,
  fileDetails,
  noUploadFiles,
  setFileDetails,
}: FileDetailsProps) {
  const [revalidateIds, setRevalidateIds] = useState(false)
  const [languages, setLanguages] = useState<any[]>([])

  useEffect(() => {
    if (languages?.length === 0)
      buildLangDropdownObjects().then(data => {
        setLanguages(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function fileAlreadyInUpload(fileKey: number, hwid: string, localization: string): boolean {
    return (
      fileDetails.filter(
        (f: ImportFile) =>
          f.fileKey !== fileKey &&
          f.hwid.toLowerCase() === hwid.toLocaleLowerCase() &&
          f.localization.toLowerCase() === localization.toLowerCase() &&
          !f.removedFromUpload
      ).length > 0
    )
  }

  return (
    <div className={styles.wrapper}>
      <h2>Provide Details</h2>
      <p>Add details to your files to increase findability. You can add details manually below.</p>
      <div className={styles.fileDetailsContainer}>
        {fileDetails
          .sort((a: ImportFile, b: ImportFile) => (a.fileKey > b.fileKey ? 1 : -1))
          .map((file: any) => {
            return (
              <FileDetailsForm
                key={file.fileKey}
                file={file}
                fileDetails={fileDetails}
                setFileDetails={setFileDetails}
                submitAttempted={submitAttempted}
                fileAlreadyInUpload={fileAlreadyInUpload}
                revalidateIds={revalidateIds}
                setRevalidateIds={setRevalidateIds}
                languages={languages}
              />
            )
          })}
      </div>
      {noUploadFiles && (
        <div className={styles.returnMsg}>
          You will be returned to the previous page to add content.
        </div>
      )}
    </div>
  )
}
