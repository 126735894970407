import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useGlobalNotificationState } from 'Store/GlobalNotificationState'
import styles from './SnackbarNotification.module.scss'
import { Button } from '@mui/material'

export default function SnackbarNotification() {
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()

  function handleCloseSnackbar() {
    GlobalNotificationStateManager.HideSnackbarNotification()
  }

  var action = <></>
  if (globalNotificationState.snackbarInfo.showClose) {
    action = (
      <Button
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '20px',
          textDecorationLine: 'underline',
          textTransform: 'uppercase',
          color: '#ffffff',
          '&:hover': {
            textDecorationLine: 'underline',
          },
        }}
        variant='text'
        onClick={handleCloseSnackbar}
      >
        Close
      </Button>
    )
  }

  return (
    <Snackbar
      open={globalNotificationState.snackbarInfo.visible}
      autoHideDuration={globalNotificationState.snackbarInfo.duration}
      onClose={handleCloseSnackbar}
      className={styles.snackBar}
    >
      <MuiAlert
        sx={{
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '16px',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#FFFFFF',
          minWidth: '344px',
          '&.MuiAlert-filledSuccess': {
            backgroundColor: '#00856A',
          },
          '&.MuiAlert-filledError': {
            backgroundColor: '#DD372F',
          },
        }}
        action={action}
        icon={false}
        severity={globalNotificationState.snackbarInfo.severity}
        variant='filled'
        onClose={handleCloseSnackbar}
      >
        {globalNotificationState.snackbarInfo.message}
      </MuiAlert>
    </Snackbar>
  )
}
