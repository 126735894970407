import classNames from 'classnames'
import { ReactNode } from 'react'
import { Button, ButtonProps } from '@mui/material'

import style from './CmxButton.module.scss'

export interface CmxButtonProps extends ButtonProps {
  'data-qa'?: string
  'data-testid'?: string
  children?: ReactNode
  className?: string
  primary?: boolean
  mode?: 'grayscale'
}

export default function CmxButton({
  'data-qa': dataQa,
  'data-testid': dataTestId,
  children,
  className,
  primary,
  mode,
  ...props
}: CmxButtonProps & ButtonProps) {
  return (
    <Button
      {...props}
      className={classNames(
        style.cmxButton,
        primary ? style.primary : style.secondary,
        props.variant === 'text' && style.text,
        mode === 'grayscale' && style.grayscale,
        className
      )}
      data-qa={dataQa}
      data-testid={dataTestId}
      disableFocusRipple
    >
      {children}
    </Button>
  )
}
