import { useState, useEffect } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'
import { DataGrid, DataGridProps, GridRowId } from '@mui/x-data-grid'

import styles from './ContentManagement.module.scss'
import DataGridToolbar from './DataGridToolbar'

import ContentUpload from 'Components/Import'
import ContentDelete from 'Components/DeleteModal/ContentDelete'

import { AlgoliaClient, AlgoliaState } from 'Store/AlgoliaState'
import { useImportState } from 'Store/ImportState'
import { AuthState } from 'Store/AuthState'
import useLocalStorage, { StorageKeys } from 'Store/Hooks/useLocalStorage'
import DataGridPagination from './DataGridPagination'
import { useManageStore } from 'Store/ManageState'

interface AbstractRow {
  hwid: string
  id: string
  language: string
  title: string
  updatedBy: string
}

export interface DataRow extends AbstractRow {
  availableLocalizations?: string[]
  lastUpdated: Date
  status: string
}

export interface ActivityRow extends AbstractRow {
  changeType: 'Updated' | 'Created' | 'Deleted'
  date: Date
}

interface ContentManagerTableProps {
  tableName: string
  selectedRowsStorageKey?: string
  algoliaClient?: AlgoliaClient
  algoliaState: AlgoliaState
  authState: AuthState
  checkboxSelection?: boolean
  columnDefinitions: DataGridProps['columns']
  fetchData: (
    page: number,
    pageSize: number,
    orderByColumn: string,
    orderByDir: string,
    filterString: string
  ) => Promise<any>
  buildRows: (items: any[]) => any[]
  enableContentUpload?: boolean
  isAlgoliaLoading: boolean
  isHwContent: boolean
  sortModelInit: { field: string; sort: 'asc' | 'desc' }[]
}

// Row extends AbstractRow and defaults to DataRow
export default function ContentManagerTable({
  tableName,
  selectedRowsStorageKey = 'SelectedRows',
  algoliaClient,
  algoliaState,
  authState,
  checkboxSelection,
  columnDefinitions,
  fetchData,
  buildRows,
  enableContentUpload,
  isAlgoliaLoading,
  isHwContent,
  sortModelInit,
}: ContentManagerTableProps) {
  const { refreshData, updateRefreshData } = useManageStore()
  const { importState } = useImportState()

  const [initializing, setInitializing] = useState(true)
  const [rowCount, setRowCount] = useState(0)
  const [dataRows, setDataRows] = useState<any[]>([])
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useLocalStorage(selectedRowsStorageKey, [])
  const [paginationModel, setPaginationModel] = useLocalStorage(
    StorageKeys.PaginationState + tableName,
    {
      pageSize: 10,
      page: 0,
    }
  )
  const [sortModel, setSortModel] = useLocalStorage(
    StorageKeys.SortState + tableName,
    sortModelInit
  )

  const [quickFilterValue, setQuickFilterValue] = useState('')

  useEffect(() => {
    if (initializing) getResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!initializing) getResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, sortModel, quickFilterValue])

  useEffect(() => {
    if (!initializing && refreshData) {
      getResults()
      setSelectedRows([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData])

  useEffect(() => {
    localStorage.removeItem('fileImportDetails')
  }, [importState.importOpen])

  function getResults() {
    if (!dataIsLoading) {
      setDataIsLoading(true)
      // Inhouse Content Items
      fetchData(
        paginationModel.page + 1,
        paginationModel.pageSize,
        sortModel[0].field,
        sortModel[0].sort,
        quickFilterValue
      ) //Page + 1 due to zero index
        .then(({ items, rowCount }: { items: any[]; rowCount: number }) => {
          setRowCount(rowCount)
          const rows = buildRows(items)
          setDataRows(rows)
        })
        .finally(() => {
          setDataIsLoading(false)
          setInitializing(false)
          updateRefreshData(false)
        })
    }
  }

  function selectionChanged(ids: GridRowId[]) {
    if (checkboxSelection) {
      var selectedRowsNotInView: any[] = []
      var selectedRowsInView = dataRows
        .filter(row => ids.filter(id => id === row.id)?.length === 1)
        .map(e => {
          return e as unknown as DataRow
        })

      ids.forEach(id => {
        if (selectedRowsInView.filter(row => row.id === id)?.length === 0) {
          let row = selectedRows.filter((row: any) => row.id === id)[0]
          if (row) selectedRowsNotInView.push(row)
        }
      })

      setSelectedRows([...selectedRowsNotInView, ...selectedRowsInView])
    }
  }

  function renderDataGrid() {
    if (!algoliaClient?.appId || !algoliaState.index || isAlgoliaLoading || dataIsLoading)
      return (
        <Grid container direction='row' item justifyContent='center'>
          <CircularProgress color='primary' className='loader' />
        </Grid>
      )

    return (
      <DataGrid
        sx={{
          backgroundColor: '#F9F9F9',
          fontSize: '16px',
          color: '#424242',
          border: 'none',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.078)',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '700',
          },
          '& .MuiDataGrid-columnSeparator--sideRight': {
            display: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: '2px dotted #424242',
            outlineOffset: '-2px',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-visible': {
            outline: '2px dotted #424242',
            outlineOffset: '-4px',
          },
          '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
            borderTop: '1px solid #D8D8D8',
          },
          '&.MuiDataGrid-root .MuiDataGrid-row': {
            border: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            borderBottom: '1px solid #F4F4F4',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:has(:focus-visible)': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiCheckbox-root:focus-within': {
            backgroundColor: '#F4F4F4',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: '1px solid #F4F4F4',
          },
          '&.MuiDataGrid-root .MuiDataGrid-sortIcon': {
            color: '#898989',
            backgroundColor: 'transparent',
          },
          '&.MuiDataGrid-root button[title="Sort"]': {
            backgroundColor: 'transparent',
          },
          '&.MuiDataGrid-root .MuiTouchRipple-root': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root:has(:focus-visible)':
            {
              backgroundColor: '#d8d8d8',
            },
        }}
        checkboxSelection={checkboxSelection}
        rows={dataRows}
        rowCount={rowCount} //Hack and doesn't handle cases when
        columns={columnDefinitions}
        experimentalFeatures={{ ariaV7: true }}
        paginationModel={paginationModel}
        paginationMode='server'
        filterMode='server'
        loading={dataIsLoading}
        keepNonExistentRowsSelected
        onSortModelChange={setSortModel}
        sortModel={sortModel}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sortingOrder={['asc', 'desc']}
        disableRowSelectionOnClick
        autoHeight
        slots={{
          toolbar: DataGridToolbar,
          pagination: DataGridPagination,
        }}
        slotProps={{
          toolbar: {
            tableName: tableName,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            enableContentUpload: enableContentUpload,
            selection: selectedRows,
            isHwContent: isHwContent,
            currentSearch: quickFilterValue,
            algoliaState: algoliaState,
            authState: authState,
            quickFilterValue: quickFilterValue,
            setQuickFilterValue: setQuickFilterValue,
          },
        }}
        getRowClassName={() => styles.dataGridRow}
        hideFooterSelectedRowCount
        rowSelectionModel={selectedRows.map((e: any) => {
          return e.id
        })}
        disableColumnMenu
        onRowSelectionModelChange={newSelection => {
          if (checkboxSelection) {
            selectionChanged(newSelection)
          }
        }}
      />
    )
  }

  return (
    <Box sx={{ mb: 12 }}>
      {renderDataGrid()}

      <ContentUpload open={importState.importOpen} updateRefreshData={updateRefreshData} />

      <ContentDelete contentToDelete={selectedRows} />
    </Box>
  )
}
