import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AuthStateAction, Subscription } from 'Store/AuthState'

const ORGUTIL_URL = `${AppSettings.CMXServiceUrl}/api/orginfo/`
const LICENSING_URL = `${AppSettings.PlatformUrl}/provisioning/v2/assets/subscription`

export function GetNonManagementSubs(subscriptions: Subscription[]): Subscription[] {
  return subscriptions.filter(sub => {
    return (
      sub.applications.filter(app => {
        return app.name.toLowerCase() === 'cmx'
      }).length === 0
    )
  })
}

export function GetCompassSubscription(subscriptions: Subscription[]): Subscription[] {
  return subscriptions.filter(sub => {
    return (
      sub.applications.filter(app => {
        return app.id === AppSettings.CompassAppId
      }).length !== 0
    )
  })
}

export const getOrgName = async (
  orgId: string,
  accessToken: string,
  authStateDispatch: any
): Promise<string> => {
  var orgName = ''
  let res = await OrgNameGET(orgId, accessToken, authStateDispatch)
  if (res) {
    orgName = await res
    authStateDispatch({ type: AuthStateAction.SETHASERROR, hasError: false })
  } else {
    authStateDispatch({ type: AuthStateAction.SETHASERROR, hasError: true })
    throw new Error('Unable to retreive organization name')
  }
  return orgName
}

export const OrgNameGET = async (
  orgId: string,
  accessToken: string,
  authStateDispatch: any
): Promise<any> => {
  return await fetch(ORGUTIL_URL + orgId, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })
    .then(res => {
      return res.json()
    })
    .then(data => {
      var orgName = data.value
      authStateDispatch({ type: AuthStateAction.SETHASERROR, hasError: false })
      return orgName
    })
    .catch(() => {
      authStateDispatch({ type: AuthStateAction.SETHASERROR, hasError: true })
      throw new Error('Unable to retreive organization name')
    })
}

export const getLicensing = (accessToken: string) => {
  return fetch(LICENSING_URL, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })
    .then(response => response.json())
    .then(responseData => {
      return responseData
    })
    .catch(error => console.warn(error))
}
