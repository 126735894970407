import { CircularProgress } from '@mui/material'
import { fetchFormattedContent } from 'Api/Content.api'
import { GetInHouseContent } from 'Api/Disco.api'
import AppSettings, { Environment } from 'ApplicationSettings/ApplicationSettings'
import { AccessToken } from 'Store/AuthState'
import { ContentType, GetContentType } from 'Store/Models/ContentItem'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import style from './ContentViewer.module.scss'

export default function ContentViewer({
  hwid,
  localization,
  pdfUrl,
  limitHeight = true,
  isTopic = false,
}: {
  hwid: string
  localization: string
  pdfUrl?: string
  limitHeight?: boolean
  isTopic?: boolean
}) {
  const [htmlContent, setHtmlContent] = useState('')
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState('')
  const [loadingPreview, setLoadingPreview] = useState(!isTopic)

  const IsHealthwiseDocument = useCallback(() => {
    return GetContentType(hwid) === ContentType.Healthwise
  }, [hwid])

  useEffect(() => {
    if (hwid !== '' && localization !== '') {
      if (IsHealthwiseDocument()) {
        if (!isTopic) {
          fetchFormattedContent(hwid, localization)
            .then(content => {
              if (content) {
                setHtmlContent(content)
              } else {
                setHtmlContent('Unable to Load Preview')
              }
            })
            .catch(function () {
              setHtmlContent('Unable to Load Preview')
            })
            .finally(() => {
              setLoadingPreview(false)
            })
        }
      } else {
        if (!pdfUrl) {
          GetInHouseContent(hwid, localization).then(url => {
            setLoadingPreview(false)
            setGeneratedPdfUrl(url)
          })
        } else {
          setGeneratedPdfUrl(pdfUrl)
          setLoadingPreview(false)
        }
      }
    } else {
      //Reset is necessary so old content isn't displayed when a new item is previewed
      setHtmlContent('')
      setGeneratedPdfUrl('')
      setLoadingPreview(true)
    }
  }, [IsHealthwiseDocument, hwid, isTopic, localization, pdfUrl])

  return (() => {
    if (loadingPreview) return <CircularProgress color='primary' />
    if (isTopic) return <TopicPreview hwid={hwid} localization={localization} />
    if (IsHealthwiseDocument())
      return <HtmlPreview previewContent={htmlContent} limitHeight={limitHeight} />
    if (hwid !== '' && localization !== '')
      return <PdfPreview pdfUrl={generatedPdfUrl} limitHeight={limitHeight} />
    return <div>No preview source provided</div>
  })()
}

function TopicPreview({ hwid, localization }: { hwid: string; localization: string }) {
  const webComponentEnvironment = (() => {
    switch (AppSettings.Environment) {
      case Environment.localdev:
        return 'dev'
      case Environment.development:
        return 'dev'
      case Environment.test:
        return 'test'
      default:
        return 'prod'
    }
  })()

  return (
    <div className={style.htmlArticleContent}>
      {
        <hw-content
          loc={localization}
          bearerToken={AccessToken()}
          hwid={hwid}
          environment={webComponentEnvironment}
          hideErrors
          useDefaultStyle
          showTitle
        />
      }
    </div>
  )
}

function HtmlPreview({ previewContent }: { previewContent: any; limitHeight: boolean }) {
  const iFrame = useRef<any>()
  const observer = useMemo(() => new ResizeObserver(resizeIframe), [])

  function resizeIframe() {
    iFrame.current.style.height = iFrame.current.contentWindow.document.body.scrollHeight + 'px'
  }

  function onLoad(e: any) {
    // Causes the scroll-to-top button to no longer display (cannot be done in service because #HwScrollToTopWrapper isn't in the initial HTML)
    const hwScrollToTopWrapper =
      iFrame.current.contentWindow.document.getElementById('HwScrollToTopWrapper')
    if (hwScrollToTopWrapper) hwScrollToTopWrapper.style.display = 'none'

    // Sets up a ResizeObserver which resizes the iframe any time it's content height changes.
    observer.observe(e.target.contentWindow.document.body)
    resizeIframe()
  }

  // ResizeObserver error explanation: https://stackoverflow.com/a/76163164
  useEffect(() => {
    function resizeObserverErrorCatcher(errorEvent: ErrorEvent) {
      if (errorEvent.message !== 'ResizeObserver loop limit exceeded') return null

      const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
      if (resizeObserverErrDiv) resizeObserverErrDiv.setAttribute('style', 'display: none')

      const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
      if (resizeObserverErr) resizeObserverErr.setAttribute('style', 'display: none')
    }

    window.addEventListener('error', resizeObserverErrorCatcher)

    return function cleanup() {
      observer.disconnect()
      window.removeEventListener('error', resizeObserverErrorCatcher)
    }
  })

  return (
    <div className={style.htmlArticleContent}>
      <iframe
        ref={iFrame}
        srcDoc={previewContent}
        referrerPolicy='origin-when-cross-origin'
        className={style.iFrame}
        onLoad={onLoad}
        title='name'
        scrolling='no'
      />
    </div>
  )
}

function PdfPreview({ pdfUrl, limitHeight }: { pdfUrl: string; limitHeight: boolean }) {
  return (
    <iframe
      className={`${style.pdfArticleContent} ${limitHeight && style.articleContentLimitHeight}`}
      src={`${pdfUrl}#view=FitH`}
      height={limitHeight ? '' : '1223'}
      scrolling='no'
      title='preview'
    />
  )
}
