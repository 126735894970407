import { MAIN_CONTENT_ID } from 'common.util'
import { KeyboardEvent, MouseEvent } from 'react'
import { Link, useHref } from 'react-router-dom'
import './SkipToContentLink.scss'

export default function SkipToContentLink() {
  const to = useHref(MAIN_CONTENT_ID)

  function goToContent() {
    document.getElementById(MAIN_CONTENT_ID)?.focus()
    window.history.pushState({}, '', window.location.pathname + MAIN_CONTENT_ID)
  }

  function onClick(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault()
    goToContent()
  }

  function onKeyDown(event: KeyboardEvent<HTMLAnchorElement>) {
    if (event.key !== 'Enter') return null
    goToContent()
  }

  return (
    <div className='SkipToContentLinkBar'>
      <Link {...{ onClick, onKeyDown, to }} className='SkipToContentLink' tabIndex={0}>
        Skip to main content
      </Link>
    </div>
  )
}
