import { Icon, Modal } from '@mui/material'
import statusErrorLogo from 'Resources/icon-status-error.svg'
import CmxButton from 'Components/CmxButton'
import style from './PublishModal.module.scss'
import { useManageStore } from 'Store/ManageState'
import { useEffect, useState } from 'react'
import { GlobalNotificationStateManager, SnackbarSeverity } from 'Store/GlobalNotificationState'
import { PublishItems, SaveMetadataChanges } from 'Api/Content.api'
import { MakeShortCode } from 'Utilities/LocaleMapUtil'
import { StorageKeys } from 'Store/Hooks/useLocalStorage'

export enum PublishAction {
  SaveAsDraft = 'SaveAsDraft',
  PublishAnyway = 'PublishAnyway',
}

export default function PublishModal() {
  const {
    contentItems,
    fromManage,
    hasEnglish,
    isBulk,
    publishItems,
    publishOpen,
    updateRefreshContentItem,
    updateRefreshData,
    updatePublishAction,
    updatePublishOpen,
  } = useManageStore()

  const [singleItemTitle, setSingleItemTitle] = useState('')
  const nextBatchItems: any[] = JSON.parse(localStorage.getItem(StorageKeys.NextBatchItems) || '[]')

  useEffect(() => {
    if (!isBulk && publishItems.length === 1) {
      setSingleItemTitle(publishItems[0].title)
    }
  }, [singleItemTitle, isBulk, publishItems, hasEnglish])

  //Happens for non english content on EditMetadataPage and for single publish
  //item from Manage table when no English variant is published
  function onPublishAnyway() {
    let publishedItem = publishItems.map((e: { hwid: any; title: any; localization: any }) => {
      return { hwid: e.hwid, title: e.title, localization: e.localization }
    })
    updatePublishOpen(false)
    updatePublishAction(PublishAction.PublishAnyway)
    GlobalNotificationStateManager.SetLoadingModalMessage(
      'Publishing title',
      'This may take a moment...',
      true
    )
    //will always be a single item being published from here
    if (fromManage) {
      //need to save first to pick up subscriptions
      SaveMetadataChanges(contentItems)
        .then(() => PublishItems(publishedItem))
        .then(response => {
          if (!response.hasError) {
            GlobalNotificationStateManager.SetLoadingModalError('')
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `${publishItems[0].title} published successfully. Title may take up to 1 minute to appear in search results.`,
              SnackbarSeverity.Success,
              false,
              1000
            )
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
          } else {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              'Error publishing title. Please try again or contact your Healthwise account manager.',
              SnackbarSeverity.Error,
              false,
              1000
            )
          }
        })
        .catch(() => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error publishing title. Please try again or contact your Healthwise account manager.',
            SnackbarSeverity.Error,
            false,
            1000
          )
        })
        .finally(() => {
          var selectedItemsKey = contentItems[0].hwid.startsWith('inhouse.')
            ? StorageKeys.SelectedInhouseRows
            : StorageKeys.SelectedHealthwiseRows
          localStorage.removeItem(selectedItemsKey)
          updatePublishOpen(false)
          updateRefreshData(true)
        })
    } else {
      //saving before publishing from EditMetadata page
      updatePublishOpen(false)
      GlobalNotificationStateManager.SetLoadingModalMessage(
        'Saving Changes',
        'Your changes are being saved.<br>This may take a moment...',
        true
      )
      if (contentItems) {
        //we'll only have one content item coming from Edit Metadata page
        SaveMetadataChanges(contentItems)
          .then(() => PublishItems(publishItems))
          .then(async () => {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `${contentItems[0].title} published successfully. Title may take up to 1 minute to appear in search results.`,
              SnackbarSeverity.Success,
              false,
              10000
            )
          })
          .catch(() => {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              'There was an error publishing. Please try again or contact your Healthwise Account Manager if the issue persists.',
              SnackbarSeverity.Error,
              false,
              10000
            )
          })
          .finally(() => {
            //refresh content item if on edit metadatapage
            updateRefreshContentItem(true)
            updateRefreshData(true)
            GlobalNotificationStateManager.SetLoadingModalMessage('', '', false)
          })
      }
    }
  }

  //Happens when Keep as Draft for non english content on EditMetadataPage and
  //for single publish item from Manage table when no English variant is published
  function onSaveAsDraft() {
    //from ManageTable page, just close modal
    if (fromManage) {
      updatePublishOpen(false)
      //from edit metadata page, save as draft
    } else {
      updatePublishOpen(false)
      GlobalNotificationStateManager.SetLoadingModalMessage(
        isBulk ? 'Saving titles' : 'Saving title',
        'This may take a moment...',
        true
      )
      if (contentItems) {
        SaveMetadataChanges(contentItems)
          .then(async () => {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `Changes to ${contentItems[0].title} saved successfully.`,
              SnackbarSeverity.Success,
              false,
              10000
            )
            //refresh content item if on edit metadatapage
            if (!fromManage) {
              updateRefreshContentItem(true)
            }
          })
          .catch(() => {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `There was an error saving changes. Please try again or contact your Healthwise Account Manager if the issue persists.`,
              SnackbarSeverity.Error,
              false,
              10000
            )
          })
          .finally(() => {
            GlobalNotificationStateManager.SetLoadingModalMessage('', '', false)
          })
      }
    }
  }

  function onCancel() {
    updatePublishOpen(false)
  }

  async function onConfirm() {
    //only happens from Manage table
    updatePublishOpen(false)
    GlobalNotificationStateManager.SetLoadingModalMessage(
      isBulk ? 'Publishing titles' : 'Publishing title',
      'This may take a moment...',
      true
    )
    //save before publishing
    if (contentItems) {
      SaveMetadataChanges(contentItems)
        .then(() => PublishItems(publishItems))
        .then(response => {
          if (!response.hasError) {
            var successMsg = ''
            if (isBulk) {
              successMsg =
                response.successCount +
                ' titles published successfully. Titles may take up to to 1 minute to appear in search results.'
            } else {
              successMsg = `${contentItems[0].title} published successfully. Title may take up to to 1 minute to appear in search results.`
            }
            GlobalNotificationStateManager.SetLoadingModalError('')
            GlobalNotificationStateManager.ShowSnackbarNotification(
              successMsg,
              SnackbarSeverity.Success,
              false
            )
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
          } else {
            if (response.errorCount > 0) {
              var errorSummary = 'The following title(s) could not be published at this time:'
              if (isBulk) {
                GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)
                GlobalNotificationStateManager.SetLoadingModalError(
                  response.errorMsg ?? 'An unknown error occured.'
                )
              } else {
                GlobalNotificationStateManager.ShowSnackbarNotification(
                  errorSummary,
                  SnackbarSeverity.Success,
                  false
                )
              }
            }
            //when Finish button is clicked from Loading modal
            if (response.successCount > 0) {
              if (isBulk) {
                successMsg =
                  response.successCount +
                  ' titles published successfully. Titles may take up to to 1 minute to appear in search results.'
              } else {
                successMsg = `${contentItems[0].title} published successfully. Title may take up to to 1 minute to appear in search results.`
              }
              GlobalNotificationStateManager.ShowSnackbarNotification(
                successMsg,
                SnackbarSeverity.Success,
                false
              )
            }
          }
        })
        .catch(() => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error publishing title(s). Please try again or contact your Healthwise administrator.',
            SnackbarSeverity.Error,
            false
          )
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
        })
        .finally(() => {
          updatePublishOpen(false)
          if (nextBatchItems?.length > 0) {
            localStorage.removeItem(StorageKeys.NextBatchItems)
          }

          var selectedItemsKey = contentItems[0].hwid.startsWith('inhouse.')
            ? StorageKeys.SelectedInhouseRows
            : StorageKeys.SelectedHealthwiseRows

          let selectedItems = JSON.parse(localStorage.getItem(selectedItemsKey) ?? '') as any[]
          if (selectedItems) {
            localStorage.setItem(
              selectedItemsKey,
              JSON.stringify(
                selectedItems.filter(
                  item => contentItems.filter(ci => ci.id === item.id).length === 0
                )
              )
            )
          }
          updateRefreshData(true)
        })
    }
  }

  return (
    <Modal open={publishOpen}>
      {!isBulk ? (
        !hasEnglish ? (
          <div className={style.modalContent}>
            <div className={style.title}>Waiting for English Version</div>
            <div className={style.warningMessage}>
              <Icon>
                <img alt='No English Version Warning' src={statusErrorLogo} />
              </Icon>
              An English version of "{singleItemTitle}" has not been Published yet.
            </div>
            <div className={style.text}>
              In order for this title to show in search results and be assigned, an English version
              must be Published.
            </div>
            <div className={style.controls}>
              <CmxButton onClick={onPublishAnyway} data-qa='modalCancelButton'>
                Publish Anyway
              </CmxButton>
              <CmxButton primary onClick={onSaveAsDraft} data-qa='modalConfirmButton'>
                {fromManage ? 'Keep as Draft' : 'Save as Draft'}
              </CmxButton>
            </div>
          </div>
        ) : (
          <div className={style.modalContent}>
            <div className={style.title}>Publish title?</div>
            <div className={style.boldtext}>
              {singleItemTitle} will be available to search or assign.
            </div>
            <div className={style.text}>
              Adding health concepts, keywords and codes to your content before publishing is highly
              recommended to help you search for your content.
            </div>
            <div className={style.controls}>
              <CmxButton onClick={onCancel} data-qa='modalCancelButton'>
                Cancel
              </CmxButton>
              <CmxButton primary onClick={onConfirm} data-qa='modalConfirmButton'>
                Publish
              </CmxButton>
            </div>
          </div>
        )
      ) : (
        <div className={style.modalContent}>
          <div className={style.title}>Publish {publishItems.length} titles?</div>
          {nextBatchItems?.length > 0 && (
            <div className={style.warningMessage}>
              <Icon>
                <img alt='You may publish up to 25 titles at a time' src={statusErrorLogo} />
              </Icon>
              You may publish up to 25 titles at a time
            </div>
          )}
          <div className={style.boldtext}>
            The following titles will be available to search or assign.
          </div>
          <div className={style.publishListContainer}>
            {publishItems.reverse().map((item: any) => (
              <p key={item.title}>
                {item.title} ({MakeShortCode(item.localization)})
              </p>
            ))}
          </div>
          <div className={style.text}>
            Adding health concepts, keywords and codes to your content before publishing is highly
            recommended to help you search for your content.
          </div>
          <div className={style.controls}>
            <CmxButton onClick={onCancel} data-qa='modalCancelButton'>
              Cancel
            </CmxButton>
            <CmxButton primary onClick={onConfirm} data-qa='modalConfirmButton'>
              Publish
            </CmxButton>
          </div>
        </div>
      )}
    </Modal>
  )
}
