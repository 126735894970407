import DOMPurify from 'dompurify'
import { Hit } from '@algolia/client-search'

export type ConceptAutocompleteItem = Hit<{
  label: string
  objectID: string
}>

type ConceptHighlightProps = {
  hit: ConceptAutocompleteItem
}

export default function ConceptHighlight({ hit }: ConceptHighlightProps) {
  return hit && hit._highlightResult && hit._highlightResult.label ? (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(hit?._highlightResult?.label?.value),
      }}
      key={hit.objectID}
    />
  ) : (
    <></>
  )
}
