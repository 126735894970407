export const getTitleFromItem = item => {
  return item?._highlightResult?.content?.clinicalTitle?.value || item?.content?.title || ''
}

export const getImageFromItem = (item, includeSocialImage = false) => {
  const image = item?.image
  if (image && (!image.includes('images/logo_social.png') || includeSocialImage)) {
    return image
  }
  return ''
}

export const getNewUniqueId = () => {
  return 'HW' + `${new Date().valueOf()}`.substring(5) + getNewDashlessGuid()
}

export const getNewDashlessGuid = () => {
  return getNewGuid().replace(/-/g, '')
}

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const getNewGuid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${S4()}${S4()}-${S4()}-4${S4().substr(0, 3)}-${S4()}-${S4()}${S4()}${S4()}`.toLowerCase()
}

export const stripHtmlTags = dirty => {
  const clean = `${dirty || ''}`.replace(/<\/?[^>]+(>|$)/g, '')
  return clean
}

export const getContentTypeFromItem = item => {
  const contentType = item?.content?.type
  if (contentType === 'aftervisit') {
    return 'After Visit'
  }
  if (contentType === 'anatomysketch') {
    return 'Anatomy Sketch'
  }
  if (contentType === 'cdc') {
    return 'CDC'
  }
  if (contentType === 'lesson') {
    return 'Lesson'
  }
  if (contentType === 'multum') {
    return 'Multum'
  }
  if (contentType === 'pi-post-op') {
    return 'Post-op'
  }
  if (contentType === 'pi-pre-op') {
    return 'Pre-op'
  }
  if (contentType === 'visuallist') {
    return 'Visual List'
  }
  if (contentType === 'video') {
    return 'Video'
  }
  return ''
}

export const getContentTypeIconFromItem = item => {
  const contentType = item?.content?.type
  if (contentType === 'video') {
    return (
      <span aria-label={'Video'} title={'Video'}>
        {/* <VideoPlay /> */}
      </span>
    )
  }
  const image = getImageFromItem(item)
  if (image) {
    return (
      <span aria-label={'Image'} title={'Image'}>
        {/* <Image /> */}
      </span>
    )
  }
  return ''
}
