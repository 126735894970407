import { FC, useState } from 'react'
import { Tooltip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import classNames from 'classnames'

import styles from './OverwriteTooltip.module.scss'

interface Props {
  children: JSX.Element
  message: string
  className?: string
}

const OverwriteTooltip: FC<Props> = ({ children, message, className }) => {
  const [openTooltip, setOpenTooltip] = useState(false)

  return (
    <div
      data-testid='overwrite-wrapper'
      className={classNames(styles.wrapper, className)}
      onMouseEnter={() => {
        setOpenTooltip(true)
      }}
      onMouseLeave={() => {
        setOpenTooltip(false)
      }}
    >
      <Tooltip
        classes={{
          tooltip: styles.tooltip,
        }}
        title={<div className={styles.message}>{message}</div>}
        placement='top-start'
        arrow
        disableInteractive
        open={openTooltip}
      >
        <ErrorIcon className={styles.errorIcon}></ErrorIcon>
      </Tooltip>
      {children}
    </div>
  )
}

export default OverwriteTooltip
