import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  RadioGroupProps,
  SelectProps,
} from '@mui/material'

import CmxButton, { CmxButtonProps } from 'Components/CmxButton'

import { AgeRangeMax, ContentItemMetadata, Gender } from 'Store/Models/ContentItem'

import { AgeRangeAll, AgeRangeCustom } from './constants'
import GenderRadioGroup from './GenderRadioGroup'
import style from './MetadataEdit.module.scss'
import CmxTextField from 'Components/CmxTextField/CmxTextField'

interface DemographicsProps {
  editable: ContentItemMetadata['editable']
  gender: Gender
  hwid: ContentItemMetadata['hwid']
  maxAge: number
  minAge: number
  onAgeRangeChange: RadioGroupProps['onChange']
  onAgeRangeHighChange: SelectProps<number>['onChange']
  onAgeRangeLowChange: SelectProps<number>['onChange']
  onAgeRangeReset: CmxButtonProps['onClick']
  onGenderRadioChange: RadioGroupProps['onChange']
  selectedAgeRange: string
}

export default function Demographics({
  editable,
  gender,
  hwid,
  maxAge,
  minAge,
  onAgeRangeChange,
  onAgeRangeHighChange,
  onAgeRangeLowChange,
  onAgeRangeReset,
  onGenderRadioChange,
  selectedAgeRange,
}: DemographicsProps) {
  return (
    <>
      <Grid item xs={12}>
        <h2 className={style.metadataEditSectionTitle}>Demographics</h2>
      </Grid>

      <Grid className={style.metadataEditField} container direction='column' item>
        <Grid container direction='row' item spacing={2}>
          <Grid item xs={6}>
            <Grid container direction='column' item spacing={2}>
              <Grid item xs={6}>
                <label className={style.metadataEditFieldLabel} id='radio-buttons-age-group-label'>
                  Age Range
                </label>

                <AgeRangeRadioGroup
                  {...{ editable, maxAge, minAge, selectedAgeRange }}
                  onChange={onAgeRangeChange}
                />
              </Grid>

              {selectedAgeRange !== AgeRangeAll && editable && (
                <Grid alignItems='center' container direction='row' item spacing='7px'>
                  <Grid item>
                    <CmxTextField
                      className={style.ageDropDown}
                      data-testid='LowAge'
                      SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: { className: 'lowAgeMenu' },
                      }}
                      onChange={onAgeRangeLowChange as any}
                      value={minAge}
                      select
                    >
                      {Array.from({ length: maxAge }, (_, i) => i).map((_, index: number) => (
                        <MenuItem
                          data-testid='content-item-status'
                          key={index}
                          role='option'
                          value={index}
                        >
                          {index}
                        </MenuItem>
                      ))}
                    </CmxTextField>
                  </Grid>

                  <Grid item>to</Grid>

                  <Grid item>
                    <CmxTextField
                      className={style.ageDropDown}
                      data-testid='HighAge'
                      disabled={selectedAgeRange === AgeRangeAll}
                      SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: { className: 'highAgeMenu' },
                      }}
                      onChange={onAgeRangeHighChange as any}
                      value={maxAge}
                      select
                    >
                      {[...Array(AgeRangeMax + 1).keys()]
                        .filter((value: number) => value > minAge)
                        .map((value: number) => (
                          <MenuItem
                            {...{ value }}
                            data-testid='content-item-status'
                            key={value}
                            role='option'
                          >
                            {value}
                          </MenuItem>
                        ))}
                    </CmxTextField>
                  </Grid>

                  <Grid item>
                    <CmxButton
                      className={style.ageResetButton}
                      disabled={selectedAgeRange === AgeRangeAll}
                      onClick={onAgeRangeReset}
                      variant='text'
                    >
                      Reset
                    </CmxButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <GenderRadioGroup {...{ gender, hwid, onGenderRadioChange }} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

interface AgeRangeRadioGroupProps {
  editable: ContentItemMetadata['editable']
  maxAge: number
  minAge: number
  onChange: RadioGroupProps['onChange']
  selectedAgeRange: string
}

function AgeRangeRadioGroup({
  editable,
  maxAge,
  minAge,
  onChange,
  selectedAgeRange,
}: AgeRangeRadioGroupProps) {
  if (!editable)
    return (
      <span className={style.readonlyText}>
        {minAge} to {maxAge}
      </span>
    )

  return (
    <RadioGroup
      {...{ onChange }}
      aria-labelledby='radio-buttons-age-group-label'
      name='radio-buttons-group-one'
      value={selectedAgeRange}
    >
      <Grid container direction='row' item>
        <Grid item>
          <FormControlLabel
            aria-label='All Ages'
            className={style.radioText}
            control={
              <Radio
                className={style.radio}
                tabIndex={0}
                sx={{
                  '&.Mui-focusVisible': {
                    outline: '2px dotted #424242',
                    backgroundColor: '#f4f4f4',
                  },
                }}
              />
            }
            label='All Ages'
            value={AgeRangeAll}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            aria-label='Choose an age range'
            className={style.radioText}
            control={
              <Radio
                className={style.radio}
                tabIndex={0}
                sx={{
                  '&.Mui-focusVisible': {
                    outline: '2px dotted #424242',
                    backgroundColor: '#f4f4f4',
                  },
                }}
              />
            }
            label='Choose a range'
            value={AgeRangeCustom}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  )
}
