export const localeMap: { [key: string]: string } = {
  all: 'All',
  'en-us': 'English',
  'en-ca': 'English (CA)',
  'es-us': 'Spanish',
  'ar-us': 'Arabic',
  'bn-us': 'Bengali',
  'bs-us': 'Bosnian',
  'fa-us': 'Farsi',
  'fr-us': 'French',
  'ht-us': 'Haitian Creole',
  'ko-us': 'Korean',
  'ne-us': 'Nepali',
  'pl-us': 'Polish',
  'pt-us': 'Portuguese (Brazil)',
  'ru-us': 'Russian',
  'so-us': 'Somali',
  'tl-us': 'Tagalog',
  'vi-us': 'Vietnamese',
  'zh-us': 'Chinese (Simplified)',
  'hm-us': 'Hmong',
  'hy-us': 'Armenian',
  'km-us': 'Khmer',
}

export function FindLocaleCodeByTitle(title: string): string {
  for (const code in localeMap) {
    if (localeMap[code] === title) {
      return code
    }
  }
  return ''
}

export function MakeShortCode(code: string): string {
  return code.split('-')[0].toUpperCase()
}
