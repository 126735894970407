import { MouseEventHandler } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton, Modal, ModalProps } from '@mui/material'

import CmxButton, { CmxButtonProps } from 'Components/CmxButton'

import modalStyle from 'Styles/Modal.module.scss'

import style from './SaveChangesModal.module.scss'

export interface SaveChangesModalProps {
  onDiscardChangesClick: CmxButtonProps['onClick']
  onSaveClick: () => void
  open: ModalProps['open']
  setGoBack: (state: boolean) => void
  setOpen: (state: boolean) => void
}

export default function SaveChangesModal({
  onDiscardChangesClick,
  onSaveClick,
  open,
  setGoBack,
  setOpen,
}: SaveChangesModalProps) {
  const handleSaveClick: MouseEventHandler<HTMLButtonElement> = _ => {
    onSaveClick()
  }

  const handleDiscardClick: MouseEventHandler<HTMLButtonElement> = event => {
    onDiscardChangesClick?.(event)
  }

  return (
    <Modal {...{ open }}>
      <Grid
        container
        className={`${style.modalConfirm} ${modalStyle.modalContent}`}
        direction='row'
        spacing={1}
      >
        <Grid item xs={12} container justifyContent='space-between' alignItems='flex-start'>
          <Grid item className={style.modalConfirmTitle}>
            Save changes?
          </Grid>

          <Grid item>
            <IconButton
              data-qa='ClosePreview'
              className={modalStyle.modalClose}
              onClick={event => {
                event.preventDefault()
                setOpen(false)
                setGoBack(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12} className={style.modalMessage}>
          You have unsaved changes that will be lost. Do you want to save changes before leaving
          this page?
        </Grid>

        <Grid item container direction='row' xs={12} spacing={1} justifyContent='flex-end'>
          <Grid item>
            <CmxButton onClick={handleDiscardClick}>Discard Changes</CmxButton>
          </Grid>

          <Grid item>
            <CmxButton primary onClick={handleSaveClick}>
              Save
            </CmxButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
