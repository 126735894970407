import React, { FunctionComponent, useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Radio,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import classNames from 'classnames'

import styles from './SplitButton.module.scss'

export interface Option {
  label: string
  action: Function
}

export interface SplitButtonProps {
  label?: string
  options: Option[]
  disabled?: boolean
  className?: string
}

const buttonStyles = {
  '&.Mui-disabled': {
    backgroundColor: '#0d8484',
    color: '#fff',
    opacity: 0.5,
  },
}

const SplitButton: FunctionComponent<SplitButtonProps> = (props: SplitButtonProps) => {
  const { label, options, disabled, className } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleClick = () => {
    options[selectedIndex].action()
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  return (
    <div className={classNames('hw-split-button', className)}>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        disabled={disabled}
        className={classNames('hw-split-button-group', styles.group)}
      >
        <Button
          sx={buttonStyles}
          aria-label='split button action'
          onClick={handleClick}
          className={classNames('hw-split-button-label-button', styles.label)}
        >
          {label ?? options[selectedIndex].label}
        </Button>
        <Button
          sx={buttonStyles}
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          className='hw-split-button-drop-button'
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        id='split-button-popper'
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      className={styles.menuItem}
                      key={option.label}
                      // selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      <Radio
                        tabIndex={-1}
                        color='primary'
                        checked={index === selectedIndex}
                        aria-describedby={option.label}
                        aria-checked={index === selectedIndex ? 'true' : 'false'}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default SplitButton
