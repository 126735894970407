import { FC, useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'

import styles from './FileItem.module.scss'

import { ImportFile, FileUploadStatus } from 'Store/ImportState'
import OverwriteTooltip from 'Components/Import/OverwriteTooltip'
import { UploadFile } from 'Api/Import.api'
import classNames from 'classnames'

export type FileItemProps = {
  file: ImportFile
  UpdateFileInfo: (file: ImportFile) => void
}

const MAX_FILE_SIZE = 25600 // 25MB

const UPLOAD_CANCELLED_MESSAGE = 'Upload Cancelled'

function UploadStatusIcon({ uploadStatusValue }: { uploadStatusValue: FileUploadStatus }) {
  switch (uploadStatusValue) {
    case FileUploadStatus.New:
    case FileUploadStatus.InProgress:
      return (
        <CircularProgress
          data-testid='FileImportProgressSpinner'
          aria-label='File import in progress'
          data-qa='FileImportProgressSpinner'
          size='25px'
          className={styles.statusIcon}
        />
      )
    case FileUploadStatus.Complete:
      return (
        <DoneIcon
          data-testid='FileImportDoneIcon'
          aria-label='File import complete'
          data-qa='FileImportDoneIcon'
          className={`${styles.successIcon} ${styles.statusIcon}`}
        />
      )
    default:
      return <></>
  }
}

const FileItem: FC<FileItemProps> = ({ file, UpdateFileInfo }) => {
  const [uploadStatus, setUploadStatus] = useState(file.uploadStatus)
  const [fileRemoved, setFileRemoved] = useState(file.removedFromUpload)
  const [overwriteFile, setOverwriteFile] = useState(file.overwriteFile)

  const [uploadError, setUploadError] = useState('')
  const [uploadErrorLabel, setUploadErrorLabel] = useState('')
  const [rightError, setRightError] = useState(false)

  const [hwid, setHwid] = useState(file.hwid)
  const [title, setTitle] = useState(file.title)
  const [localization, setLocalization] = useState(file.localization)
  const [fileId, setFileId] = useState(file.fileId)
  const [fileName, setFileName] = useState(file.filename)
  const [fileExists, setFileExists] = useState(file.fileExists)
  const [assetId, setAssetId] = useState(file.assetId)
  const [isInfected, setIsInfected] = useState(file.isInfected)

  const fileSize = (file.size / (1024 * 1024)).toFixed(2)

  useEffect(() => {
    if (uploadStatus === FileUploadStatus.New) {
      if (file.type !== 'application/pdf') {
        setUploadError('This file type is not supported.')
        setUploadErrorLabel('File Error')
        setUploadStatus(FileUploadStatus.Error)
        return
      }

      const fileSizeKiloBytes = file.size / 1024
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setUploadError('This upload exceeds the file limit size of 25 MB per file.')
        setUploadErrorLabel('File Error')
        setUploadStatus(FileUploadStatus.Error)
        return
      }

      setUploadStatus(FileUploadStatus.InProgress)
      UploadFile(file)
        .then((data: any) => {
          if (data.isInfected) {
            setIsInfected(true)
            setUploadError('A virus was found. This file could not be uploaded.')
            setUploadErrorLabel('File Error')
            setUploadStatus(FileUploadStatus.VirusError)
            setFileRemoved(true)
          } else if (data.fileId) {
            setUploadStatus(FileUploadStatus.Complete)
            setFileId(data.fileId)
            setFileName(data.filename)
            setFileExists(data.fileExists)
            setAssetId(data?.assetId ?? '')

            if (data.fileExists && data.contentItem) {
              setHwid(data.contentItem.hwid)
              setTitle(data.contentItem.title)
              setLocalization(data.contentItem.localization.code)
            } else if (data.fileExists && !data.contentItem) {
              console.error(
                'Unexpected data returned from Kontent.AI: fileExists = true and contentItem = null',
                data
              )
              setUploadStatus(FileUploadStatus.KontentAiError)
              setUploadError('KontentAI Error: Contact Healthwise Support')
              setUploadErrorLabel('File Error')
              setRightError(true)
            }
          } else {
            setUploadStatus(FileUploadStatus.Error)
          }
        })
        .catch(error => {
          console.error('Unexpected error encountered in FileUpload', error)
          setUploadStatus(FileUploadStatus.Error)
          setUploadError('API Error: Contact Healthwise Support')
          setUploadErrorLabel('File Upload Error')
          setRightError(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    if (
      file.hwid !== hwid ||
      file.localization !== localization ||
      file.title !== title ||
      file.fileId !== fileId ||
      file.fileExists !== fileExists ||
      file.assetId !== assetId ||
      file.removedFromUpload !== fileRemoved ||
      file.overwriteFile !== overwriteFile ||
      file.uploadStatus !== uploadStatus
    ) {
      UpdateFileInfo({
        ...file,
        hwid: hwid,
        localization: localization,
        title: title,
        fileId: fileId,
        fileExists: fileExists,
        assetId: assetId,
        removedFromUpload: fileRemoved,
        overwriteFile: overwriteFile,
        uploadStatus: uploadStatus,
        isInfected: isInfected,
      })
    }
  }, [
    fileId,
    fileName,
    fileExists,
    assetId,
    overwriteFile,
    fileRemoved,
    uploadStatus,
    hwid,
    title,
    localization,
    UpdateFileInfo,
    file,
    isInfected,
  ])

  return (
    <div className={styles.wrapper}>
      <div className={styles.pdfIconBackground}>
        <DescriptionOutlinedIcon className={styles.pdfIcon}></DescriptionOutlinedIcon>
      </div>
      <div className={styles.fileDetails}>
        <div
          className={classNames(
            styles.fileName,
            uploadError === UPLOAD_CANCELLED_MESSAGE && styles.uploadCancelled
          )}
          aria-label='Filename'
        >
          {file.filename}
          {!fileRemoved && <UploadStatusIcon uploadStatusValue={uploadStatus} />}
        </div>
        <div className={styles.fileInfo}>
          {moment(file.uploadedOn).format('MMM DD, YYYY') +
            ' at ' +
            moment(file.uploadedOn).format('h:mma')}{' '}
          &#8226; {fileSize !== '0.00' ? fileSize : '0.01'}MB
        </div>
        {uploadError.length > 0 && (
          <div
            className={`${styles.errorText} ${rightError && styles.rightError}`}
            aria-label={uploadErrorLabel}
          >
            {uploadError}
          </div>
        )}
        {fileExists && !overwriteFile && !fileRemoved && (
          <OverwriteTooltip
            message='Overwriting a file replaces an existing file with the same name. Any existing details
          associated with that filename (ie: ID, title, concepts, etc) are preserved. This can
          be useful when uploading a new version of an article. If this is a mistake, press the
          X icon to cancel the upload.'
          >
            <div
              className={styles.errorText}
              data-testid='fileitem-already-exists'
              aria-label='File Error'
            >
              An item with this filename already exists. -{' '}
              <u
                onClick={() => {
                  setOverwriteFile(true)
                }}
              >
                Overwrite?
              </u>
            </div>
          </OverwriteTooltip>
        )}

        {fileExists && overwriteFile && !fileRemoved && (
          <div className={styles.fileInfoText} aria-label='Override Status'>
            This item is set to be overwritten
          </div>
        )}
      </div>
      {!fileRemoved && (
        <IconButton
          data-qa='DeleteFileItem'
          aria-label='Delete file from upload'
          data-testid='DeleteFileItem'
          className={styles.removeItem}
          onClick={() => {
            setFileRemoved(true)
            setUploadError(UPLOAD_CANCELLED_MESSAGE)
            setUploadErrorLabel(UPLOAD_CANCELLED_MESSAGE)
          }}
          sx={{
            '&.Mui-focusVisible': {
              backgroundColor: '#f4f4f4',
            },
            '&:hover': {
              backgroundColor: '#f4f4f4',
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
}

export default FileItem
