import { createContext, useContext, useReducer } from 'react'

export enum FileUploadStatus {
  New = 1,
  InProgress = 2,
  Complete = 3,
  Error = 4,
  KontentAiError = 5,
  VirusError = 6,
}

export type ImportFile = {
  fileKey: number
  filename: string
  size: number
  type: string
  file: any
  fileId: string
  assetId: string
  uploadedOn: string
  hwid: string
  title: string
  localization: string
  fileExists: boolean
  overwriteFile: boolean
  contentItemExists: boolean
  overwriteContentItem: boolean
  uploadStatus: FileUploadStatus
  unsupportedType: boolean
  overSizeLimit: boolean
  canSubmit: boolean
  finalized: boolean
  validationInProgress: boolean
  removedFromUpload: boolean
  errorCount: number
  isInfected: boolean
}

export type ImportState = {
  files: Array<ImportFile>
  submitFiles: boolean
  importOpen: boolean
  currentStep: number
  finishTriggered: boolean
  finalizeErrors: Array<string>
  uploadError: string
}

const initialState: ImportState = {
  files: [],
  submitFiles: false,
  importOpen: false,
  currentStep: 1,
  finishTriggered: false,
  finalizeErrors: [],
  uploadError: '',
}

const ImportContext = createContext<{
  importState: ImportState
  importStateDispatch: React.Dispatch<any>
}>({
  importState: initialState,
  importStateDispatch: () => null,
})

export enum ImportAction {
  RESET,
  SETSTEP,
  ADDFILE,
  SETFILESTATUS,
  UPDATEFILEUPLOADSTATUS,
  SETASSETID,
  SETFILESUBMITDETAILS,
  SETFILES,
  SETHWID,
  SETTITLE,
  SETLOCALIZATION,
  SETOVERWRITEFILE,
  SETOVERWRITECONTENTITEM,
  REMOVEFILE,
  CLEARFILES,
  SETIMPORTOPEN,
  SETUNSUPPORTEDTYPE,
  SETOVERSIZE,
  SETSUBMITFILES,
  SETFILEFINALIZED,
  ADDFINALIZEERROR,
  SETUPLOADERROR,
}

function reducer(state: ImportState, action: any): ImportState {
  switch (action.type) {
    case ImportAction.RESET:
      return initialState
    case ImportAction.SETSTEP:
      return { ...state, currentStep: action.step }
    case ImportAction.ADDFILE:
      return { ...state, files: [...state.files, action.file] }
    case ImportAction.REMOVEFILE:
      return {
        ...state,
        uploadError: '',
        files: [
          ...state.files.filter(e => e.fileKey !== action.fileKey),
          {
            ...state.files.filter(e => e.fileKey === action.fileKey)[0],
            removedFromUpload: action.remove,
          },
        ],
      }
    case ImportAction.SETFILESTATUS:
      return {
        ...state,
        files: state.files.map(e =>
          e.fileKey === action.fileKey ? { ...e, uploadStatus: action.fileUploadStatus } : e
        ),
      }
    case ImportAction.UPDATEFILEUPLOADSTATUS:
      return {
        ...state,
        files: state.files.map(e =>
          e.fileKey === action.fileKey
            ? {
                ...e,
                uploadStatus: action.fileUploadStatus,
                fileId: action.fileId,
                fileName: action.fileName,
                fileExists: action.fileExists,
                assetId: action.assetId,
              }
            : e
        ),
      }
    case ImportAction.SETASSETID:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, assetId: action.assetId } : e
        ),
      }
    case ImportAction.SETHWID:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, hwid: action.hwid } : e
        ),
      }
    case ImportAction.SETTITLE:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, title: action.title } : e
        ),
      }
    case ImportAction.SETLOCALIZATION:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, localization: action.localization } : e
        ),
      }
    case ImportAction.SETOVERWRITEFILE:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey
            ? {
                ...e,
                overwriteFile: action.overwriteFile,
              }
            : e
        ),
      }
    case ImportAction.SETOVERWRITECONTENTITEM:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey
            ? {
                ...e,
                overwriteContentItem: action.overwriteContentItem,
              }
            : e
        ),
      }
    case ImportAction.CLEARFILES:
      return { ...state, files: [] }
    case ImportAction.SETIMPORTOPEN:
      return { ...state, importOpen: action.importOpen }
    case ImportAction.SETUNSUPPORTEDTYPE:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, unsupportedType: action.unsupportedType } : e
        ),
      }
    case ImportAction.SETOVERSIZE:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, overSizeLimit: action.overSizeLimit } : e
        ),
      }

    case ImportAction.SETFILESUBMITDETAILS:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey
            ? {
                ...e,
                hwid: action.hwid,
                localization: action.localization,
                title: action.title,
                overwriteContentItem: action.overwriteRecord,
                fileRecordExists: action.fileRecordExists,
              }
            : e
        ),
      }
    case ImportAction.SETFILES:
      return { ...state, files: action.files }
    case ImportAction.SETSUBMITFILES:
      return { ...state, submitFiles: action.submitFiles }
    case ImportAction.SETFILEFINALIZED:
      return {
        ...state,
        files: state.files.map((e: ImportFile) =>
          e.fileKey === action.fileKey ? { ...e, finalized: action.finalized } : e
        ),
      }
    case ImportAction.ADDFINALIZEERROR:
      return { ...state, finalizeErrors: [...state.finalizeErrors, action.finalizeError] }
    case ImportAction.SETUPLOADERROR:
      return { ...state, uploadError: action.uploadError }
    default:
      return state
  }
}

export const ImportStateProvider = (props: any) => {
  const [importState, importStateDispatch] = useReducer(reducer, initialState)
  ImportStateManager.importStateDispatch = importStateDispatch
  return (
    <ImportContext.Provider value={{ importState, importStateDispatch }}>
      {props.children}
    </ImportContext.Provider>
  )
}

export function useImportState() {
  const { importState, importStateDispatch } = useContext(ImportContext)
  return { importState, importStateDispatch, ImportStateManager }
}

export class ImportStateManager {
  public static importStateDispatch: React.Dispatch<any>

  public static SetStep(step: number) {
    this.importStateDispatch({ type: ImportAction.SETSTEP, step })
  }
  public static AddFile(file: ImportFile) {
    this.importStateDispatch({ type: ImportAction.ADDFILE, file })
  }
  public static SetRemoveFile(fileKey: number, remove: boolean) {
    this.importStateDispatch({ type: ImportAction.REMOVEFILE, fileKey, remove })
  }
  public static SetFileUploadStatus(fileKey: number, fileUploadStatus: FileUploadStatus) {
    this.importStateDispatch({ type: ImportAction.SETFILESTATUS, fileKey, fileUploadStatus })
  }
  public static UpdateFileUploadStatus(
    fileKey: number,
    fileUploadStatus: FileUploadStatus,
    fileId: string,
    fileName: string,
    fileExists: boolean,
    assetId: string
  ) {
    this.importStateDispatch({
      type: ImportAction.UPDATEFILEUPLOADSTATUS,
      fileKey,
      fileUploadStatus,
      fileId,
      fileName,
      fileExists,
      assetId,
    })
  }
  public static SetAssetId(fileKey: number, assetId: string) {
    this.importStateDispatch({ type: ImportAction.SETASSETID, fileKey, assetId })
  }
  public static SetImportOpen(importOpen: boolean) {
    this.importStateDispatch({ type: ImportAction.SETIMPORTOPEN, importOpen })
  }
  public static SetOverwriteFile(fileKey: number, overwriteFile: boolean) {
    this.importStateDispatch({ type: ImportAction.SETOVERWRITEFILE, fileKey, overwriteFile })
  }
  public static SetOverwriteContentItem(fileKey: number, overwriteContentItem: boolean) {
    this.importStateDispatch({
      type: ImportAction.SETOVERWRITECONTENTITEM,
      fileKey,
      overwriteContentItem,
    })
  }
  public static SetUnsupportedFile(fileKey: number, unsupportedType: boolean) {
    this.importStateDispatch({
      type: ImportAction.SETUNSUPPORTEDTYPE,
      fileKey,
      unsupportedType,
    })
  }
  public static SetOverSize(fileKey: number, overSizeLimit: boolean) {
    this.importStateDispatch({ type: ImportAction.SETOVERSIZE, fileKey, overSizeLimit })
  }
  public static ClearFiles() {
    this.importStateDispatch({ type: ImportAction.CLEARFILES })
  }
  public static SetFileDetails(
    fileKey: number,
    hwid: string,
    title: string,
    localization: string,
    fileRecordExists: boolean,
    overwriteRecord: boolean
  ) {
    this.importStateDispatch({
      type: ImportAction.SETFILESUBMITDETAILS,
      fileKey,
      hwid,
      title,
      localization,
      fileRecordExists,
      overwriteRecord,
    })
  }

  public static SetFiles(files: ImportFile[]) {
    this.importStateDispatch({ type: ImportAction.SETFILES, files })
  }
  public static SetSubmitFiles(submitFiles: boolean) {
    this.importStateDispatch({ type: ImportAction.SETSUBMITFILES, submitFiles })
  }
  public static SetFileFinalized(fileKey: number, finalized: boolean) {
    this.importStateDispatch({ type: ImportAction.SETFILEFINALIZED, fileKey, finalized })
  }
  public static AddFinalizeError(finalizeError: string) {
    this.importStateDispatch({ type: ImportAction.ADDFINALIZEERROR, finalizeError })
  }
  public static ResetImport() {
    this.importStateDispatch({ type: ImportAction.RESET })
  }
  public static SetUploadError(uploadError: string) {
    this.importStateDispatch({ type: ImportAction.SETUPLOADERROR, uploadError })
  }
}
