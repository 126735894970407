import React, { createContext, useReducer, useContext } from 'react'

export enum GlobalNotificationAction {
  SETLOADINGMESSAGE,
  CLEARLOADINGTEXT,
  SETLOADINGDURATIONMESSAGE,
  SETLOADINGERROR,
  SETLOADINGVISIBLE,
  SETSNACKBARDURATION,
  SETSNACKBARMESSAGE,
  SETSNACKBARSEVERITY,
  SETSNACKBARVISIBILITY,
  SETSNACKBARCLOSEACTION,
  SETLOADINGSUMMARY,
}

export enum SnackbarSeverity {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export type LoadingModalInfo = {
  message: string
  durationMessage: string
  visible: boolean
  error: string
}

export type LoadingModalSummary = {
  message: string
  visible: boolean
}

export type SnackbarInfo = {
  duration: number
  message: string
  severity: SnackbarSeverity
  visible: boolean
  showClose: boolean
}

export type GlobalNotificationState = {
  loadingModalInfo: LoadingModalInfo
  snackbarInfo: SnackbarInfo
  loadingModalSummary: LoadingModalSummary
}

const initialState: GlobalNotificationState = {
  loadingModalInfo: {
    message: 'Creating your document',
    durationMessage: 'This may take a minute...',
    visible: false,
    error: '',
  },
  snackbarInfo: {
    duration: 10000, //10 Seconds
    message: '',
    severity: SnackbarSeverity.Info,
    visible: false,
    showClose: false,
  },
  loadingModalSummary: {
    message: '',
    visible: false,
  },
}

const GlobalNotificationContext = createContext<{
  globalNotificationState: GlobalNotificationState
  globalNotificationStateDispatch: React.Dispatch<any>
}>({ globalNotificationState: initialState, globalNotificationStateDispatch: () => null })

function reducer(state: GlobalNotificationState, action: any): GlobalNotificationState {
  switch (action.type) {
    case GlobalNotificationAction.SETLOADINGMESSAGE:
      return {
        ...state,
        loadingModalInfo: { ...state.loadingModalInfo, message: action.data },
      }

    case GlobalNotificationAction.SETLOADINGSUMMARY:
      return {
        ...state,
        loadingModalSummary: { ...state.loadingModalSummary, message: action.data },
      }

    case GlobalNotificationAction.SETLOADINGDURATIONMESSAGE:
      return {
        ...state,
        loadingModalInfo: { ...state.loadingModalInfo, durationMessage: action.data },
      }

    case GlobalNotificationAction.SETLOADINGERROR:
      return {
        ...state,
        loadingModalInfo: { ...state.loadingModalInfo, error: action.data },
      }

    case GlobalNotificationAction.CLEARLOADINGTEXT:
      return {
        ...state,
        loadingModalInfo: { ...state.loadingModalInfo, message: '', durationMessage: '' },
      }

    case GlobalNotificationAction.SETLOADINGVISIBLE:
      return {
        ...state,
        loadingModalInfo: { ...state.loadingModalInfo, visible: action.data },
      }

    case GlobalNotificationAction.SETSNACKBARDURATION:
      return { ...state, snackbarInfo: { ...state.snackbarInfo, duration: action.data } }

    case GlobalNotificationAction.SETSNACKBARMESSAGE:
      return { ...state, snackbarInfo: { ...state.snackbarInfo, message: action.data } }

    case GlobalNotificationAction.SETSNACKBARSEVERITY:
      return { ...state, snackbarInfo: { ...state.snackbarInfo, severity: action.data } }

    case GlobalNotificationAction.SETSNACKBARVISIBILITY:
      return { ...state, snackbarInfo: { ...state.snackbarInfo, visible: action.data } }

    case GlobalNotificationAction.SETSNACKBARCLOSEACTION:
      return { ...state, snackbarInfo: { ...state.snackbarInfo, showClose: action.data } }

    default:
      return state
  }
}

export const GlobalNotificationStateProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  GlobalNotificationStateManager.globalNotificationStateDispatch = dispatch

  return (
    <GlobalNotificationContext.Provider
      value={{ globalNotificationState: state, globalNotificationStateDispatch: dispatch }}
    >
      {props.children}
    </GlobalNotificationContext.Provider>
  )
}

export function useGlobalNotificationState() {
  const { globalNotificationState, globalNotificationStateDispatch } =
    useContext(GlobalNotificationContext)

  return {
    globalNotificationState,
    globalNotificationStateDispatch,
    GlobalNotificationStateManager: GlobalNotificationStateManager,
  }
}

export class GlobalNotificationStateManager {
  public static globalNotificationStateDispatch: React.Dispatch<any>

  public static SetLoadingModalMessage(
    message: string = initialState.loadingModalInfo.message,
    loadingDurationMessage: string = initialState.loadingModalInfo.durationMessage,
    showLoadingModal: boolean = false
  ) {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETLOADINGMESSAGE,
      data: message,
    })
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETLOADINGDURATIONMESSAGE,
      data: loadingDurationMessage,
    })
    this.SetLoadingModalVisible(showLoadingModal)
  }

  public static SetLoadingModalError(error: string) {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETLOADINGERROR,
      data: error,
    })
  }

  public static SetLoadingModalSummary(summary: string) {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETLOADINGSUMMARY,
      data: summary,
    })
  }

  public static SetLoadingModalVisible(showLoadingModal: boolean) {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETLOADINGVISIBLE,
      data: showLoadingModal,
    })
    if (!showLoadingModal) {
      //Restore defaults
      this.globalNotificationStateDispatch({
        type: GlobalNotificationAction.SETLOADINGMESSAGE,
        data: initialState.loadingModalInfo.message,
      })
      this.globalNotificationStateDispatch({
        type: GlobalNotificationAction.SETLOADINGDURATIONMESSAGE,
        data: initialState.loadingModalInfo.durationMessage,
      })
      this.globalNotificationStateDispatch({
        type: GlobalNotificationAction.SETLOADINGSUMMARY,
        data: initialState.loadingModalSummary.message,
      })
    }
  }

  public static ShowSnackbarNotification(
    message: string,
    severity: SnackbarSeverity,
    showClose: boolean = false,
    duration: number = initialState.snackbarInfo.duration
  ) {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARMESSAGE,
      data: message,
    })
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARSEVERITY,
      data: severity,
    })
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARDURATION,
      data: duration,
    })
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARVISIBILITY,
      data: true,
    })
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARCLOSEACTION,
      data: showClose,
    })
  }

  public static HideSnackbarNotification() {
    this.globalNotificationStateDispatch({
      type: GlobalNotificationAction.SETSNACKBARVISIBILITY,
      data: false,
    })
  }
}
