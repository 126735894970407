import { Container, MenuItem } from '@mui/material'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import Footer from 'Components/Footer/Footer'
import PageRefinement, {
  PageRefinementProps,
} from 'Components/Header/PageRefinement/PageRefinement'
import { PageDropdown } from 'Pages/Browse/Browse'
import { useState } from 'react'
import style from './BrowseAll.module.scss'

type BrowseAllProps = {
  callingPageDropdown: PageDropdown
}

export default function BrowseAll({ callingPageDropdown }: BrowseAllProps) {
  const [groupType, setGroupType] = useState<PageRefinementProps['sort']>('group')
  return (
    <>
      <Container maxWidth='xl' className={style.page}>
        <div className={style.browseAllPage}>
          <div className={style.content}>
            <h2 className={style.title}>
              {callingPageDropdown.title === 'Category'
                ? ' All Categories'
                : 'Full ' + callingPageDropdown.title}
            </h2>
            <div className={style.sortBy}>
              Sort by
              <CmxTextField
                value={groupType}
                onChange={newSelect =>
                  setGroupType(newSelect.target.value as PageRefinementProps['sort'])
                }
                SelectProps={{
                  sx: { color: '#424242', width: '175px' },
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        border: '1px solid #424242',
                        '& .MuiMenuItem-root.Mui-selected': {
                          backgroundColor: '#d8d8d8',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: '#f4f4f4',
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: '#d8d8d8',
                        },
                      },
                    },
                  },
                }}
                height='28px'
                mode='borderless'
                select
              >
                <MenuItem value={'group'}>Section</MenuItem>
                <MenuItem value={'alpha'}>A - Z</MenuItem>
              </CmxTextField>
            </div>
            <PageRefinement
              callingPageDropdown={callingPageDropdown}
              sort={groupType}
              enableJumping
              maxValuesBeforeShowMore={12}
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}
