import { attributeTitles } from 'Components/CompassFacets/FacetUtilities'
import { create } from 'zustand'

import ContentItem from './Models/ContentItem'

export type SearchState = {
  searchTerm: string
  browsePath: string | null
  previewContent: ContentItem
  previewHtml: string
  searchResults: Array<ContentItem> | null
  filters: Record<string, string[]>
  filterString: string
  selectedQuickFilters: string[]
}

function initializeFilters() {
  return Object.fromEntries(
    Object.keys(attributeTitles).map(key => {
      return [(attributeTitles as any)[key], [] as string[]]
    })
  )
}

function toggleFilter(filter: string[], value: string): string[] {
  let location = filter.indexOf(value)

  if (location > -1) {
    let filterCopy = [...filter]
    filterCopy.splice(location, 1)
    return filterCopy
  } else {
    return filter.concat(value)
  }
}

type SearchAction = {
  updateSearchTerm: (searchTerm: SearchState['searchTerm']) => void
  updateBrowsePath: (browsePath: SearchState['browsePath']) => void
  updateFilterString: (filterString: SearchState['filterString']) => void
  updateSelectedQuickFilters: (selectedQuickFilters: SearchState['selectedQuickFilters']) => void
  clearAllFilters: () => void
  toggleFilter: (title: string, value: string) => void
  toggleQuickFilter: (label: string) => void
}

const useSearchStore = create<SearchState & SearchAction>()((set, get) => ({
  searchTerm: '',
  updateSearchTerm: searchTerm => set(() => ({ searchTerm: searchTerm })),
  browsePath: null,
  updateBrowsePath: browsePath => set(() => ({ browsePath: browsePath })),
  previewContent: { hwid: '', localization: '', title: '', docType: '', product: '' },
  previewHtml: '',
  searchResults: null,
  filters: initializeFilters(),
  clearAllFilters: () => set(() => ({ filters: initializeFilters() })),
  toggleFilter: (title: string, value: string) => {
    const newFilter = toggleFilter(get().filters[title], value)
    set({
      filters: {
        ...get().filters,
        [title]: newFilter,
      },
    })
  },
  toggleQuickFilter: (label: string) => {
    const selectedQuickFilters = get().selectedQuickFilters
    const sqfIndex = selectedQuickFilters.indexOf(label)
    var selected =
      sqfIndex > -1
        ? selectedQuickFilters.slice(0, sqfIndex).concat(selectedQuickFilters.slice(sqfIndex + 1))
        : selectedQuickFilters.concat([label])
    set({
      selectedQuickFilters: selected,
    })
  },
  filterString: '',
  updateFilterString: filterString => set(() => ({ filterString: filterString })),
  selectedQuickFilters: [],
  updateSelectedQuickFilters: selectedQuickFilters =>
    set(() => ({ selectedQuickFilters: selectedQuickFilters })),
}))

export default useSearchStore
