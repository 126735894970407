export function Trim(s: string, c: string): string {
  if (c === ']') c = '\\]'
  if (c === '^') c = '\\^'
  if (c === '\\') c = '\\\\'
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}

/**
 * Function to convert a string into a hash string
 *
 * Adapted from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 * @param str String to be converted into a hash
 * @returns Converted hash
 */
export function ToHashCode(str: string): string {
  var hash = 0,
    i,
    chr
  if (str.length === 0) return hash.toString()
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString()
}
