import { AccessToken } from 'Store/AuthState'
import { Trim } from 'Utilities/StringUtils'

export async function DownloadFilePOST(
  url: string,
  body: any,
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
    body: JSON.stringify(body),
  })
    .then(async res => {
      return await getFileInfo(res)
    })
    .then((resObj: any) => {
      downloadFile(resObj)
    })
    .then(() => onComplete())
    .catch(function () {
      onError()
    })
}

async function getFileInfo(res: any): Promise<any> {
  if (res.ok) {
    const header = res.headers.get('Content-Disposition')
    const parts = header!.split(';')
    let filename = parts[1].split('=')[1]
    filename = Trim(filename, '"')
    return { filename: filename, blob: await res.blob() }
  }
  throw new Error('Server Error')
}

function downloadFile(resObj: any) {
  // @ts-ignore
  if (window.navigator && window.navigator?.msSaveOrOpenBlob) {
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob)
  } else {
    const newBlob = new Blob([resObj.blob], { type: resObj.blob.type })
    const objUrl = window.URL.createObjectURL(newBlob)
    let link = document.createElement('a')
    link.href = objUrl
    link.download = resObj.filename
    link.click()
    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl)
    }, 250)
  }
}
