import React, { createContext, useReducer, useContext } from 'react'
import ContentItem, { ContentItemMetadata } from './Models/ContentItem'

export enum PreviewStateAction {
  SETPREVIEWCONTENT,
  SETPREVIEWHTML,
  CLEARPREVIEWCONTENT,
  SETPREVIEWOPEN,
  SETCONTENTITEMMETADATA,
  SETTOPICEMBEDTYPE,
}

export type PreviewState = {
  previewContent: ContentItem
  previewHtml: string
  previewOpen: boolean
  contentMetadata: ContentItemMetadata
  topicEmbedType: null | 'Image' | 'Video'
}
const emptyContentItem = { hwid: '', localization: '', title: '', docType: '', product: '' }
export const emptyMetadata: ContentItemMetadata = {
  id: -1,
  hwid: '',
  localization: '',
  title: '',
  description: '',
  contentType: '',
  metadata: {
    age: undefined,
    description: undefined,
    concepts: undefined,
    cpt: undefined,
    gender: undefined,
    icd10ca: undefined,
    icd10cm: undefined,
    keywords: undefined,
    loinc: undefined,
    rxnorm: undefined,
    snomedct: undefined,
    specialties: undefined,
    metadataItems: undefined,
  },
  status: '',
  statuses: [],
  pdfUrl: '',
  canSubmit: false,
  editable: false,
  deprecated: false,
}

const initialState: PreviewState = {
  previewContent: emptyContentItem,
  previewHtml: '',
  previewOpen: false,
  contentMetadata: emptyMetadata,
  topicEmbedType: null,
}

const PreviewContext = createContext<{
  previewState: PreviewState
  previewStateDispatch: React.Dispatch<any>
}>({ previewState: initialState, previewStateDispatch: () => null })

function reducer(state: PreviewState, action: any): PreviewState {
  switch (action.type) {
    case PreviewStateAction.SETPREVIEWCONTENT:
      return { ...state, previewContent: action.data, topicEmbedType: null }
    case PreviewStateAction.SETPREVIEWHTML:
      return { ...state, previewHtml: action.data }
    case PreviewStateAction.CLEARPREVIEWCONTENT:
      return {
        ...state,
        previewContent: emptyContentItem,
        contentMetadata: emptyMetadata,
        previewHtml: '',
      }
    case PreviewStateAction.SETPREVIEWOPEN:
      return { ...state, previewOpen: action.data }
    case PreviewStateAction.SETCONTENTITEMMETADATA:
      return { ...state, contentMetadata: action.data }
    case PreviewStateAction.SETTOPICEMBEDTYPE:
      return { ...state, topicEmbedType: action.data }
    default:
      return state
  }
}

export const PreviewStateProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <PreviewContext.Provider value={{ previewState: state, previewStateDispatch: dispatch }}>
      {props.children}
    </PreviewContext.Provider>
  )
}

export function usePreviewState() {
  return useContext(PreviewContext)
}
