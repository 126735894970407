// Type Declarations
export interface FacetItem {
  label: string
  count: number
  isRefined: boolean
  applicableItems?: FacetItem[]
  max?: number
  min?: number
  value?: string
  values?: string[]
  facet?: string
  tooltip?: string
  icon?: JSX.Element
}

export const attributeTitles: { [key: string]: string } = {
  availability: 'Availability',
  ageRange: 'Age Range',
  sex: 'Assigned Sex',
  localizations: 'Language',
  topicLocalizations: 'Language',
  source: 'Source',
}

export const attributeLabels: { [key: string]: string } = {
  availability: 'cmx.availability',
  ageRange: 'cmx.ageGroups',
  sex: 'content.gender',
  contentType: 'cmx.type',
  specialty: 'concept.specialties',
  localizations: 'lang', // Gets replaced with the language package for the client.
  topicLocalizations: 'content.availableLocalizations',
  source: 'content.source',
}

export const attributeHoverMessage: { [key: string]: string } = {
  availability: 'Indicates how content is available in different Healthwise® modules.',
  sex: 'The sex (assigned at birth) of people for whom the content is appropriate.',
  contentType: 'Indicates if content is text-based, a video, or an interactive experience.',
  specialty: 'The specialty areas for which the content is appropriate or useful.',
  localizations: 'All languages in which the content is available.',
  ageRange: 'The range of ages for which the content is appropriate.',
  source: 'The organization who owns the content.',
}

export const sourceTooltips: { [key: string]: string } = {
  'In-House': 'Content uploaded by your organization.',
  CDC: 'Centers for Disease Control and Prevention.',
  FDB: 'First Databank, Inc.',
  NCI: 'National Cancer Institute.',
  CAM: 'Aisle7 from TraceGains.',
}

// Maps label value to title (ex. content.gender => Birth Sex)
export const attributeTitleLabels: { [key: string]: string } = Object.fromEntries(
  Object.keys(attributeTitles).map(key => {
    return [attributeTitles[key], attributeTitles[key]]
  })
)

// Maps the json value to its key (ex. content.gender => sex)
export const attributeLabelKeys: { [key: string]: string } = Object.fromEntries(
  Object.keys(attributeTitles).map(key => {
    return [attributeTitles[key], key]
  })
)

export const localizationMap: { [key: string]: string } = {
  'en-us': 'English',
  'en-ca': 'English (CA)',
  'es-us': 'Spanish',
  'ar-us': 'Arabic',
  'bn-us': 'Bengali',
  'bs-us': 'Bosnian',
  'fa-us': 'Farsi',
  'fr-us': 'French',
  'ht-us': 'Haitian Creole',
  'ko-us': 'Korean',
  'ne-us': 'Nepali',
  'pl-us': 'Polish',
  'pt-us': 'Portuguese (Brazil)',
  'ru-us': 'Russian',
  'so-us': 'Somali',
  'tl-us': 'Tagalog',
  'vi-us': 'Vietnamese',
  'zh-us': 'Chinese (Simplified)',
  'hm-us': 'Hmong',
  'hy-us': 'Armenian',
  'km-us': 'Khmer',
}

export const ageRangeMap: { [key: string]: string } = {
  Infant: 'Infant (birth to 23 months)',
  Child: 'Child (2 to 12 years)',
  Teen: 'Teen (13 to 17 years)',
  Adult: 'Adult (18 to 64 years)',
  Senior: 'Senior (65 and up)',
}

export const docFam: { [key: string]: string[] } = {
  KB: [
    'Actionset',
    'Decision Point',
    'Drug Detail',
    'Focus',
    'Major',
    'Medical Test',
    'Mini',
    'Other Detail',
    'Special',
    'Surgical Detail',
    'Symptom',
    'Test Detail',
    'CAM Document',
    'Prescribing Document',
    'Interactive Tool',
  ],
  PI: ['PI Aftervisit', 'PI Post-Op', 'PI Pre-Op', 'Anatomy Sketch', 'Quick List'],
  CareSupportPages: ['Care Support Page'],
  LearningCenter: ['Learning Center'],
  FDB: ['Fdb', 'FDB Document'],
  NCI: ['NCI Document'],
  CDC: ['CDC Document'],
  Video: ['Video'],
  ClientContent: ['In-House PDF'],
}

export const allDocTypes: string[] = [
  // TODO: Grab this from algolia or a standard service or library that is used by algolia while indexing?
  ...docFam.KB,
  ...docFam.PI,
  ...docFam.Video,
  ...docFam.CDC,
  ...docFam.ClientContent,
  ...docFam.NCI,
  ...docFam.FDB,
  ...docFam.CareSupportPages,
  ...docFam.LearningCenter,
]

export const selfServeValues: string[] = [
  ...docFam.KB,
  ...docFam.Video,
  ...docFam.CDC,
  ...docFam.NCI,
  ...docFam.FDB,
  ...docFam.CareSupportPages,
  ...docFam.LearningCenter,
]

export const assignableValues: string[] = [
  ...docFam.PI,
  ...docFam.Video,
  ...docFam.CDC,
  ...docFam.ClientContent,
  ...docFam.FDB,
  ...docFam.CareSupportPages,
]

export const articleValues: string[] = allDocTypes.filter(
  i => !['Decision Point', 'Symptom', 'Interactive Tool', 'Video'].includes(i)
)
export const interactiveValues: string[] = ['Decision Point', 'Symptom', 'Interactive Tool']
export const videoValues: string[] = ['Video']

//Accepts a collection of string arrays and returns a single string array representing all the unique values of all arrays
export function ORValues(facetCollection: Array<Array<string>>): Array<string> {
  var mergedList = []
  for (var i = 0; i < facetCollection.length; ++i) {
    for (var j = 0; j < facetCollection[i].length; ++j) {
      if (mergedList.indexOf(facetCollection[i][j]) === -1) {
        mergedList.push(facetCollection[i][j])
      }
    }
  }
  return mergedList
}

//Accepts a collection of string arrays and returns a single string array representing only the intersection of all arrays
export function ANDValues(facetCollection: Array<Array<string>>): Array<string> {
  return facetCollection
    ?.filter(e => e.length > 0)
    ?.reduce((a, b) => a?.filter(c => b?.includes(c)))
}

export function ReduceVirtualFacetValues(facetCollection: Array<Array<string>>) {
  let facetHasSelection = Array<boolean>()
  for (var i = 0; i < facetCollection.length; ++i) {
    facetHasSelection.push(false)
    if (facetCollection[i].length > 0) {
      facetHasSelection[i] = true
    }
  }

  if (facetHasSelection.filter(e => e).length === 1) {
    return ORValues(facetCollection)
  } else if (facetHasSelection.filter(e => e).length > 1) {
    let reducedContentTypeFacets = ANDValues(facetCollection)
    //An empty array should result in no search results as there are no overlaps in the selected facets. Ex: Assigned and Interactive
    return reducedContentTypeFacets.length > 0
      ? reducedContentTypeFacets
      : ['no_overlapping_facet_value']
  } else {
    return []
  }
}

export function getItemLabel(title: string | undefined, label: string): string {
  if (title === attributeTitles.localizations) return localizationMap[label]
  if (title === attributeTitles.ageRange) return ageRangeMap[label]
  return label.trim()
}

export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true
  if (typeof a === 'undefined' || typeof b === 'undefined') return false
  if (a === null || b === null) return false
  if (a.length !== b.length) return false

  a = [...a].sort()
  b = [...b].sort()

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }

  return true
}

export function getFilterCount(filters: any) {
  let count = 0
  for (const key in filters) {
    const filter = filters[key]
    if (filter) count += filter.length
  }
  return count
}
