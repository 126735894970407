import Grid from '@mui/material/Grid'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'

import { ProfileProvider } from 'Components/Auth/ProfileProvider'
import Header from 'Components/Header/Header'
import LoadingModal from 'Components/Loading/Loading'
import PreviewModal from 'Components/PreviewModal/PreviewModal'
import PublishModal from 'Components/PublishModal/PublishModal'
import SkipToContentLink from 'Components/SkipToContentLink'
import SnackbarNotification from 'Components/SnackbarNotification/SnackbarNotification'
import SuppressModal from 'Components/SuppressModal/SuppressModal'
import Browse from 'Pages/Browse/Browse'
import FeatureFlipper from 'Pages/Features/FeatureFlipper'
import Manage from 'Pages/Manage/Manage'
import ManageContentItem from 'Pages/ManageContentItem/ManageContentItem'
import Reports from 'Pages/Reports/Report'
import Embedding from 'Pages/Embedding'
import { AppFeatureDictionary, FeatureEnabled, useAuthState } from 'Store/AuthState'
import { KeyboardShortcuts } from 'Store/KeyboardShortcuts'
import { useLocalizationState } from 'Store/LocalizationState'
import useSearchStore from 'Store/SearchState'
import 'Styles/App.scss'
import { theme } from 'Styles/AppTheme'
import { GetNonManagementSubs } from 'Utilities/OrgUtil'
import { Feature } from 'Utilities/PermissionManager'
import { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { AuthenticationGuard } from 'Components/Auth/SecurityWrapper'
import { MAIN_CONTENT_ID } from 'common.util'

// The properties for web components is track on the following github page:
// https://github.com/healthwise/hw.content.web.components
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'hw-content': React.DetailedHTMLProps<any, HTMLElement>
    }
  }
}

export default function App() {
  const { authState } = useAuthState()
  const updateFilterString = useSearchStore(state => state.updateFilterString)

  const navigate = useNavigate()
  const location = useLocation()
  const loc = useLocalizationState()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    let subs = GetNonManagementSubs(authState.orgInfo?.subscriptions ?? [])
    if (subs?.length)
      updateFilterString(
        `content.localization:${loc.localizationState.localizationCode} AND ${subs
          .map(sub => `NOT content.suppressors.subscribers.id:"${sub.id}"`)
          .join(' AND ')}`
      )
  }, [authState.orgInfo?.subscriptions, loc.localizationState.localizationCode, updateFilterString])

  useEffect(() => {
    const goToReports = () => {
      navigate(App_Routes.REPORTS)
    }

    if (
      authState.features.indexOf(Feature.ViewReports) > -1 &&
      authState.features.indexOf(Feature.AdvancedSearch) === -1
    ) {
      goToReports()
    }
  }, [
    authState.accessToken,
    authState.orgInfo,
    authState.hasError,
    authState.features,
    navigate,
    authState?.organizationAlias,
  ])

  return (
    <div className='App'>
      <StyledEngineProvider injectFirst>
        <ThemeProvider {...{ theme }}>
          <PreviewModal />

          <LoadingModal />

          <SuppressModal />

          <PublishModal />

          <SnackbarNotification />

          <KeyboardShortcuts />

          <div className='main-grid'>
            <SkipToContentLink />

            <ProfileProvider>
              <Header />
            </ProfileProvider>

            <Grid className='main-content' item xs={12}>
              <PageContent />
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

function PageContent() {
  return (
    <div className='Page-Content' id={MAIN_CONTENT_ID} tabIndex={-1}>
      <Routes>
        <Route
          path={App_Routes.HOME}
          key={App_Routes.HOME}
          element={<AuthenticationGuard component={Browse} />}
        />

        <Route
          path={App_Routes.REPORTS}
          key={App_Routes.REPORTS}
          element={<AuthenticationGuard component={Reports} />}
        />

        <Route
          path={App_Routes.FEATURES}
          key={App_Routes.FEATURES}
          element={<AuthenticationGuard component={FeatureFlipper} />}
        />

        <Route
          path={App_Routes.BROWSEALLINDEX}
          key={App_Routes.BROWSEALLINDEX}
          element={<AuthenticationGuard component={Browse} browseAll />}
        />

        <Route
          path={App_Routes.BROWSEALLCATEGORY}
          key={App_Routes.BROWSEALLCATEGORY}
          element={<AuthenticationGuard component={Browse} browseAll />}
        />

        <Route
          path={App_Routes.BROWSE}
          key={App_Routes.BROWSE}
          element={<AuthenticationGuard component={Browse} />}
        />

        <Route
          path={App_Routes.EMBEDDING}
          key={App_Routes.EMBEDDING}
          element={<AuthenticationGuard component={Embedding} />}
        />

        {FeatureEnabled(AppFeatureDictionary.CanUploadAndManage) && [
          <Route
            path={App_Routes.MANAGE}
            key={App_Routes.MANAGE}
            element={<AuthenticationGuard component={Manage} />}
          />,

          <Route
            path={`${App_Routes.MANAGECONTENTITEM}/:hwid/:localization`}
            key={`${App_Routes.MANAGECONTENTITEM}/:hwid/:localization`}
            element={<AuthenticationGuard component={ManageContentItem} />}
          />,
        ]}
      </Routes>
    </div>
  )
}
