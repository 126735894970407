export enum ContentTypes {
  PI_AFTERVISIT = 'PI Aftervisit',
  ANATOMY = 'Anatomy Sketch',
  CDC = 'CDC Document',
  MULTUM = 'Multum Document',
  PI_POST_OP = 'PI Post-Op',
  PI_PRE_OP = 'PI Pre-Op',
  QUICKLIST = 'Quick List',
  VIDEO = 'Video',
  DECISION = 'Decision Point',
  SYMPTOM = 'Symptom',
  INTERACTIVE = 'Interactive Tool',
}
