import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { AppBar, Badge, Box, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { FileDownloadOutlined, ShoppingBasketOutlined } from '@mui/icons-material'
import { launchFilteredReport } from 'Api/Reports.api'
import { Roles, App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { SearchWithAutocomplete } from 'Components/AutoComplete/SearchWithAutocomplete'
import { orgHasTopics, orgIsCompassOnly, useAuthState } from 'Store/AuthState'
import { CartAction, useCartState } from 'Store/CartState'
import { TriangleSvg } from 'Store/CustomSvg/TriangleSvg'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import useSearchStore from 'Store/SearchState'
import { AlgoliaState } from 'Store/AlgoliaState'
import { useEffect, useState } from 'react'
import style from './Header.module.scss'
import PagePopupModal from './Popups/PagePopup'
import {
  Configure,
  InstantSearch,
  useCurrentRefinements,
  useInstantSearch,
} from 'react-instantsearch'
import { useAlgolia } from 'Store/UseAlgolia'
import SettingsCode from 'Resources/settings-code.svg'
import { useNavigate } from 'react-router'
import { PageDropdown } from 'Pages/Browse/Browse'

type SubHeaderProps = {
  algoliaState: AlgoliaState
  browseAndRefineFilterString: string
  pageDropdowns: PageDropdown[]
}

export default function SubHeader({
  algoliaState,
  browseAndRefineFilterString,
  pageDropdowns,
}: SubHeaderProps) {
  const { authState } = useAuthState()
  const { items } = useCurrentRefinements()
  const { algoliaClient } = useAlgolia(authState.accessToken)
  const { cartState, cartStateDispatch } = useCartState()
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()
  const { status } = useInstantSearch()

  const filters = useSearchStore(state => state.filters)
  const filterString = useSearchStore(state => state.filterString)
  const searchTerm = useSearchStore(state => state.searchTerm)
  const selectedQuickFilters = useSearchStore(state => state.selectedQuickFilters)

  const navigate = useNavigate()

  const [activePage, setActivePage] = useState<string | null>(null)
  const [initLoad, setInitLoad] = useState(false)

  useEffect(() => {
    if (status === 'idle' && !initLoad) setInitLoad(true)
  }, [initLoad, status])

  const documentCountFilterString =
    selectedQuickFilters.length > 0
      ? `AND (${selectedQuickFilters.map(sqf => `cmx.type:${sqf}`).join(' OR ')})`
      : ''

  function handleFilteredReport() {
    if (!globalNotificationState.loadingModalInfo.visible) {
      GlobalNotificationStateManager.SetLoadingModalVisible(true)
      launchFilteredReport(
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState?.index || '',
        algoliaState?.availableLanguages || [],
        items,
        searchTerm,
        filters,
        orgHasTopics(authState.licensing),
        browseAndRefineFilterString + filterString + documentCountFilterString,
        orgIsCompassOnly(authState.licensing),
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
        },
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error Retrieving Filtered Report',
            SnackbarSeverity.Error,
            false,
            10000
          )
        }
      )
    }
  }

  function PageDropdowns() {
    function PageDropdown({ title }: PageDropdown): JSX.Element {
      const isActive = activePage === title

      return (
        <Button
          className={isActive ? style.pageDropdownActive : style.pageDropdown}
          onClick={() => {
            if (isActive) setActivePage(null)
            else setActivePage(title)
          }}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <Typography className={style.pageDropdownLabel}>{title}</Typography>
          {isActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          {isActive && <TriangleSvg className={style.triangle} />}
        </Button>
      )
    }

    return (
      <Box className={style.pageDropdowns}>
        {pageDropdowns.map(pageDropdown => {
          return (
            <PageDropdown
              key={pageDropdown.title}
              title={pageDropdown.title}
              attribute={pageDropdown.attribute}
              route={pageDropdown.route}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <>
      <AppBar position='static' elevation={0} className={style.subHeader}>
        <Toolbar className={style.subHeaderToolbar}>
          <h2 className={style.subHeaderTitle}>Browse by</h2>
          <Box sx={{ width: '32px', display: { xs: 'none', lg: 'inherit' } }} />
          <PageDropdowns />
          <Box
            data-qa='SearchBox'
            className={style.searchBar}
            sx={{ margin: { xs: '0 10px', md: '0 15px' } }}
          >
            {algoliaClient?.appId && (
              <InstantSearch searchClient={algoliaClient} indexName={'assets_query_suggestions'}>
                <Configure
                  distinct
                  facetingAfterDistinct={false}
                  analytics
                  clickAnalytics
                  enablePersonalization
                  filters=''
                  hitsPerPage={3}
                  page={0}
                />
                <SearchWithAutocomplete algoliaState={algoliaState} />
              </InstantSearch>
            )}
          </Box>
          <div className={style.searchButtons}>
            {orgHasTopics(authState.licensing) && (
              <Tooltip title='Approved Embedding Sites' id='approved-embedding-sites-tooltip'>
                <div data-qa='Whitelist' className={style.headerWhitelistIcon}>
                  <IconButton
                    aria-label='Approved Embedding Sites'
                    onClick={() => {
                      navigate(App_Routes.EMBEDDING)
                    }}
                    aria-describedby='approved-embedding-sites-tooltip'
                    className={style.actionIcon}
                  >
                    <img alt='Approved Embedding Sites' src={SettingsCode} />
                  </IconButton>
                </div>
              </Tooltip>
            )}

            {authState &&
              !(
                authState.managementRoles![0] === Roles.CONTENTVIEWONLY &&
                authState.managementRoles!.length === 1
              ) && (
                <Tooltip title='Export Search Results' id='export-search-results-tooltip'>
                  <div data-qa='ExportList' className={style.headerExportIcon}>
                    <IconButton
                      aria-label='Export Search Results'
                      onClick={handleFilteredReport}
                      aria-describedby='export-search-results-tooltip'
                      className={style.actionIcon}
                    >
                      <FileDownloadOutlined color='primary' />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            {!orgIsCompassOnly(authState.licensing) && (
              <Tooltip title='Show Basket' id='show-basket-tooltip'>
                <div data-qa='PrintBasket' className={style.headerCartIcon}>
                  <IconButton
                    aria-label='Show Basket'
                    onClick={() => {
                      cartStateDispatch({
                        type: CartAction.SETCARTOPEN,
                        data: !cartState.cartOpen,
                      })
                    }}
                    aria-describedby='show-basket-tooltip'
                    className={style.actionIcon}
                  >
                    <Badge
                      sx={{
                        '& .MuiBadge-badge': {
                          right: -1,
                          top: -1,
                          padding: '0 4px',
                        },
                      }}
                      badgeContent={cartState.cartItems.length}
                      color='secondary'
                      max={999}
                    >
                      <ShoppingBasketOutlined color='primary' />
                    </Badge>
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {activePage && (
        <PagePopupModal
          open={activePage !== null}
          callingPageDropdown={
            pageDropdowns.find(pageDropdown => pageDropdown.title === activePage) ??
            pageDropdowns[0]
          }
          setActivePage={setActivePage}
        />
      )}
    </>
  )
}
