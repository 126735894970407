import style from './SuppressModal.module.scss'
import { SuppressModalLSOProps } from './SuppressModalLSO'

interface SuppressionMessageContentProps {
  isBulk: boolean
  isHWContent: boolean
  isRestore: boolean
  singleItemTitle: string
  suppressItems: SuppressModalLSOProps['suppressItems']
}

export default function SuppressionMessageContent({
  isBulk,
  isHWContent,
  isRestore,
  singleItemTitle,
  suppressItems,
}: SuppressionMessageContentProps) {
  if (isRestore) {
    if (isBulk)
      return (
        <>
          <p className={style.bottomMargin}>
            <span className={style.bold}>
              {isHWContent
                ? 'The following titles will be made available to your organization.'
                : 'The following titles will be moved to Draft status.'}
            </span>
            {isHWContent
              ? 'This includes versions that exist in any language.'
              : 'This includes versions that exist in any language. You will need to Publish a title to make it available for searching and assigning.'}
          </p>

          <div className={style.suppressListContainer}>
            {suppressItems.map((item: any) => (
              <p>{item.title}</p>
            ))}
          </div>

          {isHWContent && (
            <p className={style.topMargin}>
              If a Draft version of a title exists, you will need to Publish that title to make it
              available for searching and assigning.
            </p>
          )}
        </>
      )

    return (
      <>
        <p>
          <span className={style.bold}>
            {isHWContent
              ? `${singleItemTitle} will be made available to your organization.`
              : `${singleItemTitle} will be moved to Draft status.`}
          </span>
          {isHWContent
            ? 'This includes versions that exist in any language.'
            : 'This includes versions that exist in any language. You will need to Publish that title to make it available for searching and assigning.'}
        </p>

        {isHWContent && (
          <p>
            If a Draft version of a title exists, you will need to Publish that title to make it
            available for searching and assigning.
          </p>
        )}
      </>
    )
  }

  if (isBulk)
    return (
      <>
        <p className={style.bottomMargin}>
          <span className={style.bold}>
            The following titles will be hidden from your organization and will not be available to
            search or assign.
          </span>
          This includes versions that exist in any language.
        </p>

        <div className={style.suppressListContainer}>
          {suppressItems.reverse().map((item: any) => (
            <p>{item.title}</p>
          ))}
        </div>

        <p className={style.topMargin}>
          If you wish to restore a title at a later date, you can do so on the Manage page.
        </p>
      </>
    )

  return (
    <>
      <p>
        <span className={style.bold}>
          {singleItemTitle} will be hidden from your organization and will not be available to
          search or assign.
        </span>
        This includes versions that exist in any language.
      </p>

      <p>If you wish to restore a title at a later date, you can do so on the Manage page.</p>
    </>
  )
}
