export enum Feature {
  DemoSearch,
  AdvancedSearch,
  ViewReports,
  DownloadReports,
  ImportContent,
  EditContent,
  EditMetadata,
  ApproveContent,
  PublishContent,
}

type EnumType = { [s: number]: string }

export default class PermissionManager {
  private static mapEnum(enumerable: EnumType): any[] {
    // get all the members of the enum
    let enumMembers: any[] = Object.keys(enumerable).map((key: any) => enumerable[key])
    // we are only interested in the numeric identifiers as these represent the values
    return enumMembers.filter(v => typeof v === 'number')
  }

  private static AddFeatures(features: Array<Feature>, addFeatures: Array<Feature>) {
    for (let i = 0; i < addFeatures.length; ++i) {
      if (features.indexOf(addFeatures[i]) === -1) {
        features.push(addFeatures[i])
      }
    }
  }

  public static GetFeaturesFromRoles(roles: Array<string>): Array<Feature> {
    let features: Array<Feature> = []
    for (let i = 0; i < roles.length; ++i) {
      switch (roles[i]) {
        case 'Administrator':
          this.AddFeatures(features, this.mapEnum(Feature))
          break
        case 'ReportViewer':
          this.AddFeatures(features, [Feature.ViewReports])
          break
        case 'ReportExporter':
          this.AddFeatures(features, [Feature.DownloadReports])
          break
        case 'ContentExporter':
          this.AddFeatures(features, [Feature.AdvancedSearch])
          break
        case 'ContentExplorer':
          this.AddFeatures(features, [Feature.AdvancedSearch])
          break
        case 'ContentViewOnly':
          this.AddFeatures(features, [Feature.DemoSearch])
          break
      }
    }
    return features
  }
}
