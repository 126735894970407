import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import { Section } from 'Pages/Browse/Browse'
import useSearchStore from 'Store/SearchState'
import { useState } from 'react'
import { Configure, Index, useHits } from 'react-instantsearch'
import style from './RefineResults.module.scss'

type RefineResultsProps = {
  isBrowsing: boolean
  isSearching: boolean
  browseAndRefineFilterString: string
}

type Node = {
  label: string
  refineLabel: string
  path: string
  children: Node[]
}

export default function RefineResults({
  isBrowsing,
  isSearching,
  browseAndRefineFilterString,
}: RefineResultsProps) {
  const browsePath = useSearchStore(state => state.browsePath)
  const filterString = useSearchStore(state => state.filterString)
  const callingPageAttribute = browsePath?.split(' > ')[0]

  function RefinementItems() {
    const { hits } = useHits()
    const items: { label: string; refineLabel: string }[] = (() => {
      // Transforms 'hits' into an array of label objects depending on which field we need
      if (isSearching)
        return hits.flatMap((hit: any) => {
          return {
            label: hit.concept.preferredLineage ?? '',
            refineLabel: hit.concept.preferredLineage ?? '',
          }
        })
      if (callingPageAttribute === 'Categories')
        return hits.flatMap((hit: any) => {
          // Adds spaces before a capital letter as long as it's not the first character in the string
          const typeLabel = hit.concept.types[0].replace(/(?<!^)([A-Z])/g, ' $1')
          return {
            // A rather janky solution, but it lets Categories work with our existing code.  The placeholders will never be visible.  This will change with Rachel's input.
            label: `Placeholder > Placeholder > Placeholder > ${typeLabel} > ${hit.concept.label}`,
            refineLabel: hit.concept.preferredLineage,
          }
        })
      return hits.flatMap((hit: any) =>
        hit.concept.lineagePaths.map((hclp: string) => {
          return { label: hclp, refineLabel: hclp }
        })
      )
    })()

    const displayableItems = items
      .filter((item: any) => {
        const itemSplit = item.label.split(' > ').length
        return isBrowsing && callingPageAttribute !== 'Categories'
          ? itemSplit > 3 && item.label.startsWith(browsePath) && item.label !== browsePath
          : itemSplit > 3
      })
      .map((item: any) => {
        const itemSplit = item.label.split(' > ')
        return {
          ...item,
          label: itemSplit.slice(3).join(' > '),
          fullPath: itemSplit,
        }
      })

    /**
     * Takes an array of items that contain hierarchical strings that we can morph into a real hierarchy
     * @param items the array containing hierchical strings that will be transformed
     * @returns An array that contains the hierarchy
     */
    function transformHierarchicalStrings(items: any[]): Node[] {
      const result: Node[] = []

      for (const item of items) {
        const levels = item.label.split(' > ')
        let currentLevel: Node[] = result

        for (let i = 0; i < levels.length; i++) {
          let existingNode = currentLevel.find(node => node.label === levels[i])

          if (!existingNode) {
            existingNode = {
              label: levels[i],
              refineLabel: item.refineLabel,
              path: item.fullPath.slice(0, i + 4).join(' > '),
              children: [],
            }
            currentLevel.push(existingNode)
          }

          currentLevel = existingNode.children
        }
      }

      return result
    }

    /**
     * Recurssive function that moves children up one level of the hierarchy if they are the only child of a node. This prevents the hierarchy from appearing too deep for single children.
     * @param hierarchy this iteration's level of the hierarchy
     * @param initial flag used to skip flattening the top-most level
     * @returns the new hierarchy after recurssion.
     */
    function partiallyFlattenHierarchy(hierarchy: any[], _initial?: boolean): any[] {
      if (hierarchy.length === 1 && hierarchy[0].children.length === 0) return []

      // const toAdd = []
      // for (const hier of hierarchy) {
      //   if (hier?.children?.length > 0) {
      //     const nextLevel = partiallyFlattenHierarchy(hier.children)
      //     if (nextLevel.length === 0 && (!initial || hier.children.length === 1)) {
      //       toAdd.push(hier.children[0])
      //       hier.children = []
      //     } else if (nextLevel.length > 0) {
      //       hier.children = nextLevel
      //     }
      //   }
      // }

      // return hierarchy.concat(toAdd)
      return hierarchy
    }

    /**
     * If an entry in the hierarchy has children, then it will display with a carot.  This function sorts each level and adds
     * a clickable entry for each dropdown.  This was the user has an expandable entry as well as an entry that can refine.
     * @param hierarchy initially the full hierarchy, but will be called at each level as well
     * @param toAdd the parent value that will become clickable inside its children
     * @returns the sorted hierarchy with clickable dropdown options added
     */
    function sortAndAddClickableDropdownOptions(hierarchy: any[], toAdd?: any): any[] {
      for (const hier of hierarchy) {
        if (hier?.children?.length > 0) {
          hier.children = sortAndAddClickableDropdownOptions(hier.children, {
            ...hier,
            children: [],
            refineLabel: hier.path,
          })
        }
      }

      if (callingPageAttribute !== 'Categories')
        hierarchy = toAdd ? [toAdd, ...hierarchy] : hierarchy

      const sorted = hierarchy.sort((a: { label: string }, b: { label: string }) => {
        const aLabel = a.label.toLowerCase()
        const bLabel = b.label.toLowerCase()

        if (aLabel < bLabel) return -1
        if (aLabel > bLabel) return 1
        return 0
      })

      return sorted
    }

    const hierarchy = sortAndAddClickableDropdownOptions(
      partiallyFlattenHierarchy(transformHierarchicalStrings(displayableItems), true)
    )

    const columnLength = Math.ceil(hierarchy.length / 3)
    const columns = [
      hierarchy.slice(0, columnLength),
      hierarchy.slice(columnLength, 2 * columnLength),
      hierarchy.slice(2 * columnLength, hierarchy.length),
    ]

    return hierarchy.length > 0 ? (
      <>
        <Section icon={<SearchOutlinedIcon />} title={'Refine Your Results'}>
          <Box sx={{ width: '100%' }}>
            <Grid container className={style.refinementItems}>
              {columns.map((column: any[], index: number) => (
                <Grid key={`column-${index}`} item xs={4} className={style.column}>
                  {displayHierarchy(column)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Section>
        <Box sx={{ height: '40px' }} />
      </>
    ) : (
      <></>
    )
  }

  return (
    <Index indexName='assets' indexId='refineChips_query'>
      <Configure
        distinct
        facetingAfterDistinct={false}
        analytics
        clickAnalytics
        enablePersonalization
        attributesToRetrieve={[
          'concept.label',
          'concept.types',
          'concept.preferredLineage',
          'concept.lineagePaths',
        ]}
        hitsPerPage={1000}
        filters={`${browseAndRefineFilterString} ${filterString} AND NOT content.source:FDB AND (NOT cmx.type:Topic OR cmx.coreConceptSet:true)`}
      />
      <RefinementItems />
    </Index>
  )
}

/**
 * This is a recursive function that accepts an array of hierarchical data and turns the whole tree into Accordions to display.
 * @param level  an array of hierarchical data to display recursively
 * @returns A single JSX.Element containing the display information for the hierarchy.
 */
function displayHierarchy(level: any[]) {
  return (
    <div className={style.refinementLevel}>
      {level.map((hierarchyItem: Node) => (
        <HierarchicalAccordion key={hierarchyItem.refineLabel} hierarchyItem={hierarchyItem} />
      ))}
    </div>
  )
}

function HierarchicalAccordion({ hierarchyItem }: { hierarchyItem: Node }) {
  const updateBrowsePath = useSearchStore(state => state.updateBrowsePath)
  const searchTerm = useSearchStore(state => state.searchTerm)
  const updateSearchTerm = useSearchStore(state => state.updateSearchTerm)
  const [expanded, setExpanded] = useState<boolean>(false)
  const childrenCount = hierarchyItem.children.length
  return (
    <Accordion
      className={style.refinementItem}
      id={hierarchyItem.path}
      expanded={expanded}
      disableGutters
    >
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon
            className={style.refineItemIcon}
            sx={{
              visibility: childrenCount > 0 ? 'inherit' : 'hidden',
              transform: expanded ? 'rotate(180deg)' : 'rotate(270deg)',
            }}
          />
        }
        onClick={() => {
          if (hierarchyItem.children.length > 0) {
            setExpanded(!expanded)
          } else {
            updateBrowsePath(hierarchyItem.refineLabel)
            if (searchTerm) updateSearchTerm('')
          }
        }}
        aria-controls={hierarchyItem.path}
        className={style.refineItemTitle}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            transitionDuration: '0ms',
          },
          '& .MuiAccordionSummary-content': { m: 0 },
        }}
      >
        <Typography sx={expanded ? { fontWeight: 700 } : {}}>{hierarchyItem.label}</Typography>
      </AccordionSummary>
      {childrenCount > 0 && (
        <AccordionDetails className={style.dropdownArea}>
          {displayHierarchy(hierarchyItem.children)}
        </AccordionDetails>
      )}
    </Accordion>
  )
}
