import { useCallback, useEffect } from 'react'
import { Modal } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CmxButton from 'Components/CmxButton'
import styles from './ContentDelete.module.scss'
import modalStyle from 'Styles/Modal.module.scss'
import { DeleteInHouseContent } from 'Api/Import.api'
import { useManageStore } from 'Store/ManageState'
import { GlobalNotificationStateManager, SnackbarSeverity } from 'Store/GlobalNotificationState'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { StorageKeys } from 'Store/Hooks/useLocalStorage'

export default function DeleteModal({ contentToDelete }: { contentToDelete: any[] }) {
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [textFieldDirty, setTextFieldDirty] = useState(false)
  const [confirmText, setConfirmText] = useState('')

  const { deleteOpen, updateDeleteOpen, updateRefreshData } = useManageStore()

  const navigate = useNavigate()
  useEscapeKey(onCancel)
  var previousElement: Element | null = null

  useEffect(() => {
    if (previousElement == null) getPreviousElement()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousElement])

  useEffect(() => {
    if (confirmText !== '') setTextFieldDirty(true)
    confirmText.toUpperCase().trim() === 'CONFIRM' ? setValidated(true) : setValidated(false)
  }, [confirmText])

  async function onConfirm() {
    if (validated) {
      setLoading(true)
      DeleteInHouseContent(contentToDelete.map((ctd: any) => ctd.id)).then(response => {
        if (response.hasError) {
          var errorSummary =
            response.errorCount +
            ' title(s) could not be deleted at this time. Please try again or contact your Healthwise account manager.'
          GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)
          GlobalNotificationStateManager.SetLoadingModalError(
            response.errorMsg ?? 'An unknown error occured.'
          )
          //Error occured. Show to user
          GlobalNotificationStateManager.ShowSnackbarNotification(
            `Error deleting ${
              response.errorCount > 1 ? 'titles' : 'title'
            }. Please try again or contact your Healthwise account manager.`,
            SnackbarSeverity.Error,
            false,
            10000
          )
          updateDeleteOpen(false)
        } else {
          localStorage.removeItem(StorageKeys.SelectedInhouseRows)

          GlobalNotificationStateManager.ShowSnackbarNotification(
            `${response.successCount} ${
              response.successCount > 1 ? 'titles' : 'title'
            } deleted successfully.`,
            SnackbarSeverity.Success,
            false,
            10000
          )
          navigate(App_Routes.MANAGE)
          updateDeleteOpen(false)
        }
        updateRefreshData(true)
      })
    } else {
      setTextFieldDirty(true)
    }
  }

  function onCancel() {
    setConfirmText('')
    setTextFieldDirty(false)
    updateDeleteOpen(false)
    //reset focus back to Actions button
    setTimeout(function () {
      ;(previousElement as HTMLElement)?.focus()
      //   var actionDropDown = document.getElementById('actionDropDown')?.focus()
      //   if (actionDropDown) {
      //     ;(actionDropDown as HTMLElement)?.focus()
      //   }
    }, 0)
  }

  //allowing esc to close dialog for accessibility
  const KEY_NAME_ESC = 'Escape'
  const KEY_EVENT_TYPE = 'keyup'

  function useEscapeKey(onCancel: any) {
    const handleEscKey = useCallback(
      (event: { key: string }) => {
        if (event.key === KEY_NAME_ESC) {
          onCancel()
        }
      },
      [onCancel]
    )

    useEffect(() => {
      document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)

      return () => {
        document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
      }
    }, [handleEscKey])
  }

  function getPreviousElement() {
    previousElement = document.activeElement
  }

  return (
    <Modal open={deleteOpen} role='dialog' aria-modal='true' aria-labelledby='Delete titles'>
      <div id={styles.confirmModal} className={modalStyle.modalContent}>
        <h2 className={styles.modalConfirmTitle} data-qa='modalConfirmTitle'>
          {contentToDelete?.length > 1 ? `Delete ${contentToDelete.length} titles` : 'Delete title'}
          ?
        </h2>
        <p className={styles.modalContent} tabIndex={0}>
          The following {contentToDelete?.length > 1 ? 'titles' : 'title'} will be permanently
          deleted and will no longer be available to view, manage, assign, or find within your
          content ecosystem. This action cannot be undone.
        </p>

        <ul className={styles.modalList}>
          {contentToDelete?.map((content: any) => {
            return (
              <li key={content.id} tabIndex={0}>
                {content.title}
              </li>
            )
          })}
        </ul>

        <label htmlFor='confirm' className={styles.modalConfirmMessage}>
          Are you sure you want to delete{' '}
          {contentToDelete?.length > 1 ? contentToDelete?.length + ' titles' : 'this title'}?{' '}
          <span>Type 'confirm' below.</span>
        </label>

        <CmxTextField
          name='confirm'
          id='confirm'
          data-testid='confim-delete'
          value={confirmText}
          required
          error={textFieldDirty && !validated}
          errorText={"Please type 'confirm' to continue"}
          fullWidth
          wrapperStyles={styles.confirmTextInput}
          onChange={(e: any) => setConfirmText(e.target.value)}
        />

        <div className={styles.modalActions}>
          <div>
            <CmxButton onClick={onCancel} data-qa='modalConfirmCancelButton'>
              Cancel
            </CmxButton>
          </div>
          <div>
            <LoadingButton
              sx={{
                boxShadow: 'none',
                minWidth: '85px',
                textTransform: 'none',
                height: '40px',
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                borderColor: 'transparent',
                borderRadius: '8px',
                backgroundColor: '#DD372F',
                color: '#FFFFFF',
                '&:hover': {
                  borderColor: 'transparent',
                  backgroundColor: '#DD372F',
                  boxShadow: 'none',
                },
                '&:active': {
                  borderColor: 'transparent',
                  backgroundColor: '#DD372F',
                  boxShadow: 'none',
                },
                '& .MuiLoadingButton-loadingIndicator': {
                  color: '#727272',
                },
                '&.MuiLoadingButton-loading': {
                  color: 'transparent',
                  backgroundColor: 'transparent',
                  border: 'none',
                },
                "&[aria-disabled='true']": {
                  backgroundColor: '#EE9B97',
                },
              }}
              size='medium'
              onClick={onConfirm}
              loading={loading}
              variant='outlined'
              data-qa='modalConfirmDeleteButton'
            >
              Delete
            </LoadingButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
