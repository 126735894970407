import { LocalizationStateProvider } from 'Store/LocalizationState'
import { AlgoliaProvider } from 'Store/AlgoliaState'
import { CartStateProvider } from 'Store/CartState'
import { PreviewStateProvider } from 'Store/PreviewState'
import { GlobalNotificationStateProvider } from 'Store/GlobalNotificationState'
import { ImportStateProvider } from 'Store/ImportState'

export default function Appstate({ children }: any) {
  return (
    <LocalizationStateProvider>
      <GlobalNotificationStateProvider>
        <CartStateProvider>
          <AlgoliaProvider>
            <PreviewStateProvider>
              <ImportStateProvider>{children}</ImportStateProvider>
            </PreviewStateProvider>
          </AlgoliaProvider>
        </CartStateProvider>
      </GlobalNotificationStateProvider>
    </LocalizationStateProvider>
  )
}
