import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import AppSettings, { App_Routes, Environment } from 'ApplicationSettings/ApplicationSettings'
import CmxButton from 'Components/CmxButton'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import CmxToggleButtonGroup from 'Components/CmxToggleButtonGroup'
import { CopiedTooltip } from 'Components/TopicCluster/TopicCluster'
import SettingsCode from 'Resources/settings-code.svg'
import { AccessToken, useAuthState } from 'Store/AuthState'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import { useAlgolia } from 'Store/UseAlgolia'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import styles from './MediaEmbed.module.scss'

type MediaEmbedProps = {
  availableLocalizations: string[]
}

export default function MediaEmbed({ availableLocalizations }: MediaEmbedProps) {
  const { previewState, previewStateDispatch } = usePreviewState()
  const { authState } = useAuthState()
  const { isLoading, algoliaClient } = useAlgolia(authState.accessToken)
  const navigate = useNavigate()

  const [localization, setLocalization] = useState<'en-us' | 'es-us'>('en-us')
  const [showTitle, setShowTitle] = useState(true)
  const [showAttribution, setShowAttribution] = useState(false)
  const [instructionsVisible, setInstructionsVisible] = useState(false)
  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState<boolean>(false)
  const [showAttributionTooltip, setShowAttributionTooltip] = useState<boolean>(false)
  const [showAdaptationTooltip, setShowAdaptationTooltip] = useState<boolean>(false)

  const hwid = previewState.contentMetadata.hwid
  const attributionText =
    '<p>© 2016 - <span><script type="text/javascript">document.write(new Date().getFullYear())</script></span> Healthwise, Incorporated. This information does not replace the advice of a doctor. Healthwise, Incorporated disclaims any warranty or liability for your use of this information.</p>'
  const adaptationText =
    '<p>© Copyrighted material adapted with permission from Healthwise, Incorporated. This information does not replace the advice of a doctor.</p>'

  const webComponentEnvironment = (() => {
    switch (AppSettings.Environment) {
      case Environment.localdev:
        return 'dev'
      case Environment.development:
        return 'dev'
      case Environment.test:
        return 'test'
      default:
        return 'prod'
    }
  })()

  const embedText = `<script src="${
    AppSettings.WebComponentUrl
  }"></script><hw-content hwid="${hwid}" loc="${localization}" showTitle="${showTitle}" showAttribution="${showAttribution}" hideErrors useDefaultStyle ecode="${
    authState.ecode
  }" ${webComponentEnvironment !== 'prod' && `environment="${webComponentEnvironment}" `}/>`

  const checkboxSx = {
    '&.Mui-focusVisible': {
      background: styles.white,
    },
  }

  return !isLoading && algoliaClient?.appId ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.media}>
          {
            <hw-content
              loc={localization}
              bearerToken={AccessToken()}
              hwid={hwid}
              environment={webComponentEnvironment}
              hideErrors
              useDefaultStyle
              showAttribution={showAttribution}
              showTitle={showTitle}
            />
          }
        </div>
        <div className={styles.controls}>
          <CmxToggleButtonGroup
            label='Language'
            buttons={availableLocalizations}
            value={localization}
            setValue={setLocalization as (val: string) => void}
          />
          <div className={styles.section}>
            Options
            <div className={styles.checkboxes}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showTitle}
                    onClick={() => {
                      setShowTitle(!showTitle)
                    }}
                    sx={checkboxSx}
                  />
                }
                label='Show Title'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAttribution}
                    onClick={() => {
                      setShowAttribution(!showAttribution)
                    }}
                    sx={checkboxSx}
                  />
                }
                label='Show Attribution'
              />
            </div>
          </div>
          <div className={styles.label}>Embed Code</div>
          <div className={styles.embedCopy}>
            <CmxTextField
              fullWidth
              value={embedText}
              inputProps={{ spellCheck: 'false' }}
              mode='light'
            />
            <CopiedTooltip open={copiedTooltipOpen} setOpen={setCopiedTooltipOpen} zIndex={10000}>
              <Button
                className={styles.copy}
                onClick={() => {
                  setCopiedTooltipOpen(true)
                  navigator.clipboard.writeText(embedText)
                }}
              >
                <ContentCopyIcon />
              </Button>
            </CopiedTooltip>
          </div>

          <Button
            className={styles.showInstructionsDropDown}
            onClick={() => {
              setInstructionsVisible(!instructionsVisible)
            }}
            disableRipple
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          >
            <Typography className={styles.showInstructionsDropdownLabel}>
              {instructionsVisible ? 'Hide instructions' : 'Show instructions'}
            </Typography>
            {instructionsVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
          {instructionsVisible && (
            <div className={styles.attributionWrapper}>
              <div className={styles.attributionSection}>
                <div className={styles.attributionTitle}>How to embed</div>
                <div className={styles.approvedSites}>
                  Make sure your site is included on the following list:
                  <CmxButton
                    variant='text'
                    onClick={() => {
                      previewStateDispatch({ type: PreviewStateAction.CLEARPREVIEWCONTENT })
                      previewStateDispatch({ type: PreviewStateAction.SETPREVIEWOPEN, data: false })
                      navigate(App_Routes.EMBEDDING)
                    }}
                  >
                    <img alt='Approved Embedding Sites' src={SettingsCode} />
                    Approved Embedding Sites
                  </CmxButton>
                </div>
              </div>
              <div className={styles.attributionSection}>
                <div className={styles.attributionTitle}>Attribution requirements</div>
                <p className={styles.attributionSummary}>
                  Healthwise’s copyright notice and disclaimer must be included with, near, or next
                  to where you have used or adapted Healthwise content. Or you can link to the
                  notice and disclaimer on the same page as the Healthwise content or adaptations.
                  For example, you could make the word “Source” or “Contributions” a hyperlink that
                  goes to a page that has the notice and disclaimer.
                </p>
              </div>
              <div className={styles.attributionSection}>
                <div className={styles.attributionSubTitle}>Healthwise Content</div>
                <p>
                  {`Include this statement each place that you’ve used Healthwise content (the year needs to reflect the current year):`}
                </p>
                <p className={styles.attributionQuote}>
                  "
                  <span className={styles.disclaimerText}>
                    © 2016 - {new Date().getFullYear()} Healthwise, Incorporated. This information
                    does not replace the advice of a doctor. Healthwise, Incorporated disclaims any
                    warranty or liability for your use of this information.
                  </span>
                  "
                </p>
                <div className={styles.contentCopy}>
                  <CmxTextField
                    id='copyright-textfield'
                    fullWidth
                    value={attributionText}
                    inputProps={{ spellCheck: 'false' }}
                    mode='light'
                  />
                  <CopiedTooltip
                    open={showAttributionTooltip}
                    setOpen={setShowAttributionTooltip}
                    zIndex={10000}
                  >
                    <Button
                      className={styles.copy}
                      onClick={() => {
                        setShowAttributionTooltip(true)
                        navigator.clipboard.writeText(attributionText)
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </CopiedTooltip>
                </div>
              </div>
              <div className={styles.attributionSection}>
                <div className={styles.attributionSubTitle}>Adaptations</div>
                <p>
                  Include this statement each place that you’ve used adapted Healthwise content: 
                </p>
                <p className={styles.attributionQuote}>
                  "
                  <span className={styles.disclaimerText}>
                    © Copyrighted material adapted with permission from Healthwise, Incorporated.
                    This information does not replace the advice of a doctor.
                  </span>
                  "
                </p>
                <div className={styles.contentCopy}>
                  <CmxTextField
                    id='adaptations-textfield'
                    fullWidth
                    value={adaptationText}
                    inputProps={{ spellCheck: 'false' }}
                    mode='light'
                  />
                  <CopiedTooltip
                    open={showAdaptationTooltip}
                    setOpen={setShowAdaptationTooltip}
                    zIndex={10000}
                  >
                    <Button
                      className={styles.copy}
                      onClick={() => {
                        setShowAdaptationTooltip(true)
                        navigator.clipboard.writeText(adaptationText)
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </CopiedTooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
