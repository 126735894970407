import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AccessToken } from 'Store/AuthState'

const EMBED_URL = `${AppSettings.CMXServiceUrl}/api/embeds`

export const GetWhitelist = async (ecode: string): Promise<any> => {
  let res = await fetch(EMBED_URL, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      ecode: ecode,
      'Content-Type': 'application/json',
    },
  })

  if (!res.ok) {
    console.error(res.statusText)
    return false
  }

  return res.json()
}

export const AddWhitelistItem = async (ecode: string, domain: string): Promise<any> => {
  let res = await fetch(EMBED_URL, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      ecode: ecode,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(domain),
  })

  if (!res.ok) {
    console.error(res.statusText)
    return false
  }

  return res.json()
}

export const DeleteWhitelistItem = async (ecode: string, domain: string): Promise<any> => {
  let res = await fetch(EMBED_URL, {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      ecode: ecode,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(domain),
  })

  if (!res.ok) {
    console.error(res.statusText)
    return false
  }

  return res.json()
}

export const UpdateWhitelistItem = async (
  ecode: string,
  oldDomain: string,
  newDomain: string
): Promise<any> => {
  let res = await fetch(EMBED_URL, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      ecode: ecode,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      oldDomain: oldDomain,
      newDomain: newDomain,
    }),
  })

  if (!res.ok) {
    console.error(res.statusText)
    return false
  }

  return res.json()
}
