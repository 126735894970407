import { SVGAttributes } from 'react'

interface TriangleSvgProps {
  className?: string
  fill?: SVGAttributes<SVGPolygonElement>['fill']
  height?: SVGAttributes<SVGSVGElement>['height']
  width?: SVGAttributes<SVGSVGElement>['width']
}

export function TriangleSvg({
  className,
  fill = '#fff',
  height = 24,
  width = 24,
}: TriangleSvgProps) {
  const points = `0, ${width}, ${+width / 2}, 0, ${width}, ${height}`

  return (
    <svg {...{ className, height, width }}>
      <polygon {...{ fill, points }} />
    </svg>
  )
}
