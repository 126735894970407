import { getUserProfile } from 'Api/UserProfile'
import React, { useEffect, useState } from 'react'
import { useAuthState } from 'Store/AuthState'

const ProfileSchema = {
  created: '',
  email: '',
  firstName: '',
  identityProviderId: '',
  lastLogin: '',
  lastName: '',
  name: '',
  organizationId: '',
  referenceId: '',
  roles: [],
  status: '',
  sub: '',
  logService: false,
  contentFeedback: false,
}

export const ProfileContext = React.createContext(ProfileSchema)

export const ProfileProvider = (props: any) => {
  const { authState } = useAuthState()
  const [profile, setProfile] = useState(ProfileSchema)

  useEffect(() => {
    async function fetchUserProfile(accessToken: string) {
      try {
        await getUserProfile(accessToken).then(res => {
          if (res.data) {
            setProfile(res.data)
          }
        })
      } catch (err) {
        // TODO Render error to user
        // console.error('Unable to get the current user');
        // console.error(err);
      }
    }

    if (profile.sub === '' && authState && authState.accessToken) {
      fetchUserProfile(authState.accessToken)
    }
  }, [authState, profile, authState.accessToken, authState?.permissions?.userId])

  return <ProfileContext.Provider value={profile}>{props.children}</ProfileContext.Provider>
}
