import { Checkbox } from '@mui/material'
import { LanguageSelect } from 'Components/LanguageSelect/LanguageSelect'
import { DocTypeIcon } from 'Components/ResultHit/Hit'
import { FC } from 'react'
import { CartAction, useCartState } from 'Store/CartState'
import ContentItem from 'Store/Models/ContentItem'
import style from './Cart.module.scss'

type Props = {
  selectedLanguage: string
  showPreview: (cartItem: ContentItem) => void
  cartItem: ContentItem
  handleLanguageChange: (localizationKey: string, cartItem: ContentItem) => void
}

export const CartItem: FC<Props> = ({
  selectedLanguage,
  showPreview,
  cartItem,
  handleLanguageChange,
}) => {
  const { cartStateDispatch } = useCartState()

  function removeFromCart(cartItem: ContentItem) {
    cartStateDispatch({ type: CartAction.REMOVECARTITEM, data: cartItem })
  }

  return (
    <div className={style.cartRow}>
      <Checkbox
        color='primary'
        checked={true}
        className={style.cartCheck}
        aria-describedby={cartItem.title}
        aria-checked='true'
        onChange={() => removeFromCart(cartItem)}
      />
      <LanguageSelect
        cartItem={cartItem}
        handleLanguageChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
      />
      <div className={style.textTitleAndDesc}>
        <div
          className={style.contentTitle}
          onClick={() => {
            showPreview(cartItem)
          }}
        >
          {cartItem.title}
        </div>
        <div className={style.contentDesc}>{cartItem.description}</div>
      </div>
      <div className={style.contentTypeAndId}>
        <p className={style.cartItemId}>{cartItem.hwid}</p>
        <DocTypeIcon className={style.cartItemType} type={cartItem.docType} />
      </div>
    </div>
  )
}
