import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import { ReactComponent as IndexDropdownIcon } from 'Resources/index_dropdown_icon.svg'
import { ReactComponent as CategoryDropdownIcon } from 'Resources/category_dropdown_icon.svg'
import { useNavigate } from 'react-router-dom'
import PageRefinement from '../PageRefinement/PageRefinement'
import style from './PagePopup.module.scss'
import classNames from 'classnames'
import { PageDropdown } from 'Pages/Browse/Browse'

export type PagePopupModalProps = {
  callingPageDropdown: PageDropdown
  open: boolean
  setActivePage: (page: string | null) => void
}

export default function PagePopup({
  callingPageDropdown,
  open,
  setActivePage,
}: PagePopupModalProps) {
  const navigate = useNavigate()
  const isLargeScreen = useMediaQuery('(min-width:1400px)')

  const DescriptionIcon =
    callingPageDropdown.title === 'Category' ? CategoryDropdownIcon : IndexDropdownIcon
  const description =
    callingPageDropdown.title === 'Category'
      ? 'Explore content by broad health or medical areas.'
      : 'Find content about specific conditions, tests, treatments, wellness, and prevention.'
  const showAllMessage =
    callingPageDropdown.title === 'Category' ? 'Show All Categories' : 'Show Full Index'

  return !callingPageDropdown.attribute || callingPageDropdown.attribute?.length < 1 ? (
    <h3>Under Construction...</h3>
  ) : (
    <>
      {open && (
        <Box className={style.pagePopup}>
          <Box
            className={style.content}
            sx={{
              flexDirection: isLargeScreen ? 'row' : 'column',
              alignItems: isLargeScreen ? 'flex-start' : undefined,
            }}
          >
            <Box className={style.description}>
              <Box className={style.iconContainer}>
                <DescriptionIcon
                  className={classNames(style.icon, !isLargeScreen && style.smallIcon)}
                />
              </Box>
              <Box className={style.descriptionText}>
                {description}
                <Button
                  className={style.showAllButton}
                  data-testid='showAllDropdownButton'
                  onClick={() => {
                    setActivePage(null)
                    navigate(callingPageDropdown.route ?? '')
                  }}
                >
                  {showAllMessage}
                  <ChevronRightRoundedIcon />
                </Button>
              </Box>
            </Box>
            <PageRefinement
              callingPageDropdown={callingPageDropdown}
              sort='group'
              setActivePage={setActivePage}
              headerOnlyVariant
            />
          </Box>
          <Box className={style.controls}>
            <IconButton onClick={() => setActivePage(null)} className={style.closeButton}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      {open && <Box className={style.backdrop} />}
    </>
  )
}
