export const AgeRangeAll = 'all'

export const AgeRangeCustom = 'custom'

export const IDTooltipMessage =
  'ID is selected at the time of upload. Once a file has been imported into the system, the ID cannot be changed.'

export const LanguageTooltipMessage =
  'Language is selected at the time of upload. Once a file has been imported into the system, the language cannot be changed.'

export const SpecialtiesTooltipMessage =
  'Medical Specialties are used to categorize content. Medical Specialties are tied to concepts and cannot be added or removed individually.'
