import { Grid, CircularProgress, Modal } from '@mui/material'

import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import { useManageStore } from 'Store/ManageState'

import style from './Loading.module.scss'
import modalStyle from 'Styles/Modal.module.scss'
import CmxButton from 'Components/CmxButton'

export default function LoadingModal() {
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()

  const { fromSuppress, isBulk, isRestore, suppressSuccessCount } = useManageStore()

  return (
    <Modal
      open={globalNotificationState.loadingModalInfo.visible}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <div className={`${modalStyle.modalContent} ${style.loadingContent}`}>
        <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
          <Grid item xs={12}>
            <div className={style.loadingTitle}>
              {globalNotificationState.loadingModalInfo.message}
            </div>
          </Grid>
          {globalNotificationState.loadingModalInfo.durationMessage && (
            <Grid item xs={12}>
              <div
                className={style.loadingDuration}
                dangerouslySetInnerHTML={{
                  __html: globalNotificationState.loadingModalInfo.durationMessage,
                }}
              />
            </Grid>
          )}
          {globalNotificationState.loadingModalSummary.message && (
            <Grid item xs={12}>
              <div className={style.loadingSummary}>
                {globalNotificationState.loadingModalSummary.message}
              </div>
            </Grid>
          )}
          {globalNotificationState.loadingModalInfo.error && (
            <Grid item xs={12}>
              <div className={style.loadingError}>
                {globalNotificationState.loadingModalInfo.error}
              </div>
            </Grid>
          )}
          <div className={style.loadingSpinnerContainer}>
            {globalNotificationState.loadingModalInfo.error === '' ? (
              <CircularProgress
                aria-label='Loading'
                className={style.loadingSpinner}
                color='primary'
              />
            ) : (
              <CmxButton
                className={style.loadingErrorFinish}
                primary={true}
                onClick={() => {
                  GlobalNotificationStateManager.SetLoadingModalVisible(false)
                  GlobalNotificationStateManager.SetLoadingModalError('')
                  if (fromSuppress && suppressSuccessCount > 0) {
                    //show success snack bar for suppression modal
                    var successMsg = ''
                    if (isBulk) {
                      successMsg =
                        suppressSuccessCount +
                        ` titles ${isRestore ? 'restored' : 'suppressed'} successfully.`
                    } else {
                      successMsg = `1 title ${isRestore ? 'restored' : 'suppressed'} successfully.`
                    }
                    GlobalNotificationStateManager.ShowSnackbarNotification(
                      successMsg,
                      SnackbarSeverity.Success,
                      false
                    )
                  }
                }}
              >
                Finish
              </CmxButton>
            )}
          </div>
        </Grid>
      </div>
    </Modal>
  )
}
