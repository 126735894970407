import { useEffect, useState } from 'react'
import { CircularProgress, Grid, Modal } from '@mui/material'

import { BulkSuppress, Restore } from 'Api/Content.api'

import CmxButton from 'Components/CmxButton'

import { AuthState, useAuthState } from 'Store/AuthState'
import {
  GlobalNotificationStateManager,
  SnackbarSeverity,
  useGlobalNotificationState,
} from 'Store/GlobalNotificationState'
import { ContentItemMetadata, ContentType } from 'Store/Models/ContentItem'

import SuppressionMessageContent from './SuppressionMessageContent'
import style from './SuppressModal.module.scss'
import { NetworkCallStateDispatch, useNetworkCallState } from 'Hooks'
import { GetNonManagementSubs } from 'Utilities/OrgUtil'

export interface SuppressModalLSOProps {
  isBulk?: boolean
  isHWContent: boolean
  isRestore?: boolean
  onCancel: () => void
  onComplete: (success: boolean) => void
  suppressItems: Pick<ContentItemMetadata, 'hwid' | 'title'>[]
  visible: boolean
}

export default function SuppressModalLSO({
  isBulk = false,
  isHWContent,
  isRestore = false,
  onCancel,
  onComplete,
  suppressItems,
  visible,
}: SuppressModalLSOProps) {
  const [networkCallState, networkCallStateDispatch] = useNetworkCallState()
  const { authState } = useAuthState()
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()
  const [singleItemTitle, setSingleItemTitle] = useState('')

  useEffect(() => {
    if (!isBulk && suppressItems.length) setSingleItemTitle(suppressItems[0].title)
  }, [isBulk, suppressItems])

  useEffect(() => {
    if (!networkCallState.init || networkCallState.loading) return undefined

    restoreOrBulkSuppress({
      authState,
      GlobalNotificationStateManager,
      isBulk,
      isHWContent,
      isRestore,
      networkCallStateDispatch,
      onComplete,
      suppressItems,
    })
  }, [
    authState,
    GlobalNotificationStateManager,
    isBulk,
    isHWContent,
    isRestore,
    networkCallState,
    networkCallStateDispatch,
    onComplete,
    suppressItems,
  ])

  function getTitle() {
    if (isRestore) return isBulk ? `Restore ${suppressItems.length} titles?` : 'Restore title?'

    return isBulk ? `Suppress ${suppressItems.length} titles?` : 'Suppress title?'
  }

  return (
    <Modal open={visible && !globalNotificationState.loadingModalInfo.visible}>
      <Grid container className={style.modalContent}>
        <Grid container direction='row'>
          <Grid item>
            <div className={style.modalTitle} data-qa='modalTitle'>
              {getTitle()}
            </div>
          </Grid>
        </Grid>

        <Grid container direction='row'>
          <div className={style.suppressionMessageContainer}>
            <SuppressionMessageContent
              {...{ isBulk, isHWContent, isRestore, singleItemTitle, suppressItems }}
            />
          </div>
        </Grid>

        <Grid
          className={style.buttonPanel}
          container
          direction='row'
          justifyContent='flex-end'
          spacing={1}
        >
          <Grid item>
            <CmxButton
              data-qa='modalCancelButton'
              disabled={networkCallState.loading}
              onClick={onCancel}
            >
              Cancel
            </CmxButton>
          </Grid>

          <Grid item>
            {networkCallState.loading ? (
              <CircularProgress className='loader' color='primary' />
            ) : (
              <CmxButton
                data-qa='modalConfirmButton'
                data-testid='modal-confirmation-button'
                onClick={networkCallStateDispatch.init}
                primary
              >
                {isRestore ? 'Restore' : 'Suppress'}
              </CmxButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

interface RestoreOrBulkSuppressArgs {
  authState: AuthState
  GlobalNotificationStateManager: typeof GlobalNotificationStateManager
  isBulk: SuppressModalLSOProps['isBulk']
  isHWContent: SuppressModalLSOProps['isHWContent']
  isRestore: SuppressModalLSOProps['isRestore']
  networkCallStateDispatch: NetworkCallStateDispatch
  onComplete: SuppressModalLSOProps['onComplete']
  suppressItems: SuppressModalLSOProps['suppressItems']
}

async function restoreOrBulkSuppress({
  authState,
  GlobalNotificationStateManager,
  isBulk,
  isHWContent,
  isRestore,
  networkCallStateDispatch,
  onComplete,
  suppressItems,
}: RestoreOrBulkSuppressArgs) {
  const loadingModalMessage = (() => {
    if (isRestore && isBulk) return 'Restoring titles'
    if (isRestore) return 'Restoring title'
    if (isBulk) return 'Suppressing titles'
    return 'Supressing title'
  })()

  GlobalNotificationStateManager.SetLoadingModalMessage(
    loadingModalMessage,
    'This may take a moment...',
    true
  )

  if (!suppressItems.length) return networkCallStateDispatch.failure()

  networkCallStateDispatch.request()
  let success = true
  if (isRestore) {
    Restore(suppressItems, isHWContent ? ContentType.Healthwise : ContentType.InhousePDF)
      .then(response => {
        if (response.hasError) {
          networkCallStateDispatch.failure()

          if (!response.errorCount) return null

          success = false
          if (isBulk) {
            const errorSummary =
              response.errorCount +
              ' title(s) could not be restored at this time. Please try again or contact your Healthwise account manager.'

            GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)

            GlobalNotificationStateManager.SetLoadingModalError(
              response.errorMsg ?? 'An unknown error occured.'
            )

            return null
          }

          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error restoring title. Please try again or contact your Healthwise administrator.',
            SnackbarSeverity.Error,
            false
          )

          GlobalNotificationStateManager.SetLoadingModalVisible(false)

          return null
        }

        networkCallStateDispatch.success()

        GlobalNotificationStateManager.SetLoadingModalError('')

        GlobalNotificationStateManager.ShowSnackbarNotification(
          response.successCount + ' title(s) restored successfully.',
          SnackbarSeverity.Success,
          false
        )

        GlobalNotificationStateManager.SetLoadingModalVisible(false)

        return null
      })
      .catch(() => {
        networkCallStateDispatch.failure()

        GlobalNotificationStateManager.ShowSnackbarNotification(
          'Error restoring title(s). Please try again or contact your Healthwise administrator.',
          SnackbarSeverity.Error,
          false
        )
      })
      .finally(() => {
        onComplete(success)
      })

    return undefined
  }

  const subs = GetNonManagementSubs(authState.orgInfo?.subscriptions ?? []).map(sub => sub.id)

  BulkSuppress(suppressItems, true, subs ?? []) // Just set "FromBasket" to true as it will just be a double check in some cases
    .then(response => {
      if (response.hasError) {
        networkCallStateDispatch.failure()

        if (response.errorCount) {
          const errorSummary = isBulk
            ? response.errorCount + ' title(s) could not be suppressed at this time:'
            : 'The following localization(s) could not be suppressed at this time:'

          GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)

          GlobalNotificationStateManager.SetLoadingModalError(
            response.errorMsg ?? 'An unknown error occured.'
          )
          success = false
        }

        return null
      }

      networkCallStateDispatch.success()

      const successMsg = isBulk
        ? response.successCount + ' titles suppressed successfully.'
        : '1 title suppressed successfully.'

      GlobalNotificationStateManager.SetLoadingModalError('')

      GlobalNotificationStateManager.ShowSnackbarNotification(
        successMsg,
        SnackbarSeverity.Success,
        false
      )

      GlobalNotificationStateManager.SetLoadingModalVisible(false)
    })
    .catch(e => {
      success = false
      networkCallStateDispatch.failure()

      GlobalNotificationStateManager.SetLoadingModalError(e.message ?? 'An unknown error occured.')
    })
    .finally(() => {
      onComplete(success)
    })
}
