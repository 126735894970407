import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react'
import { useAuthState } from './AuthState'
import algoliasearch, { SearchClient } from 'algoliasearch'

export enum AlgoliaActionEnum {
  INITIALIZE,
  UPDATE_ENTITLEMENT,
  HAS_ERROR,
}

interface AlgoliaKey {
  QueryAppId: string
  QueryKey: string
}

export type AlgoliaClient = SearchClient

export type AlgoliaState = {
  appId?: string
  availableLanguages?: string[]
  entitlement?: string
  hasError?: boolean
  index?: string
  key: AlgoliaKey
  languagePackageAttribute?: string
  suggestions?: string
  userToken?: string
}

const createInitialState = (userToken?: string): AlgoliaState => ({
  appId: undefined,
  availableLanguages: [],
  entitlement: undefined,
  hasError: false,
  index: undefined,
  key: { QueryAppId: '', QueryKey: '' },
  languagePackageAttribute: undefined,
  suggestions: undefined,
  userToken,
})

interface AlgoliaContextType {
  algoliaClient?: AlgoliaClient
  algoliaState: AlgoliaState
  algoliaStateDispatch: Dispatch<AlgoliaAction>
}

const AlgoliaContext = createContext<AlgoliaContextType>({
  algoliaState: createInitialState(),
  algoliaStateDispatch: () => null,
})

interface AlgoliaProviderProps {
  children: ReactNode
}

export function AlgoliaProvider({ children }: AlgoliaProviderProps) {
  const {
    authState: { accessToken },
  } = useAuthState()
  const [algoliaState, algoliaStateDispatch] = useReducer(reducer, createInitialState(accessToken))
  const algoliaClient = algoliasearch(algoliaState.key.QueryAppId, algoliaState.key.QueryKey)

  return (
    <AlgoliaContext.Provider value={{ algoliaClient, algoliaState, algoliaStateDispatch }}>
      {children}
    </AlgoliaContext.Provider>
  )
}

type AlgoliaAction =
  | {
      type: AlgoliaActionEnum.INITIALIZE
      keys: AlgoliaKey
      index: string
      suggestions: string
      languagePackageAttribute: string
      availableLanguages: string[]
    }
  | { type: AlgoliaActionEnum.UPDATE_ENTITLEMENT; entitlement: string }
  | { type: AlgoliaActionEnum.HAS_ERROR; hasError: boolean }

function reducer(state: AlgoliaState, action: AlgoliaAction) {
  switch (action.type) {
    case AlgoliaActionEnum.INITIALIZE:
      return {
        ...state,
        index: action.index,
        suggestions: action.suggestions,
        key: action.keys,
        languagePackageAttribute: action.languagePackageAttribute,
        availableLanguages: action.availableLanguages,
      }
    case AlgoliaActionEnum.UPDATE_ENTITLEMENT:
      return {
        ...state,
        entitlement: action.entitlement,
      }
    case AlgoliaActionEnum.HAS_ERROR:
      return { ...state, hasError: action.hasError }
    default:
      return state
  }
}

export function useAlgoliaState() {
  return useContext(AlgoliaContext)
}
