import { useEffect, useRef, useState } from 'react'

import { IconButton, Link } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import appsIcon from 'Resources/ic_baseline-apps.svg'
import userIcon from 'Resources/mdi_user.svg'
import helpIcon from 'Resources/material-symbols_help-rounded.svg'
import serviceIcon from 'Resources/mdi_tools.svg'
import feedbackIcon from 'Resources/ic_baseline-feedback.svg'
import signoutIcon from 'Resources/ic_outline-logout.svg'

import { UserInformation } from 'Components/Header/Header'

import style from './UserMenu.module.scss'
import AppSettings, { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { useAuthState } from 'Store/AuthState'

interface UserMenuProps {
  user: UserInformation
}

export const ancestorHasId = (el: any, id: any) => {
  let parent = el
  while (parent) {
    parent = parent.parentElement
    if (parent && parent.id === id) {
      return parent
    }
  }
  return undefined
}

const UserMenu = ({ user }: UserMenuProps) => {
  const { firstName, lastName, email, logService, contentFeedback } = user
  const name = `${firstName} ${lastName}`
  const [visible, setVisible] = useState<boolean>(false)
  const [userMenuItems, setUserMenuItems] = useState<any>(null)
  const userInfoContainerId = 'userInfoContainer'
  const { authState } = useAuthState()
  const accessToken = authState?.accessToken
  const myHealthwiseUrl = `${AppSettings.MyHealthwiseIssuer}/${authState.organizationAlias}${window.location.search}`
  const userProfile = `${AppSettings.MyHealthwiseIssuer}/${authState.organizationAlias}/profile`
  const helpUrl = AppSettings.CSCUrl + '#' + accessToken
  const logServiceRequestUrl = AppSettings.CSCLogRequestUrl + '#' + accessToken
  const feedbackUrl = `${AppSettings.MyHealthwiseIssuer}/${authState.organizationAlias}/feedback`

  const clickListener = (e: { target: any }) => {
    const isNotControlsParent = !ancestorHasId(e.target, userInfoContainerId)
    if (isNotControlsParent) {
      setVisible(false)
      removeClickHandler()
    }
  }

  const removeClickHandler = () => {
    window.removeEventListener('click', clickListener)
  }

  const addClickHandler = () => {
    window.addEventListener('click', clickListener)
  }

  const firstMenuButton = useRef<any>(null)
  const menuButton = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setUserMenuItems(document.querySelectorAll('li a'))
  }, [])

  useEffect(() => {
    if (firstMenuButton?.current && visible) {
      firstMenuButton.current.focus()
    }
  }, [firstMenuButton, visible])

  var currentIndex = 0

  var gotoIndex = function (idx: number) {
    if (idx === userMenuItems.length) {
      idx = 0
    } else if (idx < 0) {
      idx = userMenuItems.length - 1
    }

    userMenuItems[idx].focus()
    currentIndex = idx
  }

  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    setVisible(!visible)
    if (visible && menuButton?.current) {
      menuButton.current.focus()
      removeClickHandler()
    } else {
      addClickHandler()
    }
  }

  //https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
  var keys = {
    tab: 'Tab',
    enter: 'Enter',
    esc: 'Escape',
    space: 'Space',
    left: 'ArrowLeft',
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
  }

  const keyPressEventListener = (event: any) => {
    var prevdef = true
    switch (event.code) {
      case keys.tab:
        if (event.shiftKey) {
          gotoIndex(currentIndex - 1)
        } else {
          gotoIndex(currentIndex + 1)
        }
        prevdef = true
        break
      case keys.right:
        prevdef = true
        break
      case keys.left:
        prevdef = true
        break
      case keys.esc:
        setVisible(false)
        if (menuButton?.current) {
          menuButton.current.focus()
          removeClickHandler()
        }
        prevdef = true
        break
      case keys.down:
        gotoIndex(currentIndex + 1)
        prevdef = true
        break
      case keys.up:
        gotoIndex(currentIndex - 1)
        prevdef = true
        break
      case keys.enter:
      case keys.space:
        event.target.click()
        prevdef = false
        break
      default:
        prevdef = true
        break
    }

    if (prevdef) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  return (
    <>
      <IconButton
        id='usermenubutton'
        aria-controls='userMenu'
        aria-haspopup='true'
        aria-label={`${name} Account Menu`}
        aria-expanded={Boolean(visible)}
        onClick={handleClick}
        ref={menuButton}
        style={{ padding: '0 12px' }}
        className={style.profileButton}
      >
        <KeyboardArrowDown className={style.arrowDownIcon} />
      </IconButton>
      <div
        id={userInfoContainerId}
        data-testid={userInfoContainerId}
        className={`${style.userInfoContainer} ${visible ? '' : style.invisible}`}
      >
        <div className={style.header}>
          <p className={style.name}>{name}</p>
          <p className={style.email}>{email}</p>
        </div>

        <div className={style.divider} />

        <nav className={style.nav}>
          <ul id='userMenu' role='menu' className={style.myHWMenuUl}>
            <li
              role='presentation'
              className={style.myHWMenuLi}
              onKeyDown={keyPressEventListener}
              ref={firstMenuButton}
            >
              <Link
                ref={firstMenuButton}
                role='menuitem'
                className={style.myHWLink}
                href={myHealthwiseUrl}
                tabIndex={0}
                aria-label='My Healthwise'
              >
                <img alt='My Healthwise' src={appsIcon} className={style.myHwIcon} />
                MyHealthwise
              </Link>
            </li>
          </ul>
          <div className={style.divider} />
          <ul id='userMenu' role='menu' className={style.menuUl}>
            <li role='presentation' className={style.menuLi}>
              <Link
                role='menuitem'
                className={style.link}
                href={userProfile}
                aria-label='My Profile'
              >
                <img alt='My Profile Icon' src={userIcon} className={style.icon} />
                My Profile
              </Link>
            </li>
            <li role='presentation' className={style.menuLi}>
              <Link
                role='menuitem'
                className={style.link}
                href={helpUrl}
                target='_blank'
                rel='noreferrer'
                aria-label='Get Help - external link'
              >
                <img alt='Get Help Icon' src={helpIcon} className={style.icon} />
                Get Help
              </Link>
            </li>
            {logService ? (
              <li role='presentation' className={style.menuLi}>
                <div className={style.navDiv}>
                  <Link
                    role='menuitem'
                    className={style.link}
                    href={logServiceRequestUrl}
                    target='_blank'
                    rel='noreferrer'
                    aria-label='Log Service Request - external link'
                  >
                    <img alt='Service Request Icon' src={serviceIcon} className={style.icon} />
                    Log Service Request
                  </Link>
                </div>
              </li>
            ) : (
              <li className={style.menuLiHide}></li>
            )}
            {contentFeedback ? (
              <li role='presentation' className={style.menuLi}>
                <div className={style.navDiv}>
                  <Link
                    role='menuitem'
                    className={style.link}
                    href={feedbackUrl}
                    rel='noreferrer'
                    aria-label='Submit Content Feedback - external link'
                  >
                    <img alt='Submit Feedback Icon' src={feedbackIcon} className={style.icon} />
                    Submit Content Feedback
                  </Link>
                </div>
              </li>
            ) : (
              <li className={style.menuLiHide}></li>
            )}
            <div className={style.divider} />
            <li role='presentation' className={style.menuLi}>
              <Link role='menuitem' href={App_Routes.LOGOUT} className={style.link}>
                <img alt='Sign Out Icon' src={signoutIcon} className={style.icon} />
                Sign Out
              </Link>
            </li>
            <div className={style.bottomPadding}></div>
          </ul>
        </nav>
      </div>
    </>
  )
}
export default UserMenu
