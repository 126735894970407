const enUS = {
  appname: 'Content Manager',
  search: 'Search',
  manage: 'Manage',
  browse: 'Browse',
  report: 'Reports',
  dashboard: 'Dashboard',
  documenttype: 'Document Type',
  PRIVACY_POLICY_TOOLTIP_TEXT: 'You are leaving this website for information purposes only.',
  TERMS_OF_USE_TOOLTIP_TEXT: 'You are leaving this website for information purposes only.',
}
export default enUS
