import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import PrintIcon from '@mui/icons-material/Print'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  ButtonGroup,
  Checkbox,
  CircularProgress,
  IconButton,
  Modal,
  Tab,
  Tabs,
} from '@mui/material'
import { buildLangDropDown } from 'Api/Algolia.api'
import { GetContentStatus, GetMetadata, SuppressionItem, fetchPdf } from 'Api/Content.api'
import { Roles, App_Routes, VideoType } from 'ApplicationSettings/ApplicationSettings'
import CmxButton from 'Components/CmxButton'
import ContentDetails from 'Components/ContentDetails'
import ContentViewer from 'Components/ContentViewer'
import MediaEmbed from 'Components/MediaEmbed/MediaEmbed'
import OptionsButton from 'Components/OptionsButton'
import { TabPanel } from 'Pages/Manage/Manage'
import { useAuthState } from 'Store/AuthState'
import { CartAction, useCartState } from 'Store/CartState'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import { useManageStore } from 'Store/ManageState'
import { CanEdit, ContentStatus, ContentType, GetContentType } from 'Store/Models/ContentItem'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import { useAlgolia } from 'Store/UseAlgolia'
import modalStyle from 'Styles/Modal.module.scss'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import './PreviewModal.css'
import style from './PreviewModal.module.scss'

export default function PreviewModal() {
  const navigate = useNavigate()
  const { authState } = useAuthState()
  const [currentTab, setCurrentTab] = useState(0)
  const { algoliaState } = useAlgolia(authState.accessToken)
  const { previewState, previewStateDispatch } = usePreviewState()
  const { cartState, cartStateDispatch } = useCartState()

  const {
    updateFromBasket,
    updateIsBulk,
    updateIsRestore,
    updateSuppressItems,
    updateSuppressOpen,
  } = useManageStore()

  const [contentSelected, setContentSelected] = useState(false)
  const { GlobalNotificationStateManager } = useGlobalNotificationState()
  const [canEdit, setCanEdit] = useState(false)
  const [suppressed, setSuppressed] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [loadingMetadata, setLoadingMetadata] = useState(true)

  const isEmbeddable = (() => {
    // If the user is previewing a video, we need to check their licensing to know if they have topic-videos licensed for embedding
    if (previewState.topicEmbedType === 'Image') return true
    if (previewState.topicEmbedType === 'Video')
      return authState.licensing?.data.assets.some(
        (a: { name: string }) => a.name === VideoType.TOPIC_VIDEO
      )
    return false
  })()
  const previewIsTopic = // Chooses whether to show Video previews as topic-previews or article-previews.  For now it prioritizes articles.
    previewState.topicEmbedType === 'Video'
      ? !authState.licensing?.data.assets.some(
          (a: { name: string }) => a.name === VideoType.ARTICLE_VIDEO
        )
      : !!previewState.topicEmbedType

  useEffect(() => {
    if (cartState.cartItems.find(o => o.hwid === previewState.previewContent.hwid))
      setContentSelected(true)
  }, [cartState.cartItems, previewState.previewContent])

  useEffect(() => {
    if (
      previewState?.previewContent?.hwid &&
      previewState.contentMetadata.hwid !== previewState?.previewContent?.hwid
    ) {
      GetMetadata(
        previewState.previewContent.hwid,
        'en-us',
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        previewIsTopic ? 'assets' : algoliaState?.index || '',
        true,
        previewIsTopic
      ).then(data => {
        previewStateDispatch({ type: PreviewStateAction.SETCONTENTITEMMETADATA, data: data })

        let status = data.status?.toLowerCase()
        if (status) {
          setSuppressed(status.toLowerCase() === ContentStatus.Suppress.toLowerCase())
          setDeleted(status.toLowerCase() === ContentStatus.Deleted.toLowerCase())
        }
        setLoadingMetadata(false)
      })
    }
  }, [
    algoliaState.index,
    algoliaState.key.QueryAppId,
    algoliaState.key.QueryKey,
    previewState.contentMetadata.hwid,
    previewState.previewContent.hwid,
    previewState.previewContent.localization,
    previewIsTopic,
    previewStateDispatch,
  ])

  useEffect(() => {
    if (
      authState?.managementRoles &&
      previewState?.previewContent &&
      (previewState?.contentMetadata?.hwid ?? '') !== ''
    ) {
      setCanEdit(
        CanEdit(
          previewState?.previewContent,
          authState?.managementRoles,
          previewState?.contentMetadata
        )
      )
    } else {
      setCanEdit(false)
    }
  }, [authState.managementRoles, previewState.previewContent, previewState.contentMetadata])

  const [langs, setLangs] = useState<[string, string][]>([])

  useEffect(() => {
    if (!previewState.contentMetadata.hwid) return undefined
    function handleLangs(langs: [string, string][]) {
      if (!langs.length) return null
      setLangs(langs)
    }
    if (
      !loadingMetadata &&
      !suppressed &&
      !deleted &&
      previewState.contentMetadata.hwid.length > 0
    ) {
      //pulling in all available languages for content
      buildLangDropDown(
        previewState.contentMetadata.hwid,
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState.index,
        algoliaState.languagePackageAttribute,
        previewIsTopic
      ).then(handleLangs)
    }
  }, [
    algoliaState.index,
    algoliaState.key.QueryAppId,
    algoliaState.key.QueryKey,
    algoliaState.languagePackageAttribute,
    previewState.contentMetadata.hwid,
    setLangs,
    loadingMetadata,
    suppressed,
    deleted,
    previewIsTopic,
  ])

  function contentAvailable(): boolean {
    return !loadingMetadata && !suppressed && !deleted
  }

  function handleSuppress() {
    let suppressionItems: SuppressionItem[] = [
      {
        hwid: previewState.previewContent.hwid,
        title: previewState.previewContent.title,
      },
    ]
    updateIsRestore(false)
    updateSuppressItems(suppressionItems)
    updateSuppressOpen(true)
    updateFromBasket(false)
    updateIsBulk(false)
  }

  function printPreview() {
    GlobalNotificationStateManager.SetLoadingModalVisible(true)
    fetchPdf(
      [
        {
          contentId: previewState.previewContent?.hwid ?? '',
          localization: previewState.previewContent?.localization ?? 'en-us',
          title: previewState.previewContent?.title,
        },
      ],
      () => {
        GlobalNotificationStateManager.SetLoadingModalVisible(false)
        GlobalNotificationStateManager.ShowSnackbarNotification(
          'Pdf Created Successfully',
          SnackbarSeverity.Success,
          false,
          10000
        )
      },
      () => {
        GlobalNotificationStateManager.SetLoadingModalVisible(false)
        GlobalNotificationStateManager.ShowSnackbarNotification(
          'Pdf Creation Failed',
          SnackbarSeverity.Error,
          false,
          10000
        )
      }
    )
  }

  function closePreviewModal() {
    previewStateDispatch({ type: PreviewStateAction.CLEARPREVIEWCONTENT })
    previewStateDispatch({ type: PreviewStateAction.SETPREVIEWOPEN, data: false })
    setContentSelected(false)
    setCurrentTab(0)
  }

  function openContentMetadata() {
    const isHwContent = GetContentType(previewState.previewContent.hwid) === ContentType.Healthwise
    closePreviewModal()
    //navigate to the metadata page with some info to say it is a HW document. Maybe make it part of the URL
    navigate(
      `${App_Routes.MANAGECONTENTITEM}/${previewState.previewContent.hwid}/${
        isHwContent ? 'en-us' : previewState.previewContent.localization
      }`
    )
  }

  function selectedChanged(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
    setContentSelected(checked)
    cartStateDispatch({
      type: checked ? CartAction.ADDCARTITEM : CartAction.REMOVECARTITEM,
      data: previewState.previewContent,
    })

    if (checked) {
      GetContentStatus(previewState.previewContent.hwid, 'en-us').then(data => {
        if (
          data.status.name.toLowerCase() === ContentStatus.Suppress.toLowerCase() ||
          data.status.name.toLowerCase() === ContentStatus.Deleted.toLowerCase()
        ) {
          cartStateDispatch({
            type: CartAction.REMOVECARTITEM,
            data: { ...previewState.previewContent },
          })
          setContentSelected(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            `This title is being ${data.status.name} and is currently unavailable.`,
            SnackbarSeverity.Error
          )
        }
      })
    }
  }

  function SuppressedMessage() {
    return (
      <p className={style.unavailableMessage}>
        <b>This title is being Suppressed and is currently unavailable.</b> Suppressed titles can
        take up to 1 minute to be removed from search results.
      </p>
    )
  }

  function DeletedMessage() {
    return (
      <p className={style.unavailableMessage}>
        <b>This title is being Deleted and is currently unavailable.</b> Deleted titles can take up
        to 1 minute to be removed from search results.
      </p>
    )
  }

  return (
    <Modal open={previewState.previewOpen}>
      <div className={`${modalStyle.modalContent} ${style.previewModalContent}`}>
        <div className={style.previewHeader}>
          {!previewIsTopic && (
            <Checkbox
              aria-describedby={previewState.previewContent.title}
              checked={contentSelected}
              className={style.checkbox}
              color='primary'
              data-qa='PrintCheckbox'
              onChange={selectedChanged}
            />
          )}
          <div className={style.previewTitle}>
            <div className={style.previewControls}>
              {canEdit && contentAvailable() && !previewIsTopic && (
                <OptionsButton
                  label='More Actions'
                  options={[
                    {
                      component: (
                        <ButtonGroup className={style.morActionsItem}>
                          <CreateIcon />
                          <div className={style.actionLabel}>Manage</div>
                        </ButtonGroup>
                      ),
                      action: openContentMetadata,
                    },
                    {
                      component: (
                        <ButtonGroup className={style.morActionsItem}>
                          <VisibilityOffIcon />
                          <div className={style.actionLabel}>Suppress</div>
                        </ButtonGroup>
                      ),
                      action: handleSuppress,
                    },
                  ]}
                />
              )}

              {contentAvailable() && !previewIsTopic && (
                <CmxButton
                  primary
                  onClick={printPreview}
                  data-qa='PrintButton'
                  className={style.printButton}
                >
                  <PrintIcon className={style.printIcon} />
                  Print
                </CmxButton>
              )}

              <IconButton
                className={`${modalStyle.modalClose} ${style.previewClose}`}
                data-qa='ClosePreview'
                onClick={closePreviewModal}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {previewState.previewContent.title}
          </div>
        </div>

        <Tabs
          onChange={(_: SyntheticEvent, newValue: number) => setCurrentTab(newValue)}
          TabIndicatorProps={{ style: { background: '#0D8484' } }}
          value={currentTab}
          sx={{
            borderBottom: 2,
            width: '100%',
            borderColor: '#D8D8D8',
            '& .MuiTab-root.Mui-selected': { color: '#0D8484' },
            marginBottom: '20px',
            display:
              authState?.managementRoles &&
              authState.managementRoles[0] === Roles.CONTENTVIEWONLY &&
              authState.managementRoles.length === 1
                ? 'none'
                : 'inherit',
          }}
        >
          <Tab label='Content Preview' className={style.tab} />
          <Tab label='Content Details' className={style.tab} />
          {isEmbeddable && <Tab label='Embed' className={style.tab} />}
        </Tabs>

        <TabPanel index={0} padding={false} value={currentTab}>
          <div className={style.previewModalWrapper}>
            {(previewState?.contentMetadata?.hwid?.length ?? 0) > 0 && !loadingMetadata ? (
              contentAvailable() ? (
                <ContentViewer
                  hwid={
                    previewState.previewContent.hwid?.toString() ?? previewState.previewContent.hwid
                  }
                  localization={previewState.previewContent.localization}
                  isTopic={previewIsTopic}
                />
              ) : suppressed ? (
                <SuppressedMessage />
              ) : (
                <DeletedMessage />
              )
            ) : (
              <CircularProgress color='primary' className='loader' />
            )}
          </div>
        </TabPanel>

        <TabPanel index={1} padding={false} value={currentTab}>
          <div className={style.previewModalWrapper}>
            {(previewState?.contentMetadata?.hwid?.length ?? 0) > 0 && !loadingMetadata ? (
              contentAvailable() ? (
                <ContentDetails
                  contentItem={previewState.contentMetadata}
                  languages={langs.map(([_, name]) => name).join(', ')}
                />
              ) : suppressed ? (
                <SuppressedMessage />
              ) : (
                <DeletedMessage />
              )
            ) : (
              <CircularProgress color='primary' className='loader' />
            )}
          </div>
        </TabPanel>
        {isEmbeddable && (
          <TabPanel index={2} padding={false} value={currentTab}>
            <div className={style.previewModalWrapper}>
              <MediaEmbed availableLocalizations={langs.map(([localization, _]) => localization)} />
            </div>
          </TabPanel>
        )}
      </div>
    </Modal>
  )
}
