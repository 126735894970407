import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { Box, Button, Icon } from '@mui/material'
import { VideoType } from 'ApplicationSettings/ApplicationSettings'
import { articleAssets, useAuthState } from 'Store/AuthState'
import useLocalStorage from 'Store/Hooks/useLocalStorage'
import useSearchStore from 'Store/SearchState'
import classNames from 'classnames'
import noResultsIcon from 'Resources/search-results-icon.svg'
import { useState } from 'react'
import {
  Configure,
  Index,
  useInstantSearch,
  useRefinementList,
  useStats,
} from 'react-instantsearch'
import style from './QuickFilters.module.scss'
import FacetList from '../FacetList/FacetList'

type QuickFilterProps = {
  isBrowsing: boolean
  isSearching: boolean
  isLargeScreen: boolean
  isInWorkflow: boolean
  browseAndRefineFilterString: string
}

type PillsProps = {
  isBrowsing: boolean
  isSearching: boolean
  browseAndRefineFilterString: string
  attribute: string
}

type PillProps = {
  label: string
  checked: boolean
  onClick: () => void
  disabled?: boolean
}

type DocumentCountProps = {
  docCount: number
  setDocCount: (count: number) => void
}

function Pills({ isBrowsing, isSearching, attribute, browseAndRefineFilterString }: PillsProps) {
  const { authState } = useAuthState()
  const { items } = useRefinementList({ attribute: attribute })
  const { status } = useInstantSearch()

  const [docCount, setDocCount] = useState<number>(0)

  const assets = authState.licensing?.data.assets
  const browsePath = useSearchStore(state => state.browsePath)
  const filterString = useSearchStore(state => state.filterString)
  const searchTerm = useSearchStore(state => state.searchTerm)
  const selectedQuickFilters = useSearchStore(state => state.selectedQuickFilters)
  const toggleQuickFilter = useSearchStore(state => state.toggleQuickFilter)

  const quickFilterDetails = [
    {
      label: 'Topics',
      algoliaLabel: 'Topic',
      isDisplayed: assets?.some((a: { name: string }) => a.name === 'Topics'),
    },
    {
      label: 'Videos',
      algoliaLabel: 'Video',
      isDisplayed: assets?.some(
        (a: { name: string }) =>
          a.name === VideoType.TOPIC_VIDEO || a.name === VideoType.ARTICLE_VIDEO
      ),
    },
    {
      label: 'Image Topics',
      algoliaLabel: 'Image',
      isDisplayed: assets?.some((a: { name: string }) => a.name === 'Image Topics'),
    },
    {
      label: 'Articles',
      algoliaLabel: 'Article',
      isDisplayed: assets?.some((a: { name: string }) => articleAssets.includes(a.name)),
    },
    {
      label: 'Interactive',
      algoliaLabel: 'Interactive',
      isDisplayed: assets?.some((a: { name: string }) => a.name === 'Knowledgebase'),
    },
  ]
  const filteredQuickFilterDetails = quickFilterDetails.filter(qfd => qfd.isDisplayed)

  let displayQuickFilterDetails = filteredQuickFilterDetails.map(fqfd => {
    // Adds count information from Algolia
    const itemCount = items.find((item: any) => item.label === fqfd.algoliaLabel)?.count
    return {
      ...fqfd,
      count: itemCount ? itemCount : 0,
    }
  })
  displayQuickFilterDetails = [
    // Moves disabled elements to the end of the array
    ...displayQuickFilterDetails.filter(dqfd => dqfd.count > 0),
    ...displayQuickFilterDetails.filter(dqfd => dqfd.count <= 0),
  ]

  const documentCountFilterString =
    selectedQuickFilters.length > 0
      ? `AND (${selectedQuickFilters.map(sqf => `cmx.type:${sqf}`).join(' OR ')})`
      : ''

  return isBrowsing || isSearching ? (
    <div className={style.pillsAndNoResults}>
      <div className={style.quickFilterContainer}>
        <div className={style.quickFilterHeader}>
          <h3 className={style.quickFilterTitle}>
            {isBrowsing ? browsePath?.split(' > ').at(-1) : searchTerm}
          </h3>
          <Index indexName='assets' indexId={'documentCount_query'}>
            <Configure
              distinct
              facetingAfterDistinct={false}
              analytics
              clickAnalytics
              enablePersonalization
              filters={`${browseAndRefineFilterString} ${filterString} ${documentCountFilterString} AND (NOT cmx.type:Topic OR cmx.coreConceptSet:true)`}
            />
            <DocumentCount docCount={docCount} setDocCount={setDocCount} />
          </Index>
        </div>
        <div className={style.quickFilters}>
          {displayQuickFilterDetails.map(dqfd => {
            const isRefined = selectedQuickFilters.includes(dqfd.algoliaLabel)
            return (
              <Pill
                key={dqfd.algoliaLabel}
                label={dqfd.label}
                checked={isRefined}
                onClick={() => {
                  toggleQuickFilter(dqfd.algoliaLabel)
                }}
                disabled={dqfd.count < 1}
              />
            )
          })}
        </div>
      </div>
      {docCount < 1 && status === 'idle' && <NoResultsMessage />}
    </div>
  ) : (
    <></>
  )
}

const Pill = ({ label, checked, onClick, disabled }: PillProps) => {
  return (
    <Button
      onClick={onClick}
      className={classNames(
        style.quickFilter,
        checked && style.activeQuickFilter,
        disabled && style.disabledQuickFilter
      )}
      disabled={disabled}
    >
      {checked && !disabled && <CheckOutlinedIcon fontSize='small' />}
      {label}
    </Button>
  )
}

const DocumentCount = ({ docCount, setDocCount }: DocumentCountProps) => {
  const { nbHits } = useStats()
  const [enableCount] = useLocalStorage('enableCount', false)

  if (nbHits !== docCount) setDocCount(nbHits)

  return (
    <Box className={style.quickFilterCount} sx={{ display: enableCount ? 'inherit' : 'none' }}>
      ({nbHits.toLocaleString('en-US')} results)
    </Box>
  )
}

const NoResultsMessage = () => {
  return (
    <Box className={style.noResultsMessageContainer}>
      <Icon className={style.noResultsMessageIcon}>
        <img alt='no results icon' src={noResultsIcon} className={style.noResultsIconImage} />
      </Icon>
      <Box className={style.sorryMessage}>
        Sorry, no items were found matching your search request.
      </Box>
      <Box className={style.suggestionMessage}>Search Suggestions:</Box>
      <ul className={style.suggestionList}>
        <li>Check your spelling.</li>
        <li>Try more general words.</li>
        <li>Try different words that mean the same thing.</li>
        <li>Reduce the amount of filters in use.</li>
      </ul>
    </Box>
  )
}

export default function QuickFilters({
  isBrowsing,
  isSearching,
  isLargeScreen,
  isInWorkflow,
  browseAndRefineFilterString,
}: QuickFilterProps) {
  return (
    <div className={style.quickFiltersAndFacetButton}>
      <Pills
        isBrowsing={isBrowsing}
        isSearching={isSearching}
        browseAndRefineFilterString={browseAndRefineFilterString}
        attribute='cmx.type'
      />
      <div className={style.facetButton}>
        {!isLargeScreen && <FacetList smallScreenVariant isInWorkflow={isInWorkflow} />}
      </div>
    </div>
  )
}
