import ApplicationSettings, { Roles } from 'ApplicationSettings/ApplicationSettings'

export type AdminOrgsSub = {
  organizationId: string
  subscriptionId: string
}

export default abstract class TokenParser {
  public static parseJwt(token: string) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    //console.log(jsonPayload);
    return JSON.parse(jsonPayload)
  }

  public static getSubscriptionIdFromToken(token: string) {
    let subscriptionId = this.parseJwt(token).subscriptionId
    return subscriptionId
  }

  public static getOrgIdFromToken(token: string) {
    let organizationId = this.parseJwt(token).organizationId
    return organizationId
  }

  public static getProperRole(roles: string[]): string {
    if (roles.includes(Roles.ADMINISTRATOR)) {
      return Roles.ADMINISTRATOR
    }
    return ''
  }

  // TODO: limit refine role distribution so this method can contain less logic.
  public static getOrgRoleFromToken(token: string) {
    if (token) {
      // declare role array
      let rolesArray = []
      try {
        // split roles into array
        let roles = this.parseJwt(token).hwRoles
        let org = this.getOrgIdFromToken(token)
        // iterate through array and parse out role names
        for (let i = 0; i < roles.length; i++) {
          let parts = roles[i].split('::')
          if (parts.length !== 4) continue
          if (parts[0] === org && parts[2] === ApplicationSettings.AppId) {
            rolesArray.push(parts[3])
          }
        }
        // check if provisioning manager role exists otherwise return org admin or null
        return this.getProperRole(rolesArray)
      } catch (error) {
        // TODO  Render error to user
        //console.log('role err: ' + error);
      }
    }
    return undefined
  }

  public static getAdminOrgsFromToken(token: string): AdminOrgsSub[] {
    let adminOrgs = []
    if (token) {
      try {
        let roles = this.parseJwt(token).hwRoles
        // iterate through roles array and parse out role names
        for (let i = 0; i < roles.length; i++) {
          let parts = roles[i].split('::')
          if (parts.length !== 4) continue
          if (parts[3] === Roles.ADMINISTRATOR) {
            const adminOrgsSub = {
              organizationId: parts[0],
              subscriptionId: parts[1],
            }
            adminOrgs.push(adminOrgsSub)
          }
        }
      } catch (error) {
        // Error parsing the jwt, bail.
        return []
      }
    }
    return adminOrgs
  }

  public static getOrganizationAlias(token: string): string {
    let organizationAias = ''

    if (token) {
      try {
        // split roles into array
        organizationAias = this.parseJwt(token).tempOrganizationAlias
      } catch (error) {
        // Error parsing the jwt, bail.
      }
    }

    return organizationAias
  }

  /**
   * Retrieve only the management roles from the JWT
   */
  public static getManagementRolesFromToken(token: string) {
    let managementRoles = []

    if (token) {
      try {
        // split roles into array
        let roles = this.parseJwt(token).hwRoles
        let org = this.getOrgIdFromToken(token)
        // iterate through array and parse out role names
        for (let i = 0; i < roles.length; i++) {
          let parts = roles[i].split('::')
          if (parts.length !== 4) continue
          // TODO: Removed Application ID lookup here. Add it back in.
          if (parts[0] === org) {
            managementRoles.push(parts[3])
          }
        }
      } catch (error) {
        // Error parsing the jwt, bail.
        return []
      }
    }

    return managementRoles
  }
}
