import { useRef, useState } from 'react'
import styles from './OptionsButton.module.scss'
import classNames from 'classnames'
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import CmxButton from 'Components/CmxButton'

export interface ButtonOption {
  component: any
  action: Function
}

export interface OptionsButtonProps {
  label: string
  options: ButtonOption[]
  disabled?: boolean
  className?: string
}

export default function OptionsButton({ label, options, disabled, className }: OptionsButtonProps) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    options[index].action()
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return
    setOpen(false)
  }

  return (
    <div className={classNames('hw-options-button', className)}>
      <ButtonGroup
        sx={{
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: '8px',
          '& .MuiButton-root': {
            backgroundColor: '#f4f4f4',
            borderRadius: '8px',
          },
          '&:hover': {
            backgroundColor: '#D8D8D8',
          },
        }}
        variant='contained'
        ref={anchorRef}
        aria-label='options button'
        disabled={disabled}
        className={classNames('hw-options-button-group', styles.group)}
      >
        <CmxButton
          aria-controls={open ? 'options-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          className='hw-options-button-drop-button'
          startIcon={<MoreHorizIcon />}
        >
          {label}
        </CmxButton>
      </ButtonGroup>
      <Popper
        id='options-button-popper'
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='options-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      // selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option.component}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
