import enUS from 'Resources/LocalizationDictionary/en-us'

export class Localization {
  private _localizationLibrary: any
  private _defaultLocalizationLibrary: any

  public constructor(localizationLibrary: any, defaultLocalizationLibrary = enUS) {
    this._localizationLibrary = localizationLibrary
    this._defaultLocalizationLibrary = defaultLocalizationLibrary
  }

  public GetLocalized(key: Localization.Key): string {
    let value = this._localizationLibrary[key]
    if (!value) {
      value = this._defaultLocalizationLibrary[key]
    }
    return value
  }
}

export namespace Localization {
  export enum Key {
    AppName = 'appname',
    Search = 'search',
    Browse = 'browse',
    Reports = 'report',
    Dashboard = 'dashboard',
    DocumentType = 'documenttype',
    Content = 'content',
    Manage = 'manage',
    PRIVACY_POLICY_TOOLTIP_TEXT = 'PRIVACY_POLICY_TOOLTIP_TEXT',
    TERMS_OF_USE_TOOLTIP_TEXT = 'TERMS_OF_USE_TOOLTIP_TEXT',
  }
}
