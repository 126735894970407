import { SearchClient } from 'algoliasearch'
import {
  BaseSyntheticEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  AutocompleteOptions,
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'

import CloseIcon from '@mui/icons-material/Close'
import { FormControl, IconButton, InputAdornment, List, Paper } from '@mui/material'

import CmxButton from 'Components/CmxButton'

import style from './ConceptAutocomplete.module.scss'
import ConceptHighlight, { ConceptAutocompleteItem } from './ConceptHighlight'
import CmxTextField from 'Components/CmxTextField/CmxTextField'

type Props = Partial<AutocompleteOptions<ConceptAutocompleteItem>> & {
  'data-testid'?: string
  error?: boolean
  errorText?: string | JSX.Element
  onAddConcept: (conceptType: string, conceptValueObj: any, conceptObj: any) => void
  searchClient?: SearchClient
}

const ConceptAutocomplete: FC<Props> = (
  { 'data-testid': dataTestId, error, errorText, onAddConcept, searchClient },
  props
) => {
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<ConceptAutocompleteItem>
  >({
    activeItemId: null,
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: '',
    status: 'idle',
  })

  const autocomplete = useMemo(
    () =>
      createAutocomplete<ConceptAutocompleteItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>({
        onStateChange({ state }) {
          setAutocompleteState(state)
        },
        getSources() {
          return [
            {
              sourceId: 'concepts',
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient: (searchClient || {}) as SearchClient,
                  queries: [
                    {
                      indexName: 'concepts',
                      query,
                      params: {
                        hitsPerPage: 10,
                        highlightPreTag: '<b>',
                        highlightPostTag: '</b>',
                        facetFilters: ['deleted:false', 'deprecated:false'],
                      },
                    },
                  ],
                })
              },
            },
          ]
        },
        ...props,
      }),
    [props, searchClient]
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const [conceptValue, setConceptValue] = useState<any>()
  const [conceptError, setConceptError] = useState('')

  const handleClickOutside = useCallback(
    (e: any) => {
      let elementIsInsideWrapper =
        panelRef.current && panelRef.current.contains(e.target as HTMLElement)
      let elementIsInsideAutocomplete =
        inputRef.current && inputRef.current.contains(e.target as HTMLElement)

      if (!elementIsInsideAutocomplete && !elementIsInsideWrapper) {
        autocomplete.setQuery('')
        autocomplete.setIsOpen(false)
      }
    },
    [autocomplete]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, conceptValue, autocompleteState])

  const handleIconClick = () => {
    if (autocompleteState.query) {
      autocomplete.setQuery('')
      autocomplete.setIsOpen(false)
      setConceptValue('')
    }
  }

  function handleAddClick() {
    autocomplete.setIsOpen(false)
    if (conceptValue) {
      setConceptError('')
      let valueObj = {}
      //@ts-ignore
      valueObj['conceptId'] = conceptValue.objectID
      //@ts-ignore
      valueObj['conceptLabel'] = conceptValue.label
      onAddConcept('concepts', valueObj, conceptValue)
    } else {
      //concept not chosen from list, error message
      setConceptError('Invalid concept. Concepts must be selected from the list provided.')
    }
    autocomplete.setQuery('')
    setConceptValue('')
  }

  const handleListItemClick = (item: any) => {
    if (item) {
      setConceptError('')
      autocomplete.setQuery(item.label)
      autocomplete.setIsOpen(false)
      setConceptValue(item)
    }
  }

  let endAdornment = null

  if (autocompleteState.query.length)
    endAdornment = (
      <InputAdornment position='end'>
        <IconButton
          className={style.searchIcon}
          data-testid='CloseIcon'
          onClick={handleIconClick}
          aria-label='reset'
        >
          <CloseIcon />
        </IconButton>
      </InputAdornment>
    )

  return (
    <FormControl>
      <div
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
        className={style.conceptForm}
        data-testid={dataTestId}
      >
        <CmxTextField
          data-qa='ConceptSearchField'
          InputProps={{
            ...autocomplete.getInputProps({ inputElement: inputRef.current }),
            endAdornment,
            placeholder: 'e.g: Ear Canal',
          }}
          ref={inputRef}
          error={error || conceptError !== ''}
          errorText={conceptError === '' ? errorText : conceptError}
          sx={{ width: '567px' }}
        />

        {autocompleteState.isOpen && (
          <Paper className={style.autocompleteListPaper} tabIndex={0}>
            <div ref={panelRef} {...autocomplete.getPanelProps({})}>
              {autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection

                return (
                  <List
                    tabIndex={0}
                    className={style.conceptList}
                    key={index}
                    {...autocomplete.getListProps()}
                  >
                    {items.map(item => (
                      <li
                        key={item.objectID}
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                        className={style.listItem}
                        id={item.objectID}
                        onClick={() => handleListItemClick(item)}
                      >
                        <ConceptHighlight hit={item} key={item.objectID} />
                      </li>
                    ))}
                  </List>
                )
              })}
            </div>
          </Paper>
        )}

        <CmxButton
          className={style.metadataBtn}
          data-testid='AddConcept'
          onClick={handleAddClick}
          primary
          tabIndex={0}
        >
          Add
        </CmxButton>
      </div>
    </FormControl>
  )
}
export default ConceptAutocomplete
