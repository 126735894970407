import { createContext, useContext, useReducer } from 'react'
import { UpdateCartItemLanguage } from 'Utilities/CartUtil'
import ContentItem from './Models/ContentItem'

export type CartState = {
  cartItems: Array<ContentItem>
  selectedCartItem: ContentItem | null
  cartOpen: boolean
  previewContentItem: ContentItem | null
  languageError: string
  previewError: string
}

const initialState: CartState = {
  cartItems: [],
  selectedCartItem: null,
  cartOpen: false,
  previewContentItem: null,
  languageError: '',
  previewError: '',
}

const CartContext = createContext<{ cartState: CartState; cartStateDispatch: React.Dispatch<any> }>(
  {
    cartState: initialState,
    cartStateDispatch: () => null,
  }
)

export enum CartAction {
  ADDCARTITEM,
  REMOVECARTITEM,
  CLEARCART,
  SETSELECTEDCARTITEM,
  SETCARTOPEN,
  SETCARTPREVIEWCONTENTITEM,
  LANGUAGECHANGE,
  SETLANGUAGEERROR,
  SETPREVIEWERROR,
}

function reducer(state: CartState, action: any) {
  let items: ContentItem[] = []
  switch (action.type) {
    case CartAction.ADDCARTITEM:
      return { ...state, cartItems: [...state.cartItems, action.data] }
    case CartAction.REMOVECARTITEM:
      return {
        ...state,
        cartItems: [...state.cartItems.filter(item => item.hwid !== action.data.hwid)],
      }
    case CartAction.CLEARCART:
      return { ...state, cartItems: [], previewError: '', languageError: '' }
    case CartAction.SETSELECTEDCARTITEM:
      return { ...state, selectedCartItem: action.data }
    case CartAction.SETCARTOPEN:
      return { ...state, cartOpen: action.data }
    case CartAction.SETCARTPREVIEWCONTENTITEM:
      return { ...state, previewContentItem: action.data }
    case CartAction.LANGUAGECHANGE:
      items = UpdateCartItemLanguage(
        state.cartItems,
        action.data.cartItemId,
        action.data.localization
      )
      return { ...state, items }
    case CartAction.SETLANGUAGEERROR:
      return { ...state, languageError: action.data }
    case CartAction.SETPREVIEWERROR:
      return { ...state, previewError: action.data }
    default:
      return state
  }
}

export const CartStateProvider = (props: any) => {
  const [cartState, cartStateDispatch] = useReducer(reducer, initialState)
  return (
    <CartContext.Provider value={{ cartState, cartStateDispatch }}>
      {props.children}
    </CartContext.Provider>
  )
}

export function useCartState() {
  return useContext(CartContext)
}
