import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { ButtonGroup, Collapse, Link } from '@mui/material'
import { fetchPdf, fetchPdfZip } from 'Api/Content.api'
import { Roles } from 'ApplicationSettings/ApplicationSettings'
import OptionsButton from 'Components/OptionsButton'
import SplitButton, { Option } from 'Components/SplitButton'
import { AppFeatureDictionary, FeatureEnabled, useAuthState } from 'Store/AuthState'
import { CartAction, useCartState } from 'Store/CartState'
import { TriangleSvg } from 'Store/CustomSvg/TriangleSvg'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import { useManageStore } from 'Store/ManageState'
import ContentItem from 'Store/Models/ContentItem'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import { useEffect, useState } from 'react'
import style from './Cart.module.scss'
import { CartItem } from './CartItem'

export default function Cart() {
  const { authState } = useAuthState()
  const { cartState, cartStateDispatch } = useCartState()
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()
  const { previewStateDispatch } = usePreviewState()

  const {
    updateFromBasket,
    updateIsBulk,
    updateIsRestore,
    updateSuppressItems,
    updateSuppressOpen,
  } = useManageStore()

  const [canSuppress, setCanSuppress] = useState(false)
  const [cartHasItems, setCartHasItems] = useState(false)
  const [langErrorVisible, setHasLanguageError] = useState(false)

  const printOptions: Option[] = [
    {
      label: 'Single PDF',
      action: printPdfCart,
    },
    {
      label: 'PDF Per Item',
      action: zipCart,
    },
  ]

  useEffect(() => {
    if (cartState.cartItems.length === 0) {
      setCartHasItems(false)
    } else {
      setCartHasItems(true)
    }
  }, [cartState.cartItems, setCartHasItems])

  useEffect(() => {
    if (cartState.languageError) {
      setHasLanguageError(true)
    }
  }, [cartState.languageError, setHasLanguageError])

  useEffect(() => {
    if (authState?.managementRoles && cartState.cartItems) {
      setCanSuppress(
        FeatureEnabled(AppFeatureDictionary.CanUploadAndManage) &&
          authState.managementRoles.findIndex((item: string) => item === Roles.ADMINISTRATOR) > -1
      )
    }
  }, [authState.managementRoles, cartState.cartItems, setCanSuppress])

  function printPdfCart() {
    if (!globalNotificationState.loadingModalInfo.visible) {
      GlobalNotificationStateManager.SetLoadingModalVisible(true)
      let contentItems = cartState.cartItems
        .map((cartItem: any) => {
          return {
            contentId: cartItem.hwid ?? '',
            localization: cartItem.localization ?? 'en-us',
            title: cartItem.title,
          }
        })
        .reverse()
      fetchPdf(
        contentItems,
        () => {
          cartStateDispatch({ type: CartAction.CLEARCART })
          closeCart()
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Pdf Created Successfully',
            SnackbarSeverity.Success,
            false,
            10000
          )
        },
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Pdf Creation Failed',
            SnackbarSeverity.Error,
            false,
            10000
          )
        }
      )
    }
  }

  function zipCart() {
    if (!globalNotificationState.loadingModalInfo.visible) {
      GlobalNotificationStateManager.SetLoadingModalVisible(true)
      let contentItems = cartState.cartItems
        .map((cartItem: any) => {
          return {
            contentId: cartItem.hwid ?? '',
            localization: cartItem.localization ?? 'en-us',
            title: cartItem.title,
          }
        })
        .reverse()
      fetchPdfZip(
        contentItems,
        () => {
          cartStateDispatch({ type: CartAction.CLEARCART })
          closeCart()
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Zip Created Successfully',
            SnackbarSeverity.Success,
            false,
            10000
          )
        },
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Zip Creation Failed',
            SnackbarSeverity.Error,
            false,
            10000
          )
        }
      )
    }
  }

  function clearCart() {
    cartStateDispatch({ type: CartAction.CLEARCART })
  }

  function closeCart() {
    cartStateDispatch({ type: CartAction.SETCARTPREVIEWCONTENTITEM, data: null })
    cartStateDispatch({ type: CartAction.SETCARTOPEN, data: false })
  }

  function showPreview(cartItem: ContentItem) {
    previewStateDispatch({
      type: PreviewStateAction.SETPREVIEWCONTENT,
      data: cartItem,
    })
    if (cartItem.docType === 'Video' || cartItem.docType === 'Image') {
      previewStateDispatch({
        type: PreviewStateAction.SETTOPICEMBEDTYPE,
        data: cartItem.docType,
      })
    }
  }

  function handleLanguageChange(localizationKey: string, cartItem: ContentItem) {
    cartStateDispatch({
      type: CartAction.LANGUAGECHANGE,
      data: { cartItemId: cartItem.hwid, localization: localizationKey },
    })
  }

  function handleBatchSuppress() {
    updateIsRestore(false)
    updateSuppressItems(cartState.cartItems)
    updateSuppressOpen(true)
    updateFromBasket(true)
    updateIsBulk(true)
  }

  return (
    <>
      {cartState.cartOpen && <TriangleSvg className={style.triangle} />}

      <Collapse in={cartState.cartOpen}>
        <div className={style.cartContent}>
          <div className={style.cartHeader}>
            <div className={style.basketLabel}>
              <div>
                Basket <div className={style.basketCount}>({cartState.cartItems.length})</div>
                {cartHasItems && (
                  <Link onClick={clearCart} data-qa='ClearCart' className={style.clearCart}>
                    Clear Basket
                  </Link>
                )}
              </div>
              {!cartHasItems && (
                <div>
                  <p className={style.emptyCart}>Your basket is currently empty.</p>
                </div>
              )}
            </div>

            <div className={style.moreActionsAndPrintOptions}>
              {canSuppress && (
                <OptionsButton
                  label='More Actions'
                  disabled={!cartHasItems}
                  options={[
                    {
                      component: (
                        <ButtonGroup className={style.morActionsItem}>
                          <VisibilityOffIcon />
                          <div className={style.actionLabel}>Suppress</div>
                        </ButtonGroup>
                      ),
                      action: handleBatchSuppress,
                    },
                  ]}
                />
              )}
              <SplitButton
                label='Print'
                options={printOptions}
                disabled={!cartHasItems}
                className={style.splitButton}
              />
            </div>
          </div>
          {cartHasItems && (
            <div className={style.cartItems}>
              {langErrorVisible ? (
                <p className={style.langError}>{cartState.languageError}</p>
              ) : (
                <></>
              )}
              <div className={style.cartRows}>
                {cartState.cartItems
                  .map((cartItem: any) => (
                    <CartItem
                      handleLanguageChange={handleLanguageChange}
                      showPreview={showPreview}
                      selectedLanguage={cartItem.localization}
                      cartItem={cartItem}
                      key={`CartItem-${cartItem.hwid}`}
                    />
                  ))
                  .reverse()}
              </div>
            </div>
          )}
        </div>
      </Collapse>
    </>
  )
}
