import classNames from 'classnames'
import { TextField, TextFieldProps } from '@mui/material'

import style from './CmxTextField.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

export interface CmxTextFieldProps {
  'data-qa'?: string
  'data-testid'?: string
  className?: string
  title?: string | JSX.Element
  supportingText?: string | JSX.Element
  supportingTextProps?: { props: any }
  errorText?: string | JSX.Element
  wrapperStyles?: string
  mode?: 'light' | 'borderless'
  height?: string
}

const styles = {
  root: {
    '.MuiOutlinedInput-root': {
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      '&.Mui-focused': {
        backgroundColor: '#d8d8d8',
        outline: '2px dotted #262626',
        outlineOffset: '1px',
      },
      '&.Mui-error': {
        backgroundColor: '#f4f4f4',
      },
      '&:hover': {
        backgroundColor: '#d8d8d8',
      },
      '&.Mui-disabled': {
        backgroundColor: '#f4f4f4',
      },
      '& fieldset': {
        borderRadius: '8px',
        borderColor: '#727272',
      },
      '&:hover fieldset': {
        borderColor: '#262626',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#262626',
      },
      '&.Mui-error fieldset': {
        borderColor: '#dd372f',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#727272',
      },
      '& input::placeholder': {
        opacity: 1,
        color: '#727272',
      },
    },
    '& .MuiFormHelperText-root': {
      '&.Mui-error': {
        color: '#dd372f',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
  lightRoot: {
    '.MuiOutlinedInput-root': {
      backgroundColor: '#fff',
      color: '#424242',
      borderRadius: '8px',
      '&.Mui-focused': {
        outline: '2px dotted #262626',
        outlineOffset: '1px',
      },
      '& fieldset': {
        borderRadius: '8px',
        borderColor: '#727272',
      },
      '&:hover fieldset': {
        borderColor: '#727272',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#727272',
      },
      '&.Mui-error fieldset': {
        borderColor: '#dd372f',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#727272',
      },
    },
    '& .MuiFormHelperText-root': {
      '&.Mui-error': {
        color: '#dd372f',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
  borderlessRoot: {
    '.MuiOutlinedInput-root': {
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      '&.Mui-focused': {
        backgroundColor: '#d8d8d8',
        outline: '2px dotted #262626',
        outlineOffset: '1px',
      },
      '&:hover': {
        backgroundColor: '#d8d8d8',
      },
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#424242',
    },
  },
}

export default function CmxTextField({
  'data-qa': dataQa,
  'data-testid': dataTestId,
  className,
  title,
  supportingText,
  supportingTextProps,
  errorText,
  wrapperStyles,
  mode,
  height = '44px',
  ...props
}: CmxTextFieldProps & TextFieldProps) {
  const stylesByMode =
    mode === 'light'
      ? styles.lightRoot
      : mode === 'borderless'
      ? styles.borderlessRoot
      : styles.root

  function renderSections() {
    return (
      <>
        {title ? (
          <label
            className={classNames(
              style.cmxTextFieldTitle,
              props.disabled ? style.disabledText : ''
            )}
          >
            {title}
          </label>
        ) : (
          <></>
        )}
        {supportingText && !props.disabled ? (
          <div {...supportingTextProps} className={style.cmxTextFieldSupportText}>
            {supportingText}
          </div>
        ) : (
          <></>
        )}
        <TextField
          {...props}
          className={classNames(style.cmxTextField, className)}
          data-qa={dataQa}
          data-testid={dataTestId}
          SelectProps={{ ...{ IconComponent: KeyboardArrowDownIcon }, ...props.SelectProps }}
          sx={{
            height: height,
            ...stylesByMode,
            '& .MuiOutlinedInput-root': { height: height },
            ...props.sx,
          }}
          aria-describedby='cmxTextfieldErrorMessage'
        />
        {props.error && errorText && (
          <div className={style.cmxTextfieldErrorMessage} id='cmxTextfieldErrorMessage'>
            <WarningRoundedIcon />
            {errorText}
          </div>
        )}
      </>
    )
  }

  if (wrapperStyles) return <div className={classNames(wrapperStyles)}>{renderSections()}</div>
  else return renderSections()
}
