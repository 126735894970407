import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { InstantSearch } from 'react-instantsearch-core'

import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'

import {
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material'

import CmxButton from 'Components/CmxButton'
import ConceptAutocomplete from 'Components/ConceptDropDown/ConceptAutocomplete'
import Dropdown from 'Components/Dropdown'
import Tooltip from 'Components/Tooltip'

import { useAuthState } from 'Store/AuthState'
import { ContentItemMetadataStorageManager } from 'Store/ContentItemMetadataStorageManager'
import {
  AgeRangeMax,
  AgeRangeMin,
  ContentItemMetadata,
  ContentType,
  Gender,
  GetContentType,
  KeywordLangTypes,
  MetadataItem,
  MetadataItemAction,
  MetadataSource,
  MetadataTypes,
} from 'Store/Models/ContentItem'
import { useAlgolia } from 'Store/UseAlgolia'

import { localeMap } from 'Utilities/LocaleMapUtil'

import {
  AgeRangeAll,
  AgeRangeCustom,
  IDTooltipMessage,
  LanguageTooltipMessage,
  SpecialtiesTooltipMessage,
} from './constants'
import Demographics from './Demographics'
import style from './MetadataEdit.module.scss'
import MetadataItemsList from './MetadataItemsList'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import { WarningRounded } from '@mui/icons-material'
import classNames from 'classnames'

interface MetadataEditProps {
  changesMade: (changed: boolean, globalMetadataChanged: boolean) => void
  contentItem: ContentItemMetadata
  title: string
  setTitle: (title: string) => void
  errorCount: number
  setErrorCount: (errorCount: number) => void
  saveClicked: boolean
  setSaveClicked: (saveClicked: boolean) => void
}

export const MAX_TITLE_LENGTH = 250
export const MAX_DESCRIPTION_LENGTH = 90

const FEW_REMAINING_CHARACTER_COUNT = 10

export default function MetadataEdit({
  changesMade,
  contentItem,
  title,
  setTitle,
  errorCount,
  setErrorCount,
  saveClicked,
  setSaveClicked,
}: MetadataEditProps) {
  const { authState } = useAuthState()
  const { isLoading, algoliaClient } = useAlgolia(authState.accessToken)
  const [initializing, setInitializing] = useState(true)
  const [titleError, setTitleError] = useState('')
  const hwid: string = contentItem.hwid
  const filename: string = contentItem.filename ?? 'unknown'
  const localization: string = contentItem.localization
  const [description, setDescription] = useState<string>('')
  const [descriptionError, setDescriptionError] = useState('')
  const [gender, setGender] = useState<Gender>(Gender.Any)
  const [selectedAgeRange, setSelectedAgeRange] = useState(AgeRangeAll)
  const [minAge, setMinAge] = useState<number>(AgeRangeMin)
  const [maxAge, setMaxAge] = useState<number>(AgeRangeMax)
  const [metadataType, setMetadataType] = useState('')
  const [metadataValue, setMetadataValue] = useState('')
  const [metadataValueError, setMetadataValueError] = useState('')
  const [conceptError, setConceptError] = useState('')
  const [metadataItems, setMetadataItems] = useState<MetadataItem[]>([])
  const StorageManager = useMemo(() => new ContentItemMetadataStorageManager(), [])

  function getGenderSelectionFromMetadata(cim: ContentItemMetadata): Gender {
    if (!cim?.metadata?.gender || cim.metadata.gender?.length !== 1) return Gender.Any

    if (cim.metadata.gender[0].toLowerCase() === Gender.Male.toLowerCase()) return Gender.Male

    return Gender.Female
  }

  const UpdateLocalState = useCallback(
    (cim: ContentItemMetadata) => {
      const minAge = cim?.metadata?.age?.minAge ?? AgeRangeMin
      const maxAge = cim?.metadata?.age?.maxAge ?? AgeRangeMax

      setSelectedAgeRange(
        minAge === AgeRangeMin && maxAge === AgeRangeMax ? AgeRangeAll : AgeRangeCustom
      )

      setTitle(cim.title)
      setDescription(cim.description ?? '')
      setGender(getGenderSelectionFromMetadata(cim))
      setMinAge(minAge)
      setMaxAge(maxAge)
      setMetadataItems(cim?.metadata?.metadataItems ?? [])
      setInitializing(false)
    },
    [setTitle]
  )

  const handleMetadataSubmit = (event: any) => {
    event.preventDefault()
  }

  const GetGenderValues = useCallback(() => {
    if (gender === Gender.Any) return [Gender.Male, Gender.Female]

    return [gender]
  }, [gender])

  const GetMetadataValuesForCategory = useCallback(
    (category: string) =>
      metadataItems
        .filter(
          item =>
            item.category === category &&
            item.action !== MetadataItemAction.Removing &&
            item.source === MetadataSource.AdHoc
        )
        .map(item => item.value),
    [metadataItems]
  )

  const GetMetadataValuesForConcept = useCallback(
    () =>
      metadataItems
        .filter(
          item =>
            item.category === 'concepts' &&
            item.action !== MetadataItemAction.Removing &&
            item.source === MetadataSource.AdHoc
        )
        .map(item => item.value),
    [metadataItems]
  )

  const BuildContentItemMetadataFromForm = useCallback(
    (canSubmit: boolean) => ({
      ...contentItem,
      title,
      description,
      metadata: {
        age: { minAge, maxAge },
        description,
        gender: GetGenderValues(),
        concepts: GetMetadataValuesForConcept(),
        cpt: GetMetadataValuesForCategory('cpt'),
        icd10ca: GetMetadataValuesForCategory('icd10ca'),
        icd10cm: GetMetadataValuesForCategory('icd10cm'),
        keywords: GetMetadataValuesForCategory('keywords'),
        loinc: GetMetadataValuesForCategory('loinc'),
        rxnorm: GetMetadataValuesForCategory('rxnorm'),
        snomedct: GetMetadataValuesForCategory('snomedct'),
        metadataItems, //For use to save state if the preview tab is selected
      },
      canSubmit,
      //subscriptions?: Subscription[] //TODO: Work selected subscriptions into result
    }),
    [
      GetGenderValues,
      GetMetadataValuesForCategory,
      GetMetadataValuesForConcept,
      contentItem,
      description,
      maxAge,
      metadataItems,
      minAge,
      title,
    ]
  )

  const validateTitle = useCallback(() => {
    if (!initializing && (title === '' || !title)) return 'A title is required.'

    if (title?.length > MAX_TITLE_LENGTH)
      return `A title cannot exceed ${MAX_TITLE_LENGTH} characters.`

    return ''
  }, [initializing, title])

  const validateDescription = useCallback(() => {
    if (description?.length > MAX_DESCRIPTION_LENGTH)
      return `A description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.`

    return ''
  }, [description?.length])

  useEffect(() => {
    if (errorCount < 1) {
      setSaveClicked(false)
    }
  }, [errorCount, setSaveClicked])

  useEffect(() => {
    if (!contentItem?.hwid) return undefined

    const storedContentItem = StorageManager.Get(contentItem.hwid, contentItem.localization)

    // dump localstorage values into local state
    UpdateLocalState(storedContentItem ? storedContentItem : contentItem)
  }, [StorageManager, UpdateLocalState, contentItem])

  useEffect(() => {
    if (initializing) return undefined

    const titleError = validateTitle()
    const descriptionError = validateDescription()
    setTitleError(titleError)
    setDescriptionError(descriptionError)
    if (
      Number(!!titleError.length) + Number(!!descriptionError.length) !== errorCount &&
      GetContentType(contentItem.hwid) !== ContentType.Healthwise
    ) {
      setErrorCount(Number(!!titleError.length) + Number(!descriptionError.length))
    }

    const titleChanged = title !== contentItem.title
    // contentItem.description is sometimes null and the initial state for description is an empty string.
    // this also handles the case where contentItem.description is an empty string.
    const descChanged = (() => {
      if (!description && !contentItem.description) return false

      return description !== contentItem.description
    })()
    const genderChanged = gender !== getGenderSelectionFromMetadata(contentItem)
    const minAgeChanged = AgeModified(minAge, contentItem?.metadata?.age?.minAge, AgeRangeMin)
    const maxAgeChanged = AgeModified(maxAge, contentItem?.metadata?.age?.maxAge, AgeRangeMax)
    const metadataItemsChanged = !!metadataItems.find(
      miv => miv.action !== MetadataItemAction.NoAction
    )

    const contentItemModified =
      titleChanged ||
      descChanged ||
      genderChanged ||
      minAgeChanged ||
      maxAgeChanged ||
      metadataItemsChanged

    changesMade(
      contentItemModified,
      genderChanged ||
        minAgeChanged ||
        maxAgeChanged ||
        !!metadataItems.find(
          miv => miv.category !== 'keywords' && miv.action !== MetadataItemAction.NoAction
        )
    )

    StorageManager.AddItem(
      BuildContentItemMetadataFromForm(!titleError && !descriptionError && contentItemModified)
    )
  }, [
    title,
    description,
    gender,
    minAge,
    maxAge,
    metadataItems,
    initializing,
    contentItem,
    validateTitle,
    validateDescription,
    changesMade,
    StorageManager,
    BuildContentItemMetadataFromForm,
    errorCount,
    setErrorCount,
  ])

  function AgeModified(
    formValue: number,
    contentItemValue: number | undefined,
    defaultFormValue: number
  ) {
    if (contentItemValue !== undefined) return formValue !== contentItemValue

    return formValue !== defaultFormValue
  }

  function ExtractConceptMetadata(
    category: string,
    metadataValues: string[] | any,
    conceptId: string
  ): MetadataItem[] {
    return metadataValues.map(
      (item: any): MetadataItem => ({
        action: MetadataItemAction.Adding,
        category,
        conceptId,
        saved: false,
        source: MetadataSource.FromConcept,
        value: item,
      })
    )
  }

  function validateMetadataValue(): string {
    const splitMetadataValue = metadataValue?.split(',').map(value => value.trim())
    const hasDuplicates = new Set(splitMetadataValue).size !== splitMetadataValue.length
    if (hasDuplicates) return 'Cannot add duplicate keywords or codes.'
    if (splitMetadataValue.filter((value: string) => value.length > 250).length)
      return 'Keywords and codes cannot exceed 250 characters.'

    if (
      !!metadataItems.find(
        mi =>
          mi.category.toLowerCase() === metadataType.toLowerCase() &&
          mi.value.toLowerCase() === metadataValue.toLowerCase() &&
          mi.action !== MetadataItemAction.Removing
      )
    )
      return 'This value already exists.'

    if (
      splitMetadataValue.filter(
        (value: string) =>
          !!metadataItems.find(
            mi =>
              mi.category.toLowerCase() === metadataType.toLowerCase() &&
              mi.value.toLowerCase() === value.toLowerCase() &&
              mi.action !== MetadataItemAction.Removing
          )
      ).length > 0
    )
      return 'One or more values in the list already exist.'

    return ''
  }

  function onAgeRangeChange(_: ChangeEvent<HTMLInputElement>, value: string) {
    setSelectedAgeRange(value)

    if (value === AgeRangeAll) {
      setMinAge(AgeRangeMin)
      setMaxAge(AgeRangeMax)
    }
  }

  function onAgeRangeLowChange(event: SelectChangeEvent<number>) {
    setMinAge(event.target.value as number)
  }

  function onAgeRangeHighChange(e: SelectChangeEvent<number>) {
    setMaxAge(e.target.value as number)
  }

  function onAgeRangeReset() {
    setMinAge(contentItem.metadata!.age?.minAge ?? AgeRangeMin)
    setMaxAge(contentItem.metadata!.age?.maxAge ?? AgeRangeMax)
  }

  function onGenderRadioChange(_: ChangeEvent<HTMLInputElement>, value: string) {
    setGender(value as Gender)
  }

  function onDeleteConcept(category: string, value: any) {
    const itemToDelete = metadataItems.find(
      mi => mi.category === category && (mi.value === value || mi.value.conceptId === value)
    )

    const itemFilter = (item: MetadataItem) =>
      (item.category === category && item.value !== value && item.value.conceptId !== value) ||
      (item.category !== category && item.conceptId !== value)

    setMetadataItems((prevState: MetadataItem[]) => {
      if (itemToDelete?.action === MetadataItemAction.Adding) return prevState.filter(itemFilter)

      function updateItemToDelete(item: MetadataItem) {
        if (item.value === value || item.conceptId === itemToDelete?.conceptId)
          return {
            ...item,
            action: MetadataItemAction.Removing,
          }

        return item
      }

      return prevState.map(updateItemToDelete)
    })
  }

  function addMetadataValue() {
    if (!metadataType) return setMetadataValueError('A type must be selected from the dropdown.')

    if (!metadataValue) return setMetadataValueError('')

    const error = validateMetadataValue()
    if (error) return setMetadataValueError(error)

    setMetadataItems((prevState: MetadataItem[]) => {
      if (!metadataValue.includes(','))
        return [
          ...prevState,
          {
            action: MetadataItemAction.Adding,
            category: metadataType,
            conceptId: '',
            saved: false,
            source: MetadataSource.AdHoc,
            value: metadataValue,
          },
        ]

      const nextState = [...prevState]

      metadataValue
        .split(',')
        .filter((v: string) => !!v.trim().length)
        .forEach(value =>
          nextState.push({
            action: MetadataItemAction.Adding,
            category: metadataType,
            conceptId: '',
            saved: false,
            source: MetadataSource.AdHoc,
            value,
          })
        )

      return nextState
    })

    setMetadataValueError('')
    setMetadataValue('')
  }

  function addConceptValue(
    conceptType: string,
    conceptValueObj: ConceptValue,
    conceptObj: Concept
  ) {
    if (!conceptType || !conceptValueObj || !conceptObj) return null

    if (
      metadataItems.find(
        mi =>
          mi.category.toLowerCase() === conceptType.toLowerCase() &&
          mi.value.conceptId === conceptValueObj.conceptId &&
          mi.action !== MetadataItemAction.Removing
      )
    )
      return setConceptError('Unable to add concept. This concept already exists.')

    setConceptError('')
    setMetadataValue('')
    // set other metadata values
    addMetadataFromConcept(conceptObj, conceptValueObj)
  }

  function addMetadataFromConcept(conceptObj: Concept, conceptValueObj: ConceptValue) {
    setMetadataItems((prevState: MetadataItem[]) => {
      // adding concept first
      const nextState: MetadataItem[] = [
        ...prevState,
        {
          action: MetadataItemAction.Adding,
          category: 'concepts',
          conceptId: '',
          saved: false,
          source: MetadataSource.AdHoc,
          value: conceptValueObj,
        },
      ]

      // keywords by language
      const lang = KeywordLangTypes[contentItem.localization.toLowerCase()] ?? 'en'
      const { r1, r2 } = conceptObj?.keywords?.[lang] || {}
      if (r1?.length)
        nextState.push(...ExtractConceptMetadata('keywords', r1, conceptValueObj.conceptId))
      if (r2?.length)
        nextState.push(...ExtractConceptMetadata('keywords', r2, conceptValueObj.conceptId))

      // codes
      Object.entries(conceptObj.codeSystems || {}).forEach(([key, { r1, r2 }]) => {
        if (r1?.length)
          nextState.push(...ExtractConceptMetadata(key, r1, conceptValueObj.conceptId))

        if (r2?.length)
          nextState.push(...ExtractConceptMetadata(key, r2, conceptValueObj.conceptId))
      })

      // specialties
      if (Object.entries(conceptObj?.specialties || {}).length)
        nextState.push(
          ...ExtractConceptMetadata(
            'specialties',
            conceptObj.specialties,
            conceptValueObj.conceptId
          )
        )

      return nextState
    })
  }

  function ConceptItemRow({ metadataItem }: { metadataItem: any }) {
    let secondaryAction
    if (metadataItem.source === MetadataSource.AdHoc)
      secondaryAction = (
        <IconButton
          tabIndex={0}
          sx={{
            '&.Mui-focusVisible': {
              outline: '2px dotted #424242',
              backgroundColor: 'transparent',
            },
          }}
          className={style.removeMetadataItem}
          data-qa='RemoveMetadataItem'
          aria-label={`Remove metadata item - ${metadataItem.value.conceptLabel}`}
          data-testid='RemoveMetadataItem'
          key={metadataItem.value.conceptId}
          onClick={() => onDeleteConcept(metadataItem.category, metadataItem.value.conceptId)}
        >
          <CloseIcon key={metadataItem.value.conceptId} />
        </IconButton>
      )

    return (
      <ListItem
        {...{ secondaryAction }}
        className={classNames(
          metadataItem.action === MetadataItemAction.Adding
            ? style.metadataItemNew
            : style.metadataItem,
          style.conceptMetadataItem
        )}
        key={metadataItem.value.conceptId}
        tabIndex={0}
      >
        <ListItemText key={metadataItem.value.conceptId}>
          <div className={style.conceptItemText}>{metadataItem.value.conceptLabel}</div>
        </ListItemText>
      </ListItem>
    )
  }

  interface ConceptItemsListProps {
    title: string
    category: string
    'data-testid'?: string
  }

  function ConceptItemsList({
    title: _title,
    category,
    'data-testid': dataTestId,
  }: ConceptItemsListProps) {
    let mi = metadataItems.filter(mi => mi.category === category) ?? []

    return (
      <Grid
        alignItems='flex-start'
        className={style.metadataCategoryListContainer}
        container
        data-testid={dataTestId}
        direction='row'
        item
        xs={12}
      >
        <List className={style.conceptCategoryList}>
          {mi
            .filter(mi => mi.action !== MetadataItemAction.Removing)
            .reverse()
            .map((metadataItem: any) => (
              <ConceptItemRow {...{ metadataItem }} key={metadataItem.value.conceptId} />
            ))}
        </List>
      </Grid>
    )
  }

  function ErrorBanner({
    displayCondition,
    message,
  }: {
    displayCondition: boolean
    message: string
  }) {
    return (
      <Grid item xs={12}>
        <Fade in={displayCondition} unmountOnExit>
          <div className={style.deprecatedMsg}>
            <WarningRounded /> {message}
          </div>
        </Fade>
      </Grid>
    )
  }

  function CharacterCounter({
    max,
    currentText,
    oldText,
  }: {
    max: number
    currentText: string
    oldText: string
  }) {
    return (
      <>
        Maximum {max} characters{' '}
        {currentText === oldText ? (
          <></>
        ) : currentText.length > max ? (
          <span>
            ({' '}
            <span className={style.tooManyCharacterWarning}>{`${Math.abs(
              max - currentText.length
            )} too many`}</span>{' '}
            )
          </span>
        ) : (
          <span>
            ({' '}
            <span
              className={
                max - currentText.length <= FEW_REMAINING_CHARACTER_COUNT
                  ? style.fewRemainingCharacterWarning
                  : ''
              }
            >
              {max - currentText.length}
            </span>{' '}
            remaining )
          </span>
        )}
      </>
    )
  }

  return (
    <Grid container direction='row' item justifyContent='flex-start'>
      <ErrorBanner
        displayCondition={contentItem.deprecated!}
        message='Healthwise will be retiring this piece of content soon.'
      />

      <ErrorBanner
        displayCondition={saveClicked && errorCount > 0}
        message={`Please resolve ${errorCount} errors before saving.`}
      />

      {/* Content Information */}
      <Grid item xs={12}>
        <h2 className={style.metadataEditSectionTitle}>Content Information</h2>
      </Grid>

      {GetContentType(contentItem.hwid) !== ContentType.Healthwise ? (
        <>
          <Grid container item direction='column' className={style.metadataEditField}>
            <CmxTextField
              className={style.metadataEditTextField}
              name='title'
              title='Content Title (Required)'
              supportingText={
                <CharacterCounter
                  max={MAX_TITLE_LENGTH}
                  currentText={title}
                  oldText={contentItem.title}
                />
              }
              inputProps={{ 'data-testid': 'title-field' }}
              errorText={saveClicked ? titleError : ''}
              error={validateTitle() !== ''}
              onChange={e => setTitle(e.target.value)}
              value={title}
            />
          </Grid>

          <Grid container item direction='column' className={style.metadataEditField}>
            <div className={style.metadataEditFieldLabel}>ID</div>

            <div className={style.metadataEditStaticText}>
              <Tooltip text={IDTooltipMessage}>
                <span data-testid='hwid-field'>{hwid}</span>
              </Tooltip>
            </div>
          </Grid>

          <Grid container item direction='column' className={style.metadataEditField}>
            <div className={style.metadataEditFieldLabel}>Filename</div>

            <div tabIndex={0} className={style.metadataEditStaticText} data-testid='filename-field'>
              {filename}
            </div>
          </Grid>

          <Grid container item direction='column' className={style.metadataEditField}>
            <div className={style.metadataEditFieldLabel}>Language</div>

            <div className={style.metadataEditStaticText}>
              <Tooltip text={LanguageTooltipMessage}>
                <span data-testid='localization-field'>
                  {localeMap[localization.toLowerCase()]}
                </span>
              </Tooltip>
            </div>
          </Grid>

          <Grid container item direction='column' className={style.metadataEditField}>
            <CmxTextField
              className={style.metadataEditMultilineTextField}
              error={validateDescription() !== ''}
              errorText={saveClicked ? descriptionError : ''}
              height='68px'
              inputProps={{ 'data-testid': 'description-field' }}
              multiline
              name='description'
              onChange={e => setDescription(e.target.value)}
              rows={2}
              supportingText={
                <CharacterCounter
                  max={MAX_DESCRIPTION_LENGTH}
                  currentText={description}
                  oldText={contentItem.description || ''}
                />
              }
              title='Description'
              value={description}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            item
            direction='row'
            alignItems='center'
            className={style.metadataEditField}
            spacing='26px'
          >
            <Grid className={style.readonlyTextLabel} item>
              Title
            </Grid>

            <Grid className={style.readonlyText} data-testid='title-field' item xs={10}>
              {title}
            </Grid>

            <Grid className={style.readonlyTextLabel} item>
              ID
            </Grid>

            <Grid className={style.readonlyText} data-testid='hwid-field' item xs={10}>
              {hwid}
            </Grid>

            <Grid className={style.readonlyTextLabel} item>
              Language
            </Grid>

            <Grid className={style.readonlyText} data-testid='localization-field' item xs={10}>
              {localeMap[localization.toLowerCase()]}
            </Grid>

            <Grid className={style.readonlyTextLabel} item>
              Description
            </Grid>

            <Grid className={style.readonlyText} data-testid='description-field' item xs={10}>
              {description}
            </Grid>
          </Grid>
        </>
      )}

      <Grid alignItems='center' item xs={12}>
        <div className={style.fileSeperator}></div>
      </Grid>

      <Demographics
        {...{
          gender,
          maxAge,
          minAge,
          onAgeRangeChange,
          onAgeRangeHighChange,
          onAgeRangeLowChange,
          onAgeRangeReset,
          onGenderRadioChange,
          selectedAgeRange,
        }}
        editable={contentItem.editable}
        hwid={contentItem.hwid}
      />

      <Grid alignItems='center' item xs={12}>
        <div className={style.fileSeperator}></div>
      </Grid>

      {/* Concepts, Keywords and Medical Codes */}
      <Grid item xs={12}>
        <h2 className={style.metadataEditSectionTitle}>Concepts, Keywords and Medical Codes</h2>
      </Grid>

      <Grid item xs={12}>
        <label className={style.metadataEditSectionSubTitle}>
          Health Concepts <span className={style.recommendedField}>(Recommended)</span>
          <Tooltip text='Adding health concepts to your content is highly recommended. Concepts improve how to search, browse, and find your content.'>
            <InfoIcon className={style.sectionDetails} />
          </Tooltip>
        </label>
      </Grid>

      {contentItem.editable && (
        <>
          <Grid className={style.metadataEditField} container item spacing='10px'>
            <Grid data-testid='concept-instant-search-container' item xs={12}>
              {!isLoading && algoliaClient?.appId ? (
                <InstantSearch searchClient={algoliaClient} indexName='concepts'>
                  <ConceptAutocomplete
                    data-testid='concept-autocomplete'
                    error={conceptError !== ''}
                    errorText={conceptError}
                    onAddConcept={addConceptValue}
                    searchClient={algoliaClient}
                  />
                </InstantSearch>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                <ConceptItemsList
                  category='concepts'
                  data-testid='concept-items-list'
                  title='Concepts'
                />
              </Grid>
            </Grid>
          </Grid>

          <form onSubmit={handleMetadataSubmit}>
            <Grid item xs={12}>
              <label className={style.metadataEditSectionSubTitle}>
                Keywords and Medical Codes
                <Tooltip text="You can add codes and keywords if you didn't use a health concept, or you can add to what is there. Separate every added code and keyword with a comma.">
                  <InfoIcon className={style.sectionDetails} />
                </Tooltip>
              </label>
            </Grid>

            <Grid className={style.metadataEditField} container item spacing='10px'>
              <Grid item>
                <Dropdown
                  itemTestId='metadata-type-select'
                  className={style.metadataTypeSelect}
                  data-testid='MetadataCategory'
                  defaultValue='Type'
                  items={MetadataTypes}
                  nameKey='name'
                  onChange={e => setMetadataType(e.target.value)}
                  value={metadataType}
                  valueKey='key'
                />
              </Grid>

              <Grid item>
                <CmxTextField
                  className={style.metadataValueTextField}
                  error={metadataValueError !== ''}
                  errorText={metadataValueError}
                  hiddenLabel
                  inputProps={{ 'data-testid': 'metadata-value-field' }}
                  name='metadataValue'
                  onChange={e => setMetadataValue(e.target.value)}
                  value={metadataValue}
                />
              </Grid>

              <Grid item>
                <CmxButton
                  primary
                  data-testid='add-metadata-item-button'
                  onClick={addMetadataValue}
                  type='submit'
                  className={style.metadataBtn}
                >
                  Add
                </CmxButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Grid container>
        <Grid
          alignItems='flex-start'
          className={style.metadataEditField}
          container
          direction='row'
          item
          justifyContent='center'
          spacing='10px'
          xs={10}
        >
          <Grid item xs={9}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='keywords'
              title='Keywords'
            />
          </Grid>

          <Grid item xs={3}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='icd10cm'
              title='ICD-10-CM Codes'
            />
          </Grid>

          <Grid item xs={4}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='cpt'
              title='CPT ® Codes'
            />
          </Grid>

          <Grid item xs={4}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='snomedct'
              title='SNOMED CT Codes'
            />
          </Grid>

          <Grid item xs={4}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='loinc'
              title='LOINC ® Codes'
            />
          </Grid>

          <Grid item xs={4}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='rxnorm'
              title='RxNorm Codes'
            />
          </Grid>

          <Grid item xs={8}>
            <MetadataItemsList
              {...{ metadataItems, setMetadataItems }}
              category='specialties'
              title='Medical Specialties'
              tooltip
              tooltipText={SpecialtiesTooltipMessage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

type Concept = {
  alternativeLabels?: string[]
  codeSystems?: {
    [key: string]: {
      r1?: string[]
      r2?: string[]
    }
  }
  keywords?: {
    [key: string]: {
      r1?: string[]
      r2?: string[]
    }
  }
  label?: string
  objectID?: string
  relationships?: Array<{
    aspectId?: string
    label?: string
    relatedConcepts?: Array<{
      id?: string
      label?: string
    }>
  }>
  specialties?: string[]
  specialtyTree?: Array<{
    [key: string]: string | Array<unknown>
  }>
  taxonomy?: {
    children?: Array<unknown>
    parents?: Array<{
      id?: string
      label?: string
    }>
  }
}

type ConceptValue = {
  conceptId: string
  conceptLabel?: string
}
