import { Hit } from 'instantsearch.js'
import ContentItem from 'Store/Models/ContentItem'
import { AlgoliaHit } from 'Types/HitItem'

export function ItemExistsInCart(cartItemId: string, cartItems: ContentItem[]): boolean {
  return typeof cartItems.find(item => item.hwid === cartItemId) !== 'undefined'
}

export function CartItemFromHit(hit: Hit<AlgoliaHit>): ContentItem {
  const { hwid, title, localization, description } = hit.content
  return {
    hwid: hwid,
    title,
    description,
    localization,
    docType: hit.content.type,
    product: hit.content.product ?? '',
  }
}

export function CreateCartItemId(contentId: string, localizationKey: string) {
  return contentId + '_' + localizationKey
}

export function UpdateCartItemLanguage(items: ContentItem[], itemId: string, localization: string) {
  const copy = [...items]
  const itemExists = copy.find(item => item.hwid === itemId)

  if (!itemExists) {
    return copy
  }

  const remainingItems = items.filter(item => !(item.hwid === itemId))
  itemExists.localization = localization
  return [...remainingItems, itemExists]
}
