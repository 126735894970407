import classNames from 'classnames'

import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  SelectProps,
  IconButton,
} from '@mui/material'

import styles from './Dropdown.module.scss'
import CmxTextField from 'Components/CmxTextField/CmxTextField'

interface DropdownProps {
  'data-testid'?: string
  className?: string
  defaultValue?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  items: DropdownItem[] | any[]
  itemTestId?: string
  label?: string
  nameKey?: string
  onChange?: SelectProps<string>['onChange']
  onClose?: SelectProps['onClose']
  onOpen?: SelectProps['onOpen']
  value?: string
  valueKey?: string
  title?: string
  supportingText?: string
  supportingTextProps?: { props: any }
  placeholder?: string
  mode?: 'light'
  id?: string
}

export default function Dropdown({
  'data-testid': dataTestId,
  className,
  defaultValue,
  disabled,
  error,
  helperText,
  itemTestId,
  items = [],
  label,
  nameKey = '',
  onChange,
  onClose,
  onOpen,
  value = '',
  valueKey = '',
  title,
  supportingText,
  supportingTextProps,
  placeholder,
  mode,
  id,
}: DropdownProps) {
  if (!isDropdownItem(items[0]) && !(nameKey && valueKey)) {
    console.error(
      'Must define valueKey and nameKey props if not using array of DropdownItems in Dropdown component.'
    )

    return null
  }

  if (!items?.length) return null

  const borderColor = error ? styles.red : styles.black

  const thickerBorder = { border: `2px solid ${borderColor}` }

  const selectSx: SelectProps['sx'] = {
    '&& fieldset': { border: `1px solid ${borderColor}` },

    '&&:hover fieldset': thickerBorder,

    '&&.Mui-focused fieldset': thickerBorder,

    '.MuiSelect-icon': { color: styles.darkestGrey },
  }

  const menuItemProps = {
    'data-testid': itemTestId,
    role: 'option',
  }

  function renderMenuItem(item: DropdownItem | any, index: number) {
    if (!isDropdownItem(item))
      return (
        <MenuItem {...menuItemProps} key={index} value={item[valueKey]}>
          <div className={styles.menuItem}>{item[nameKey]}</div>
        </MenuItem>
      )

    if (!item?.visible) return null

    //To show Divider, add empty menu item element like below
    //{ visible: true, text: '',}
    if (!item?.text)
      return (
        <Divider
          key={index}
          sx={{ color: styles.lightGrey, marginTop: '0px', marginBottom: '0px' }}
        />
      )

    return (
      <MenuItem {...menuItemProps} key={index} value={item.text} tabIndex={0}>
        <IconButton disableRipple className={styles.menuItem}>
          {item.icon} {item.text}
        </IconButton>
      </MenuItem>
    )
  }

  return (
    <FormControl {...{ disabled, error }} size='small'>
      {label && <InputLabel>{label}</InputLabel>}
      {placeholder && <InputLabel className={styles.placeholder}>{placeholder}</InputLabel>}

      <CmxTextField
        {...{
          label,
          mode,
          supportingText,
          supportingTextProps,
          title,
          value,
        }}
        className={classNames(styles.dropdown, className)}
        data-testid={dataTestId}
        id={id}
        select
        SelectProps={{
          displayEmpty: true,
          MenuProps,
          onChange: onChange as SelectProps['onChange'],
          onClose: e => {
            handleClose()
            onClose && onClose(e)
          },
          onOpen,
        }}
        disabled={disabled}
        sx={selectSx}
      >
        {defaultValue && (
          <MenuItem {...menuItemProps} sx={{ display: 'none', height: '25px' }} value=''>
            {defaultValue}
          </MenuItem>
        )}

        {items.map(renderMenuItem)}
      </CmxTextField>

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export interface DropdownItem {
  icon?: any
  text?: string
  visible?: boolean
}

function isDropdownItem(object: any): object is DropdownItem {
  if (object) return 'text' in object

  return false
}

function handleClose() {
  setTimeout(() => {
    // This is to remove focus from the Dropdown element after a selection is made
    ;(document.activeElement as HTMLElement).blur()
  }, 0)
}

const MenuProps = {
  PaperProps: {
    sx: {
      borderRadius: '8px',
      border: '1px solid #424242',
      background: '#FFF',
      boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.20)',
      '& .MuiMenuItem-root.Mui-selected': {
        backgroundColor: styles.lightGrey,
      },
      '& .MuiMenuItem-root': {
        height: '48px',
      },
      '& .MuiMenuItem-root:hover': {
        backgroundColor: styles.offWhite,
      },
      '& .MuiMenuItem-root.Mui-selected:hover': {
        backgroundColor: styles.offWhite,
      },
    },
  },
}
