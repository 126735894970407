import { useEffect, useState } from 'react'

import { getDiscoResults } from 'Api/Disco.api'

import { DiscoServiceResult } from '../Types/DiscoTypes'
import { AlgoliaActionEnum, useAlgoliaState } from './AlgoliaState'

const LOCALE = 'default' // Use Disco.API default locale for now

export function useAlgolia(accessToken: string) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { algoliaState, algoliaStateDispatch, algoliaClient } = useAlgoliaState() // needed for algolia to connect up as well

  useEffect(() => {
    if (accessToken !== '') {
      const fetchAsync = async () => {
        const disco: DiscoServiceResult = await getDiscoResults(accessToken, algoliaStateDispatch)
        algoliaStateDispatch({
          type: AlgoliaActionEnum.INITIALIZE,
          index: disco.indexes[LOCALE]?.wordsIndex,
          suggestions: disco.indexes[LOCALE]?.suggestionsIndex,
          keys: { QueryAppId: disco.appId, QueryKey: disco.queryKey },
          languagePackageAttribute: disco.languagePackageAttribute,
          availableLanguages: disco.availableLanguages,
        })
      }
      fetchAsync()
        .catch(() => algoliaStateDispatch({ type: AlgoliaActionEnum.HAS_ERROR, hasError: true }))
        .finally(() => setIsLoading(false))
    }
  }, [
    algoliaState.appId,
    algoliaState.entitlement,
    algoliaState.userToken,
    accessToken,
    algoliaStateDispatch,
  ])

  return { isLoading, algoliaState, algoliaStateDispatch, algoliaClient }
}
