import { Box, Container, Tab, Tabs } from '@mui/material'
import {
  GetAllActivitiesPaginated,
  GetAllHWItemsPaginated,
  GetAllInHouseItemsPaginated,
} from 'Api/Content.api'
import { Roles } from 'ApplicationSettings/ApplicationSettings'
import ActivityLogDefinitions from 'Components/ContentManagement/ActivityLogDefinitions'
import ColumnDefinitions from 'Components/ContentManagement/ColumnDefinitions'
import ContentManagerTable from 'Components/ContentManagement/ContentManagerTable'
import Footer from 'Components/Footer/Footer'
import PageError from 'Components/PageError/PageError'
import PagePermissionError from 'Components/PageError/PagePermissionError'
import { useAuthState } from 'Store/AuthState'
import { StorageKeys } from 'Store/Hooks/useLocalStorage'
import { useAlgolia } from 'Store/UseAlgolia'
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import styles from './Manage.module.scss'

export default function Manage() {
  const { authState } = useAuthState()
  const {
    isLoading: isAlgoliaLoading,
    algoliaState,
    algoliaClient,
  } = useAlgolia(authState.accessToken)

  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    //clear any selected items still in local storage
    localStorage.removeItem(StorageKeys.SelectedInhouseRows)
    localStorage.removeItem(StorageKeys.SelectedHealthwiseRows)
  }, [])

  function ContentTabs() {
    const handleChange = (_: SyntheticEvent, newValue: number) => {
      //updateSelectedTab(newValue)
      setSelectedTab(newValue)
    }

    function buildContentItemRows(items: any[]): any[] {
      if (items)
        return items.map(row => {
          return {
            availableLocalizations: row.publishedLocalizations?.split(','),
            hwid: row?.hwid,
            id: row?.id,
            language: row?.language,
            lastUpdated: new Date(row.lastUpdated + 'Z'),
            status: row?.isDraftPublished ? 'Draft Published' : row?.status,
            title: row?.title,
            updatedBy: row.updatedBy,
          }
        })
      return []
    }

    function buildActivityRows(items: any[]): any[] {
      if (items)
        return items.map((row: any) => ({
          changeType: row?.activityType.name,
          date: new Date(row?.activityDate + 'Z'),
          hwid: row?.contentItem.hwid,
          id: row?.id,
          language: row?.contentItem.localization.name,
          title: row?.contentItem.title,
          updatedBy: row?.user?.userName,
        }))
      return []
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
          <Tabs
            onChange={handleChange}
            sx={{ '& .MuiTab-root.Mui-selected': { color: '#0D8484' } }}
            TabIndicatorProps={{
              style: {
                background: '#0D8484',
              },
            }}
            value={selectedTab}
          >
            <Tab
              tabIndex={0}
              label='In-House Content'
              id='simple-tab-0'
              data-testid='inhouse-content-tab'
              className={styles.manageTab}
            />
            <Tab
              tabIndex={0}
              label='Healthwise Content'
              id='simple-tab-1'
              data-testid='healthwise-content-tab'
              className={styles.manageTab}
            />
            <Tab
              tabIndex={0}
              label='Activity Log'
              id='simple-tab-2'
              data-testid='activity-log-tab'
              className={styles.manageTab}
            />
          </Tabs>
        </Box>

        <TabPanel data-testid='inhouse-tab-panel' value={selectedTab} index={0}>
          <ContentManagerTable
            tableName='InHouseTable'
            selectedRowsStorageKey={StorageKeys.SelectedInhouseRows}
            {...{ algoliaClient, algoliaState, isAlgoliaLoading, authState }}
            checkboxSelection
            columnDefinitions={ColumnDefinitions()}
            fetchData={GetAllInHouseItemsPaginated}
            buildRows={buildContentItemRows}
            enableContentUpload
            isHwContent={false}
            sortModelInit={[{ field: 'lastUpdated', sort: 'desc' }]}
          />
        </TabPanel>

        <TabPanel data-testid='healthwise-tab-panel' value={selectedTab} index={1}>
          <ContentManagerTable
            tableName='HMATable'
            selectedRowsStorageKey={StorageKeys.SelectedHealthwiseRows}
            {...{ algoliaClient, algoliaState, isAlgoliaLoading, authState }}
            checkboxSelection
            columnDefinitions={ColumnDefinitions(true)}
            fetchData={GetAllHWItemsPaginated}
            buildRows={buildContentItemRows}
            isHwContent
            sortModelInit={[{ field: 'lastUpdated', sort: 'desc' }]}
          />
        </TabPanel>

        <TabPanel data-testid='activity-log-tab-panel' value={selectedTab} index={2}>
          <ContentManagerTable
            tableName='ActivitiesTable'
            {...{ algoliaClient, algoliaState, isAlgoliaLoading, authState }}
            columnDefinitions={ActivityLogDefinitions()}
            fetchData={GetAllActivitiesPaginated}
            buildRows={buildActivityRows}
            isHwContent={false}
            sortModelInit={[{ field: 'date', sort: 'desc' }]}
          />
        </TabPanel>
      </Box>
    )
  }

  return (
    <>
      <div className={styles.managePage}>
        <div className={styles.banner}>
          <h1 className={styles.title} data-testid='manage-page-title'>
            Manage
          </h1>
        </div>
        {/* show page error */}
        {authState.hasError || algoliaState.hasError ? (
          <PageError />
        ) : authState.managementRoles!.findIndex((item: string) => item === Roles.ADMINISTRATOR) ===
          -1 ? (
          <PagePermissionError />
        ) : (
          <Container maxWidth='xl' sx={{ py: 2 }}>
            <ContentTabs />
          </Container>
        )}
      </div>

      <Footer />
    </>
  )
}

interface TabPanelProps {
  children?: ReactNode
  index: number
  padding?: boolean
  value: number
  className?: string
}

export function TabPanel({ children, index, padding = true, value, ...other }: TabPanelProps) {
  return (
    <div
      aria-labelledby={`simple-tabpanel-${index}`}
      className={styles.tabPanel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && (padding ? <Box sx={{ p: 3 }}>{children}</Box> : children)}
    </div>
  )
}
