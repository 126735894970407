import { VideoType } from 'ApplicationSettings/ApplicationSettings'
import CodeList from 'Components/CodeList'
import { useAuthState } from 'Store/AuthState'
import { ContentItemMetadata } from 'Store/Models/ContentItem'
import { usePreviewState } from 'Store/PreviewState'
import { ToHashCode } from 'Utilities/StringUtils'
import * as _ from 'lodash'
import styles from './ContentDetails.module.scss'

interface ContentDetailsProps {
  contentItem: ContentItemMetadata
  languages?: string
}

export default function ContentDetails({ contentItem, languages }: ContentDetailsProps) {
  const { previewState } = usePreviewState()
  const { authState } = useAuthState()

  const ageRange =
    typeof contentItem?.metadata?.age?.minAge === 'number' &&
    typeof contentItem?.metadata?.age?.maxAge === 'number'
      ? `${contentItem.metadata.age.minAge} to ${contentItem.metadata.age.maxAge}`
      : 'blank'

  const isTopic = (() => {
    if (previewState.topicEmbedType === 'Image') return true
    if (previewState.topicEmbedType === 'Video')
      return !authState.licensing?.data.assets.some(
        (a: { name: string }) => a.name === VideoType.ARTICLE_VIDEO
      )
    return false
  })()

  const gender = (() => {
    if (!Array.isArray(contentItem.metadata!.gender) || !contentItem.metadata!.gender.length)
      return 'blank'

    if (contentItem.metadata!.gender.length > 1) return 'Any'

    return contentItem.metadata!.gender[0]
  })()

  /**
   * Maps set of metadataItems to string array and filters based on input string filter. Also removes any duplicates
   */
  function getList(filter: string) {
    return [
      ...new Set(
        contentItem
          .metadata!.metadataItems?.filter(item => item.category === filter)
          ?.map(item => item.value)
      ),
    ]
  }

  const concepts =
    contentItem
      .metadata!.concepts?.filter(concept => concept.conceptId !== 'IN_HOUSE')
      .map(concept => concept.conceptLabel) ?? []

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Content Information</h2>

      <Row label='Content Title' value={contentItem.title} />

      <Row label='ID' value={contentItem.hwid} />

      {contentItem.hwid.includes('inhouse') && (
        <Row label='Filename' value={contentItem.filename} />
      )}

      <Row label='Language' value={languages} />

      {!isTopic && <Row label='Description' value={contentItem.description} />}

      <hr />

      <h2 className={styles.title}>Demographics</h2>

      <Row label='Age Range' value={ageRange} />

      {!isTopic && <Row label='Assigned Sex' value={gender} />}

      <hr />

      <h2 className={styles.title}>Health Concepts, Keywords, and Medical Codes</h2>

      <div className={styles.blocks}>
        <CodeList
          codes={concepts}
          half
          label='Health Concepts'
          noCodesMessage='No Assigned Concepts'
        />

        <CodeList
          codes={getList('keywords')}
          half
          label='Keywords'
          noCodesMessage='No Assigned Keywords'
        />
      </div>

      <div className={styles.blocks}>
        <CodeList codes={getList('icd10cm')} label='ICD-10-CM Codes' horizontal />

        <CodeList codes={getList('cpt')} horizontal label='CPT Codes' />

        <CodeList codes={getList('snomedct')} horizontal label='SNOMED CT Codes' />
      </div>

      <div className={styles.blocks}>
        <CodeList codes={getList('loinc')} horizontal label='LOINC Codes' />

        <CodeList codes={getList('rxnorm')} horizontal label='Rx Norm Codes' />

        <CodeList
          codes={getList('specialties')}
          label='Medical Specialties'
          noCodesMessage='No Assigned Medical Specialties'
        />
      </div>
    </div>
  )
}

interface RowProps {
  label: string
  value?: string
}

function Row({ label, value = '' }: RowProps) {
  return (
    <div className={styles.row}>
      <div className={styles.fieldLabel} data-testid={`contentdetails-label-${_.kebabCase(label)}`}>
        {label}
      </div>
      <div className={styles.fieldValue} data-testid={`contentdetails-value-${ToHashCode(value)}`}>
        {value}
      </div>
    </div>
  )
}
