import * as utils from 'Utilities/InvocationUtils'
import AppSettings from 'ApplicationSettings/ApplicationSettings'

export const USERPROFILE_ROOT = 'myprofile'
export const USERPROFILE_API_VERSION = '1.0'

//Changing baseurl to Provisioning API
const baseUrl = AppSettings.PlatformUrl + '/provisioning/' + USERPROFILE_ROOT

const passwordResetUrl =
  AppSettings.PlatformUrl + '/provisioning/myprofile/passwordReset?api-version=1.0'

/**
 * Lookup and retrieve the current user
 * @param {string} accessToken A valid access token
 */
export async function getUserProfile(accessToken: string) {
  return await utils.getResource(`${baseUrl}?api-version=${USERPROFILE_API_VERSION}`, accessToken)
}

export async function passwordReset(accessToken: string): Promise<any> {
  const response = await fetch(passwordResetUrl, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    body: JSON.stringify({}),
  })
  return response.json
}
