import { Roles } from 'ApplicationSettings/ApplicationSettings'
import { AppFeatureDictionary, FeatureEnabled, SubscriptionApp } from 'Store/AuthState'

export enum ContentType {
  InhousePDF = 1,
  InhouseVideo = 2,
  Healthwise = 3,
  Unknown = 100,
}

export function GetContentType(hwid: string): ContentType {
  if (hwid.indexOf('inhouse') <= -1) return ContentType.Healthwise
  else if (hwid.indexOf('inhouse.') >= 0) return ContentType.InhousePDF
  //TODO: Replace with the actual prefix we will be using
  else if (hwid.indexOf('inhousevid.') >= 0) return ContentType.InhouseVideo
  else return ContentType.Unknown
}

export enum ContentStatus {
  Draft = 'Draft',
  Publish = 'Published',
  Suppress = 'Suppressed',
  Deleted = 'Deleted',
  Restore = 'Restored',
}

export function CanEdit(
  contentItem: ContentItem,
  roles: string[],
  contentItemMetadata: ContentItemMetadata
): boolean {
  if (!FeatureEnabled(AppFeatureDictionary.CanUploadAndManage)) {
    return false
  }
  let contentType = GetContentType(contentItem.hwid)
  if (contentType !== ContentType.Healthwise) {
    return (
      roles?.findIndex((item: string) => item === Roles.ADMINISTRATOR) > -1 &&
      contentItemMetadata?.status?.toLowerCase() !== ContentStatus.Suppress.toLowerCase()
    )
  } else {
    return (
      contentItemMetadata?.status?.toLowerCase() !== ContentStatus.Suppress.toLowerCase() &&
      roles?.findIndex((item: string) => item === Roles.ADMINISTRATOR) > -1
    )
  }
}

export const MetadataTypes: { key: string; name: string; 'data-testid': string }[] = [
  { key: 'keywords', name: 'Keywords', 'data-testid': 'option-keyword' },
  { key: 'icd10cm', name: 'ICD-10-CM Codes', 'data-testid': 'option-icd10cm' },
  { key: 'cpt', name: 'CPT ® Codes', 'data-testid': 'option-cpt' },
  { key: 'snomedct', name: 'SNOMED CT Codes', 'data-testid': 'option-snomedct' },
  { key: 'loinc', name: 'LOINC ® Codes', 'data-testid': 'option-loinc' },
  { key: 'rxnorm', name: 'RxNorm Codes', 'data-testid': 'option-rxnorm' },
]

export const KeywordLangTypes: { [key: string]: string } = {
  'en-us': 'en',
  'en-ca': 'en',
  'es-us': 'es',
  'fr-us': 'fr',
}

export const AgeRangeMin = 0
export const AgeRangeMax = 110

export enum Gender {
  Any = 'Any',
  Female = 'Female',
  Male = 'Male',
}

export type Status = {
  name: string
}

export type User = {
  userName: string
}

export type ContentItemStatus = {
  status: Status
  timeStamp: Date | string
  user: string
}

export enum MetadataItemAction {
  NoAction = 1,
  Adding = 2,
  Removing = 3,
}

export enum MetadataSource {
  Unknown = 1,
  AdHoc = 2,
  FromConcept = 3,
  HWAuthored = 4,
}

export type MetadataItem = {
  category: string
  value: any
  source: MetadataSource
  action: MetadataItemAction
  saved?: boolean
  conceptId?: string
}

export type Localization = {
  id: number
  name: string
  code: string
}

export type AgeMetadata = {
  minAge: number
  maxAge: number
}

export enum ConceptSource {
  Custom = 1,
  HWAuthored = 2,
}

export type Concept = {
  conceptLabel: string
  conceptId: string
  source?: ConceptSource
  metadataItems?: MetadataItem[]
}

export type Metadata = {
  age?: AgeMetadata
  description?: string
  concepts?: Concept[]
  cpt?: string[]
  gender?: string[]
  icd10ca?: string[]
  icd10cm?: string[]
  keywords?: string[]
  loinc?: string[]
  rxnorm?: string[]
  snomedct?: string[]
  specialties?: string[]

  //This property is a merged collection of metadata that tells us where each piece came from
  metadataItems?: MetadataItem[]
}

export const defaultContentItem: ContentItemMetadata = {
  id: -1,
  hwid: '',
  title: '',
  filename: '',
  localization: '',
  description: '',
  contentType: '',
  metadata: {},
  status: '',
  statuses: [],
  pdfUrl: '',
  canSubmit: false,
  editable: false,
  deprecated: false,
}

export type ContentItemMetadata = {
  id: number
  hwid: string
  localization: string
  title: string
  filename?: string
  description?: string
  contentType: string
  metadata?: Metadata
  status?: string
  statuses?: ContentItemStatus[]
  publishTo?: SubscriptionApp[]
  pdfUrl?: string
  canSubmit?: boolean
  editable?: boolean
  deprecated?: boolean
  globalMetadataChanged?: boolean
}

export type ContentItem = {
  id?: number
  hwid: string
  title: string
  filename?: string
  description?: string
  localization: string
  docType: string
  contentItemStatuses?: ContentItemStatus[]
  product: string
}

export default ContentItem
