import { SelectProps } from '@mui/material'

import Dropdown from 'Components/Dropdown'

import {
  ContentItemMetadata,
  ContentStatus,
  ContentType,
  GetContentType,
} from 'Store/Models/ContentItem'

import { SelectedStatus } from './ManageContentItem'

export interface UpdateStatusDropdownProps {
  canSuppress: boolean
  className?: string
  hwid: ContentItemMetadata['hwid']
  onChange: SelectProps<string>['onChange']
  value?: SelectedStatus
}

export default function UpdateStatusDropdown({
  canSuppress,
  className,
  hwid,
  onChange,
  value = '' as ContentStatus,
}: UpdateStatusDropdownProps) {
  const { Draft, Publish, Suppress } = ContentStatus
  const contentType = GetContentType(hwid)

  function createItem(contentStatus: ContentStatus) {
    return { name: contentStatus, value: contentStatus }
  }

  const items = [createItem(Draft), createItem(Publish)]

  if (canSuppress) items.push(createItem(Suppress))

  function infoMessage() {
    if (contentType === ContentType.Healthwise) return ''

    if (value === ContentStatus.Draft) return 'This content is not available until you publish it.'

    if (value === ContentStatus.Publish) return 'This content is available to be assigned.'

    if (value === ContentStatus.Suppress)
      return 'This content has been hidden by your organization and is not available to assign.'

    return ''
  }

  return (
    <Dropdown
      {...{ className, items, onChange, value }}
      data-testid='UpdateStatusSelection'
      itemTestId='content-item-status'
      nameKey='name'
      valueKey='value'
      title='Update Status'
      supportingText={infoMessage()}
      supportingTextProps={{ style: { width: '282px' } } as any}
    />
  )
}
