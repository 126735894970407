import { useCallback, useEffect, useState } from 'react'

import Whitelist from 'Components/Whitelist'
import { useAuthState } from 'Store/AuthState'
import { GlobalNotificationStateManager, SnackbarSeverity } from 'Store/GlobalNotificationState'

import Footer from 'Components/Footer/Footer'

import styles from './Embedding.module.scss'

import {
  AddWhitelistItem,
  DeleteWhitelistItem,
  GetWhitelist,
  UpdateWhitelistItem,
} from 'Api/Embed.api'

export default function Embedding() {
  const { authState } = useAuthState()
  const [whitelist, setWhitelist] = useState<string[]>([])

  const snackbarMessages = {
    DeleteSuccess: 'Site was successfully deleted.',
    DeleteFailure:
      'Unable to delete site. Please try again or contact your Healthwise account manager if the issue persists.',
    RetrievalError:
      'Unable to retrieve Approved Embed Sites. Please try again or contact your Healthwise account manager if the issue persists.',
    SaveSuccess: 'Site was successfully saved.',
    SaveFailure:
      'Unable to save changes. Please try again or contact your Healthwise account manager if the issue persists.',
  }

  const handleRefresh = useCallback(() => {
    GetWhitelist(authState.ecode).then(data => {
      if (Array.isArray(data)) {
        setWhitelist(data)
      } else {
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.RetrievalError,
          SnackbarSeverity.Error
        )
      }
    })
  }, [authState.ecode, snackbarMessages.RetrievalError])

  useEffect(() => {
    if (authState?.ecode?.length > 0) handleRefresh()
  }, [authState.ecode, handleRefresh])

  function handleAdd(domain: string) {
    setWhitelist(previousState => [...previousState, domain].sort())

    AddWhitelistItem(authState.ecode, domain).then(data => {
      if (data) {
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.SaveSuccess,
          SnackbarSeverity.Success
        )
        handleRefresh()
      } else
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.SaveFailure,
          SnackbarSeverity.Error
        )
    })
  }

  function handleRemove(domain: string) {
    setWhitelist(previousState => previousState.filter(str => str !== domain))

    DeleteWhitelistItem(authState.ecode, domain).then(data => {
      if (data) {
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.DeleteSuccess,
          SnackbarSeverity.Success
        )
        handleRefresh()
      } else
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.DeleteFailure,
          SnackbarSeverity.Error
        )
    })
  }

  function handleUpdate(oldDomain: string, newDomain: string) {
    if (oldDomain === newDomain) return null

    function updateWhitelist(domain: string) {
      if (domain === oldDomain) return newDomain

      return domain
    }

    setWhitelist(previousState => previousState.map(updateWhitelist))

    UpdateWhitelistItem(authState.ecode, oldDomain, newDomain).then(data => {
      if (data) {
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.SaveSuccess,
          SnackbarSeverity.Success
        )
        handleRefresh()
      } else
        GlobalNotificationStateManager.ShowSnackbarNotification(
          snackbarMessages.SaveFailure,
          SnackbarSeverity.Error
        )
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h2>Approved Embedding Sites</h2>
        <span>
          To embed, your site must be listed below. The root of your website's address will be
          recorded, removing any subpages or subdomains. Remove unneeded sites when necessary.
        </span>
      </div>

      <div className={styles.whitelist}>
        <Whitelist
          domains={whitelist}
          addNew={handleAdd}
          remove={handleRemove}
          update={handleUpdate}
        />
      </div>

      <Footer />
    </div>
  )
}
