import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Grid, Modal } from '@mui/material'

import { BulkSuppress, Restore } from 'Api/Content.api'

import CmxButton from 'Components/CmxButton'

import { useAuthState } from 'Store/AuthState'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import { CartAction, useCartState } from 'Store/CartState'
import { useManageStore } from 'Store/ManageState'
import { ContentType } from 'Store/Models/ContentItem'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'

import style from './SuppressModal.module.scss'
import { GetNonManagementSubs } from 'Utilities/OrgUtil'
import { StorageKeys } from 'Store/Hooks/useLocalStorage'

export default function SuppressModal() {
  const [loading, setLoading] = useState(false)
  const { authState } = useAuthState()

  const {
    fromBasket,
    fromManage,
    isBulk,
    isHwContent,
    isRestore,
    suppressOpen,
    suppressItems,
    updateFromSuppress,
    updateRefreshData,
    updateSuppressOpen,
    updateSuppressSuccessCount,
  } = useManageStore()

  const { cartStateDispatch } = useCartState()
  const { previewStateDispatch } = usePreviewState()
  const navigate = useNavigate()
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()
  const [singleItemTitle, setSingleItemTitle] = useState('')

  useEffect(() => {
    if (!isBulk && suppressItems.length > 0) {
      setSingleItemTitle(suppressItems[0].title)
    }
  }, [singleItemTitle, isBulk, suppressItems])

  async function onConfirm() {
    setLoading(true)
    if (isRestore) {
      GlobalNotificationStateManager.SetLoadingModalMessage(
        isBulk ? 'Restoring titles' : 'Restoring title',
        'This may take a moment...',
        true
      )
    } else {
      GlobalNotificationStateManager.SetLoadingModalMessage(
        isBulk ? 'Suppressing titles' : 'Suppressing title',
        'This may take a moment...',
        true
      )
    }

    let subs = GetNonManagementSubs(authState.orgInfo?.subscriptions ?? []).map(sub => sub.id)

    if ((suppressItems.length ?? 0) === 0) {
      throw new Error('No suppressable items selected')
    }

    //Restoring
    if (isRestore) {
      Restore(suppressItems, isHwContent ? ContentType.Healthwise : ContentType.InhousePDF)
        .then(response => {
          if (!response.hasError) {
            GlobalNotificationStateManager.SetLoadingModalError('')
            GlobalNotificationStateManager.ShowSnackbarNotification(
              response.successCount + ' title(s) restored successfully.',
              SnackbarSeverity.Success,
              false
            )
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
          } else {
            if (response.errorCount > 0) {
              if (isBulk) {
                var errorSummary =
                  response.errorCount +
                  ' title(s) could not be restored at this time. Please try again or contact your Healthwise account manager.'
                GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)
                GlobalNotificationStateManager.SetLoadingModalError(
                  response.errorMsg ?? 'An unknown error occured.'
                )
              } else {
                GlobalNotificationStateManager.ShowSnackbarNotification(
                  'Error restoring title. Please try again or contact your Healthwise administrator.',
                  SnackbarSeverity.Error,
                  false
                )
                GlobalNotificationStateManager.SetLoadingModalVisible(false)
              }
            }
            if (response.successCount > 0) {
              updateFromSuppress(true)
              updateSuppressSuccessCount(response.successCount)
            }
          }
        })
        .catch(() => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error restoring title(s). Please try again or contact your Healthwise administrator.',
            SnackbarSeverity.Error,
            false
          )
        })
        .finally(() => {
          clearSelection()
          setLoading(false)
          updateRefreshData(true)
          updateSuppressOpen(false)
        })
      //Suppressing
    } else {
      BulkSuppress(suppressItems, fromBasket, subs ?? [])
        .then(response => {
          if (!response.hasError) {
            var successMsg = ''
            if (isBulk) {
              successMsg = response.successCount + ' titles suppressed successfully.'
            } else {
              successMsg = '1 title suppressed successfully.'
            }
            GlobalNotificationStateManager.SetLoadingModalError('')
            GlobalNotificationStateManager.ShowSnackbarNotification(
              successMsg,
              SnackbarSeverity.Success,
              false
            )
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
            //go back on success
            if (fromManage) {
              navigate(-1)
            }
          } else {
            if (response.errorCount > 0) {
              var errorSummary = ''
              if (isBulk) {
                errorSummary =
                  response.errorCount + ' title(s) could not be suppressed at this time:'
              } else {
                errorSummary = 'The following localization(s) could not be suppressed at this time:'
              }
              GlobalNotificationStateManager.SetLoadingModalSummary(errorSummary)
              GlobalNotificationStateManager.SetLoadingModalError(
                response.errorMsg ?? 'An unknown error occured.'
              )
            }
            //when Finish button is clicked from Loading modal
            if (response.successCount > 0) {
              updateFromSuppress(true)
              updateSuppressSuccessCount(response.successCount)
            }
          }
        })
        .catch(e => {
          //use e.message to show error on modal
          GlobalNotificationStateManager.SetLoadingModalError(
            e.message ?? 'An unknown error occured.'
          )
        })
        .finally(() => {
          clearSelection()
          if (fromBasket) {
            cartStateDispatch({ type: CartAction.CLEARCART })
            cartStateDispatch({ type: CartAction.SETCARTOPEN, data: false })
          }
          //close preview if needed
          previewStateDispatch({ type: PreviewStateAction.SETPREVIEWOPEN, data: false })
          previewStateDispatch({ type: PreviewStateAction.CLEARPREVIEWCONTENT })
          setLoading(false)
          updateRefreshData(true)
          updateSuppressOpen(false)
        })
    }
  }

  function clearSelection() {
    localStorage.removeItem(
      suppressItems[0].hwid.startsWith('inhouse.')
        ? StorageKeys.SelectedInhouseRows
        : StorageKeys.SelectedHealthwiseRows
    )
  }

  function onCancel() {
    updateSuppressOpen(false)
  }

  return (
    <Modal open={suppressOpen && !globalNotificationState.loadingModalInfo.visible}>
      <Grid container className={style.modalContent}>
        <Grid container direction='row'>
          <Grid item>
            {!isRestore ? (
              isBulk ? (
                <div className={style.modalTitle} data-qa='modalTitle'>
                  Suppress {suppressItems.length} titles?
                </div>
              ) : (
                <div className={style.modalTitle} data-qa='modalTitle'>
                  Suppress title?
                </div>
              )
            ) : isBulk ? (
              <div className={style.modalTitle} data-qa='modalTitle'>
                Restore {suppressItems.length} titles?
              </div>
            ) : (
              <div className={style.modalTitle} data-qa='modalTitle'>
                Restore title?
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container direction='row'>
          <div className={style.suppressionMessageContainer}>
            {!isRestore ? (
              isBulk ? (
                <div>
                  <p className={style.bottomMargin}>
                    <span className={style.bold}>
                      The following titles will be hidden from your organization and will not be
                      available to search or assign.
                    </span>
                    This includes versions that exist in any language.
                  </p>
                  <div className={style.suppressListContainer}>
                    {suppressItems.reverse().map((item: any) => (
                      <p key={item.title}>{item.title}</p>
                    ))}
                  </div>
                  <p className={style.topMargin}>
                    If you wish to restore a title at a later date, you can do so on the Manage
                    page.
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    <span className={style.bold}>
                      {singleItemTitle} will be hidden from your organization and will not be
                      available to search or assign.
                    </span>
                    This includes versions that exist in any language.
                  </p>
                  <p>
                    If you wish to restore a title at a later date, you can do so on the Manage
                    page.
                  </p>
                </div>
              )
            ) : isBulk ? (
              <div>
                {isHwContent ? (
                  <p className={style.bottomMargin}>
                    <span className={style.bold}>
                      The following titles will be made available to your organization.
                    </span>
                    This includes versions that exist in any language.
                  </p>
                ) : (
                  <p className={style.bottomMargin}>
                    <span className={style.bold}>
                      The following titles will be moved to Draft status.
                    </span>
                    This includes versions that exist in any language. You will need to Publish a
                    title to make it available for searching and assigning.
                  </p>
                )}
                <div className={style.suppressListContainer}>
                  {suppressItems.map((item: any) => (
                    <p>{item.title}</p>
                  ))}
                </div>
                {isHwContent ? (
                  <p className={style.topMargin}>
                    If a Draft version of a title exists, you will need to Publish that title to
                    make it available for searching and assigning.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                {isHwContent ? (
                  <p>
                    <span className={style.bold}>
                      {singleItemTitle} will be made available to your organization.
                    </span>
                    This includes versions that exist in any language.
                  </p>
                ) : (
                  <p>
                    <span className={style.bold}>
                      {singleItemTitle} will be moved to Draft status.
                    </span>
                    This includes versions that exist in any language. You will need to Publish that
                    title to make it available for searching and assigning.
                  </p>
                )}
                {isHwContent ? (
                  <p>
                    If a Draft version of a title exists, you will need to Publish that title to
                    make it available for searching and assigning.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          spacing={1}
          className={style.buttonPanel}
        >
          <Grid item>
            <CmxButton onClick={onCancel} data-qa='modalCancelButton' disabled={loading}>
              Cancel
            </CmxButton>
          </Grid>
          <Grid item>
            {loading ? (
              <CircularProgress color='primary' className='loader' />
            ) : (
              <CmxButton
                primary
                onClick={onConfirm}
                data-qa='modalConfirmButton'
                data-testid='modal-confirmation-button'
              >
                {isRestore ? 'Restore' : 'Suppress'}
              </CmxButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
