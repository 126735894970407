import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { ImportFile, FileUploadStatus } from 'Store/ImportState'

import styles from './FileUpload.module.scss'

import DragonDrop from '../DragonDrop'
import FileItem from './FileItem'
import useLocalStorage from 'Store/Hooks/useLocalStorage'
import { NO_UPLOADED_FILES_ERROR } from '../ContentUpload/ContentUpload'

interface FileUploadProps {
  uploadErrors: boolean
  setUploadErrors: (e: boolean) => void
  goNextStatus?: string
  setGoNextStatus?: (e: string) => void
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  uploadErrors,
  setUploadErrors,
  goNextStatus,
  setGoNextStatus,
}) => {
  const [uploadStatus, setUploadStatus] = useState('')
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [files, setFiles] = useLocalStorage('fileImportDetails', [])
  const [fileUploadError, setFileUploadError] = useState('')

  useEffect(() => {
    if (files.filter((file: ImportFile) => !file.removedFromUpload).length > 0) {
      if (goNextStatus && goNextStatus === NO_UPLOADED_FILES_ERROR) {
        setGoNextStatus!('')
      }
      if (files.filter((file: ImportFile) => !file.removedFromUpload).length > 20) {
        setFileUploadError('A maximum of 20 files can be uploaded at one time.')
      } else {
        setFileUploadError('')
      }
      let inProgressUploads = files.filter(
        (file: ImportFile) =>
          !file.removedFromUpload && file.uploadStatus === FileUploadStatus.InProgress
      ).length
      let importsInError = files.filter(
        (file: ImportFile) =>
          !file.removedFromUpload &&
          ((file.fileExists && !file.overwriteFile) || file.unsupportedType || file.overSizeLimit)
      ).length
      setUploadInProgress(inProgressUploads > 0)
      if (inProgressUploads > 0) {
        setUploadErrors(false)
        setUploadStatus(`${inProgressUploads} Upload${inProgressUploads > 1 ? 's' : ''} Remaining`)
      } else if (importsInError > 0) {
        setUploadErrors(true)
        setUploadStatus(`Resolve ${importsInError} Error${importsInError > 1 ? 's' : ''}`)
      } else {
        setUploadErrors(false)
        if (files.length > 0) {
          setUploadStatus('Upload Complete')
        } else {
          setFileUploadError('')
        }
      }
    } else {
      setFileUploadError('')
    }
  }, [files, setUploadErrors, goNextStatus, setGoNextStatus])

  function onFilesAdded(newFiles: Array<ImportFile>) {
    const uploadedFiles: ImportFile[] = JSON.parse(
      localStorage.getItem('fileImportDetails') || '[]'
    )
    let duplicates: Array<string> = []
    let reAddedFiles: Array<ImportFile> = []
    if (uploadedFiles?.length) {
      for (let i = 0; i < newFiles.length; ++i) {
        if (uploadedFiles.find((file: ImportFile) => file.filename === newFiles[i].filename)) {
          duplicates.push(newFiles[i].filename)
        }
      }
      for (let i = 0; i < duplicates.length; ++i) {
        let reAddedFile = uploadedFiles.filter(
          (file: ImportFile) => file.filename === duplicates[i]
        )[0]
        newFiles.splice(
          newFiles.findIndex(file => file.filename === duplicates[i]),
          1
        )
        reAddedFiles.push({ ...reAddedFile, removedFromUpload: false })
      }
    }
    let maxFileKey =
      uploadedFiles.length > 0
        ? Math.max(...uploadedFiles.map((file: ImportFile) => file.fileKey)) + 1
        : 1

    if (
      newFiles.length + uploadedFiles.filter((file: ImportFile) => !file.removedFromUpload).length >
      20
    ) {
      setFileUploadError('A maximum of 20 files can be uploaded at one time.')
    } else {
      setFileUploadError('')
      for (let i = 0; i < newFiles.length; ++i) {
        let fileKey = maxFileKey + i
        newFiles[i].fileKey = fileKey
      }
      setFiles([
        ...uploadedFiles.filter(
          (file: ImportFile) =>
            reAddedFiles.filter((rAF: ImportFile) => file.fileKey === rAF.fileKey).length === 0
        ),
        ...reAddedFiles,
        ...newFiles,
      ])
    }
  }

  function UpdateFileInfo(file: ImportFile) {
    setFiles([...files.filter((f: ImportFile) => f.fileKey !== file.fileKey), file])
  }

  return (
    <div className={styles.wrapper}>
      <h2>Add Content</h2>
      <DragonDrop onFilesAdded={onFilesAdded}></DragonDrop>
      {uploadStatus && (
        <div
          className={`${styles.uploadStatus} ${
            uploadStatus === ''
              ? ''
              : uploadErrors
              ? styles.uploadStatusError
              : uploadInProgress
              ? styles.uploadStatusInfo
              : styles.uploadStatusSuccess
          }`}
          aria-live='polite'
        >
          {uploadStatus}
        </div>
      )}
      <div className={styles.fileListContainer}>
        {files
          .sort((a: ImportFile, b: ImportFile) => (a.fileKey > b.fileKey ? 1 : -1))
          .map((file: any, index: number) => {
            return (
              <Fragment key={`FileItem_${index}`}>
                <FileItem file={file} UpdateFileInfo={UpdateFileInfo} />
                {index < files.length - 1 && <div className={styles.fileSeperator}></div>}
              </Fragment>
            )
          })}
      </div>
      {fileUploadError && <div className={styles.errorText}>{fileUploadError}</div>}
    </div>
  )
}

export default FileUpload
