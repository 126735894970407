import { MenuItem } from '@mui/material'
import { buildLangDropDown, LanguageArray } from 'Api/Algolia.api'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import { FC, useEffect, useState } from 'react'
import { useAuthState } from 'Store/AuthState'
import { CartAction, useCartState } from 'Store/CartState'
import ContentItem from 'Store/Models/ContentItem'
import { useAlgolia } from 'Store/UseAlgolia'

interface Props {
  selectedLanguage: string
  handleLanguageChange: (localizationKey: string, cartItem: ContentItem) => void
  cartItem: ContentItem
}

export const LanguageSelect: FC<Props> = ({ selectedLanguage, handleLanguageChange, cartItem }) => {
  const { authState } = useAuthState()
  const { cartStateDispatch } = useCartState()
  const { algoliaState } = useAlgolia(authState.accessToken)
  const [licensedLanguages, setLicensedLanguages] = useState<LanguageArray>([['en-us', 'English']])
  const [language] = useState('en-us')

  useEffect(() => {
    buildLangDropDown(
      cartItem.hwid,
      algoliaState.key.QueryAppId,
      algoliaState.key.QueryKey,
      algoliaState.index,
      algoliaState.languagePackageAttribute
    )
      .then(data => {
        if (data) {
          setLicensedLanguages(data)
          cartStateDispatch({ type: CartAction.SETLANGUAGEERROR, data: '' })
        }
      })
      .catch(function () {
        cartStateDispatch({
          type: CartAction.SETLANGUAGEERROR,
          data: 'There was an error retrieving languages from the database. Please reload the basket or contact your Healthwise administrator if the issue persists.',
        })
      })
    // eslint-disable-next-line
  }, [])

  if (licensedLanguages.length) {
    return (
      <CmxTextField
        sx={{
          marginTop: '16px',
          width: '67px',
          minWidth: '67px',
        }}
        onChange={e => {
          handleLanguageChange(e.target.value, cartItem)
        }}
        value={selectedLanguage}
        SelectProps={{
          renderValue: selected => <div>{(selected as string).split('-')[0].toUpperCase()}</div>,
        }}
        mode='borderless'
        defaultValue={language}
        height='28px'
        select
      >
        {licensedLanguages.map((locale: any) => {
          return (
            <MenuItem role='option' value={locale[0]} key={locale[0]}>
              {locale[1]}
            </MenuItem>
          )
        })}
      </CmxTextField>
    )
  } else return <></>
}
