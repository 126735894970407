import { Chip } from '@mui/material'
import { useClearRefinements } from 'react-instantsearch'
import useSearchStore from 'Store/SearchState'

import styles from './SelectedFacets.module.scss'
import { attributeTitleLabels, getItemLabel } from '../FacetUtilities'
import classNames from 'classnames'

function CurrentRefinements() {
  const filters = useSearchStore(state => state.filters)
  const clearAllFilters = useSearchStore(state => state.clearAllFilters)
  const toggleFilter = useSearchStore(state => state.toggleFilter)
  const { refine: clear } = useClearRefinements()

  const ageRangeOrders: Record<string, number> = {
    'Infant (birth to 23 months)': 0,
    'Child (2 to 12 years)': 1,
    'Teen (13 to 17 years)': 2,
    'Adult (18 to 64 years)': 3,
    'Senior (65 and up)': 4,
  }

  function sortChips(a: string, b: string, key: string): number {
    if (key === 'Age Range') {
      return ageRangeOrders[a] - ageRangeOrders[b]
    } else if (key === 'Language') {
      if (a === 'en-us') return -1
      if (b === 'en-us') return 1
      if (a === 'es-us') return -1
      if (b === 'es-us') return 1
      return getItemLabel(attributeTitleLabels[key], a).localeCompare(
        getItemLabel(attributeTitleLabels[key], b)
      )
    } else {
      return a > b ? 1 : -1
    }
  }

  // Flattens filter map
  let selectedFilters: any[] = []
  Object.keys(filters).forEach(key => {
    selectedFilters = selectedFilters.concat(
      filters[key]
        .sort((a: string, b: string) => sortChips(a, b, key))
        .map((filter: string) => ({ attribute: key, label: filter }))
    )
  })

  return (
    <div className={styles.selections}>
      {selectedFilters.length > 0 && (
        <Chip
          onClick={() => {
            clearAllFilters()
            clear()
          }}
          className={classNames(styles.filterChip, styles.clearAllChip)}
          label='Clear All Filters'
        />
      )}
      {selectedFilters.map(filter => {
        return (
          <Chip
            key={filter.label}
            label={getItemLabel(attributeTitleLabels[filter.attribute], filter.label)}
            className={styles.filterChip}
            onDelete={() => {
              toggleFilter(filter.attribute, filter.label)
            }}
          />
        )
      })}
    </div>
  )
}

export default function SelectedFacets() {
  return <CurrentRefinements />
}
