import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const KeyboardShortcuts: FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const handleShortcuts = (e: KeyboardEvent) => {
      if (e.altKey && e.ctrlKey && e.key === 'f') {
        navigate(App_Routes.FEATURES)
      }
    }

    window.addEventListener('keydown', handleShortcuts)
    return () => {
      window.removeEventListener('keydown', handleShortcuts)
    }
  }, [navigate])

  return <></>
}
