import { Dispatch, SetStateAction } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'

import useLocalStorage from 'Store/Hooks/useLocalStorage'

import style from './Features.module.scss'

type RenderSectionProps = {
  title: string
  features: RenderFeatureProps[]
}

type RenderFeatureProps = {
  label: string
  info: string
  status: boolean
  setStatus: Dispatch<SetStateAction<boolean>>
}

export default function FeatureFlipper() {
  const [enableCount, setEnableCount] = useLocalStorage('enableCount', false)
  const [showExpMenus, setShowExpMenus] = useLocalStorage('expMenus', false)

  const sections: RenderSectionProps[] = [
    {
      title: 'Experimental Features',
      features: [
        {
          label: "Show 'Category' and 'Population' menus",
          info: 'Displays browse bar menus that are under development',
          status: showExpMenus,
          setStatus: setShowExpMenus,
        },
      ],
    },
    {
      title: 'Debugging',
      features: [
        {
          label: 'Show Browse Page count',
          info: 'Adds a count to the quick filters after browsing/searching.',
          status: enableCount,
          setStatus: setEnableCount,
        },
      ],
    },
  ]

  return (
    <div className={style.featureFlipperPage}>
      <div className={style.banner}>
        <h1 className={style.title}>Feature Flippers</h1>
      </div>
      {sections.map(section => (
        <div className={style.flippers}>
          <div className={style.sectionTitle}>{section.title}</div>
          <Box className={style.container}>
            {section.features.map(feature => {
              const { label, info, status, setStatus } = feature
              return (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e: any) => {
                        setStatus(e.target.checked)
                      }}
                      checked={status}
                      size='medium'
                    />
                  }
                  label={
                    <>
                      {label} - <i>{info}</i>
                    </>
                  }
                />
              )
            })}
          </Box>
        </div>
      ))}
    </div>
  )
}
