import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Checkbox, FormControlLabel, IconButton } from '@mui/material'
import CmxToggleButtonGroup from 'Components/CmxToggleButtonGroup'
import styles from './Embed.module.scss'
import { useState } from 'react'
import { CopiedTooltip } from './TopicCluster'
import CmxButton from 'Components/CmxButton'
import SettingsCode from 'Resources/settings-code.svg'
import { useNavigate } from 'react-router'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import CmxTextField from 'Components/CmxTextField/CmxTextField'

interface EmbedProps {
  length: string
  setLength: (length: string) => void
  availableLengths: string[]
  localization: string
  setLocalization: (language: string) => void
  availableLocalizations: string[]
  showTitle: boolean
  setShowTitle: (showTitle: boolean) => void
  showAttribution: boolean
  setShowAttribution: (showAttribution: boolean) => void
  embedCodeBtns: string[]
  embedType: string
  setEmbedType: (embedType: string) => void
  embedText: string // The text that a user will copy to embed in their own site
}

export default function Embed(props: EmbedProps) {
  const {
    length,
    setLength,
    availableLengths,
    localization,
    setLocalization,
    availableLocalizations,
    showTitle,
    setShowTitle,
    showAttribution,
    setShowAttribution,
    embedCodeBtns,
    embedType,
    setEmbedType,
    embedText,
  } = props

  const [copiedTooltipOpen, setCopiedTooltipOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <CmxToggleButtonGroup
        label='Language'
        buttons={availableLocalizations}
        value={localization}
        setValue={setLocalization}
      />
      <CmxToggleButtonGroup
        label='Length'
        buttons={availableLengths}
        value={length}
        setValue={setLength}
      />
      <div className={styles.section}>
        Options
        <div className={styles.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showTitle}
                onClick={() => {
                  setShowTitle(!showTitle)
                }}
                sx={{
                  '&.Mui-focusVisible': {
                    backgroundColor: 'white',
                  },
                }}
              />
            }
            label='Show Title'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showAttribution}
                onClick={() => {
                  setShowAttribution(!showAttribution)
                }}
                sx={{
                  '&.Mui-focusVisible': {
                    backgroundColor: 'white',
                  },
                }}
              />
            }
            label='Show Attribution'
          />
        </div>
      </div>
      <CmxToggleButtonGroup
        label='Embed Code'
        buttons={embedCodeBtns}
        value={embedType}
        setValue={setEmbedType}
      />
      <div className={styles.embedCopy}>
        <CmxTextField
          fullWidth
          value={embedText}
          inputProps={{ spellCheck: 'false' }}
          mode='light'
        />
        <CopiedTooltip open={copiedTooltipOpen} setOpen={setCopiedTooltipOpen}>
          <IconButton
            className={styles.copy}
            onClick={() => {
              setCopiedTooltipOpen(true)
              navigator.clipboard.writeText(embedText)
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </CopiedTooltip>
      </div>

      <div className={styles.approvedSites}>
        <CmxButton
          variant='text'
          onClick={() => {
            navigate(App_Routes.EMBEDDING)
          }}
        >
          <img alt='Approved Embedding Sites' src={SettingsCode} />
          Approved Embedding Sites
        </CmxButton>
        To embed, your site must be on the list.
      </div>
    </div>
  )
}
