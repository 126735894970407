import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import RestoreIcon from '@mui/icons-material/Restore'
import statusErrorLogo from 'Resources/icon-status-error.svg'
import { Box, Chip, Icon, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridComparatorFn,
  gridStringOrNumberComparator,
  GridValueGetterParams,
  gridNumberComparator,
} from '@mui/x-data-grid'
import { useManageStore } from 'Store/ManageState'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { cloneElement, FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { theme } from 'Styles/AppTheme'
import styles from './ContentManagement.module.scss'
import moment from 'moment'
import { FindLocaleCodeByTitle } from 'Utilities/LocaleMapUtil'
import { ContentStatus } from 'Store/Models/ContentItem'
import { useOverflowDetector } from 'react-detectable-overflow'
import { Link } from 'react-router-dom'

//TODO
//import useLocalStorage from 'Store/Hooks/useLocalStorage'

const SUPPRESSED_STATUS: string = 'suppressed'

export function getStatusChip(label: string): JSX.Element {
  const labels = label
    .trim()
    .split(' ')
    .map(item => {
      return item
    })

  const labelColors = labels.map(item => {
    switch (item.toLowerCase()) {
      case 'draft':
        return 'warning'
      case 'suppressed':
        return 'error'
      default:
        return 'success'
    }
  })

  return (
    <>
      <Chip
        label={labels[0]}
        size='small'
        className={styles.primaryStatusChip}
        sx={{
          backgroundColor: theme.palette[labelColors[0]].light,
        }}
      />
      {labels[1] && (
        <Chip
          label={labels[1]}
          size='small'
          className={styles.secondaryStatusChip}
          sx={{
            backgroundColor: theme.palette[labelColors[1]].light,
          }}
        />
      )}
    </>
  )
}

interface SingleActionItemProps {
  actionId: string
  status: string
  actionItem: GridRowModel
  isHwContent: boolean
  hasFocus: boolean
}

const SingleItemActions: FunctionComponent<SingleActionItemProps> = ({
  actionId: _actionId,
  status,
  actionItem,
  isHwContent,
  hasFocus,
}) => {
  const navigate = useNavigate()

  const {
    updateDeleteOpen,
    updateIsBulk,
    updateIsHwContent,
    updateIsRestore,
    updateSuppressItems,
    updateSuppressOpen,
  } = useManageStore()

  const isSuppressed =
    status.toLocaleLowerCase().trim() === SUPPRESSED_STATUS.toLocaleLowerCase().trim()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const [canSuppress, setCanSuppress] = useState(false)
  //TODO will be needed to return focus to single actions dropdown
  //const [activeElement, setActiveElement] = useLocalStorage('previousActiveElement', null)

  useEffect(() => {
    setCanSuppress(
      actionItem.status.toLowerCase().trim().endsWith(ContentStatus.Publish.trim().toLowerCase()) ||
        (isHwContent &&
          actionItem.status.toLowerCase().trim().endsWith(ContentStatus.Draft.trim().toLowerCase()))
    )
  }, [canSuppress, isHwContent, actionItem.status])

  const handleOpenDelete = (actionItem: any) => {
    let itemToDelete = []
    itemToDelete.push(actionItem.id)
    updateDeleteOpen(true)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //capture active element with id of dropdown
    //not using document.activeElement due to Material UI nesting
    //TODO set active element to id of single action dropdown (after its made accessible)
    //setActiveElement('putidhere')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleSingleManage() {
    handleClose()
    let localization = FindLocaleCodeByTitle(actionItem.language)
    navigate(`${App_Routes.MANAGECONTENTITEM}/${actionItem.hwid}/${localization}`)
  }

  function handleSingleSuppress() {
    handleClose()
    updateSuppressItems([{ hwid: actionItem.hwid, title: actionItem.title }])
    updateIsRestore(false)
    updateIsBulk(false)
    updateSuppressOpen(true)
  }

  function handleSingleRestore() {
    handleClose()
    updateSuppressItems([{ hwid: actionItem.hwid, title: actionItem.title }])
    updateIsHwContent(isHwContent)
    updateIsRestore(true)
    updateIsBulk(false)
    updateSuppressOpen(true)
  }

  return (
    <>
      <IconButton
        aria-label='choose an action'
        aria-controls={open ? 'row-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        id='singleActionsBtn'
        onClick={handleClick}
        className={styles.singleItemActions}
        tabIndex={hasFocus ? 0 : -1}
      >
        <MoreHoriz className={styles.singleItemActionsIcon} />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'row-button',
        }}
        PaperProps={{
          sx: {
            width: '164px',
            paddingTop: '0',
            paddingBottom: '0',
            boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.20)',
            '& .MuiMenuItem-root.Mui-selected': {
              backgroundColor: styles.lightGrey,
            },
            '& .MuiMenuItem-root': {
              height: '48px',
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: styles.offWhite,
            },
            '& .MuiMenuItem-root.Mui-selected:hover': {
              backgroundColor: styles.offWhite,
            },
          },
        }}
      >
        {FindLocaleCodeByTitle(actionItem.language).toLowerCase() !== 'all' &&
          status.toLowerCase().trim() !== ContentStatus.Suppress.toLowerCase() && (
            <MenuItem onClick={handleSingleManage}>
              <Link
                className={styles.menuItem}
                to={`${App_Routes.MANAGECONTENTITEM}/${actionItem.hwid}/${FindLocaleCodeByTitle(
                  actionItem.language
                )}`}
                tabIndex={0}
              >
                <CreateIcon className={styles.padding} /> Manage
              </Link>
            </MenuItem>
          )}
        {isSuppressed && (
          <MenuItem onClick={handleSingleRestore}>
            <IconButton disableRipple className={styles.menuItem} aria-haspopup='dialog'>
              <RestoreIcon /> Restore
            </IconButton>
          </MenuItem>
        )}
        {canSuppress && (
          <MenuItem onClick={handleSingleSuppress}>
            <IconButton disableRipple className={styles.menuItem} aria-haspopup='dialog'>
              <VisibilityOffIcon /> Suppress
            </IconButton>
          </MenuItem>
        )}
        {!isHwContent ? (
          <MenuItem
            className={`${styles.menuItem} ${styles.deleteIcon}`}
            onClick={() => handleOpenDelete(actionItem)}
          >
            <IconButton disableRipple className={styles.menuItem} aria-haspopup='dialog'>
              <DeleteIcon color='secondary' /> Delete
            </IconButton>
          </MenuItem>
        ) : (
          <div></div>
        )}
      </Menu>
    </>
  )
}

const warningTitleSortComparator: GridComparatorFn = (v1, v2, param1, param2) => {
  if (v1.errorStatus && v2.errorStatus) {
    return gridStringOrNumberComparator(v1.title, v2.title, param1, param2)
  }

  const warningCompare = gridNumberComparator(v1.errorStatus, v2.errorStatus, param1, param2)
  if (warningCompare !== 0) {
    return -warningCompare
  }

  return gridStringOrNumberComparator(v1.title, v2.title, param1, param2)
}

export function OverflowDetectionCell({ params, children }: any) {
  const { ref, overflow } = useOverflowDetector({})

  return (
    <Tooltip
      title={overflow ? (params.value.title ? params.value.title : params.value) : ''}
      arrow
      className={styles.cellTooltip}
    >
      {cloneElement(children, { ref: ref })}
    </Tooltip>
  )
}

function doShowWarning(row: any) {
  return (
    row.availableLocalizations &&
    row.language !== 'English' &&
    row.status.indexOf('Published') > -1 &&
    row.availableLocalizations.indexOf('en-us') < 0
  )
}

export default function ColumnDefinitions(isHWContent: boolean = false) {
  const dateFormat = 'MMM DD, YYYY'
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Identifier',
      getApplyQuickFilterFn: undefined,
    },
    {
      field: 'hwid',
      minWidth: 120,
      maxWidth: 190,
      renderCell: (params: GridCellParams<any, any>) => {
        return (
          <OverflowDetectionCell params={params}>
            <Box className={styles.showMoreOnOverflow} tabIndex={params.hasFocus ? 0 : -1}>
              {params.value}
            </Box>
          </OverflowDetectionCell>
        )
      },
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'title',
      width: 250,
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) => ({
        title: params.value,
        errorStatus: doShowWarning(params.row),
      }),
      sortComparator: warningTitleSortComparator,
      renderCell: (params: GridCellParams<any, any>) => {
        let localization = FindLocaleCodeByTitle(params.row.language)
        return (
          <>
            {params.value.errorStatus && (
              <Tooltip
                title='In order for this title to show in search results and be assigned, an English version must be Published.'
                arrow
                className={styles.cellTooltip}
              >
                <Icon aria-label='No English Version Warning'>
                  <img alt='No English Version Warning' src={statusErrorLogo} />
                </Icon>
              </Tooltip>
            )}
            <OverflowDetectionCell params={params}>
              {params.row.status.toLowerCase().trim() === SUPPRESSED_STATUS ? (
                <Box
                  className={`${styles.dataTableTitleColumn} ${styles.showMoreOnOverflow}`}
                  tabIndex={params.hasFocus ? 0 : -1}
                >
                  {params.value.title}
                </Box>
              ) : (
                <Link
                  className={`${styles.dataTableTitleColumn} ${styles.showMoreOnOverflow}`}
                  to={`${App_Routes.MANAGECONTENTITEM}/${params.row.hwid}/${localization}`}
                  tabIndex={params.hasFocus ? 0 : -1}
                >
                  {params.value.title}
                </Link>
              )}
            </OverflowDetectionCell>
          </>
        )
      },
      headerName: 'Title',
      getApplyQuickFilterFn: value => {
        return (params: GridCellParams<any, any>): boolean => {
          return params.value.title.toLowerCase().indexOf(value.toLowerCase()) > -1
        }
      },
      flex: 1,
    },
    {
      field: 'language',
      minWidth: 90,
      maxWidth: 160,
      renderCell: (params: GridCellParams<any, any>) => {
        return (
          <OverflowDetectionCell params={params}>
            <Box className={styles.showMoreOnOverflow} tabIndex={params.hasFocus ? 0 : -1}>
              {params.value}
            </Box>
          </OverflowDetectionCell>
        )
      },
      headerName: 'Language',
      flex: 1,
    },
    {
      field: 'status',
      minWidth: 125,
      maxWidth: 170,
      renderCell: (params: GridRenderCellParams<any, any>) => getStatusChip(params.value || ''),
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'lastUpdated',
      type: 'date',
      minWidth: 130,
      maxWidth: 180,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box className={styles.showMoreOnOverflow}>{moment(params.value).format(dateFormat)}</Box>
        )
      },
      headerName: 'Last Updated',
      getApplyQuickFilterFn: value => {
        return (params: GridCellParams<any, any>): boolean =>
          moment(params.value).format(dateFormat).toLowerCase().indexOf(value.toLowerCase()) > -1
      },
      flex: 1,
    },
    {
      field: 'updatedBy',
      minWidth: 150,
      maxWidth: 190,
      renderCell: (params: GridCellParams<any, any>) => {
        return (
          <OverflowDetectionCell params={params}>
            <Box className={styles.showMoreOnOverflow}>{params.value}</Box>
          </OverflowDetectionCell>
        )
      },
      headerName: 'Updated By',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 90,
      renderCell: (params: GridRenderCellParams) => (
        <SingleItemActions
          actionId={params.row?.id}
          status={params.row?.status}
          actionItem={params.row}
          isHwContent={isHWContent}
          hasFocus={params.hasFocus}
        />
      ),
      headerName: 'Actions',
    },
  ]

  return columns
}
