import { MouseEventHandler, useState } from 'react'
//import { unstable_usePrompt } from 'react-router-dom'

import { Button, TextField, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'

import statusErrorLogo from 'Resources/icon-status-error-red.svg'

import CmxButton from 'Components/CmxButton'
import SaveChangesModal from 'Components/SaveChangesModal/SaveChangesModal'

import styles from './Whitelist.module.scss'

interface WhitelistItemProps {
  domain: string
  close?: () => void
  remove?: (domain: string) => void
  saveNew?: (domain: string) => void
  update?: (oldDomain: string, newDomain: string) => void
  //setUnsavedChanges: (changes: boolean) => void
}

function WhitelistItem({
  domain,
  close,
  remove,
  saveNew,
  update,
}: //setUnsavedChanges,
WhitelistItemProps) {
  const [isEditing, setIsEditing] = useState(domain === '')
  const [showError, setShowError] = useState(false)
  const [newDomain, setNewDomain] = useState(domain)

  const [leaving, setLeaving] = useState(false)
  const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false)

  const buttonStyles = {
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: isEditing ? styles.offWhite : styles.white,
    },
    '&.Mui-focusVisible': {
      background: styles.white,
      border: 'none',
    },
  }

  const isValidUrl = (urlStr: string) => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        'localhost|' + // validate localhost
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // validate fragment locator
    return !!urlPattern.test(urlStr)
  }

  function handleEditing(editing: boolean) {
    setIsEditing(editing)
    //setUnsavedChanges(editing)
  }

  function handleClose() {
    handleEditing(false)
    setShowError(false)
    setNewDomain(domain)
    if (typeof close !== 'undefined') close()
  }

  function handleCloseClicked() {
    if (domain !== newDomain.trim()) setSaveChangesModalOpen(true)
    else {
      handleClose()
    }
  }

  function handleRemove() {
    handleEditing(false)
    if (typeof remove !== 'undefined') remove(domain)
  }

  function handleSave() {
    const tmpIsValid = isValidUrl(newDomain)
    setShowError(!tmpIsValid)

    if (tmpIsValid) {
      handleEditing(false)
      if (typeof saveNew !== 'undefined') saveNew(newDomain.trim())
      else if (typeof update !== 'undefined') update(domain, newDomain.trim())
      else throw new Error('Encountered unexpected WhitelistItem state!')
    } else setShowError(true)
  }

  const onDiscardChangesClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    setSaveChangesModalOpen(false)
    handleClose()
  }

  return (
    <div className={styles.listItemWrapper}>
      <div
        {...(isEditing ? { style: { backgroundColor: 'transparent' } } : {})}
        data-testid={`WhitelistItem-${domain}`}
        className={styles.listItem}
      >
        {isEditing ? (
          <TextField
            fullWidth
            error={showError}
            autoFocus
            inputProps={{ 'data-testid': `WhitelistItem-${domain}-input` }}
            value={newDomain}
            onChange={e => {
              setNewDomain(e.currentTarget.value)
              //setUnsavedChanges(true)
              if (showError) setShowError(false)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') handleSave()
              if (showError) setShowError(false)
            }}
            onFocus={() => {
              setShowError(false)
            }}
            onBlur={() => {
              let tmpIsValid = isValidUrl(newDomain)
              setShowError(!tmpIsValid)
            }}
            sx={{
              margin: '0 8px 0 0',
              height: '44px',
              '.MuiOutlinedInput-root': {
                height: '44px',
                backgroundColor: styles.lightestGray,
                borderRadius: '8px',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  border: '1px solid #424242',
                },
              },
            }}
          />
        ) : (
          domain
        )}

        <div className={styles.btnGroup}>
          {isEditing ? (
            <>
              <Tooltip title='Cancel Changes'>
                <Button
                  variant='text'
                  data-testid={`WhitelistItem-${domain}-Close`}
                  onClick={() => handleCloseClicked()}
                  sx={buttonStyles}
                >
                  <CloseIcon />
                </Button>
              </Tooltip>

              {/* only shows save button when trimmed newDomain doesn't match domain */}
              {domain !== newDomain.trim() && (
                <Tooltip title='Save Changes'>
                  <Button
                    variant='text'
                    data-testid={`WhitelistItem-${domain}-Save`}
                    onClick={() => handleSave()}
                    sx={buttonStyles}
                  >
                    <SaveIcon />
                  </Button>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <Tooltip title='Edit'>
                <Button
                  variant='text'
                  data-testid={`WhitelistItem-${domain}-Edit`}
                  onClick={() => handleEditing(true)}
                  sx={buttonStyles}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </>
          )}
          <Tooltip title='Delete'>
            <Button
              variant='text'
              data-testid={`WhitelistItem-${domain}-Delete`}
              onClick={() => handleRemove()}
              sx={buttonStyles}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </div>
      </div>

      {showError && (
        <p>
          <img alt='Please use a valid website URL' src={statusErrorLogo} /> Please use a valid
          website URL
        </p>
      )}

      <SaveChangesModal
        {...{
          leaving,
          onDiscardChangesClick,
        }}
        onSaveClick={handleSave}
        open={saveChangesModalOpen}
        setGoBack={setLeaving}
        setOpen={setSaveChangesModalOpen}
      />
    </div>
  )
}

interface WhitelistProps {
  domains: string[]
  addNew: (domain: string) => void
  remove: (domain: string) => void
  update: (oldDomain: string, newDomain: string) => void
}

export default function Whitelist({ domains, addNew, remove, update }: WhitelistProps) {
  const [showNew, setShowNew] = useState(false)

  // Commenting this out because it requires a data router.
  // Leaving this here to make it easier when we are ready to change to a data router.

  //const [unsavedChanges, setUnsavedChanges] = useState(false)

  // const promptMessage =
  //   'You have unsaved changes that will be lost. Are you sure you want to leave this page?'

  // unstable_usePrompt({
  //   message: promptMessage,
  //   when: ({ currentLocation, nextLocation }) =>
  //     unsavedChanges && currentLocation.pathname !== nextLocation.pathname,
  // })

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <CmxButton
          className={styles.btn}
          variant='text'
          data-testid='Whitelist-Add'
          onClick={() => setShowNew(true)}
        >
          <AddIcon /> Add new site
        </CmxButton>
      </div>
      <div className={styles.list}>
        {showNew && (
          <WhitelistItem
            domain=''
            key='New-Site'
            saveNew={e => {
              addNew(e)
              setShowNew(false)
              //setUnsavedChanges(false)
            }}
            close={() => {
              setShowNew(false)
              //setUnsavedChanges(false)
            }}
            //setUnsavedChanges={setUnsavedChanges}
          />
        )}

        {domains.map(domain => {
          return (
            <WhitelistItem
              domain={domain}
              key={domain}
              remove={remove}
              update={update}
              //setUnsavedChanges={setUnsavedChanges}
            />
          )
        })}
      </div>
    </div>
  )
}
