import { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { LinearProgress } from '@mui/material'

import style from './FinalizeUpload.module.scss'

import { useImportState } from 'Store/ImportState'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import CmxButton from 'Components/CmxButton'

type FinalizeUploadProps = {
  updateRefreshData: (flag: boolean) => void
}

export default function FinalizeUpload({ updateRefreshData }: FinalizeUploadProps) {
  const { importState, ImportStateManager } = useImportState()
  const { GlobalNotificationStateManager } = useGlobalNotificationState()

  const getProgress = useCallback(() => {
    return Math.round(
      100 *
        (importState.files.filter(file => file.finalized === true).length /
          importState.files.length)
    )
  }, [importState.files])
  const [progress, setProgress] = useState(getProgress())

  const closeImport = useCallback(() => {
    ImportStateManager.ResetImport()
  }, [ImportStateManager])

  const closeImportDelayed = useCallback(async () => {
    await timeout(() => {
      closeImport()

      updateRefreshData(true)
      let numFiles = importState.files.length
      GlobalNotificationStateManager.ShowSnackbarNotification(
        `${numFiles} ${numFiles === 1 ? 'File was' : 'Files were'} successfully uploaded.`,
        SnackbarSeverity.Success,
        true,
        300000
      )
    }, 3000)
    //eslint-disable-next-line
  }, [GlobalNotificationStateManager, closeImport, importState.files.length])

  useEffect(() => {
    setProgress(getProgress())
  }, [getProgress, importState.files])

  useEffect(() => {
    if (progress === 100 && importState.finalizeErrors.length === 0) {
      closeImportDelayed()
    }
  }, [closeImportDelayed, importState.finalizeErrors.length, progress])

  function timeout(callback: () => void, delay: number) {
    return new Promise(() => setTimeout(callback, delay))
  }

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2 className={style.title}>Finalizing Upload</h2>
      </Grid>
      <Grid item xs={12} className={style.finalizeMessage}>
        Your files and associated data are being indexed into the system. This may take a moment...
      </Grid>
      <Grid item xs={12}>
        <Grid container direction='column' justifyContent='center' alignItems='center'>
          {importState.finalizeErrors.length > 0 && progress === 100 ? (
            <></>
          ) : (
            <>
              <p>{progress}%</p>
              <LinearProgress
                sx={{
                  '&.MuiLinearProgress-colorPrimary': {
                    backgroundColor: '#f4f4f4',
                  },
                  '& .MuiLinearProgress-barColorPrimary': {
                    backgroundColor: '#00a2c7',
                  },
                }}
                className={style.progressBar}
                variant='determinate'
                value={progress}
              />
            </>
          )}

          {importState.finalizeErrors.length > 0 && (
            <>
              <div className={style.errorsContainer}>
                <p className={style.errorRow}>The following file(s) could not be uploaded:</p>
                {importState.finalizeErrors.map((error: string, index: number) => (
                  <p className={style.errorRow} key={`error-${index}`}>
                    <b>{error}</b>
                  </p>
                ))}
                <p className={style.errorRow}>Please contact your Healthwise Account Manager.</p>
              </div>

              <CmxButton primary onClick={closeImport} data-qa='CloseErrorFinal'>
                Finish
              </CmxButton>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={style.finalizeUploadImage}></div>
      </Grid>
    </Grid>
  )
}
