import { ReactNode, useState } from 'react'
import { Button } from '@mui/material'

import styles from './CodeList.module.scss'

interface CodeListProps {
  codes: string[]
  half?: boolean
  horizontal?: boolean
  label: string
  maxLength?: number
  noCodesMessage?: string
}

export default function CodeList({
  codes,
  half = false,
  horizontal = false,
  label,
  maxLength = 4,
  noCodesMessage,
}: CodeListProps) {
  const [showMore, setShowMore] = useState(false)

  return (
    <div className={half ? styles.halfBlock : styles.thirdBlock} data-testid='codelist-container'>
      <div className={styles.blockLabel} data-testid='codelist-label'>
        {label}
      </div>

      <ItemList {...{ codes, horizontal, label, maxLength, noCodesMessage, showMore }} />

      <ShowMoreOrLessButton codesCount={codes.length} {...{ maxLength, setShowMore, showMore }} />
    </div>
  )
}

interface ItemListProps {
  codes: string[]
  horizontal: boolean
  maxLength: number
  noCodesMessage?: string
  showMore: boolean
}

function ItemList({ codes, horizontal, maxLength, noCodesMessage, showMore }: ItemListProps) {
  function renderListItems() {
    if (!codes?.length) return renderListItem(noCodesMessage || `No Assigned Codes`)

    return codes.filter((_, index) => showMore || index <= maxLength - 1).map(renderListItem)
  }

  function renderListItem(code: string, key: number = 0) {
    return <ListItem {...{ key }}>{code}</ListItem>
  }

  return (
    <ul
      className={horizontal ? styles.horizontalList : styles.list}
      data-testid={horizontal ? 'codelist-items-horizontal' : 'codelist-items-vertical'}
    >
      {renderListItems()}
    </ul>
  )
}

interface ListItemProps {
  children: ReactNode
}

function ListItem({ children }: ListItemProps) {
  return (
    <li className={styles.listItem} data-testid='codelist-item'>
      {children}
    </li>
  )
}

interface ShowMoreOrLessButtonProps {
  codesCount: number
  maxLength: number
  setShowMore: (value: boolean) => void
  showMore: boolean
}

function ShowMoreOrLessButton({
  codesCount,
  maxLength,
  setShowMore,
  showMore,
}: ShowMoreOrLessButtonProps) {
  if (codesCount <= maxLength) return null

  return (
    <Button
      className={styles.showMore}
      data-testid='codelist-button'
      onClick={() => setShowMore(!showMore)}
    >
      {showMore ? 'Show less' : `${codesCount - maxLength} more`}
    </Button>
  )
}
