import { IconButton, Modal } from '@mui/material'
import CmxButton, { CmxButtonProps } from 'Components/CmxButton'
import CloseIcon from '@mui/icons-material/Close'
import style from './ResetToDefaultModal.module.scss'

export type ResetToDefaultModalType = {
  setOpen: boolean
  onConfirm: CmxButtonProps['onClick']
  onCancel: CmxButtonProps['onClick']
}

export default function ResetToDefaultModal({
  setOpen,
  onConfirm,
  onCancel,
}: ResetToDefaultModalType) {
  return (
    <Modal open={setOpen}>
      <div className={style.modalContent}>
        <div className={style.banner}>
          <div className={style.title}>Restore default version?</div>

          <IconButton className={style.closeModal} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={style.warningMessage}>
          This title will be restored back to the default version provided by Healthwise. Any
          changes that you have made will be removed.
        </div>

        <div className={style.confirmationMessage}>
          Are you sure you want to restore the default version?
        </div>

        <div className={style.controls}>
          <CmxButton data-qa='modalCancelButton' onClick={onCancel}>
            Cancel
          </CmxButton>

          <CmxButton
            data-qa='modalConfirmButton'
            data-testid='modal-confirmation-button'
            onClick={onConfirm}
            className={style.restoreToDefaultButton}
          >
            Restore Default Version
          </CmxButton>
        </div>
      </div>
    </Modal>
  )
}
