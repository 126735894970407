import { FC, useCallback, useEffect, useState } from 'react'
import { IconButton, CircularProgress } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorIcon from '@mui/icons-material/Error'
import * as _ from 'lodash'

import styles from './FileDetailsForm.module.scss'

import { ImportFile } from 'Store/ImportState'
import { CheckContentUniqueId } from 'Api/Import.api'
import { HWIDPrefix } from 'ApplicationSettings/ApplicationSettings'
import Dropdown from 'Components/Dropdown'
import OverwriteTooltip from 'Components/Import/OverwriteTooltip'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import classNames from 'classnames'

export type FileDetailsConfig = {
  file: ImportFile
  fileDetails: Array<ImportFile>
  setFileDetails: (fileDetails: Array<ImportFile>) => void
  submitAttempted: boolean
  fileAlreadyInUpload: (fileKey: number, hwid: string, localization: string) => boolean
  revalidateIds: boolean
  setRevalidateIds: (validateIds: boolean) => void
  languages: any[]
}

const FileDetailsForm: FC<FileDetailsConfig> = ({
  file,
  fileDetails,
  setFileDetails,
  submitAttempted,
  fileAlreadyInUpload,
  revalidateIds,
  setRevalidateIds,
  languages,
}) => {
  //We have to track the state of the text inputs here and not directly from the file as it causes focus issues with the text inputs
  const thisFile: ImportFile = fileDetails.filter(fd => fd.fileKey === file.fileKey)[0]

  const [localization, setLocalization] = useState(thisFile?.localization ?? '')
  const [localeDirty, setLocaleDirty] = useState(!!thisFile?.localization)
  const [langError, setLangError] = useState('')

  const [hwid, setHwid] = useState(thisFile?.hwid ?? HWIDPrefix)
  const [hwidDirty, setHwidDirty] = useState(false)
  const [hwidError, setHwidError] = useState('')
  const [hwidInUse, setHwidInUse] = useState(false)

  const [contentItemError, setContentItemError] = useState('')

  const [title, setTitle] = useState(thisFile?.title ?? '')
  const [titleDirty, setTitleDirty] = useState(!!thisFile?.title)
  const [titleError, setTitleError] = useState('')

  const [overwriteContentItem, setOverwriteRecord] = useState(
    thisFile?.overwriteContentItem ?? false
  ) //Set to true when the id and localization of an existing content record is entered
  const [contentItemExists, setFileRecordExists] = useState(false)
  const [checkingId, setCheckingId] = useState(false)
  const [removeFile, setRemoveFile] = useState(file.removedFromUpload)

  const iDInUse = useCallback(() => {
    return !contentItemExists || (contentItemExists && overwriteContentItem)
  }, [contentItemExists, overwriteContentItem])

  const getErrorCount = useCallback(() => {
    if (removeFile) return 0

    let errors = 0

    if (!!titleError) errors++
    if (!!hwidError) errors++
    if (!!langError) errors++
    if (!!contentItemError) errors++
    if (!file.overwriteFile && contentItemExists && !overwriteContentItem) errors++

    return errors
  }, [
    contentItemError,
    contentItemExists,
    file.overwriteFile,
    hwidError,
    langError,
    overwriteContentItem,
    removeFile,
    titleError,
  ])

  const canSubmit = useCallback(() => {
    return !removeFile && !checkingId && getErrorCount() === 0
  }, [checkingId, removeFile, getErrorCount])

  const buildDetails = useCallback(() => {
    return {
      ...file,
      hwid,
      title,
      localization,
      contentItemExists,
      overwriteContentItem,
      canSubmit: canSubmit(),
      validationInProgress: checkingId,
      removedFromUpload: removeFile,
      errorCount: getErrorCount(),
    }
  }, [
    file,
    hwid,
    title,
    localization,
    contentItemExists,
    overwriteContentItem,
    checkingId,
    removeFile,
    canSubmit,
    getErrorCount,
  ])

  const validateTitle = useCallback(() => {
    if (!title?.length) {
      setTitleError('Title is required')
    } else if (title?.length > 250) {
      setTitleError('Title can be a maximum of 250 characters.')
    }
    // if (checkForInvalidCharacters(title, TITLE_FORBIDDENCHARACTERS)) {
    //     setTitleError(
    //         'Title can not contain any of the following characters: ' +
    //         TITLE_FORBIDDENCHARACTERS.join(', ')
    //     )
    // }
    else {
      setTitleError('')
    }
  }, [title])

  const validateHwid = useCallback(() => {
    if (!hwid.length || hwid === HWIDPrefix) {
      setHwidError('ID is required')
    } else if (hwid?.length > 43) {
      setHwidError('ID can be a maximum of 43 characters.')
    } else if (!allCharactersValid(hwid)) {
      setHwidError('ID can only have numbers, letters, and underscores.')
    } else if (!iDInUse() && !file.overwriteFile) {
      setHwidInUse(true)
    } else {
      setHwidError('')
      setHwidInUse(false)
    }
  }, [hwid, file.overwriteFile, iDInUse])

  const validateLanguage = useCallback(() => {
    if (localization.length < 1) {
      setLangError('Language is required')
    } else {
      setLangError('')
    }
  }, [localization])

  const validateFileRecordExists = useCallback(() => {
    if (hwid && localization && fileAlreadyInUpload(file.fileKey, hwid, localization)) {
      setContentItemError(
        'This ID and Language combination has already been used in this upload. Please remove this file or change the ID or Language.'
      )
      setFileRecordExists(false)
      return
    } else {
      setContentItemError('')
    }

    if (localization.length && hwid.length) {
      if (!fileAlreadyInUpload(file.fileKey, hwid, localization)) {
        //ping the service so we know if the hwid localization combo already exists for this client
        CheckContentUniqueId(hwid, localization).then(data => {
          setCheckingId(false)
          if (data.contentItemExists) {
            setFileRecordExists(true)
          } else {
            setFileRecordExists(false)
          }
        })
      } else {
        setCheckingId(false)
      }
    }
  }, [hwid, localization, file.fileKey, fileAlreadyInUpload])

  const updateFileDetails = useCallback(() => {
    const currentlySavedDetails: ImportFile = fileDetails.filter(
      (f: ImportFile) => f.fileKey === file.fileKey
    )[0]
    const details = buildDetails()

    if (!_.isEqual(currentlySavedDetails, details)) {
      setFileDetails([
        ...fileDetails.filter((f: ImportFile) => f.fileKey !== file.fileKey),
        details,
      ])
    }
  }, [file.fileKey, fileDetails, buildDetails, setFileDetails])

  const validate = useCallback(() => {
    validateTitle()
    validateHwid()
    validateLanguage()
    updateFileDetails()
  }, [validateTitle, validateHwid, validateLanguage, updateFileDetails])

  useEffect(() => {
    validate()
  }, [validate])

  useEffect(() => {
    if (!localeDirty || !hwidDirty) return
    setRevalidateIds(true)
  }, [hwid, localeDirty, hwidDirty, localization, setRevalidateIds])

  useEffect(() => {
    validateLanguage()
    validateFileRecordExists()
    validateHwid()
    updateFileDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localization])

  useEffect(() => {
    if (revalidateIds) {
      validateHwid()
      validateLanguage()
      setRevalidateIds(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revalidateIds])

  useEffect(() => {
    if (file.overwriteFile) {
      setHwid(file.hwid)
    }
  }, [file.hwid, file.overwriteFile])

  //Could be used in the future
  // function checkForInvalidCharacters(str: string, invalidStrings: string[]): boolean {
  //     for (let i = 0; i < invalidStrings.length; ++i) {
  //         if (str.indexOf(invalidStrings[i]) > -1) {
  //             return true
  //         }
  //     }
  //     return false
  // }

  function allCharactersValid(str: string) {
    var id = str.substring(HWIDPrefix.length)
    var code, i, len
    for (i = 0, len = id.length; i < len; i++) {
      code = id.charCodeAt(i)
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123) && // lower alpha (a-z)
        !(code === 95) // Underscore
      )
        return false
    }
    return true
  }

  function onHwidChange(value: string) {
    if (value.length < HWIDPrefix.length) {
      if (HWIDPrefix.startsWith(value)) {
        setHwid(HWIDPrefix)
      } else {
        setHwid(HWIDPrefix + value.toLowerCase())
      }
    } else {
      if (value.startsWith(HWIDPrefix)) {
        setHwid(value.toLowerCase())
      } else {
        setHwid(HWIDPrefix + value.toLowerCase())
      }
    }
  }

  const borderType =
    ((((titleDirty && hwidDirty && localeDirty) || submitAttempted) &&
      (!!titleError || !!hwidError || !!langError || !!contentItemError)) ||
      (!file.overwriteFile && contentItemExists && !overwriteContentItem)) &&
    !removeFile

  return (
    <div className={`${styles.wrapper} ${borderType ? styles.borderError : styles.border}`}>
      <div className={classNames(styles.fileName, removeFile && styles.uploadCancelled)}>
        {file.filename}
        {!removeFile && (
          <>
            {checkingId ? (
              <CircularProgress
                aria-label='File import in progress'
                data-qa='FileImportProgressSpinner'
                size='25px'
                className={styles.statusIcon}
              />
            ) : (
              <DoneIcon className={styles.successIcon}></DoneIcon>
            )}
            <IconButton
              className={styles.deleteIcon}
              data-qa='DeleteImportItem'
              aria-label='Delete file from Import'
              onClick={() => {
                setRemoveFile(true)
                validateFileRecordExists()
                updateFileDetails()
              }}
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: '#f4f4f4',
                },
                '&:hover': {
                  backgroundColor: '#f4f4f4',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </div>
      {removeFile ? (
        <div className={styles.cancelled}>
          <p>Upload cancelled.</p>
        </div>
      ) : (
        <div className={styles.rows}>
          <div className={styles.row}>
            <label htmlFor='title'>Content Title</label>
            <CmxTextField
              id='title'
              name='title'
              inputProps={{ 'data-testid': 'ContentTitle' }}
              error={(titleDirty || submitAttempted) && !!titleError}
              errorText={titleError}
              wrapperStyles={styles.field}
              fullWidth
              value={title}
              onChange={e => {
                setTitle(e.target.value)
                setTitleDirty(true)
              }}
              onBlur={() => {
                validateTitle()
                updateFileDetails()
              }}
            />
          </div>
          <div className={styles.row}>
            <label htmlFor='id'>ID</label>
            <CmxTextField
              id='id'
              name='id'
              error={
                (hwidDirty || submitAttempted) && (!!hwidError || !!contentItemError || hwidInUse)
              }
              errorText={hwidError}
              inputProps={{ 'data-testid': 'ContentHwid', spellCheck: 'false' }}
              wrapperStyles={styles.field}
              fullWidth
              value={hwid}
              onChange={e => {
                onHwidChange(e.target.value)
                setHwidDirty(true)
              }}
              onBlur={() => {
                validateHwid()
                validateFileRecordExists()
                updateFileDetails()
              }}
              disabled={file.overwriteFile}
            />
          </div>
          <div className={styles.row}>
            <label htmlFor='language'>Language</label>
            <div className={styles.field}>
              <Dropdown
                id='language'
                defaultValue='Choose Language'
                data-testid='ContentLanguage'
                className={styles.langDropdown}
                value={localization}
                items={languages}
                valueKey={'value'}
                nameKey={'name'}
                error={
                  (localeDirty || submitAttempted) &&
                  (!!langError || !!contentItemError || hwidInUse)
                }
                helperText={localeDirty || submitAttempted ? langError : undefined}
                disabled={file.overwriteFile}
                onChange={e => {
                  setLocalization(e.target.value)
                  setLocaleDirty(true)
                }}
              />
            </div>
          </div>
          {!file.overwriteFile && contentItemExists && !overwriteContentItem ? (
            <OverwriteTooltip
              className={styles.tooltip}
              message='Overwriting content replaces an existing piece of content with a different file. Any
            existing details associated with that piece of content (ie: ID, title, concepts,
            etc) are preserved. This can be useful when uploading a new version of an article.
            If this is a mistake, press the trash can icon to cancel the upload.'
            >
              <p className={styles.errorText} aria-label='File Error'>
                An item with this ID and Language combination already exists –{' '}
                <u
                  data-testid={'filedetails-overwrite'}
                  onClick={() => {
                    setOverwriteRecord(true)
                    updateFileDetails()
                  }}
                >
                  Overwrite?
                </u>
              </p>
            </OverwriteTooltip>
          ) : (contentItemExists && overwriteContentItem) || file.overwriteFile ? (
            <p
              className={styles.overwriteMessage}
              aria-label='File Error'
              data-testid='filedetails-overwrite-selected'
            >
              This file will replace an existing item with this ID and Language combination.
            </p>
          ) : (
            <></>
          )}
          {contentItemError.length > 0 && (
            <div className={styles.dupeError}>
              <ErrorIcon className={styles.errorIcon} />
              <p className={styles.errorText} aria-label='File Error'>
                {contentItemError}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FileDetailsForm
