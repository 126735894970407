import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, CircularProgress, Grid, Tab, Tabs } from '@mui/material'
import { AlgoliaContentLanguagesGET } from 'Api/Algolia.api'
import {
  DeleteAllHWMetadata,
  GetMetadata,
  PublishItem,
  PublishItems,
  SaveMetadataChanges,
  getPublishToValues,
} from 'Api/Content.api'
import CmxButton from 'Components/CmxButton'
import ContentViewer from 'Components/ContentViewer'
import ContentDelete from 'Components/DeleteModal/ContentDelete'
import Footer from 'Components/Footer'
import MetadataEdit from 'Components/MetadataEdit/MetadataEdit'
import ResetToDefaultModal from 'Components/MetadataEdit/ResetToDefaultModal/ResetToDefaultModal'
import { PublishAction } from 'Components/PublishModal/PublishModal'
import SaveChangesModal from 'Components/SaveChangesModal/SaveChangesModal'
import SuppressModalLSO from 'Components/SuppressModal/SuppressModalLSO'
import { useNetworkCallState } from 'Hooks'
import { Subscription, SubscriptionApp, useAuthState } from 'Store/AuthState'
import { ContentItemMetadataStorageManager } from 'Store/ContentItemMetadataStorageManager'
import { SnackbarSeverity, useGlobalNotificationState } from 'Store/GlobalNotificationState'
import { useManageStore } from 'Store/ManageState'
import {
  ContentItemMetadata,
  ContentItemStatus,
  ContentStatus,
  ContentType,
  GetContentType,
  defaultContentItem,
} from 'Store/Models/ContentItem'
import { useAlgolia } from 'Store/UseAlgolia'
import moment from 'moment'
import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLocation,
  useNavigate,
  //unstable_usePrompt as usePrompt,
  useParams,
} from 'react-router-dom'
import { TabPanel } from '../Manage/Manage'
import manageStyles from '../Manage/Manage.module.scss'
import Controls, { ContentReset } from './Controls'
import style from './ManageContentItem.module.scss'

//import PromptWrapper from './PromptWrapper'

export type SelectedStatus = ContentStatus | typeof defaultContentItem.status

export default function ManageContentItem() {
  let { hwid, localization } = useParams()
  const { authState } = useAuthState()
  const { algoliaState } = useAlgolia(authState.accessToken)
  const [backToPreviousClicked, setBackToPreviousClicked] = useState(false)
  const [canSuppress, setCanSuppress] = useState(false)
  const { GlobalNotificationStateManager } = useGlobalNotificationState()
  const [contentItem, setContentItem] = useState<ContentItemMetadata>(defaultContentItem)
  const [currentTab, setCurrentTab] = useState(0)
  const [errorCount, setErrorCount] = useState<number>(0)
  const navigate = useNavigate()
  const [lastDraftStatus, setLastDraftStatus] = useState<ContentItemStatus | undefined>(undefined)
  const [lastPublishedStatus, setLastPublishedStatus] = useState<ContentItemStatus | undefined>(
    undefined
  )
  const [leaving, setLeaving] = useState(false)
  const location = useLocation()
  const [metadataModified, setMetadataModified] = useState(false)
  const [_selectedSubscriptions, setSelectedSubscriptions] = useState<SubscriptionApp[]>([])
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [reset, setReset] = useState<ContentReset | null>(null)
  const [postResetSuccessMessage, setPostResetSuccessMessage] = useState('')
  const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false)
  const [resetToDefaultModalOpen, setResetToDefaultModalOpen] = useState<boolean>(false)
  const [saveClicked, setSaveClicked] = useState(false)
  const [globalMetadataChanged, setGlobalMetadataChanged] = useState<boolean>(false)
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatus>(defaultContentItem.status)
  const [userStatusOverride, setUserStatusOverride] = useState(false)
  const [suppressModalOpen, setSuppressModalOpen] = useState(false)
  const [suppressAfterDraftSaved, setSuppressAfterDraftSaved] = useState(false)
  const [title, setTitle] = useState<string>(contentItem.title)
  const [restoringToDefault, setRestoringToDefault] = useState<boolean>(false)
  const [didRestore, setDidRestore] = useState<boolean>(false)
  const StorageManager = useMemo(() => new ContentItemMetadataStorageManager(), [])
  const statusChanged = selectedStatus !== contentItem.status
  const [deleteAllHWMetadataCallState, deleteAllHWMetadataCallDispatch] = useNetworkCallState()
  const [leavePage, setLeavePage] = useState(false)

  const {
    fromManage,
    publishAction,
    refreshContentItem,
    updateContentItems,
    updateDeleteOpen,
    updateFromManage,
    updateHasEnglish,
    updateIsBulk,
    updatePublishItems,
    updatePublishOpen,
  } = useManageStore()

  const resetContentItem = useCallback(
    (onFinished?: () => void) => {
      if (!hwid || !localization) return alert('ERROR!! Specify HWID and Localization')

      GetMetadata(
        hwid,
        localization,
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState?.index || '',
        false,
        false
      ).then((cim: ContentItemMetadata) => {
        cim = {
          ...cim,
          status: (() => {
            if (cim.status === ContentStatus.Restore) return ContentStatus.Draft
            if (cim.status === null) return ContentStatus.Publish
            return cim.status as ContentStatus
          })(),
        }

        // clear localStorage
        StorageManager.RemoveItem(cim.hwid, cim.localization)
        setContentItem(cim)
        setMetadataModified(false)
        setSelectedStatus(cim.status as ContentStatus)
        onFinished?.()
        setReset(prevReset => {
          if (prevReset === ContentReset.Undo) {
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `Recent changes have been removed.`,
              SnackbarSeverity.Success,
              false,
              5000
            )
          } else if (prevReset === ContentReset.RestoreDefault) {
            setRestoringToDefault(false)
            if (deleteAllHWMetadataCallState.error) {
              GlobalNotificationStateManager.ShowSnackbarNotification(
                `There was an error restoring this title to the default version. Please try again, or contact your Healthwise Account Manager if the problem persists.`,
                SnackbarSeverity.Error,
                false,
                5000
              )
            } else {
              GlobalNotificationStateManager.ShowSnackbarNotification(
                `This title has been successfully restored to the default version.`,
                SnackbarSeverity.Success,
                false,
                5000
              )
            }
          }
          return null
        })
      })
    },
    // eslint-disable-next-line
    [
      algoliaState.index,
      algoliaState.key.QueryAppId,
      algoliaState.key.QueryKey,
      location.pathname,
      GlobalNotificationStateManager,
      StorageManager,
      setReset,
      reset,
    ]
  )

  useEffect(() => {
    if (fromManage) updateFromManage(false)

    if (
      typeof hwid !== 'undefined' &&
      hwid.length > 0 &&
      typeof localization !== 'undefined' &&
      localization.length > 0
    )
      resetContentItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (leavePage) {
      setLeavePage(false)
      navigate(-1)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leavePage])

  useEffect(() => {
    if (algoliaState.key.QueryAppId && authState.orgInfo?.subscriptions) {
      setSubscriptions(authState?.orgInfo?.subscriptions ?? [])
    }
    // eslint-disable-next-line
  }, [algoliaState.key.QueryAppId, authState.orgInfo])

  useEffect(() => {
    resetContentItem()
  }, [resetContentItem, reset])

  //reset item after publish modal
  useEffect(() => {
    if (!refreshContentItem) return undefined

    resetContentItem()
  }, [resetContentItem, refreshContentItem])

  // selectedStatus logic
  useEffect(() => {
    if (!contentItem.hwid) return undefined

    const lastDraft = getLastStatus(contentItem, ContentStatus.Draft)
    if (lastDraft) setLastDraftStatus(lastDraft as ContentItemStatus)

    setLastPublishedStatus(getLastStatus(contentItem, ContentStatus.Publish))

    const isPublished = [getPreviousStatus(contentItem)?.status.name, contentItem.status].includes(
      ContentStatus.Publish
    )

    const isSuppressableContentType = [
      ContentType.Healthwise,
      ContentType.InhousePDF,
      ContentType.InhouseVideo,
    ].includes(GetContentType(contentItem.hwid))

    //needed to determine if HW content is suppressable, it may not have a previous Published status record
    const isHWSuppressable =
      contentItem.status === ContentStatus.Draft &&
      GetContentType(contentItem.hwid) === ContentType.Healthwise

    setCanSuppress((isPublished && isSuppressableContentType) || isHWSuppressable)

    setSelectedStatus((prevState: SelectedStatus) => {
      if (prevState) return prevState

      if (
        (contentItem.status === null || contentItem.status === ContentStatus.Restore) &&
        GetContentType(contentItem.hwid) === ContentType.Healthwise
      )
        return ContentStatus.Publish

      if (contentItem.status === ContentStatus.Restore) return ContentStatus.Draft

      return contentItem.status as ContentStatus
    })

    setSelectedSubscriptions(contentItem.publishTo ?? [])

    GlobalNotificationStateManager.SetLoadingModalVisible(false)
    if (postResetSuccessMessage) {
      GlobalNotificationStateManager.ShowSnackbarNotification(
        postResetSuccessMessage,
        SnackbarSeverity.Success,
        false,
        10000
      )
      setPostResetSuccessMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem])

  useEffect(() => {
    if (!publishAction) return undefined

    if (publishAction === PublishAction.SaveAsDraft) {
      setSelectedStatus(ContentStatus.Draft)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishAction])

  useEffect(() => {
    if (!deleteAllHWMetadataCallState.init || deleteAllHWMetadataCallState.loading) return undefined

    deleteAllHWMetadataCallDispatch.request()
    DeleteAllHWMetadata([contentItem.id]).then(response => {
      if (response.ok) {
        deleteAllHWMetadataCallDispatch.success()
        setReset(ContentReset.RestoreDefault)
      } else {
        deleteAllHWMetadataCallDispatch.failure('Could not restore content to its original state')
        setRestoringToDefault(false)
      }
    })
  }, [deleteAllHWMetadataCallState, deleteAllHWMetadataCallDispatch, setReset, contentItem.id])

  function onBackToPrevious() {
    if (!metadataModified && !statusChanged) setLeavePage(true)
    else {
      setLeaving(true)
      setSaveChangesModalOpen(true)
      setBackToPreviousClicked(true)
    }
  }

  function getLastStatus(ci: ContentItemMetadata, status: string): ContentItemStatus | undefined {
    return (
      ci?.statuses
        ?.filter(s => s.status.name.toLowerCase() === status.toLowerCase())
        .sort((a: ContentItemStatus, b: ContentItemStatus) =>
          moment.utc(b.timeStamp).diff(moment.utc(a.timeStamp))
        )[0] || undefined
    )
  }

  function getPreviousStatus(ci: ContentItemMetadata): ContentItemStatus | undefined {
    return (
      ci?.statuses?.sort(
        (a: ContentItemStatus, b: ContentItemStatus) =>
          moment(b.timeStamp).date() - moment(a.timeStamp).date()
      )[1] || undefined
    )
  }

  function subscriptionCheckedChanged(
    event: ChangeEvent<HTMLInputElement>,
    subscriptionApp: SubscriptionApp
  ) {
    setSelectedSubscriptions((prevState: SubscriptionApp[]) => {
      if (event.currentTarget.checked) return [...prevState, subscriptionApp]

      function subscriptionIdAndApplicationIdMatch(selectedSubscription: SubscriptionApp) {
        return !(
          selectedSubscription.subscriptionId === subscriptionApp.subscriptionId &&
          selectedSubscription.applicationId === subscriptionApp.applicationId
        )
      }

      return prevState.filter(subscriptionIdAndApplicationIdMatch)
    })
  }

  function onSaveClick(isModal?: boolean) {
    setSaveClicked(true)

    if (errorCount) return null

    const cimItem = StorageManager.Get(contentItem.hwid, contentItem.localization)

    if (!cimItem) return alert('Fix all errors before saving')

    if (!suppressAfterDraftSaved && selectedStatus === ContentStatus.Suppress) {
      if (metadataModified && !isModal) return setSaveChangesModalOpen(true)

      setSaveChangesModalOpen(false)

      return setSuppressModalOpen(true)
    }

    return initSave()
  }

  function initSave() {
    const cimItem = StorageManager.Get(contentItem.hwid, contentItem.localization)

    if (!cimItem) return alert('Fix all errors before saving')

    //if publishTo is blank, we need to update global variant
    if (cimItem.publishTo?.length === 0 || !cimItem.publishTo) {
      //setGlobalMetadataChanged(true)
      cimItem.globalMetadataChanged = true
    }

    let publishToValues = getPublishToValues(subscriptions)

    let publishItems: PublishItem[] = [
      {
        hwid: cimItem.hwid,
        title: cimItem.title,
        localization: cimItem.localization,
        globalMetadataChanged: globalMetadataChanged,
        publishTo: publishToValues,
      },
    ]

    cimItem.publishTo = publishToValues
    cimItem.globalMetadataChanged = globalMetadataChanged

    let contentItems: ContentItemMetadata[] = [cimItem]

    //publish inhouse (non english)
    if (
      cimItem.contentType === 'inhouse_pdf' &&
      cimItem.localization.toLowerCase() !== 'en-us' &&
      selectedStatus === ContentStatus.Publish
    ) {
      //check for english version
      AlgoliaContentLanguagesGET(
        publishItems[0].hwid,
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState?.index || '',
        algoliaState?.languagePackageAttribute || ''
      ).then(data => {
        if (!data.ok) {
          //failed finding english language - go to Publish Modal
          updateHasEnglish(false)
          updatePublishOpen(true)
          updatePublishItems(publishItems)
          updateIsBulk(false)
          updateContentItems(contentItems)
        } else {
          // has English - Save and Publish directly - does not go to Publish Modal
          updateHasEnglish(true)
          SaveAndPublishMetadataChanges(contentItems, publishItems)
        }
      })
    } else {
      //Publish English version - does not go to Publish Modal

      // TODO: Move this out of here so as soon as subscriptions are changed the document can only go into draft
      // cimItem.publishTo = selectedSubscriptions
      SaveAndPublishMetadataChanges(contentItems, publishItems)
    }
  }

  function SaveAndPublishMetadataChanges(
    cimItems: ContentItemMetadata[],
    publishItems: PublishItem[]
  ) {
    GlobalNotificationStateManager.SetLoadingModalMessage(
      'Saving Changes',
      'Your changes are being saved.<br>This may take a moment...',
      true
    )

    if (selectedStatus === ContentStatus.Draft || suppressAfterDraftSaved) {
      SaveMetadataChanges(cimItems)
        .then(async () => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            `Changes to ${cimItems[0].title} saved successfully.`,
            SnackbarSeverity.Success,
            false,
            10000
          )
          if (suppressAfterDraftSaved) {
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
            setSaveChangesModalOpen(false)
            setSuppressModalOpen(true)
          } else {
            // This is required so Kontent.ai can catch up. If this isn't here when we reload the content item it still has the previous values before we saved
            await new Promise(r => setTimeout(r, 3000))
            setDidRestore(false)
          }
        })
        .catch(() => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'There was an error saving changes. Please try again or contact your Healthwise Account Manager if the issue persists.',
            SnackbarSeverity.Error,
            false,
            10000
          )
        })
        .finally(() => {
          if (!suppressAfterDraftSaved) {
            if (leaving) {
              setSaveChangesModalOpen(false)
              GlobalNotificationStateManager.SetLoadingModalVisible(false)
              setLeavePage(true)
            } else {
              resetContentItem()
            }
          }
        })
    } else {
      SaveMetadataChanges(cimItems)
        .then(() => PublishItems(publishItems))
        .then(async () => {
          setPostResetSuccessMessage(
            `${cimItems[0].title} published successfully. Title may take up to 1 minute to appear in search results.`
          )
          if (!leaving)
            // This is required so Kontent.ai can catch up. If this isn't here when we reload the content item it still has the previous values before we saved
            await new Promise(r => setTimeout(r, 3000))

          setDidRestore(false)
        })
        .catch(() => {
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'There was an error publishing. Please try again or contact your Healthwise Account Manager if the issue persists.',
            SnackbarSeverity.Error,
            false,
            10000
          )
        })
        .finally(() => {
          if (leaving) {
            setSaveChangesModalOpen(false)
            GlobalNotificationStateManager.SetLoadingModalVisible(false)
            setLeavePage(true)
          } else {
            resetContentItem()
          }
        })
    }
  }

  const onDiscardChangesClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (backToPreviousClicked) return navigate(-1)

    event.preventDefault()
    setSaveChangesModalOpen(false)

    if (selectedStatus === ContentStatus.Suppress) return setSuppressModalOpen(true)

    setReset(ContentReset.Undo)
  }

  const deleteContentItem: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    updateDeleteOpen(true)
  }

  function changesMade(changed: boolean, globalMetadataChanged: boolean) {
    setMetadataModified(changed)
    setGlobalMetadataChanged(globalMetadataChanged)
    if (selectedStatus === ContentStatus.Suppress && changed) {
      setSuppressAfterDraftSaved(true)
    }

    if (
      !userStatusOverride &&
      //If there were no changes made to the metadata and the Update Status is "Suppress" then don't update the status
      !(!changed && selectedStatus === ContentStatus.Suppress)
    ) {
      if (changed && contentItem.status === ContentStatus.Publish) {
        setSelectedStatus(ContentStatus.Draft)
      }
      //adding so content with changes that goes straight to Publish, will not show as Draft again
      else if (!changed && contentItem.status === ContentStatus.Publish) {
        setSelectedStatus(ContentStatus.Publish)
      }
    }
  }

  // usePrompt({
  //   message:
  //     'You have unsaved changes that will be lost. Are you sure you want to leave this page?',
  //   when: (statusChanged || metadataModified) && !leaving,
  // })

  if (typeof contentItem === 'undefined' || contentItem.hwid.length === 0)
    return (
      <div className={style.loading}>
        <CircularProgress color='primary' className='loader' />
      </div>
    )
  else
    return (
      <>
        {/* <PromptWrapper
        when={(statusChanged || metadataModified) && !leaving}
        message='You have unsaved changes that will be lost. Are you sure you want to leave this page?'
      /> */}
        <SuppressModalLSO
          isHWContent={GetContentType(contentItem.hwid) === ContentType.Healthwise}
          onCancel={() => {
            setSaveClicked(false)
            setSuppressModalOpen(false)
          }}
          onComplete={success => {
            setSuppressModalOpen(false)
            setSaveClicked(false)
            resetContentItem(() => {
              if (success && (saveClicked || backToPreviousClicked)) navigate(-1)
            })
          }}
          suppressItems={[{ hwid: contentItem.hwid, title: contentItem.title }]}
          visible={suppressModalOpen}
        />

        <SaveChangesModal
          {...{
            leaving,
            onDiscardChangesClick,
          }}
          onSaveClick={() => onSaveClick(true)}
          open={saveChangesModalOpen}
          setGoBack={setLeaving}
          setOpen={setSaveChangesModalOpen}
        />

        <ResetToDefaultModal
          setOpen={resetToDefaultModalOpen}
          onConfirm={() => {
            setRestoringToDefault(true)
            deleteAllHWMetadataCallDispatch.init()
            setDidRestore(true)
            setResetToDefaultModalOpen(false)
          }}
          onCancel={() => {
            setResetToDefaultModalOpen(false)
          }}
        />

        <div className={style.manageContentItemPage}>
          <Grid container>
            <div className={manageStyles.featuresContainer}>
              <div className={manageStyles.featuresContainerBanner}>
                <h1 className={manageStyles.manageTitle}>
                  Manage
                  <ArrowForwardIosIcon className={manageStyles.manageTitleSeperator} />
                  <span data-testid='content-item-title'>{contentItem.title}</span>
                </h1>
              </div>
            </div>

            <Grid container item justifyContent='center' xs={12}>
              <Grid
                className={style.manageContentItemPageContent}
                container
                direction='row'
                item
                justifyContent='flex-start'
                xs={12}
              >
                <Grid container direction='row' item justifyContent='flex-start'>
                  <Grid item>
                    <CmxButton
                      className={style.BackLink}
                      data-testid='go-back-button'
                      onClick={onBackToPrevious}
                      startIcon={<ArrowBackIcon />}
                      variant='text'
                    >
                      Back to Previous Page
                    </CmxButton>
                  </Grid>
                </Grid>

                <Grid container direction='row' item justifyContent='center'>
                  <Grid gridRow={1} item xs={9}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 3, mr: 6 }}>
                      <Tabs
                        onChange={(_, newValue: number) => setCurrentTab(newValue)}
                        sx={{ '& .MuiTab-root.Mui-selected': { color: '#0D8484' } }}
                        TabIndicatorProps={{ style: { background: '#0D8484' } }}
                        value={currentTab}
                      >
                        <Tab
                          className={manageStyles.manageTab}
                          disableFocusRipple
                          label='Manage Content'
                          aria-selected='true'
                          aria-controls='simple-tabpanel-0'
                        />

                        <Tab
                          className={manageStyles.manageTab}
                          disableFocusRipple
                          label='Preview Content'
                          tabIndex={-1}
                          aria-controls='simple-tabpanel-1'
                        />
                      </Tabs>
                    </Box>

                    <TabPanel
                      index={0}
                      value={currentTab}
                      className={style.manageContentItemTabPanel}
                    >
                      <MetadataEdit
                        {...{
                          changesMade,
                          contentItem,
                          title,
                          setTitle,
                          errorCount,
                          setErrorCount,
                          saveClicked,
                          setSaveClicked,
                        }}
                      />
                    </TabPanel>

                    <TabPanel
                      index={1}
                      value={currentTab}
                      className={style.manageContentItemTabPanel}
                    >
                      <ContentViewer
                        hwid={contentItem.hwid}
                        limitHeight={false}
                        localization={contentItem.localization}
                        pdfUrl={contentItem?.pdfUrl}
                      />
                    </TabPanel>
                  </Grid>

                  <Grid item xs={3}>
                    <Controls
                      {...{
                        canSuppress,
                        contentItem,
                        deleteContentItem,
                        lastDraftStatus,
                        lastPublishedStatus,
                        selectedStatus,
                        subscriptions,
                        subscriptionCheckedChanged,
                        reset,
                        restoringToDefault,
                      }}
                      disableSave={!metadataModified && !statusChanged}
                      disableRestore={didRestore || contentItem.id === null}
                      onDiscardChanges={event => {
                        event.preventDefault()
                        setReset(ContentReset.Undo)
                      }}
                      onRestoreDefaultHWVersion={event => {
                        event.preventDefault()
                        setResetToDefaultModalOpen(true)
                      }}
                      onSave={() => onSaveClick()}
                      onUpdateStatusDropdownChange={event => {
                        setUserStatusOverride(true)
                        const status = event.target.value as ContentStatus
                        setSelectedStatus(status)
                        if (status === ContentStatus.Suppress && metadataModified) {
                          setSuppressAfterDraftSaved(true)
                        } else {
                          setSuppressAfterDraftSaved(false)
                        }
                      }}
                      selectedStatus={selectedStatus}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <ContentDelete contentToDelete={[contentItem]} />

        <Footer />
      </>
    )
}
