import { Reducer, useReducer } from 'react'

export enum NetworkCallActionType {
  CancelInit = 'CancelInit',
  Failure = 'Failure',
  Init = 'Init',
  Request = 'Request',
  Success = 'Success',
}

export const initialState = {
  error: undefined,
  init: false,
  loading: false,
}

export interface NetworkCallStateDispatch<Error = string> {
  cancelInit: () => void
  failure: (error?: Error) => void
  init: () => void
  request: () => void
  success: () => void
}

type ReturnValue<Error> = [NetworkCallState<Error>, NetworkCallStateDispatch<Error>]

export default function useNetworkCallState<Error = string>(): ReturnValue<Error> {
  const [state, dispatch] = useReducer<Reducer<NetworkCallState<Error>, NetworkCallAction<Error>>>(
    reducer,
    initialState
  )

  function cancelInit() {
    dispatch({ type: NetworkCallActionType.CancelInit })
  }

  function failure(error?: Error) {
    dispatch({ error, type: NetworkCallActionType.Failure })
  }

  function init() {
    dispatch({ type: NetworkCallActionType.Init })
  }

  function request() {
    dispatch({ type: NetworkCallActionType.Request })
  }

  function success() {
    dispatch({ type: NetworkCallActionType.Success })
  }

  return [state, { cancelInit, failure, init, request, success }] as ReturnValue<Error>
}

function reducer<Error>(state: NetworkCallState<Error>, { error, type }: NetworkCallAction<Error>) {
  if (type === NetworkCallActionType.CancelInit) return { ...state, init: false }

  if (type === NetworkCallActionType.Failure) return { ...state, error, loading: false }

  if (type === NetworkCallActionType.Init) return { ...state, error: undefined, init: true }

  if (type === NetworkCallActionType.Request) return { ...state, init: false, loading: true }

  if (type === NetworkCallActionType.Success) return { ...state, loading: false }

  return state
}

export type NetworkCallAction<Error = string> = {
  error?: Error
  type: NetworkCallActionType
}

export type NetworkCallState<Error = string> = {
  error?: Error
  init: boolean
  loading: boolean
}
