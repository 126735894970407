import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { CircularProgress } from '@mui/material'
import App from 'App'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { useAuth0ConfigState } from 'Store/Auth0ConfigState'
import { Auth0StateProvider } from 'Store/AuthState'
import jwt_decode from 'jwt-decode'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { AuthHandler } from './AuthHandler'
import styles from './SecurityWrapper.module.scss'

export const AuthenticationGuard = ({ component, ...componentProps }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className={styles.wrapper}>
        <CircularProgress className={styles.loader} />
      </div>
    ),
  })
  return <Component {...componentProps} />
}

interface HealthwiseToken {
  tempOrganizationAlias: string
}
interface PostLoginRedirectorProps {
  orgAlias: string
  setOrgAlias: Dispatch<SetStateAction<string>>
  filterRoutes: string[]
}
export const PostLoginRedirector = ({
  orgAlias,
  setOrgAlias,
  filterRoutes,
}: PostLoginRedirectorProps) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const setToken = async () => {
      const token = await getAccessTokenSilently()
      const decodedToken = jwt_decode<HealthwiseToken>(token)

      let baseRoute = decodedToken.tempOrganizationAlias.split('/')[0]
      setOrgAlias(baseRoute)

      // lastElement might be a url with params. EG - "unauthorized/:application/:email"
      // just using pop() would get rid of other parts of the route
      const lastElement = location.pathname
        .split('/')
        .filter(e => e.length) // first element is always "" after splitting
        .splice(1)
        .join('/')

      const additionalRoute =
        filterRoutes.includes(lastElement) || filterRoutes.some(r => lastElement.includes(r)) // If there are query params in a route
          ? lastElement
          : null

      let fullRoute = baseRoute
      if (additionalRoute) {
        fullRoute = `${baseRoute}/${additionalRoute}`
      }

      navigate({
        pathname: window.location.href.indexOf(baseRoute) < 0 ? baseRoute : fullRoute,
      })
    }

    if (isAuthenticated && orgAlias === '') {
      setToken()
    }

    //This case covers when a user enters the url containing the org alias prior to authenticating
    let alias = window.location.pathname
      .split('/')
      .filter(o => o.length) // Filter out empty elements
      .filter(o => !filterRoutes.includes(o))[0] // Filter out any application routes
    if (!isLoading && !isAuthenticated && alias !== '' && orgAlias === '' && alias !== orgAlias) {
      setOrgAlias(alias)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, orgAlias])

  return <></>
}

export default function SecurityWrapper() {
  const { auth0ConfigState } = useAuth0ConfigState()
  const [orgAlias, setOrgAlias] = useState<string>('')

  const filterRoutes = [
    App_Routes.HOME.replace('/', ''),
    App_Routes.LOGIN.replace('/', ''),
    App_Routes.LOGOUT.replace('/', ''),
  ]

  return (
    <>
      {!auth0ConfigState?.auth0Config || auth0ConfigState?.isLoading ? (
        <div className={styles.wrapper}>
          <CircularProgress className={styles.loader} />
        </div>
      ) : (
        <Auth0Provider {...auth0ConfigState?.auth0Config}>
          <BrowserRouter basename={orgAlias}>
            <Auth0StateProvider>
              <PostLoginRedirector
                orgAlias={orgAlias}
                setOrgAlias={setOrgAlias}
                filterRoutes={filterRoutes}
              />
              <App />
              <AuthHandler />
            </Auth0StateProvider>
          </BrowserRouter>
        </Auth0Provider>
      )}
    </>
  )
}
