import React from 'react'
import style from './ErrorBoundary.module.scss'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Log, LogLevel } from 'Api/Log.api'

type ErrorBoundaryProps = {
  children: React.ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
  errorMessage: string
  errorDetails: string
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, errorMessage: '', errorDetails: '' }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: any, errorInfo: any) {
    Log(error.message, LogLevel.Error)
    this.setState({
      ...this.state,
      errorMessage: error.message,
      errorDetails: errorInfo.componentStack,
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid container justifyContent='center' alignItems='flex-start' className={style.errorpage}>
          <Grid container item className={style.error} xs={4}>
            <Grid container direction='row' justifyContent='center'>
              <Grid item>
                <h1 className={style.title}>Unexpected Error</h1>
              </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='center'>
              <Grid item>
                <p>{this.state.errorMessage}</p>
              </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='center'>
              <Grid item>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>Error Details</AccordionSummary>
                  <AccordionDetails>
                    <p>Error: {this.state.errorMessage}</p>
                    <p>Details: {this.state.errorDetails}</p>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return this.props.children
  }
}
