import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { DownloadFilePOST } from './DownloadFile'
import { OrgName, Subscriptions } from 'Store/AuthState'
import { GetNonManagementSubs } from 'Utilities/OrgUtil'

const CMXSERVICE_URL = AppSettings.CMXServiceUrl

export async function launchPublishedReport(
  appId: string,
  apiKey: string,
  indexName: string,
  licensedLanguages: string[],
  hasTopics: boolean,
  compassOnly: boolean,
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  var subscriptions = GetNonManagementSubs(Subscriptions())
  await DownloadFilePOST(
    `${CMXSERVICE_URL}/api/publishedreport`,
    { appId, apiKey, indexName, licensedLanguages, subscriptions, hasTopics, compassOnly },
    onComplete,
    onError
  )
}

export async function launchFilteredReport(
  appId: string,
  apiKey: string,
  indexName: string,
  licensedLanguages: string[],
  filters: any,
  searchTerm: string,
  friendlyFilters: any,
  hasTopics: boolean,
  filterString: string,
  compassOnly: boolean,
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  var subscriptions = Subscriptions()
  await DownloadFilePOST(
    `${CMXSERVICE_URL}/api/filteredreport`,
    {
      appId,
      apiKey,
      indexName,
      licensedLanguages,
      searchTerm,
      facets: { AlgoliaFilters: filters, UIFilters: friendlyFilters },
      subscriptions,
      hasTopics,
      filterString,
      compassOnly,
    },
    onComplete,
    onError
  )
}

export async function launchManagedReport(
  appId: string,
  apiKey: string,
  indexName: string,
  licensedLanguages: string[],
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  await DownloadFilePOST(
    `${CMXSERVICE_URL}/api/managedreport/${OrgName()}`,
    { appId, apiKey, indexName, licensedLanguages },
    onComplete,
    onError
  )
}
