import env from '@beam-australia/react-env'

export const HWIDPrefix = 'inhouse.'

export enum Environment {
  localdev,
  development,
  test,
  production,
}

export abstract class Roles {
  public static readonly ADMINISTRATOR: string = 'Administrator'
  public static readonly REPORTVIEWER = 'ReportViewer'
  public static readonly REPORTEXPORTER = 'ReportExporter'
  public static readonly CONTENTEXPLORER = 'ContentExplorer'
  public static readonly CONTENTVIEWONLY = 'ContentViewOnly'
}

export default abstract class AppSettings {
  public static PublishBatchSize = 25

  public static AdviseAppId = 'aCR1T000000k9hoWAA'
  public static CMXAppId = 'aCR1T000000k9okWAA'
  public static CompassAppId = 'aCRHs000000br2XOAQ'

  public static readonly Environment: Environment =
    Environment[env('ENVIRONMENT') as keyof typeof Environment]

  public static readonly AppId = 'aCR1T000000k9okWAA'
  public static readonly ClientId = env('Auth0_CLIENT_ID')
  public static readonly Issuer = env('Auth0_ISSUER')

  public static readonly OktaClientId = env('OKTA_CLIENT_ID')
  public static readonly OktaIssuer = env('OKTA_ISSUER')

  public static readonly AUTH0_DOMAIN = env('AUTH0_DOMAIN')
  public static readonly AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID')
  public static readonly AUTH0_AUDIENCE = env('AUTH0_AUDIENCE')
  public static readonly AUTH0_SCOPE = env('AUTH0_SCOPE')

  public static readonly MyHealthwiseIssuer = env('MYHEALTHWISE_ISSUER')
  public static readonly CMXServiceUrl = env('CMX_SERVICE_URL')
  public static readonly DiscoUrl = env('DISCO_URL')
  public static readonly PlatformUrl = env('PLATFORM_URL')
  public static readonly CSCUrl = env('CSC_URL')
  public static readonly CSCReportUrl = env('CSC_REPORT_URL')
  public static readonly CSCLogRequestUrl = env('CSC_LOG_REQUEST_URL')
  public static readonly WebComponentUrl = env('WEB_COMPONENT_URL')

  //Delivery API Endpoint - only for getting disclaimers
  public static readonly DeliveryUrl = env('DELIVERY_API_URL')

  //Docker file injected environment variables
  public static readonly VersionNumber = process.env.REACT_APP_VERSION_NUMBER
  public static readonly CommitHash = process.env.REACT_APP_COMMIT_HASH
  public static readonly BuildNumber = process.env.REACT_APP_BUILD_NUMBER
  public static readonly BuildId = process.env.REACT_APP_BUILD_ID

  //CMX Endpoints
  public static readonly CMX_UPLOADFILE = `${this.CMXServiceUrl}/asset/uploadfile`
  public static readonly CMX_CREATE = `${this.CMXServiceUrl}/inhousecontent`
  public static readonly CMX_UPDATE = `${this.CMXServiceUrl}/inhousecontent/save`
  public static readonly CMX_CHECKID = `${this.CMXServiceUrl}/inhousecontent/check`
  public static readonly CMX_DELETE = `${AppSettings.CMXServiceUrl}/inhousecontent/delete`
}

export abstract class App_Routes {
  public static readonly RELOAD_LOGIN = '/login/reload'
  public static readonly LOGIN = '/login'
  public static readonly LOGOUT = '/logout'
  public static readonly REDIRECT = '/implicit/callback'

  public static readonly HOME = '/'
  public static readonly INVENTORY = '/inventory'
  public static readonly DASHBOARD = '/dashboard'
  public static readonly REPORTS = '/reports'
  public static readonly BROWSE = '/browse'
  public static readonly BROWSEALLINDEX = '/browse/index'
  public static readonly BROWSEALLCATEGORY = '/browse/category'
  public static readonly MANAGE = '/manage'
  public static readonly MANAGECONTENTITEM = '/contentitem'
  public static readonly HEALTH = '/health'
  public static readonly FEATURES = '/features'
  public static readonly EMBEDDING = '/embedding'
}

export abstract class VideoType {
  public static readonly TOPIC_VIDEO = 'Videos'
  public static readonly ARTICLE_VIDEO = 'Video Library'
}

export const algoliaApplicationTags = () => {
  const tags = []
  if (env) {
    const envTag = `environment:${env('ENVIRONMENT')}`
    tags.push(envTag)
  }
  tags.push('application:cmx')
  return tags
}
