import { Grid } from '@mui/material'
import { ImportFile, FileUploadStatus } from 'Store/ImportState'
import React, { useCallback, useEffect, useState } from 'react'
import style from './DragonDrop.module.scss'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'

export type DragonDropConfig = {
  onFilesAdded: (files: Array<ImportFile>) => void
}

export default function DragonDrop({ onFilesAdded }: DragonDropConfig): any {
  const drop: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
  const [dragging, setDragging] = useState(false)

  const addFiles = useCallback(
    (files: any[]) => {
      let formattedFileArray: Array<ImportFile> = []
      let now = new Date()
      for (let i = 0; i < files.length; ++i) {
        let file = files[i]
        let filePackage: ImportFile = {
          filename: file.name,
          size: file.size,
          type: file.type,
          file: file,
          uploadedOn: now.toISOString(),
          fileKey: 0,
          fileId: '',
          assetId: '',
          hwid: 'inhouse.',
          title: '',
          localization: '',
          uploadStatus: FileUploadStatus.New,
          fileExists: false,
          overwriteFile: false,
          contentItemExists: false,
          overwriteContentItem: false,
          unsupportedType: false,
          overSizeLimit: false,
          canSubmit: false,
          finalized: false,
          validationInProgress: false,
          removedFromUpload: false,
          errorCount: 0,
          isInfected: false,
        }
        formattedFileArray.push(filePackage)
      }

      if (formattedFileArray && formattedFileArray.length) {
        onFilesAdded(formattedFileArray)
      }
    },
    [onFilesAdded]
  )

  const handleDrop = useCallback(
    (e: any) => {
      setDragging(false)
      e.preventDefault()
      e.stopPropagation()
      addFiles(e.dataTransfer.files)
    },
    [addFiles]
  )

  useEffect(() => {
    drop.current?.addEventListener('dragover', handleDragOver)
    drop.current?.addEventListener('dragleave', handleDragLeave)
    drop.current?.addEventListener('mouseleave', handleMouseLeave)
    drop.current?.addEventListener('drop', handleDrop)

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver)
      drop.current?.removeEventListener('dragleave', handleDragLeave)
      drop.current?.removeEventListener('mouseleave', handleMouseLeave)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      drop.current?.removeEventListener('drop', handleDrop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDragOver = (e: any) => {
    setDragging(true)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragLeave = (e: any) => {
    setDragging(false)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleMouseLeave = () => {
    setDragging(false)
  }

  function handleFileSelect(e: any) {
    addFiles(e.target.files)
  }

  return (
    <>
      <Grid
        container
        className={`${style.dragDropArea} ${dragging ? style.dragDropAreaHover : ''}`}
        spacing={0}
        aria-label='Drag and drop area'
        ref={drop}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <DriveFolderUploadIcon className={style.UploadIcon}></DriveFolderUploadIcon>
        <p className={style.Description}>
          Drop files here or click to{' '}
          <a
            className={style.FileLink}
            href='/#'
            onClick={e => {
              e.preventDefault()
              document?.getElementById('dragonDropUpload')?.click()
              return false
            }}
          >
            select files to upload
          </a>
        </p>
        <i className={style.SubText}>25 MB limit per file, PDF files only</i>
      </Grid>
      <input
        type='file'
        id='dragonDropUpload'
        name='upload'
        alt='Browse for files'
        onChange={handleFileSelect}
        style={{ visibility: 'hidden', width: '1px', height: '1px' }}
        multiple
      />
    </>
  )
}
