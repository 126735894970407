import { Hit, BaseHit } from 'instantsearch.js'

export type MultiIndexHits = {
  index: string
  hits: Hit<AlgoliaHit>[] | Hit<ConceptHit>[]
}

export interface AlgoliaHit extends Hit {
  concept: { label: string }
  content: {
    ageRange: string
    audience?: string[]
    availableLocalizations: string[]
    behaviorChange?: string[]
    categories?: Array<{ id: string; name: string }>
    certifiedDate: number
    client?: {
      applications: Array<{ id: string; label: string }>
      id: string
      label: string
      subscribers: Array<{ id: string; label: string }>
    }
    clinicalTitle?: string
    collections: Array<{ id: string; label: string }>
    collectionTree: Array<{ lvl0: string; lvl1: string[]; lvl2: string[] }>
    custom: number
    delivery: string[]
    description: string
    docType: string
    excluded: string[]
    favoredBy: string[]
    gender: string[]
    groups: any[]
    groupTree: any[]
    hma: boolean
    html?: string
    htmlVersion?: string
    hwid: string
    id: string
    inHouse: boolean
    ixCount: number
    languagePackage: any
    learningObjective?: string
    localization: string
    maxAge: number
    minAge: number
    minutesToRead?: string
    patientJourney: any
    pdf?: string
    product?: string
    purpose: Array<{ id: string; label: string }>
    source: string
    thumbnail: string
    title: string
    topics?: string[]
    type: string
    version?: string
    videos: any[]
    videoTree: any[]
  }
  image: string
  licensedContent: boolean
  objectID: string
  url?: string
  __position: number
  __queryID: string
  _highlightResult: any
  assetType?: string
}

export interface ConceptHit extends BaseHit {
  articles: {
    exact_nb_hits: number
    facets: {
      exact_matches: {
        'content.inHouse': Array<{ value: string; count: number }>
        keys: Array<{ value: string; count: number }>
      }
      analytics: {
        'content.inHouse': Array<{ value: string; count: number }>
        keys: Array<{ attribute: string; operator: string; value: string; count: number }>
      }
    }
  }
  'concept.label'?: string[] | null
  'content.delivery'?: string[] | null
  'content.specialties'?: string[] | null
  'content.type'?: string[] | null
  nb_words: number
  popularity: number
  query: string
  objectID: string
  _highlightResult: HighlightResult
  __position: number
  __queryID: string
}

interface HighlightResult {
  query: Query
}

interface Query {
  value: string
  matchLevel: string
  matchedWords?: null[] | null
}

export const normalizeHits = <T extends BaseHit>(hits: any, indexName: string): T[] => {
  let items = hits
  if (Array.isArray(hits) && hits[0]?.index && Array.isArray(hits[0].hits)) {
    items = hits[0].hits
    hits.forEach(hitIndex => {
      if (hitIndex.index === indexName) {
        items = hitIndex?.hits || hitIndex
      }
    })
  }
  return (items?.hits || items) as T[]
}

export type HistoryItem = {
  id: string
  label: string
}

export interface HistoryHit extends BaseHit {
  id: string
  label: string
  objectID: string
  _highlightResult: any
}
