import moment from 'moment'
import { CircularProgress, Grid } from '@mui/material'

import CmxButton, { CmxButtonProps } from 'Components/CmxButton'

import { Subscription } from 'Store/AuthState'
import {
  ContentItemMetadata,
  ContentItemStatus,
  ContentStatus,
  ContentType,
  GetContentType,
} from 'Store/Models/ContentItem'

import style from './ManageContentItem.module.scss'
import SubscriptionApps, { SubscriptionAppsProps } from './SubscriptionApps'
import UpdateStatusDropdown, { UpdateStatusDropdownProps } from './UpdateStatusDropdown'
import classNames from 'classnames'
import { SelectedStatus } from './ManageContentItem'

export interface ControlsProps {
  canSuppress: boolean
  contentItem: ContentItemMetadata
  deleteContentItem: CmxButtonProps['onClick']
  disableSave: boolean
  disableRestore: boolean
  lastDraftStatus?: ContentItemStatus
  lastPublishedStatus?: ContentItemStatus
  onDiscardChanges: CmxButtonProps['onClick']
  onRestoreDefaultHWVersion: CmxButtonProps['onClick']
  onUpdateStatusDropdownChange: UpdateStatusDropdownProps['onChange']
  onSave: () => void
  selectedStatus?: SelectedStatus
  subscriptionCheckedChanged: SubscriptionAppsProps['onCheckboxChange']
  subscriptions: Subscription[]
  reset: ContentReset | null
  restoringToDefault: boolean
}

export enum ContentReset {
  Undo,
  RestoreDefault,
}

export default function Controls({
  canSuppress,
  contentItem: { editable, hwid, status },
  deleteContentItem,
  disableSave,
  disableRestore,
  lastDraftStatus,
  lastPublishedStatus,
  onDiscardChanges,
  onRestoreDefaultHWVersion,
  onSave,
  onUpdateStatusDropdownChange,
  selectedStatus,
  subscriptionCheckedChanged,
  subscriptions,
  reset,
  restoringToDefault,
}: ControlsProps) {
  if (!editable) return null

  return (
    <Grid container direction='row' item justifyContent='flex-end'>
      <Grid
        alignItems='flex-start'
        container
        direction='column'
        item
        justifyContent='flex-start'
        spacing={2}
      >
        <Grid item>
          <CmxButton
            className={style.manageControlPanelItem}
            disabled={disableSave}
            onClick={onSave}
            primary
          >
            Save
          </CmxButton>
        </Grid>

        <Grid item>
          {reset === ContentReset.Undo ? (
            <CircularProgress
              className={classNames(style.manageControlPanelItem, style.buttonLoader)}
            />
          ) : (
            <CmxButton
              className={style.manageControlPanelItem}
              onClick={onDiscardChanges}
              mode='grayscale'
              disabled={disableSave}
            >
              Undo Recent Changes
            </CmxButton>
          )}
        </Grid>

        {GetContentType(hwid) === ContentType.Healthwise && (
          <Grid item>
            {restoringToDefault ? (
              <CircularProgress
                className={classNames(style.manageControlPanelItem, style.buttonLoader)}
              />
            ) : (
              <CmxButton
                className={style.manageControlPanelItem}
                onClick={onRestoreDefaultHWVersion}
                mode='grayscale'
                disabled={disableRestore}
              >
                Restore Default Version
              </CmxButton>
            )}
          </Grid>
        )}

        <Grid item />

        <Grid item>
          <UpdateStatusDropdown
            {...{ canSuppress, hwid }}
            className={style.manageControlPanelItem}
            onChange={onUpdateStatusDropdownChange}
            value={selectedStatus}
          />
        </Grid>

        <Grid item />

        <Grid item>
          <div className={style.manageControlPanelLabel}>Publication Status</div>

          {lastDraftStatus && status?.toLowerCase() !== ContentStatus.Publish.toLowerCase() && (
            <div className={style.manageControlPanelInfo}>
              <b>Draft</b> saved on{' '}
              {moment(new Date(lastDraftStatus.timeStamp + 'Z')).format('MMM DD, YYYY')}
            </div>
          )}

          {lastPublishedStatus && (
            <div className={style.manageControlPanelInfo}>
              <b>Published</b> on{' '}
              {moment(new Date(lastPublishedStatus?.timeStamp + 'Z')).format('MMM DD, YYYY')}
            </div>
          )}
        </Grid>

        <Grid item />

        {GetContentType(hwid) !== ContentType.Healthwise && (
          <>
            <Grid item>
              <div className={style.manageControlPanelLabel}>Publish To</div>

              {subscriptions
                .sort((s1, s2) => (s1.id > s2.id ? 1 : s1.id < s2.id ? -1 : 0))
                .map((subscription, index) => (
                  <SubscriptionApps
                    {...{ subscription }}
                    key={index}
                    onCheckboxChange={subscriptionCheckedChanged}
                  />
                ))}
            </Grid>

            <Grid item />

            <Grid item />

            <Grid item>
              <CmxButton
                className={style.deleteContentItem}
                onClick={deleteContentItem}
                variant='text'
              >
                Delete this content
              </CmxButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
