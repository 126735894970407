import { createRoot } from 'react-dom/client'
import 'index.css'
import reportWebVitals from './reportWebVitals'
import SecurityWrapper from 'Components/Auth/SecurityWrapper'
import AppState from 'Components/AppState'
import ErrorBoundary from 'Pages/ErrorBoundary/ErrorBoundary'
import 'typeface-roboto'
import 'typeface-inter'

import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { Auth0ConfigStateProvider } from 'Store/Auth0ConfigState'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)
root.render(
  <ErrorBoundary>
    <Auth0ConfigStateProvider
      domain={AppSettings.AUTH0_DOMAIN}
      clientId={AppSettings.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AppSettings.AUTH0_AUDIENCE}
      scope={AppSettings.AUTH0_SCOPE}
      myHealthwiseUrl={AppSettings.MyHealthwiseIssuer}
    >
      <AppState>
        <SecurityWrapper />
      </AppState>
    </Auth0ConfigStateProvider>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
