import { DiscoServiceResult } from '../Types/DiscoTypes'
import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { attributeLabels } from 'Components/CompassFacets/FacetUtilities'
import { attributeLabels as compassAttributeLabels } from 'Components/CompassFacets/FacetUtilities'
import { AlgoliaActionEnum } from 'Store/AlgoliaState'
import { AccessToken } from 'Store/AuthState'

const discoEntitlementUrl: string = AppSettings.DiscoUrl + '/api/v1/entitlement'

export async function getDiscoResults(
  accessToken: string,
  algoliaStateDispatch: any
): Promise<DiscoServiceResult> {
  let searchParams = {
    method: 'GET',
    headers: {
      app_id: 'CMX',
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(discoEntitlementUrl, searchParams)
  const responseData = await response.json()
  if (response.ok) {
    attributeLabels.localizations = responseData.languagePackageAttribute
    compassAttributeLabels.localizations = responseData.languagePackageAttribute
    algoliaStateDispatch({ type: AlgoliaActionEnum.HAS_ERROR, hasError: false })
    return {
      indexes: responseData.indexes,
      queryKey: responseData.queryKey ?? '',
      appId: responseData.appId ?? '',
      languagePackageAttribute: responseData.languagePackageAttribute,
      availableLanguages: responseData.availableLanguages,
    }
  } else {
    algoliaStateDispatch({ type: AlgoliaActionEnum.HAS_ERROR, hasError: true })
    throw new Error('Unable to retreive entitlement info from Stage')
  }
}

export function GetInHouseContent(hwid: string, localization: string): Promise<string> {
  return fetch(`${AppSettings.DiscoUrl}/api/v4/article/${hwid}/${localization}/content`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
      app_id: 'CMX',
    },
  })
    .then(response => response.blob())
    .then(blob => {
      return window.URL.createObjectURL(blob)
    })
    .catch(() => {
      // process error
      return ''
    })
}
