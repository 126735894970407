import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import { Button, CircularProgress, Tab, Tabs, Tooltip, tooltipClasses } from '@mui/material'
import { Aspect, Cluster, GetCluster } from 'Api/Content.api'
import AppSettings, { Environment } from 'ApplicationSettings/ApplicationSettings'
import { arraysEqual } from 'Components/CompassFacets/FacetUtilities'
import { Section } from 'Pages/Browse/Browse'
import { AccessToken, useAuthState } from 'Store/AuthState'
import useSearchStore from 'Store/SearchState'
import classNames from 'classnames'
import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { Configure, Index, useHits } from 'react-instantsearch'
import Attribution from './Attribution'
import Embed from './Embed'
import styles from './TopicCluster.module.scss'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      className={styles.tabPanels}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export function CopiedTooltip({
  open,
  setOpen,
  children,
  zIndex,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  children: any
  zIndex?: number
}) {
  useEffect(() => {
    if (open) {
      setTimeout(() => setOpen(false), 3000)
    }
  }, [open, setOpen])

  return (
    <Tooltip
      title='Copied!'
      placement='top'
      arrow
      describeChild={false}
      disableInteractive
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={open}
      componentsProps={{
        popper: {
          sx: {
            zIndex: zIndex ? zIndex : 0,
            [`& .${tooltipClasses.arrow}`]: {
              color: 'rgba(38, 38, 38, 0.90)',
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: 'rgba(38, 38, 38, 0.90)',
              color: '#fff',
              fontFamily: 'Roboto',
              fontSize: '12px',
            },
            '.MuiTooltip-tooltip': {
              padding: '7px',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

interface AspectSelectorProps {
  cluster: Cluster
  currAspectIndex: number
  setCurrAspectIndex: (num: number) => void
}

function AspectSelector(props: AspectSelectorProps) {
  const { cluster, currAspectIndex, setCurrAspectIndex } = props
  const aspects = cluster.aspects
  aspects.sort((a: Aspect, b: Aspect) => (a.uiOrder > b.uiOrder ? 1 : -1))

  return (
    <div className={styles.aspects}>
      {aspects.map((aspect: Aspect, index: number) => {
        return (
          <Fragment key={`${cluster.id}-${aspect.uiLabel.replace(/\s+/g, '-')}`}>
            <Button
              className={classNames(
                styles.commonBtn,
                currAspectIndex === index ? styles.selectedBtn : styles.unselectedBtn
              )}
              variant='text'
              sx={{
                '&:hover': {
                  background: 'transparent',
                },
              }}
              onClick={() => {
                setCurrAspectIndex(index)
              }}
            >
              {aspect.uiLabel}
            </Button>
            {aspects.length - 1 > index && '|'}
          </Fragment>
        )
      })}
    </div>
  )
}

function GetClusterIdFromHits(hits: any[]) {
  // TODO We are currently making the assumption that at this point we have done enough refinement to
  // narrow down the Topics to where there is only ever 1 cluster associated with all of our hits. We
  // should verify this and perhaps do some testing to make sure this is the case.
  return hits.find(hit => hit.clusters)?.clusters[0]?.id
}

function RenderTopicCluster({ hasRefined }: { hasRefined: boolean }) {
  const embedCodeBtns = ['ID', 'Concept-Aspect']

  const [cluster, setCluster] = useState<Cluster | null>()
  const [loadingCluster, setLoadingCluster] = useState(false)
  const [currAspectIndex, setCurrAspectIndex] = useState(0)
  const [currentTab, setCurrentTab] = useState(0)
  const [localization, setLocalization] = useState<string>('en-us')
  const [availableLocalizations, setAvailableLocalizations] = useState<string[]>(['en-us'])
  const [length, setLength] = useState<string>('short')
  const [availableLengths, setAvailableLengths] = useState<string[]>([])
  const [showTitle, setShowTitle] = useState(true)
  const [showAttribution, setShowAttribution] = useState(false)
  const [embedType, setEmbedType] = useState(embedCodeBtns[0])

  const { hits } = useHits()
  const { authState } = useAuthState()

  const currentHwid = cluster?.aspects[currAspectIndex]?.sizes[length]?.id
  const searchTerm = useSearchStore(state => state.searchTerm)

  const topicPanelShouldTryToAppear = hasRefined
    ? true
    : hits.every((hit: any) => hit?.clusters[0]?.id === (hits as any)[0].clusters[0]?.id) // Checks if all hits in the search results share the same cluster (meaning the panel should appear)

  const webComponentEnvironment = (() => {
    switch (AppSettings.Environment) {
      case Environment.localdev:
        return 'dev'
      case Environment.development:
        return 'dev'
      case Environment.test:
        return 'test'
      default:
        return 'prod'
    }
  })()

  const embedText = `<script src="${AppSettings.WebComponentUrl}"></script><hw-content ${
    embedType === 'ID'
      ? `hwid="${currentHwid}"`
      : `clusterId="${cluster?.id}" aspect="${cluster?.aspects[currAspectIndex].labelText}"`
  } loc="${localization}" size="${length}" showTitle="${showTitle}" showAttribution="${showAttribution}" hideErrors useDefaultStyle ecode="${
    authState.ecode
  }" ${webComponentEnvironment !== 'prod' && `environment="${webComponentEnvironment}" `}/>`

  useEffect(() => {
    const matchedSearchAspect = cluster?.aspects.find(aspect =>
      Object.values(aspect.sizes).some(size => size !== null && size.id === searchTerm)
    )
    if (matchedSearchAspect) {
      const newAspect = matchedSearchAspect ? cluster?.aspects.indexOf(matchedSearchAspect) || 0 : 0
      const newLength =
        Object.keys(matchedSearchAspect.sizes).find(
          key => matchedSearchAspect.sizes[key]?.id === searchTerm
        ) || 'short'
      setCurrAspectIndex(newAspect)
      setLength(newLength)
    }
  }, [searchTerm, cluster])

  useEffect(() => {
    async function fetchCluster() {
      let id = GetClusterIdFromHits(hits)
      if (id) {
        setLoadingCluster(true)
        const currCluster = await GetCluster(id)
        setLoadingCluster(false)
        setCluster(currCluster)
      } else {
        setCluster(null)
      }
    }
    fetchCluster()
  }, [hits])

  useEffect(() => {
    const updateLengths = () => {
      const sizes = cluster?.aspects[currAspectIndex].sizes
      if (!sizes) return
      const lengths = Object.entries(sizes) // Maps the size object into available sizes
        .filter(([, value]) => value !== null)
        .map(([key]) => key)
      setAvailableLengths(lengths)
      if (!lengths.includes(length)) setLength(lengths[0])
    }

    const updateLocalizations = () => {
      const localizations = cluster?.aspects[currAspectIndex]?.sizes[length]?.availableLocalizations
      if (!localizations) return
      if (!arraysEqual(localizations, availableLocalizations)) {
        setAvailableLocalizations(localizations)
        setLocalization(localizations[0])
      }
    }

    if (cluster) {
      updateLengths()
      if (length && length.length > 0) {
        updateLocalizations()
      }
    }
    // eslint-disable-next-line
  }, [cluster, currAspectIndex])

  return cluster && hits.length > 0 && topicPanelShouldTryToAppear ? (
    <Section icon={<AlignHorizontalLeftIcon />} title='Topics'>
      <div className={styles.wrapper}>
        {typeof cluster !== 'undefined' &&
          AspectSelector({ cluster, currAspectIndex, setCurrAspectIndex })}
        <div className={styles.content}>
          <div className={styles.text}>
            {
              <hw-content
                loc={localization}
                size={length}
                bearerToken={AccessToken()}
                hwid={currentHwid}
                environment={webComponentEnvironment}
                hideErrors
                useDefaultStyle
                showAttribution={showAttribution}
                showTitle={showTitle}
              />
            }
            <div className={styles.hwid}>ID: {currentHwid}</div>
          </div>
          <div className={styles.controls}>
            <Tabs
              value={currentTab}
              onChange={(e: SyntheticEvent, newValue: number) => setCurrentTab(newValue)}
              aria-label='Topic Embed and Attribution Tabs'
              className={styles.tabs}
              variant='fullWidth'
              sx={{ '& .MuiTab-root.Mui-selected': { color: '#006272' } }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#006272',
                },
              }}
            >
              <Tab label='Embed' className={styles.tab} />
              <Tab label='Attribution' className={styles.tab} />
            </Tabs>
            <TabPanel index={0} value={currentTab}>
              <Embed
                length={length}
                setLength={setLength}
                availableLengths={availableLengths}
                localization={localization}
                setLocalization={setLocalization}
                availableLocalizations={availableLocalizations}
                showTitle={showTitle}
                setShowTitle={setShowTitle}
                showAttribution={showAttribution}
                setShowAttribution={setShowAttribution}
                embedCodeBtns={embedCodeBtns}
                embedType={embedType}
                setEmbedType={setEmbedType}
                embedText={embedText}
              />
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <Attribution />
            </TabPanel>
          </div>
        </div>
      </div>
    </Section>
  ) : hits.length > 0 && loadingCluster ? (
    <div className={styles.loadingSpinnerWrapper}>
      <CircularProgress color='primary' />
    </div>
  ) : (
    <></>
  )
}

type TopicClusterProps = {
  quickFilterDependency: string
  isSearching: boolean
  hasRefined: boolean
}

export default function TopicCluster({
  quickFilterDependency,
  isSearching,
  hasRefined,
}: TopicClusterProps) {
  const browsePath = useSearchStore(state => state.browsePath)
  const selectedQuickFilters = useSearchStore(state => state.selectedQuickFilters)

  return selectedQuickFilters.length === 0 ||
    selectedQuickFilters.includes(quickFilterDependency) ? (
    <Index indexName='assets' indexId='cluster_query'>
      <Configure
        distinct
        facetingAfterDistinct={false}
        analytics
        clickAnalytics
        enablePersonalization
        hitsPerPage={999}
        filters={`${
          isSearching ? '' : `concept.lineagePaths:"${browsePath}" AND `
        }cmx.coreConceptSet:true`}
      />
      <RenderTopicCluster hasRefined={hasRefined} />
    </Index>
  ) : (
    <></>
  )
}
