import { Box, Chip } from '@mui/material'
import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import moment from 'moment'

import styles from './ContentManagement.module.scss'

export function getStatusLabel(label: string): JSX.Element {
  let labelColor = '#2FA787'
  if (label === 'Updated') {
    labelColor = '#00A2C7'
  } else if (label === 'Deleted') {
    labelColor = '#DD372F'
  }

  return (
    <>
      <Chip
        label={''}
        size='small'
        className={styles.primaryStatusChip}
        sx={{
          backgroundColor: labelColor,
          height: '12px',
          width: '12px',
          mr: 1,
        }}
      />
      {label}
    </>
  )
}

export default function ActivityLogDefinitions() {
  const dateFormat = 'MMM D, YYYY [at] h:mma'

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Identifier',
      getApplyQuickFilterFn: undefined,
    },
    {
      field: 'date',
      type: 'date',
      minWidth: 220,
      maxWidth: 250,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box className={styles.showMoreOnOverflow}>{moment(params.value).format(dateFormat)}</Box>
        )
      },
      headerName: 'Date of Change',
      getApplyQuickFilterFn: value => {
        return (params: GridCellParams<any, any>): boolean => {
          return (
            moment(params.value).format(dateFormat).toLowerCase().indexOf(value.toLowerCase()) > -1
          )
        }
      },
      flex: 1,
    },
    {
      field: 'changeType',
      minWidth: 125,
      maxWidth: 150,
      headerName: 'Change',
      renderCell: (params: GridRenderCellParams) => getStatusLabel(params.value || ''),
      flex: 1,
    },
    {
      field: 'hwid',
      minWidth: 170,
      maxWidth: 190,
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'title',
      width: 250,
      minWidth: 200,
      headerName: 'Title',
      renderCell: params => (
        <div
          className={styles.activityTableTitleColumn}
          // eslint-disable-next-line
          aria-role='rowheader'
          tabIndex={params.hasFocus ? 0 : -1}
        >
          {params.value}
        </div>
      ),
      flex: 1,
    },
    {
      field: 'language',
      minWidth: 120,
      maxWidth: 190,
      headerName: 'Language',
      flex: 1,
    },
    {
      field: 'updatedBy',
      minWidth: 150,
      maxWidth: 190,
      headerName: 'Updated By',
      flex: 1,
    },
  ]

  return columns
}
