import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import { Box, Button, Checkbox, Icon, Tooltip } from '@mui/material'
import { GetContentStatus } from 'Api/Content.api'
import InteractiveIcon from 'Resources/interactive-content-icon.svg'
import { CartAction, useCartState } from 'Store/CartState'
import { GlobalNotificationStateManager, SnackbarSeverity } from 'Store/GlobalNotificationState'
import { ContentStatus } from 'Store/Models/ContentItem'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import { ContentTypes } from 'Types/ContentTypes'
import { CartItemFromHit, ItemExistsInCart } from 'Utilities/CartUtil'
import styles from './Hit.module.scss'
import { useEffect, useState } from 'react'

export type HitProps = {
  hit: any
  type: 'image' | 'video' | 'article' | 'interactive'
  showControls?: boolean
}

interface DocTypeIconProps {
  className?: string
  type: string
}

export const MAX_DESCRIPTION_LENGTH = 90

export function DocTypeIcon({ className, type }: DocTypeIconProps): JSX.Element {
  let element = <></>
  switch (type) {
    case ContentTypes.VIDEO:
      element = (
        <Tooltip title='Format: Video'>
          <span aria-label='Format: Video' role='img' data-qa='VideoIcon'>
            <OndemandVideoOutlinedIcon />
          </span>
        </Tooltip>
      )
      break
    case ContentTypes.DECISION:
    case ContentTypes.SYMPTOM:
    case ContentTypes.INTERACTIVE:
      element = (
        <Tooltip title='Format: Interactive'>
          <span aria-label='Format: Interactive' role='img' data-qa='InteractiveIcon'>
            <TouchAppOutlinedIcon />
          </span>
        </Tooltip>
      )
      break
    default:
      element = (
        <Tooltip title='Format: Article'>
          <span aria-label='Format: Article' role='img' data-qa='FormatIcon'>
            <DescriptionOutlinedIcon />
          </span>
        </Tooltip>
      )
      break
  }
  return <div {...{ className }}>{element}</div>
}

export default function Hit({ hit, type, showControls }: HitProps) {
  const { previewStateDispatch } = usePreviewState()

  const textDescription = hit.content?.learningObjective
    ? hit.content.learningObjective
    : hit.content.description

  function showPreview() {
    const item = CartItemFromHit(hit)
    previewStateDispatch({
      type: PreviewStateAction.SETPREVIEWCONTENT,
      data: item,
    })
    if (hit.cmx.type === 'Video' || hit.cmx.type === 'Image') {
      previewStateDispatch({
        type: PreviewStateAction.SETTOPICEMBEDTYPE,
        data: hit.cmx.type,
      })
    }
  }

  return (() => {
    switch (type) {
      case 'video':
        return (
          <ThumbnailHit
            hit={hit}
            icon={<OndemandVideoOutlinedIcon fontSize='small' />}
            showControls={!!showControls}
            showPreview={showPreview}
            textDescription={textDescription}
            type={type}
          />
        )
      case 'image':
        return (
          <ThumbnailHit
            hit={hit}
            icon={<PermMediaOutlinedIcon fontSize='small' />}
            showControls={!!showControls}
            showPreview={showPreview}
            textDescription={textDescription}
            type={type}
          />
        )
      case 'interactive':
        return (
          <TextHit
            hit={hit}
            icon={
              <Icon fontSize='small' className={styles.textIcon}>
                <img alt='' src={InteractiveIcon} height='100%' />
              </Icon>
            }
            showPreview={showPreview}
            textDescription={textDescription}
            type={type}
          />
        )
      default:
        return (
          <TextHit
            hit={hit}
            icon={<DescriptionOutlinedIcon fontSize='small' className={styles.textIcon} />}
            showPreview={showPreview}
            textDescription={textDescription}
            type={type}
          />
        )
    }
  })()
}

const CartCheckbox = ({ hit }: { hit: any }) => {
  const { cartState, cartStateDispatch } = useCartState()
  const [existsInCart, setExistsInCart] = useState<boolean>(
    ItemExistsInCart(hit.content.hwid, cartState.cartItems)
  )

  useEffect(() => {
    setExistsInCart(ItemExistsInCart(hit.content.hwid, cartState.cartItems))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartState.cartItems])

  const handleCheck = (newChecked: boolean, _id: string) => {
    const item = CartItemFromHit(hit)
    cartStateDispatch(
      newChecked
        ? { type: CartAction.ADDCARTITEM, data: { ...item } }
        : { type: CartAction.REMOVECARTITEM, data: { ...item } }
    )

    if (newChecked) {
      GetContentStatus(hit.content.hwid, 'en-us').then(data => {
        if (
          data.status.name.toLowerCase() === ContentStatus.Suppress.toLowerCase() ||
          data.status.name.toLowerCase() === ContentStatus.Deleted.toLowerCase()
        ) {
          cartStateDispatch({ type: CartAction.REMOVECARTITEM, data: { ...item } })
          GlobalNotificationStateManager.ShowSnackbarNotification(
            `This title is being ${data.status.name} and is currently unavailable.`,
            SnackbarSeverity.Error
          )
        }
      })
    }
  }

  return (
    <Checkbox
      role='checkbox'
      tabIndex={0}
      color='primary'
      id={hit.content.hwid}
      checked={existsInCart}
      aria-checked={existsInCart}
      aria-label={`Content title "${hit.content.title}". Add to cart`}
      data-qa='SearchResultCheckbox'
      onMouseDown={event => event.stopPropagation()}
      className='checkbox'
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
        handleCheck((event.target as HTMLInputElement).checked, hit.content.hwid)
      }}
      sx={{
        color: '#424242',
        '&:hover': { backgroundColor: 'transparent' },
        '&:has(:focus-visible)': { outline: '2px dotted #424242', outlineOffset: '-2px' },
        '.Mui-checked': {
          color: '#006272',
        },
      }}
    />
  )
}

const ThumbnailHit = ({
  hit,
  icon,
  showPreview,
  showControls = true,
  textDescription,
  type,
}: {
  hit: any
  icon: JSX.Element
  showPreview: () => void
  showControls?: boolean
  textDescription: string
  type: string
}) => {
  const thumbnail = hit.image
  return (
    <Button
      className={styles.card}
      onClick={showPreview}
      data-qa={`${type}-${hit.content.hwid}`}
      sx={{
        '&:hover': {
          '& .checkbox': {
            color: '#262626',
          },
          '& .Mui-checked': {
            color: '#006272',
          },
        },
      }}
    >
      {showControls && (
        <Box className={styles.cardCheckboxContainer}>
          <CartCheckbox key={`checkbox-${hit.content.hwid}`} hit={hit} />
        </Box>
      )}
      <div className={styles.cardMediaContainer}>
        <img src={thumbnail} className={styles.cardMedia} alt='' />
        {showControls && (
          <div className={styles.videoPlayIconContainer} aria-hidden>
            <PlayArrowIcon className={styles.videoPlayIcon} />
          </div>
        )}
      </div>
      <Box className={styles.cardContent}>
        <div className={styles.cardTitle}>{hit.content.title}</div>
        {textDescription?.length > 0 ? (
          <div className={styles.cardDesc}>{textDescription}</div>
        ) : (
          <Box height='5px' />
        )}
        <div className={styles.cardFooter}>
          {icon}
          <span>{hit.content.hwid}</span>
        </div>
      </Box>
    </Button>
  )
}

const TextHit = ({
  hit,
  icon,
  showPreview,
  textDescription,
  type,
}: {
  hit: any
  icon: JSX.Element
  showPreview: () => void
  textDescription: string
  type: string
}) => {
  return (
    <Button
      className={styles.textWrapper}
      onClick={showPreview}
      data-qa={`${type}-${hit.content.hwid}`}
      sx={{
        '&:hover': {
          '& .checkbox': {
            color: '#262626',
          },
          '& .Mui-checked': {
            color: '#006272',
          },
        },
      }}
    >
      <CartCheckbox key={`checkbox-${hit.content.hwid}`} hit={hit} />
      <div className={styles.textTitleAndDesc}>
        <div className={styles.textTitle}>{hit.content.title}</div>
        <div className={styles.textDesc}>{textDescription}</div>
      </div>
      <div className={styles.textHwidAndIcon}>
        <p data-qa='SearchResultHwid' className={styles.textHwid}>
          {hit.content.hwid}
        </p>
        {icon}
      </div>
    </Button>
  )
}
