import style from './Report.module.scss'
import { launchPublishedReport, launchManagedReport } from 'Api/Reports.api'
import { orgHasTopics, orgIsCompassOnly, useAuthState } from 'Store/AuthState'
import { useAlgolia } from 'Store/UseAlgolia'
import { Divider, IconButton } from '@mui/material'
import { useGlobalNotificationState, SnackbarSeverity } from 'Store/GlobalNotificationState'
import { Feature } from 'Utilities/PermissionManager'
import InventoryReportIcon from 'Resources/inventory-report-icon.svg'
import Footer from 'Components/Footer/Footer'
import PageError from 'Components/PageError/PageError'
import downloadIcon from 'Resources/icon-download.svg'
import launchIcon from 'Resources/icon-launch.svg'
import AppSettings from 'ApplicationSettings/ApplicationSettings'

function Reports() {
  const { authState } = useAuthState()
  const { algoliaState } = useAlgolia(authState.accessToken)
  const { globalNotificationState, GlobalNotificationStateManager } = useGlobalNotificationState()
  const accessToken = authState?.accessToken
  const cscReportUrl = AppSettings.CSCReportUrl + '#' + accessToken

  function handlePublishedReport() {
    if (!globalNotificationState.loadingModalInfo.visible) {
      GlobalNotificationStateManager.SetLoadingModalVisible(true)

      launchPublishedReport(
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState?.index || '',
        algoliaState?.availableLanguages || [],
        orgHasTopics(authState.licensing),
        orgIsCompassOnly(authState.licensing),
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
        },
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error Retrieving Report',
            SnackbarSeverity.Error,
            false,
            10000
          )
        }
      )
    }
  }

  function handleManagedReport() {
    if (!globalNotificationState.loadingModalInfo.visible) {
      GlobalNotificationStateManager.SetLoadingModalVisible(true)
      launchManagedReport(
        algoliaState.key.QueryAppId,
        algoliaState.key.QueryKey,
        algoliaState?.index || '',
        algoliaState?.availableLanguages || [],
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
        },
        () => {
          GlobalNotificationStateManager.SetLoadingModalVisible(false)
          GlobalNotificationStateManager.ShowSnackbarNotification(
            'Error Retrieving Report',
            SnackbarSeverity.Error,
            false,
            10000
          )
        }
      )
    }
  }

  if (authState?.features.indexOf(Feature.ViewReports) > -1) {
    return (
      <>
        <div className={style.reportPage}>
          <div className={style.banner}>
            <h1 className={style.title}>Reports</h1>
          </div>
          {/* show page error */}
          {authState.hasError || algoliaState.hasError ? (
            <PageError></PageError>
          ) : (
            <div className={style.cardContainer}>
              <button
                data-qa='FullInventoryReport'
                className={style.reportCard}
                onClick={handlePublishedReport}
                aria-label='Download Published Content Inventory Report'
                tabIndex={0}
              >
                <div className={style.cardRow}>
                  <div>
                    <img src={InventoryReportIcon} alt='' className={style.inventoryReportIcon} />
                  </div>
                  <div className={style.reportContainerMain}>
                    <h2 className={style.reportContainerTitle}>Your Published Content Inventory</h2>
                    <p className={style.reportCardDesc}>
                      A complete list of all published content that is available for your use.
                    </p>
                  </div>
                </div>
                <Divider className={style.divider}></Divider>
                <div className={style.reportLinks}>
                  <IconButton
                    sx={{
                      color: '#0d8484',
                      paddingLeft: '0',
                      '&:hover': {
                        outline: 'none',
                        backgroundColor: 'transparent',
                      },
                    }}
                    aria-label='Download Published Content Inventory Report'
                    aria-describedby='download-excel-published-content-inventory'
                    tabIndex={-1}
                  >
                    <img alt='' src={downloadIcon} />
                  </IconButton>
                  Download Excel File
                </div>
              </button>
              {orgIsCompassOnly(authState.licensing) ? (
                <></>
              ) : (
                <button
                  data-qa='ManagedInventoryReport'
                  className={style.reportCard}
                  onClick={handleManagedReport}
                  aria-label='Download Managed Content Inventory Report'
                  tabIndex={0}
                >
                  <div className={style.cardRow}>
                    <div>
                      <img src={InventoryReportIcon} alt='' className={style.inventoryReportIcon} />
                    </div>
                    <div className={style.reportContainerMain}>
                      <h2 className={style.reportContainerTitle}>Your Managed Content Inventory</h2>
                      <p className={style.reportCardDesc}>
                        A complete list of all content that has been edited or uploaded by your
                        health system.
                      </p>
                    </div>
                  </div>
                  <Divider></Divider>
                  <div className={style.reportLinks}>
                    <IconButton
                      sx={{
                        color: '#0d8484',
                        paddingLeft: '0',
                        '&:hover': {
                          outline: 'none',
                          backgroundColor: 'transparent',
                        },
                      }}
                      aria-label='Download Managed Content Inventory Report'
                      aria-describedby='download-excel-managed-content-inventory'
                      tabIndex={-1}
                    >
                      <img alt='' src={downloadIcon} />
                    </IconButton>
                    Download Excel File
                  </div>
                </button>
              )}

              <button
                data-qa='CSCReport'
                className={style.reportCard}
                onClick={() => window.open(cscReportUrl, '_blank')}
                aria-label='Open Change Reports'
                tabIndex={0}
              >
                <div className={style.cardRow}>
                  <div>
                    <img src={InventoryReportIcon} alt='' className={style.inventoryReportIcon} />
                  </div>
                  <div className={style.reportContainerMain}>
                    <h2 className={style.reportContainerTitle}>
                      Healthwise Content Change Reports
                    </h2>
                    <p className={style.reportCardDesc}>
                      What's new, changed, or enhanced. Includes 3rd party content sources.
                    </p>
                  </div>
                </div>
                <Divider></Divider>
                <div className={style.reportLinks}>
                  <IconButton
                    sx={{
                      color: '#0d8484',
                      '&:hover': {
                        outline: 'none',
                        backgroundColor: 'transparent',
                      },
                    }}
                    aria-label='Open Change Reports'
                    aria-describedby='open-change-reports'
                    tabIndex={-1}
                  >
                    <img alt='' src={launchIcon} />
                  </IconButton>
                  Open Change Reports
                </div>
              </button>
            </div>
          )}
        </div>
        <Footer />
      </>
    )
  }
  return <></>
}

export default Reports
