import { AccessToken } from 'Store/AuthState'
import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { localeMap } from 'Utilities/LocaleMapUtil'
import { GetAllLanguages } from './Content.api'

const CONTENTLANG_URL = `${AppSettings.CMXServiceUrl}/api/languages/v2/`

export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export type LanguageArray = [string, string][]

export const buildLangDropDown = async (
  hwid: string = '',
  appId: string = '',
  apiKey: string = '',
  indexName: string = '',
  languagePackage: string = '',
  isTopic: boolean = false
): Promise<LanguageArray> => {
  const langArray: LanguageArray = []
  const res =
    hwid !== ''
      ? await fetchContentLang(hwid, appId, apiKey, indexName, languagePackage, isTopic)
      : await GetAllLanguages()

  if (res && typeof res !== 'string') {
    res.forEach((lang: string) => {
      const label = localeMap[lang]
      langArray.push([lang, label])
    })

    //sort to match Language Facet order
    langArray.sort(function (a, b) {
      if (a[0] === 'en-us') return -1
      if (b[0] === 'en-us') return 1
      if (a[0] === 'es-us') return -1
      if (b[0] === 'es-us') return 1
      if (a[1] < b[1]) {
        return -1
      }
      if (a[1] > b[1]) {
        return 1
      }
      return 0
    })
    return langArray
  }
  return []
}

interface Language {
  value: string
  name: string
}

export const buildLangDropdownObjects = async (
  hwid: string = '',
  appId: string = '',
  apiKey: string = '',
  indexName: string = '',
  languagePackage: string = '',
  isTopic: boolean = false
): Promise<any> => {
  const langObjects: Language[] = []

  const res =
    hwid !== ''
      ? await fetchContentLang(hwid, appId, apiKey, indexName, languagePackage, isTopic)
      : await GetAllLanguages()

  if (res && typeof res !== 'string') {
    res.forEach((lang: string) => {
      const label = localeMap[lang]
      langObjects.push({ value: lang, name: label })
    })

    //sort to match Language Facet order
    langObjects.sort(function (a, b) {
      if (a.value === 'en-us') return -1
      if (b.value === 'en-us') return 1
      if (a.value === 'es-us') return -1
      if (b.value === 'es-us') return 1
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    return langObjects
  }
  return []
}

export const fetchContentLang = async (
  hwid: string,
  appId: string,
  apiKey: string,
  indexName: string,
  languagePackage: string,
  isTopic: boolean
): Promise<string[] | string> => {
  let attempts = 0
  let maxAttempts = 5

  while (attempts < maxAttempts) {
    if (attempts > 0) {
      await delay(2000)
    }
    let res = await AlgoliaContentLanguagesGET(
      hwid,
      appId,
      apiKey,
      indexName,
      languagePackage,
      isTopic,
      'en-us'
    )

    if (res.ok) {
      return await res.json()
    }

    attempts++
  }

  return 'Cannot Load Licensed Languages'
}

export const AlgoliaContentLanguagesGET = async (
  hwid: string,
  appId: string,
  apiKey: string,
  indexName: string,
  languagePackage: string,
  isTopic: boolean = false,
  defaultLanguage?: string
): Promise<any> => {
  return await fetch(
    `${CONTENTLANG_URL}${hwid}/${isTopic}${defaultLanguage ? `/${defaultLanguage}` : ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
        appId: appId,
        apiKey: apiKey,
        indexName: indexName,
        languagePackage: languagePackage,
      },
    }
  )
}
