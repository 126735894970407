import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AccessToken, OrgName } from 'Store/AuthState'
import { ImportFile } from 'Store/ImportState'
import { EventResponse } from './Content.api'

const UPLOAD_URL = AppSettings.CMX_UPLOADFILE
const CREATECONTENT_URL = AppSettings.CMX_CREATE
const UPDATECONTENT_URL = AppSettings.CMX_UPDATE
const CHECKID_URL = AppSettings.CMX_CHECKID
const DELETECONTENT_URL = AppSettings.CMX_DELETE

export function UploadFile(file: ImportFile): Promise<any> {
  const formData = new FormData()
  formData.append('formFile', file.file)
  formData.append('fileName', file.filename)

  return fetch(`${UPLOAD_URL}/${OrgName()}`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
    body: formData,
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

export function CheckContentUniqueId(hwid: string, localization: string): Promise<any> {
  return fetch(`${CHECKID_URL}/${OrgName()}/${hwid}/${localization}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response.json()
  })
}

export function SubmitContent(file: ImportFile): Promise<any> {
  return fetch(`${CREATECONTENT_URL}/${OrgName()}/pdf`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(file),
  }).then(response => {
    if (!response.ok) {
      return response.json()
    }
    return
  })
}

export function UpdateContent(file: ImportFile): Promise<any> {
  return fetch(`${UPDATECONTENT_URL}/${OrgName()}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify([file]),
  }).then(response => {
    if (!response.ok) {
      return response.json()
    }
  })
}

async function Delete(contentItemIds: any[]) {
  let res = await fetch(`${DELETECONTENT_URL}/${OrgName()}`, {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(contentItemIds),
  })
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export async function DeleteInHouseContent(contentItemIds: any[]) {
  var errorCount = 0
  var formattedString = ''
  let deleteResponse = {} as EventResponse
  deleteResponse.hasError = false

  let res = await Delete(contentItemIds)

  if (res.failedItems) {
    deleteResponse.hasError = true
    errorCount = res.failedItems.length
    formattedString = res.failedItems.toString().split(',').join('\n\n')
  }
  deleteResponse.errorCount = errorCount
  deleteResponse.errorMsg = formattedString
  deleteResponse.successCount = res.successCount

  return deleteResponse
}
