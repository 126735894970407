import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import { Box, Button, CircularProgress, Icon, useMediaQuery } from '@mui/material'
import AppSettings, {
  App_Routes,
  VideoType,
  algoliaApplicationTags,
} from 'ApplicationSettings/ApplicationSettings'
import BreadCrumbs from 'Components/BreadCrumbs/BreadCrumbs'
import Cart from 'Components/Cart/Cart'
import FacetList from 'Components/CompassFacets/FacetList/FacetList'
import { getFilterCount } from 'Components/CompassFacets/FacetUtilities'
import QuickFilters from 'Components/CompassFacets/QuickFilters/QuickFilters'
import SelectedFacets from 'Components/CompassFacets/SelectedFacets/SelectedFacets'
import Footer from 'Components/Footer/Footer'
import SubHeader from 'Components/Header/SubHeader'
import PageError from 'Components/PageError/PageError'
import RefineResults from 'Components/RefineResults/RefineResults'
import Hit, { HitProps } from 'Components/ResultHit/Hit'
import TopicCluster from 'Components/TopicCluster'
import BrowseAll from 'Pages/BrowseAll/BrowseAll'
import InteractiveIcon from 'Resources/interactive.svg'
import { OrgName, useAuthState } from 'Store/AuthState'
import useLocalStorage from 'Store/Hooks/useLocalStorage'
import { PreviewStateAction, usePreviewState } from 'Store/PreviewState'
import useSearchStore from 'Store/SearchState'
import { useAlgolia } from 'Store/UseAlgolia'
import 'Styles/Overwrite.scss'
import { Feature } from 'Utilities/PermissionManager'
import classNames from 'classnames'
import 'instantsearch.css/themes/reset.css'
import { ReactNode, useEffect, useState } from 'react'
import { Configure, Index, InstantSearch, useHits, useStats } from 'react-instantsearch'
import { useLocation } from 'react-router-dom'
import style from './Browse.module.scss'
import { SessionInfo, launchEvent } from 'Api/BackendEvents.api'

type SectionProps = {
  children?: ReactNode
  hitType?: ContentSectionProps['hitType']
  icon: ContentSectionProps['icon']
  title: ContentSectionProps['title']
}

type ContentSectionProps = {
  title: string
  icon: JSX.Element
  hitType: HitProps['type']
  quickFilterDependency: string
  indexId: string // Any unique string (if not unique, other queries will affect this one)
  isInWorkflow: boolean
  defaultHitsPerPage?: number
  filters?: string
}

export type PageDropdown = {
  title: string
  attribute: string | null
  route: string | null
}

export function Section(props: SectionProps) {
  const { icon, hitType, title, children } = props

  return (
    <Box className={style.section}>
      <Box className={style.sectionHeader}>
        {icon}
        <h2 className={style.sectionTitle}>{title}</h2>
        <Box className={style.sectionLine} />
      </Box>
      <Box
        className={style.sectionContent}
        sx={{
          rowGap: hitType === 'video' || hitType === 'image' ? '44px' : '3px',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

function Hits(props: {
  title: string
  icon: JSX.Element
  hitType: HitProps['type']
  defaultHitsPerPage: number
  displayedCount: number
  setDisplayedCount: (displayedCount: number) => void
  isInWorkflow: boolean
}) {
  // TODO somehow there are a lot of React errors being generated here, either fix them or suppress them.
  const {
    title,
    icon,
    hitType,
    defaultHitsPerPage,
    displayedCount,
    setDisplayedCount,
    isInWorkflow,
  } = props

  const browsePath = useSearchStore(state => state.browsePath)
  const searchTerm = useSearchStore(state => state.searchTerm)

  const { authState } = useAuthState()
  const { nbHits } = useStats()
  const { hits } = useHits()

  const moreToShowCount = nbHits - displayedCount
  if (moreToShowCount === 1) setDisplayedCount(displayedCount + 1)

  useEffect(() => {
    setDisplayedCount(defaultHitsPerPage)
    // eslint-disable-next-line
  }, [browsePath, searchTerm])

  //getting page load time - then calling launch event
  useEffect(() => {
    const perfObserver = new PerformanceObserver(observedEntries => {
      const entry: PerformanceEntry = observedEntries.getEntriesByType('navigation')[0]

      //call launch back end event
      const lastPageUrl = document.referrer
      let sessionInfo: SessionInfo = {
        orgName: `${OrgName()}`,
        sessionid: sessionStorage.getItem('sessionid'),
        previousUrl: lastPageUrl,
        launchDuration: Math.round(entry.duration),
      }
      if (sessionStorage.getItem('initialLoad') === 'true') {
        launchEvent(sessionInfo)
      }
    })

    perfObserver.observe({
      type: 'navigation',
      buffered: true,
    })

    if (!sessionStorage.getItem('initialLoad')) {
      sessionStorage.setItem('initialLoad', 'true')
    } else {
      sessionStorage.setItem('initialLoad', 'false')
    }
  }, [])

  return hits && hits?.length > 0 ? (
    <div className={style.contentSectionContainer}>
      <Section title={title} icon={icon} hitType={hitType}>
        {hits.map((hit: any) => {
          return (
            <Hit
              hit={hit}
              type={hitType}
              showControls={(() => {
                if (hitType === 'image') return false
                if (hitType === 'video')
                  return authState.licensing?.data.assets.some(
                    // Only show controls if the user licenses article videos
                    (a: { name: string }) => a.name === VideoType.ARTICLE_VIDEO
                  )
                return true
              })()}
              key={`hit-${hit.content.hwid}`}
            />
          )
        })}
      </Section>
      {displayedCount < nbHits && isInWorkflow ? (
        <>
          <Button
            className={style.showAllButton}
            onClick={() => {
              setDisplayedCount(
                moreToShowCount < 10 ? displayedCount + moreToShowCount : displayedCount + 10
              )
            }}
          >
            {moreToShowCount > 10 ? 'Show More' : 'Show All'} ({moreToShowCount})
            <KeyboardArrowDownRoundedIcon />
          </Button>
          <Box className={style.largePaddingBox} />
        </>
      ) : (
        <Box className={style.largePaddingBox} />
      )}
    </div>
  ) : (
    <></>
  )
}

function ContentSection(props: ContentSectionProps) {
  const {
    icon,
    title,
    hitType,
    defaultHitsPerPage = 3,
    indexId,
    isInWorkflow,
    filters,
    quickFilterDependency,
  } = props
  const selectedQuickFilters = useSearchStore(state => state.selectedQuickFilters)

  const [displayedCount, setDisplayedCount] = useState<number>(defaultHitsPerPage)

  return selectedQuickFilters.length === 0 ||
    selectedQuickFilters.includes(quickFilterDependency) ? (
    <Index indexName='assets' indexId={indexId}>
      <Configure
        distinct
        facetingAfterDistinct={false}
        analytics
        clickAnalytics
        enablePersonalization
        hitsPerPage={displayedCount}
        filters={filters}
      />
      <Hits
        title={title}
        icon={icon}
        hitType={hitType}
        defaultHitsPerPage={defaultHitsPerPage}
        displayedCount={displayedCount}
        setDisplayedCount={setDisplayedCount}
        isInWorkflow={isInWorkflow}
      />
    </Index>
  ) : (
    <></>
  )
}

function RenderBrowse(props: { browseLevels: number; browseAndRefineFilterString: string }) {
  const { browseLevels, browseAndRefineFilterString } = props

  const browsePath = useSearchStore(state => state.browsePath)
  const filters = useSearchStore(state => state.filters)
  const filterString = useSearchStore(state => state.filterString)
  const searchTerm = useSearchStore(state => state.searchTerm)

  const { authState } = useAuthState()

  // Helper variables for the various workflows.  isInWorkflow determines if they should be on the default landing page or not
  const isBrowsing = browsePath ? true : false
  const isLargeScreen = useMediaQuery('(min-width:1300px)')
  const isSearching = searchTerm.length > 0
  const hasRefined = browseLevels ? browseLevels > 3 : false
  const isInWorkflow = isSearching || isBrowsing || hasRefined
  //const isInWorkflow = true
  const filterCount = getFilterCount(filters)

  useEffect(() => {
    //if hw-content.js has not already been loaded, we will add it here
    const script = document.querySelector(`script[src="${AppSettings.WebComponentUrl}"]`)
    if (!script) {
      const hwContentScript = document.createElement('script')
      hwContentScript.setAttribute('src', AppSettings.WebComponentUrl)
      document.body.appendChild(hwContentScript)
    }
  }, [])

  return (
    <Box sx={{ height: '100%' }}>
      <Box maxWidth={isLargeScreen ? '1434px' : '980px'} className={style.container}>
        <div className={style.breadCrumbsAndPage}>
          {isBrowsing && <BreadCrumbs />}
          {isSearching && <Box className={style.paddingBox} />}
          <div className={style.page}>
            {isLargeScreen && <FacetList isInWorkflow={isInWorkflow} />}
            <Box maxWidth='980px' className={style.wrapper}>
              {!isInWorkflow && (
                <h1 className={style.landingHeader}>
                  The most comprehensive, trusted
                  <br />
                  health library.
                </h1>
              )}
              <div className={style.quickFilters}>
                <QuickFilters
                  isBrowsing={isBrowsing}
                  isSearching={isSearching}
                  isLargeScreen={isLargeScreen}
                  isInWorkflow={isInWorkflow}
                  browseAndRefineFilterString={browseAndRefineFilterString}
                />
              </div>
              <div className={style.content}>
                {isInWorkflow && filterCount > 0 && <SelectedFacets />}
                {isInWorkflow && <Box className={style.paddingBox} />}
                {isInWorkflow && !hasRefined && (
                  <RefineResults
                    isBrowsing={isBrowsing}
                    isSearching={isSearching}
                    browseAndRefineFilterString={browseAndRefineFilterString}
                  />
                )}
                {isInWorkflow && (
                  <TopicCluster
                    quickFilterDependency='Topic'
                    isSearching={isSearching}
                    hasRefined={hasRefined}
                  />
                )}
                {(browseAndRefineFilterString?.length > 0 || filterString?.length > 0) && (
                  <>
                    <ContentSection
                      icon={<OndemandVideoOutlinedIcon />}
                      title={isInWorkflow ? 'Videos' : 'Featured Videos'}
                      hitType='video'
                      quickFilterDependency='Video'
                      indexId='videos_query'
                      isInWorkflow={isInWorkflow}
                      filters={`${browseAndRefineFilterString} ${filterString} AND cmx.type:Video AND ${
                        authState.licensing?.data.assets.some(
                          (a: { name: string }) => a.name === VideoType.ARTICLE_VIDEO
                        )
                          ? 'assetType:article'
                          : 'assetType:topic'
                      }`} // Prioritizes article-videos from the index but uses topic-videos if they are Compass-only
                    />
                    <ContentSection
                      icon={<PermMediaOutlinedIcon />}
                      title={isInWorkflow ? 'Image Topics' : 'Featured Image Topics'}
                      hitType='image'
                      quickFilterDependency='Image'
                      indexId='images_query'
                      isInWorkflow={isInWorkflow}
                      filters={`${browseAndRefineFilterString} ${filterString} AND cmx.type:Image`}
                    />
                    <ContentSection
                      icon={<DescriptionOutlinedIcon />}
                      title={isInWorkflow ? 'Articles' : 'Featured Articles'}
                      hitType='article'
                      quickFilterDependency='Article'
                      indexId='articles_query'
                      isInWorkflow={isInWorkflow}
                      defaultHitsPerPage={5}
                      filters={`${browseAndRefineFilterString} ${filterString} AND cmx.type:Article ${
                        isInWorkflow ? '' : `AND content.inHouse:false`
                      }`}
                    />
                    <ContentSection
                      icon={
                        <Icon>
                          <img alt='' src={InteractiveIcon} height='100%' />
                        </Icon>
                      }
                      title={isInWorkflow ? 'Interactive' : 'Featured Interactive Content'}
                      hitType='interactive'
                      quickFilterDependency='Interactive'
                      indexId='interactive_query'
                      isInWorkflow={isInWorkflow}
                      filters={`${browseAndRefineFilterString} ${filterString} AND cmx.type:Interactive`}
                    />
                  </>
                )}
              </div>
            </Box>
          </div>
        </div>
      </Box>
      <Footer />
    </Box>
  )
}

export default function Browse(props: { browseAll?: boolean }) {
  const { browseAll } = props
  const { authState } = useAuthState()

  const browsePath = useSearchStore(state => state.browsePath)
  const updateBrowsePath = useSearchStore(state => state.updateBrowsePath)
  const filters = useSearchStore(state => state.filters)
  const clearAllFilters = useSearchStore(state => state.clearAllFilters)
  const filterString = useSearchStore(state => state.filterString)
  const searchTerm = useSearchStore(state => state.searchTerm)
  const selectedQuickFilters = useSearchStore(state => state.selectedQuickFilters)
  const updateSelectedQuickFilters = useSearchStore(state => state.updateSelectedQuickFilters)

  const { isLoading, algoliaState, algoliaClient } = useAlgolia(authState.accessToken)
  const { previewState, previewStateDispatch } = usePreviewState()
  const algoliaTags = algoliaApplicationTags()
  const location = useLocation()
  const [showExpMenus] = useLocalStorage('expMenus', false)

  const browseLevels = browsePath ? browsePath.split(' > ').length : 0
  const browseField = browsePath?.startsWith('Categories') ? 'categories' : 'concept'
  const browseAndRefineFilterString = browsePath
    ? `${browseField}.lineages.lvl${browseLevels - 1}:"${browsePath}" AND `
    : ''

  let filterStr = ''
  if (browseAndRefineFilterString?.length) filterStr += browseAndRefineFilterString
  if (filterString?.length)
    filterStr += browseAndRefineFilterString?.length ? ` ${filterString}` : filterString

  const pageDropdowns: PageDropdown[] = [
    // The menus that will appear in the subheader
    {
      title: 'Index',
      attribute: 'concept.lineages.lvl2',
      route: App_Routes.BROWSEALLINDEX,
    },
    ...(showExpMenus
      ? [
          {
            title: 'Category',
            attribute: 'categories.lineages.lvl2',
            route: App_Routes.BROWSEALLCATEGORY,
          },
          { title: 'Population', attribute: null, route: null },
        ]
      : []),
  ]

  // Clears the current browse if the user starts searching
  useEffect(() => {
    if (searchTerm.length === 0) return
    if (browsePath !== null && browsePath?.length !== 0) updateBrowsePath(null)
    if (selectedQuickFilters.length > 0) updateSelectedQuickFilters([])

    let clearFilters = false
    for (const [value] of Object.entries(filters)) {
      if (filters[value].length > 0) {
        clearFilters = true
        break
      }
    }

    if (clearFilters) clearAllFilters()
    // eslint-disable-next-line
  }, [searchTerm.length])

  useEffect(() => {
    if (selectedQuickFilters.length > 0) updateSelectedQuickFilters([])
    // eslint-disable-next-line
  }, [browseAndRefineFilterString, filters])

  useEffect(() => {
    if (previewState.previewContent?.hwid)
      previewStateDispatch({ type: PreviewStateAction.SETPREVIEWOPEN, data: true })
  }, [previewState.previewContent, authState.accessToken, previewStateDispatch])

  if (
    authState?.features.indexOf(Feature.AdvancedSearch) > -1 ||
    authState?.features.indexOf(Feature.DemoSearch) > -1
  ) {
    // show page error if there is an error
    if (authState.hasError || algoliaState.hasError) return <PageError />
    else
      return !algoliaClient?.appId || !algoliaState.index || isLoading ? (
        <div className={style.wrapper}>
          <div id='LoadingSpinner'>
            <CircularProgress
              style={{ marginTop: '4em' }}
              color='primary'
              className={classNames('loader', style.loader)}
            />
          </div>
        </div>
      ) : (
        <InstantSearch searchClient={algoliaClient} indexName='assets'>
          <Configure
            hitsPerPage={12}
            distinct
            facetingAfterDistinct
            analytics
            clickAnalytics
            enablePersonalization
            analyticsTags={algoliaTags}
            filters={filterStr}
            query={searchTerm}
          />
          {/* TODO Fix all the console errors being generated in here */}
          <SubHeader
            algoliaState={algoliaState}
            browseAndRefineFilterString={browseAndRefineFilterString}
            pageDropdowns={pageDropdowns}
          />
          <Cart />
          {browseAll ? (
            <BrowseAll
              callingPageDropdown={
                pageDropdowns.find(
                  pageDropdown => (pageDropdown.route ?? '') === location.pathname
                ) ?? pageDropdowns[0]
              }
            />
          ) : (
            <RenderBrowse
              browseLevels={browseLevels}
              browseAndRefineFilterString={browseAndRefineFilterString}
            />
          )}
        </InstantSearch>
      )
  }
  return <></>
}
