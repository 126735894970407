import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AccessToken } from 'Store/AuthState'

const LAUNCH_URL = `${AppSettings.CMXServiceUrl}/events/launch`

export type SessionInfo = {
  orgName: string | null
  sessionid: string | null
  previousUrl: string | null
  launchDuration: number | null
}

export async function launchEvent(sessionInfo: SessionInfo): Promise<any> {
  const response = await fetch(`${LAUNCH_URL}/${sessionInfo.orgName}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + AccessToken(),
    },
    body: JSON.stringify(sessionInfo),
  })
  return response.json
}
