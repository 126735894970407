import { ReactElement } from 'react'

import { Tooltip as MuiTooltip } from '@mui/material'

import style from './Tooltip.module.scss'

export interface TooltipProps {
  children: ReactElement
  text: string
}

export default function Tooltip({ children, text }: TooltipProps) {
  return (
    <MuiTooltip
      tabIndex={0}
      arrow
      classes={{ tooltip: style.tooltip }}
      disableInteractive
      placement='top-start'
      title={<div className={style.tooltipMessage}>{text}</div>}
    >
      {children}
    </MuiTooltip>
  )
}
