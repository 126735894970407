import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { Badge, Button, Drawer } from '@mui/material'
import CmxButton from 'Components/CmxButton'
import { orgIsCompassOnly, useAuthState } from 'Store/AuthState'
import useSearchStore from 'Store/SearchState'
import { useState } from 'react'
import Facet from '../Facet/Facet'
import {
  attributeLabels,
  attributeTitles,
  getFilterCount,
  getItemLabel,
  sourceTooltips,
} from '../FacetUtilities'
import SelectedFacets from '../SelectedFacets/SelectedFacets'
import styles from './FacetList.module.scss'

type FacetListProps = {
  isInWorkflow: boolean // Cannot hide this component on the Browse page because the facet logic needs to be running the entire duration of a session
  smallScreenVariant?: boolean
}

type AlphabeticalSortProp1 = {
  name: string
  escapedValue: string
  count: number
  isRefined: boolean
}

type AlphabeticalSortProp2 = {
  value: string
  label: string
  highlighted: string
  count: number
  isRefined: boolean
}

const alphabetical = (
  a: AlphabeticalSortProp1 | AlphabeticalSortProp2,
  b: AlphabeticalSortProp1 | AlphabeticalSortProp2,
  title?: string
) => {
  const aLabel = getItemLabel(title, 'name' in a ? a.name : a.value)
  const bLabel = getItemLabel(title, 'name' in b ? b.name : b.value)

  if (aLabel < bLabel) return -1
  if (aLabel > bLabel) return 1
  return 0
}

// Sorts English and Spanish to the top, otherwise sorted alphabetically.
export const languageSort = (
  a: AlphabeticalSortProp1 | AlphabeticalSortProp2,
  b: AlphabeticalSortProp1 | AlphabeticalSortProp2
) => {
  const aLabel = 'name' in a ? a.name : a.value
  const bLabel = 'name' in b ? b.name : b.value

  if (aLabel === 'en-us') return -1
  if (bLabel === 'en-us') return 1
  if (aLabel === 'es-us') return -1
  if (bLabel === 'es-us') return 1

  return alphabetical(a, b, attributeTitles.localizations)
}

export const ageSort = (
  a: AlphabeticalSortProp1 | AlphabeticalSortProp2,
  b: AlphabeticalSortProp1 | AlphabeticalSortProp2
): number => {
  const aLabel = 'name' in a ? a.name : a.value
  const bLabel = 'name' in b ? b.name : b.value

  // Define the order mapping
  const orderMap: { [key: string]: number } = {
    Infant: 0,
    Child: 1,
    Teen: 2,
    Adult: 3,
    Senior: 4,
  }

  // Compare based on the order map
  return orderMap[aLabel] - orderMap[bLabel]
}

export const sourceSort = (
  a: AlphabeticalSortProp1 | AlphabeticalSortProp2,
  b: AlphabeticalSortProp1 | AlphabeticalSortProp2
) => {
  const aLabel = 'name' in a ? a.name : a.value
  const bLabel = 'name' in b ? b.name : b.value

  if (aLabel === 'Healthwise') return -1
  if (bLabel === 'Healthwise') return 1
  if (aLabel === 'In-House') return -1
  if (bLabel === 'In-House') return 1
  if (aLabel === 'CDC') return -1
  if (bLabel === 'CDC') return 1

  return alphabetical(a, b, attributeTitles.source)
}

const Facets = ({
  filterCount,
  smallScreenVariant,
}: {
  filterCount: number
  smallScreenVariant?: boolean
}) => {
  const { authState } = useAuthState()
  const compassOnly = orgIsCompassOnly(authState.licensing)

  return (
    <div
      className={smallScreenVariant ? styles.smallWrapper : styles.wrapper}
      data-testid='FacetList'
    >
      <div className={styles.banner}>
        <h3 className={styles.wrapperTitle}>Filters</h3>
        {smallScreenVariant && filterCount > 0 && <SelectedFacets />}
      </div>
      {!compassOnly && (
        <Facet
          title={attributeTitles.availability}
          attribute={attributeLabels.availability}
          sortMethod={alphabetical}
          defaultMaxItems={2}
        />
      )}
      <Facet
        title={attributeTitles.ageRange}
        attribute={attributeLabels.ageRange}
        sortMethod={ageSort}
        defaultMaxItems={5}
      />
      {!compassOnly && (
        <Facet
          title={attributeTitles.sex}
          attribute={attributeLabels.sex}
          sortMethod={alphabetical}
          defaultMaxItems={2}
        />
      )}
      <Facet
        title={attributeTitles.source}
        attribute={attributeLabels.source}
        tooltipValues={sourceTooltips}
        sortMethod={sourceSort}
        defaultMaxItems={5}
      />
      <Facet
        title={compassOnly ? attributeTitles.topicLocalizations : attributeTitles.localizations}
        attribute={compassOnly ? attributeLabels.topicLocalizations : attributeLabels.localizations}
        sortMethod={languageSort}
        defaultMaxItems={10}
        itemLabelFilter={compassOnly ? ['global', 'en-ca'] : undefined}
      />
    </div>
  )
}

const FacetList = ({ isInWorkflow, smallScreenVariant }: FacetListProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const filters = useSearchStore(state => state.filters)

  const filterCount = getFilterCount(filters)

  return isInWorkflow && smallScreenVariant ? (
    <>
      <Drawer
        keepMounted
        anchor='left'
        open={isDrawerOpen}
        classes={{ paper: styles.drawer }}
        data-testid='FacetList-Filters-Drawer'
      >
        <Facets smallScreenVariant={smallScreenVariant} filterCount={filterCount} />
        <div className={styles.stickyWrapper}>
          <CmxButton primary className={styles.closeDrawer} onClick={() => setIsDrawerOpen(false)}>
            Show Results
          </CmxButton>
        </div>
      </Drawer>
      <Button
        onClick={() => setIsDrawerOpen(true)}
        className={styles.filterButton}
        startIcon={<FilterListRoundedIcon className={styles.filterIcon} />}
        endIcon={
          filterCount > 0 ? (
            <Badge badgeContent={filterCount} className={styles.filterBadge} />
          ) : (
            <></>
          )
        }
        sx={{
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        Filters
      </Button>
    </>
  ) : isInWorkflow ? (
    <Facets smallScreenVariant={smallScreenVariant} filterCount={filterCount} />
  ) : (
    <></>
  )
}

export default FacetList
