import Alert from '@mui/material/Alert'
import ErrorIcon from '@mui/icons-material/Error'

export default function PageError() {
  return (
    <Alert
      sx={{
        backgroundColor: '#FFF',
        padding: '20px 0 20px 35px',
        color: '#DD372F',
        '& .MuiAlert-message': {
          paddingTop: '10px',
        },
        '& .MuiAlert-icon': {
          marginRight: '5px',
          color: '#DD372F',
        },
      }}
      icon={<ErrorIcon fontSize='inherit' />}
      severity='error'
    >
      You do not have permission to view this page. Contact your Organization Administrator to
      manage your permissions.
    </Alert>
  )
}
