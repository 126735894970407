import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import RestoreIcon from '@mui/icons-material/Restore'
import CloseIcon from '@mui/icons-material/Close'
import uploadLogo from 'Resources/icon-upload.svg'
import {
  Grid,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Tooltip,
} from '@mui/material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid'
import Dropdown, { DropdownItem } from 'Components/Dropdown'
import CmxButton from 'Components/CmxButton'
import { ImportStateManager } from 'Store/ImportState'
import { useManageStore } from 'Store/ManageState'
import styles from './ContentManagement.module.scss'
import { DataRow } from './ContentManagerTable'
import { useEffect, useRef, useState } from 'react'
import { PublishItem, SuppressionItem, getPublishToValues } from 'Api/Content.api'
import { ContentItemMetadata, ContentStatus } from 'Store/Models/ContentItem'
import { visuallyHidden } from '@mui/utils'
import uploadIcon from 'Resources/ic_round-publish.svg'
import useLocalStorage, { StorageKeys } from 'Store/Hooks/useLocalStorage'
import { AlgoliaContentLanguagesGET } from 'Api/Algolia.api'
import { AlgoliaState } from 'Store/AlgoliaState'
import { FindLocaleCodeByTitle } from 'Utilities/LocaleMapUtil'
import { AuthState, Subscription } from 'Store/AuthState'
import AppSettings from 'ApplicationSettings/ApplicationSettings'

interface DataGridToolbarProps {
  enableContentUpload: boolean
  selection: DataRow[]
  isHwContent: boolean
  currentSearch: string
  algoliaState: AlgoliaState
  authState: AuthState
  quickFilterValue: string
  setQuickFilterValue: (quickFilterValue: string) => void
}

export default function DataGridToolbar({
  enableContentUpload,
  selection,
  isHwContent,
  currentSearch,
  algoliaState,
  authState,
  quickFilterValue,
  setQuickFilterValue,
}: DataGridToolbarProps) {
  const apiRef = useGridApiContext()

  const {
    updateContentItems,
    updateDeleteOpen,
    updateHasEnglish,
    updateIsBulk,
    updateIsHwContent,
    updateIsRestore,
    updateFromManage,
    updatePublishItems,
    updatePublishOpen,
    updateSuppressItems,
    updateSuppressOpen,
  } = useManageStore()

  const [canSuppress, setCanSuppress] = useState(false)
  const [canRestore, setCanRestore] = useState(false)
  const [canPublish, setCanPublish] = useState(false)
  const setNextBatchItems = useLocalStorage(StorageKeys.NextBatchItems, [])[1]
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [searchTerm, setSearchTerm] = useState(quickFilterValue)
  const prevSearchTerm = useRef<string>(quickFilterValue)
  const [activeElement, setActiveElement] = useLocalStorage('previousActiveElement', null)

  let charCount = 0

  useEffect(() => {
    if (searchTerm !== '' || prevSearchTerm.current !== '') {
      let thisChange = ++charCount
      const delayDebounceFn = setTimeout(() => {
        if (charCount === thisChange) {
          if (searchTerm !== prevSearchTerm.current) apiRef.current.setPage(0)
          setQuickFilterValue(searchTerm)
        }
        prevSearchTerm.current = searchTerm
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    if (activeElement !== '' || activeElement !== null) {
      var element = document.getElementById(activeElement)
      element?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElement])

  useEffect(() => {
    setCanPublish(
      selection?.length > 0 &&
        selection.filter(e =>
          e.status.toLowerCase().trim().startsWith(ContentStatus.Draft.toLowerCase())
        )?.length > 0
    )
    setCanRestore(
      selection?.length > 0 &&
        selection.filter(e =>
          e.status.toLowerCase().trim().endsWith(ContentStatus.Suppress.toLowerCase())
        )?.length > 0
    )
    setCanSuppress(
      selection?.length > 0 &&
        selection.filter(
          e =>
            (isHwContent &&
              !e.status
                .toLowerCase()
                .trim()
                .endsWith(ContentStatus.Suppress.trim().toLowerCase())) ||
            e.status.toLowerCase().trim().endsWith(ContentStatus.Publish.trim().toLowerCase())
        )?.length > 0
    )
  }, [selection, isHwContent])

  //getting subscriptions
  useEffect(() => {
    if (algoliaState.key.QueryAppId && authState?.orgInfo?.subscriptions) {
      setSubscriptions(authState?.orgInfo?.subscriptions ?? [])
    }
    // eslint-disable-next-line
  }, [algoliaState.key.QueryAppId, authState.orgInfo])
  const handleOpen = () => ImportStateManager.SetImportOpen(true)

  const handleBatchDelete = () => {
    updateDeleteOpen(true)
  }

  function handleBatchPublish() {
    //globalMetadataChanged will always be true here in order to update the publishTo values on global variant
    let publishItems: PublishItem[] = selection.map(e => {
      return {
        hwid: e.hwid,
        title: e.title,
        localization: FindLocaleCodeByTitle(e.language),
        publishTo: getPublishToValues(subscriptions),
        status: e.status,
        globalMetadataChanged: true,
      }
    })
    //only need limited fields for saving from Manage table
    let contentItems: ContentItemMetadata[] = selection.map(e => {
      return {
        id: Number(e.id),
        hwid: e.hwid,
        title: e.title,
        contentType: '',
        localization: FindLocaleCodeByTitle(e.language),
        publishTo: getPublishToValues(subscriptions),
        status: e.status,
        globalMetadataChanged: true,
      }
    })

    //for single item, check for English first
    if (publishItems.length === 1) {
      if (publishItems[0].localization !== 'en-us') {
        AlgoliaContentLanguagesGET(
          publishItems[0].hwid,
          algoliaState.key.QueryAppId,
          algoliaState.key.QueryKey,
          algoliaState?.index || '',
          algoliaState?.languagePackageAttribute || ''
        ).then(data => {
          updateHasEnglish(data.ok)
        })
      }
    }
    if (contentItems.length > AppSettings.PublishBatchSize) {
      setNextBatchItems(contentItems.slice(AppSettings.PublishBatchSize))
      contentItems = contentItems.slice(0, AppSettings.PublishBatchSize)
      publishItems = publishItems.slice(0, AppSettings.PublishBatchSize)
    }
    updateContentItems(contentItems)
    updateHasEnglish(true)
    updateIsBulk(publishItems.length > 1)
    updatePublishItems(publishItems)
    updateFromManage(true)
    updatePublishOpen(true)
  }

  function handleBatchSuppress() {
    let suppressionItems: SuppressionItem[] = selection.map(e => {
      return { hwid: e.hwid, title: e.title }
    })
    updateIsHwContent(isHwContent)
    updateIsRestore(false)
    updateIsBulk(true)
    updateSuppressItems(suppressionItems)
    updateSuppressOpen(true)
  }

  function handleBatchRestore() {
    let restoreItems: SuppressionItem[] = selection.map(e => {
      return { hwid: e.hwid, title: e.title }
    })
    updateIsHwContent(isHwContent)
    updateIsRestore(true)
    updateIsBulk(true)
    updateSuppressItems(restoreItems)
    updateSuppressOpen(true)
  }

  const toolbarSelectItems: DropdownItem[] = [
    {
      icon: <img alt='Publish' src={uploadIcon} style={{ color: styles.darkestGrey }} />,
      text: 'Publish',
      visible: canPublish,
    },
    {
      icon: <VisibilityOffIcon style={{ color: styles.darkestGrey }} />,
      text: 'Suppress',
      visible: canSuppress,
    },
    {
      icon: <RestoreIcon style={{ color: styles.darkestGrey }} />,
      text: 'Restore',
      visible: canRestore,
    },
    //Passing empty DropDownItem in order to trigger Divider
    {
      visible: !isHwContent,
      text: '',
    },
    {
      icon: <DeleteIcon style={{ color: styles.darkestGrey }} />,
      text: 'Delete',
      visible: !isHwContent,
    },
  ]

  return (
    <GridToolbarContainer sx={{ padding: '12px' }}>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Stack direction='row' spacing={2}>
            {selection?.length > 0 && (
              <>
                <div className={styles.selectionButton}>{selection.length} Selected</div>
                <Dropdown
                  id='actionDropDown'
                  placeholder='Choose Action'
                  data-testid='row-action-dropdown'
                  items={toolbarSelectItems}
                  className={styles.dropDown}
                  mode='light'
                  onChange={e => {
                    //capture active element with id of dropdown
                    //not using document.activeElement due to Material UI nesting
                    setActiveElement('actionDropDown')
                    switch (e.target.value) {
                      case 'Publish':
                        handleBatchPublish()
                        break
                      case 'Suppress':
                        handleBatchSuppress()
                        break
                      case 'Restore':
                        handleBatchRestore()
                        break
                      case 'Delete':
                        handleBatchDelete()
                        break
                      default:
                        console.warn('Encountered unexpected selection:', e.target.value)
                    }
                  }}
                />
              </>
            )}
          </Stack>
        </Grid>
        <Grid container direction='column' item xs={8}>
          <Stack
            direction='row'
            spacing={2}
            className={styles.rightWrapper}
            alignItems='center'
            justifyContent='flex-end'
          >
            <InputLabel htmlFor='grid-toolbar-quick-filter' sx={visuallyHidden}>
              Search table content
            </InputLabel>
            <Input
              id='grid-toolbar-quick-filter'
              autoFocus
              type='text'
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <Tooltip
                    title={currentSearch.length > 0 ? 'Clear' : 'Search'}
                    placement='left'
                    PopperProps={{
                      // Test Harness placement compatibility
                      disablePortal: true,
                    }}
                  >
                    {currentSearch.length > 0 ? (
                      <IconButton
                        aria-label='Clear Search'
                        data-qa='ClearSearchIcon'
                        className={styles.searchIcon}
                        onClick={() => setQuickFilterValue('')}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label='Start Search'
                        data-qa='SearchIcon'
                        className={styles.searchIcon}
                        tabIndex={-1}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </Tooltip>
                </InputAdornment>
              }
              data-qa={'InHouse-searchbar'}
              placeholder='Search'
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px 8px 0px 0px',
                borderBottomColor: '#727272',
                height: '44px',
                paddingLeft: '10px',
                minWidth: '353px',
                fontSize: '16px',
                '& .MuiInput-root': {
                  paddingBottom: '6px',
                  paddingTop: '6px',
                  paddingLeft: '10px',
                },
                '& .MuiSvgIcon-root': {
                  color: '#424242',
                },
                '& .MuiInput-underline:before': {
                  borderBottom: '1px solid #727272',
                },
                '& .MuiInput-underline:after': {
                  borderBottom: '2px solid #424242',
                },
                '&:focus': {
                  borderBottomColor: '#424242',
                },
              }}
            ></Input>

            {enableContentUpload && (
              <CmxButton
                onClick={handleOpen}
                primary
                startIcon={
                  <Icon>
                    <img alt='Upload Content' src={uploadLogo} />
                  </Icon>
                }
                data-qa='uploadContentButton'
                className={styles.uploadContentButton}
                aria-haspopup='dialog'
              >
                Upload Content
              </CmxButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  )
}
