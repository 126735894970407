import { FC } from 'react'
import Avatar from '@mui/material/Avatar'
import DoneIcon from '@mui/icons-material/Done'

import styles from './Step.module.scss'

interface StepProps {
  order: number
  title?: string
  finalStep?: boolean
  selected: boolean
  done: boolean
  last: boolean
}

const Step: FC<StepProps> = ({ order, title, selected, done, last }) => {
  return (
    <div className={styles.wrapper} key={order}>
      <Avatar
        className={`${styles.avatarOutter} ${
          selected ? styles.badgeBackgroundActive : styles.noRing
        }`}
      >
        <Avatar
          className={`${styles.avatarInner} ${
            selected || done ? styles.badgeBackgroundActive : styles.badgeBackgroundInactive
          }`}
        >
          {done ? <DoneIcon /> : order}
        </Avatar>
      </Avatar>
      <div className={styles.label}>{title}</div>
      {!last && <div className={styles.line}></div>}
    </div>
  )
}

export default Step
