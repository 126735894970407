import { Dispatch, SetStateAction } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

import { Grid, IconButton, List, ListItem, ListItemText } from '@mui/material'

import { MetadataItem, MetadataItemAction, MetadataSource } from 'Store/Models/ContentItem'

import Tooltip from '../Tooltip/Tooltip'
import style from './MetadataEdit.module.scss'
import classNames from 'classnames'

type SetMetadataItems = Dispatch<SetStateAction<MetadataItem[]>>

interface MetadataItemsListProps {
  category: string
  metadataItems: MetadataItem[]
  setMetadataItems: SetMetadataItems
  title: string
  tooltip?: boolean
  tooltipText?: string
}

export default function MetadataItemsList({
  category,
  metadataItems,
  setMetadataItems,
  title,
  tooltip = false,
  tooltipText,
}: MetadataItemsListProps) {
  // A map of concepts to derive conceptSource (The source value of the parent concept)
  const conceptMap = Object.fromEntries(
    metadataItems
      .filter(mi => mi.category === 'concepts' && mi.action !== MetadataItemAction.Removing)
      .map(c => [c.conceptId, c])
  )

  // All manually added metadata items from a user
  const adHocItems =
    metadataItems.filter(
      mi =>
        mi.category === category &&
        mi.action !== MetadataItemAction.Removing &&
        mi.source === MetadataSource.AdHoc
    ) ?? []

  // All metadata items that derive from a concept (Also adds conceptSource to each record)
  const conceptItems =
    getDistinctMetadataItems(
      metadataItems.filter(
        mi =>
          mi.category === category &&
          mi.action !== MetadataItemAction.Removing &&
          mi.source === MetadataSource.FromConcept
      )
    ).map(ci => {
      return {
        ...ci,
        conceptSource: conceptMap[ci.conceptId ?? '']?.source ?? MetadataSource.Unknown,
      }
    }) ?? []

  const sortMethod = (a: MetadataItem, b: MetadataItem) => {
    const newA = a.value.toLowerCase()
    const newB = b.value.toLowerCase()
    return newA > newB ? 1 : newB > newA ? -1 : 0
  }

  // Each of these const values must be sorted according to filteredMetadataItems
  const addingAndAdHocItems = adHocItems.filter(mi => mi.action === MetadataItemAction.Adding)
  const notAddingAndAdHocItems = adHocItems.filter(mi => mi.action !== MetadataItemAction.Adding)
  const addingAndConceptItems = conceptItems.filter(
    mi => mi.action === MetadataItemAction.Adding && mi.conceptSource !== MetadataSource.HWAuthored
  )
  const notAddingAndConceptItems = conceptItems.filter(
    mi => mi.action !== MetadataItemAction.Adding && mi.conceptSource !== MetadataSource.HWAuthored
  )
  const hwAuthoredItems = conceptItems.filter(mi => mi.conceptSource === MetadataSource.HWAuthored)

  // Recombines the above values in the sorted manner that the styles expect
  const filteredMetadataItems = [
    ...addingAndAdHocItems.reverse(),
    ...addingAndConceptItems.reverse(),
    ...notAddingAndAdHocItems.sort(sortMethod),
    ...notAddingAndConceptItems.sort(sortMethod),
    ...hwAuthoredItems.sort(sortMethod),
  ]

  function ToolTip() {
    if (!tooltip) return null

    return (
      <Tooltip text={tooltipText!}>
        <InfoIcon className={style.infoIcon} />
      </Tooltip>
    )
  }

  return (
    <Grid
      alignItems='flex-start'
      className={style.metadataCategoryListContainer}
      container
      direction='row'
      item
    >
      <h4 className={style.metadataCategoryListTitle}>
        {title} ({filteredMetadataItems.length})
        <ToolTip />
      </h4>

      <List className={style.metadataCategoryList} tabIndex={0}>
        {filteredMetadataItems
          .filter(mi => mi.action !== MetadataItemAction.Removing)
          .map((metadataItem: MetadataItem & { conceptSource?: number }) => (
            <MetadataItemRow
              key={metadataItem.value + metadataItem.conceptId}
              metadataItem={metadataItem}
              onDeleteMetadataItem={onDeleteMetadataItem({ metadataItems, setMetadataItems })}
            />
          ))}
      </List>
    </Grid>
  )
}

interface OnDeleteMetadataItemHofArgs {
  metadataItems: MetadataItem[]
  setMetadataItems: SetMetadataItems
}

interface OnDeleteMetadataItemArgs {
  category: string
  source: MetadataSource
  value: any
}

const onDeleteMetadataItem =
  ({ metadataItems, setMetadataItems }: OnDeleteMetadataItemHofArgs) =>
  ({ category, source, value }: OnDeleteMetadataItemArgs) => {
    const itemToDelete = metadataItems.find(
      item =>
        item.category === category &&
        [item.value, item.value.conceptId].includes(value) &&
        item.source === source
    )

    setMetadataItems(prevState => {
      const filteredItems = prevState.filter(
        item => !(item.category === category && item.value === value && item.source === source)
      )

      if (itemToDelete?.action === MetadataItemAction.Adding) return filteredItems

      return [
        ...filteredItems,
        {
          ...itemToDelete,
          action: MetadataItemAction.Removing,
        } as MetadataItem,
      ]
    })
  }

interface MetadataItemRowProps {
  metadataItem: MetadataItem & { conceptSource?: number }
  onDeleteMetadataItem: (args: OnDeleteMetadataItemArgs) => void
}

function MetadataItemRow({
  metadataItem: { action, category, source, value, conceptSource },
  onDeleteMetadataItem,
}: MetadataItemRowProps) {
  const className = classNames(
    style.metadata,
    (() => {
      if (conceptSource === MetadataSource.HWAuthored) return style.metadataItemHealthwise
      if (action === MetadataItemAction.Adding) return style.metadataItemNew
      return style.metadataItem
    })()
  )

  let secondaryAction = null

  if (source === MetadataSource.AdHoc)
    secondaryAction = (
      <IconButton
        aria-label={`Remove metadata item - ${value}`}
        sx={{
          width: '30px',
          height: '30px',
          marginRight: '-8px !important',
          '&.Mui-focusVisible': {
            outline: '2px dotted #424242',
            backgroundColor: '#f4f4f4',
            background: 'transparent',
          },
        }}
        className={style.removeMetadataItem}
        data-qa='RemoveMetadataItem'
        data-testid='RemoveMetadataItem'
        key={value}
        onClick={() => onDeleteMetadataItem({ category, source, value })}
      >
        <CloseIcon key={value} />
      </IconButton>
    )

  return (
    <ListItem {...{ className, secondaryAction }} key={value} tabIndex={0}>
      <ListItemText>
        <div className={style.metadataItemText}>{value} </div>
      </ListItemText>
    </ListItem>
  )
}

function getDistinctMetadataItems(metadataItems: MetadataItem[]): MetadataItem[] {
  const distinctMetadataItems = new Array<MetadataItem>()

  metadataItems.forEach(itemToAdd => {
    if (distinctMetadataItems.filter(mi => mi.value === itemToAdd.value).length === 0) {
      const adding =
        (metadataItems.filter(
          mi => mi.value === itemToAdd.value && mi.action === MetadataItemAction.Adding
        ).length ?? 0) > 0
      distinctMetadataItems.push({
        ...itemToAdd,
        action: adding ? MetadataItemAction.Adding : itemToAdd.action,
      })
    }
  })

  return distinctMetadataItems
}
