import { MouseEvent } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import styles from './CmxToggleButtonGroup.module.scss'

interface CmxToggleButtonGroupProps {
  buttons: string[]
  value: string
  setValue: (val: string) => void
  label?: string
}

export default function CmxToggleButtonGroup(props: CmxToggleButtonGroupProps) {
  const { buttons, value, setValue, label } = props

  const handleChange = (event: MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment) setValue(newAlignment)
  }

  const TogglBtnGrpSx = {
    backgroundColor: styles.lighterGray,
    borderRadius: '28px',
    height: '40px',
  }

  const ToggleBtnSx = {
    borderRadius: '28px',
    margin: '3px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '400',
    color: styles.black,
    width: '50%',

    '&.MuiToggleButtonGroup-grouped': {
      borderRadius: '28px !important',
      border: 'none',
    },

    '&:focus-visible': {
      outline: '2px dotted #424242 !important',
    },

    '&:hover': {
      backgroundColor: styles.lighterGray,
    },

    '&.Mui-selected': {
      backgroundColor: styles.white,
      color: styles.black,
      fontWeight: '500',
      border: `1px solid ${styles.mediumGrey} !important`,
      filter: 'drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2))',

      '&:hover': {
        backgroundColor: styles.white,
      },
    },
  }

  return (
    <div className={styles.wrapper}>
      {typeof label !== 'undefined' && label.length > 0 ? <span>{label}</span> : null}

      {buttons.length > 1 ? (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          aria-label='text alignment'
          sx={TogglBtnGrpSx}
        >
          {buttons.map((btn: string) => {
            const buttonLabel = (() => {
              if (btn === 'en-us') return 'English'
              if (btn === 'es-us') return 'Spanish'
              return btn.charAt(0).toUpperCase() + btn.slice(1) // Capitalizes the first letter of the string
            })()

            return (
              <ToggleButton
                value={btn}
                sx={ToggleBtnSx}
                key={`${btn}-toggle-btn`}
                aria-label={`${btn}-toggle-btn`}
              >
                {buttonLabel}
              </ToggleButton>
            )
          })}
        </ToggleButtonGroup>
      ) : (
        <div>
          {buttons.map((btn: string) => {
            const buttonLabel = (() => {
              if (btn === 'en-us') return 'English'
              if (btn === 'es-us') return 'Spanish'
              return btn.charAt(0).toUpperCase() + btn.slice(1) // Capitalizes the first letter of the string
            })()

            return buttonLabel
          })}
        </div>
      )}
    </div>
  )
}
