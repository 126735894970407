import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  List,
  ListItem,
  Tooltip,
} from '@mui/material'
import FacetItemComponent from 'Components/CompassFacets/FacetItem/FacetItem'
import {
  FacetItem,
  attributeHoverMessage,
  attributeLabelKeys,
  getItemLabel,
} from 'Components/CompassFacets/FacetUtilities'
import useSearchStore from 'Store/SearchState'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useInstantSearch, useRefinementList } from 'react-instantsearch'
import { arraysEqual } from '../FacetUtilities'
import style from './Facet.module.scss'

type FacetProps = {
  attribute: string
  title: string
  defaultMaxItems?: number
  sortMethod?: any
  tooltipValues?: { [key: string]: string }
  itemLabelFilter?: string[]
}

type ZionRefinementListProps = FacetProps & {
  limit: number
}

const ZionRefinementList: React.FC<ZionRefinementListProps> = ({
  limit,
  attribute,
  title,
  defaultMaxItems = 3,
  sortMethod,
  tooltipValues,
  itemLabelFilter,
}): JSX.Element => {
  const refinementProps = { attribute: attribute, sortBy: sortMethod, limit: limit }

  const {
    items,
    //@ts-ignore Have to ignore this line b/c the types do not include string[] as a possible type for sortBy
  } = useRefinementList(refinementProps)

  const { setIndexUiState } = useInstantSearch()
  const filters = useSearchStore(state => state.filters)
  const toggleFilter = useSearchStore(state => state.toggleFilter)
  const [showMore, setShowMore] = useState(false)
  const oldItems = useRef([...items])
  const selections = filters[title]

  let activeItems = itemLabelFilter
    ? items.filter(item => !itemLabelFilter.includes(item.label))
    : [...items] // A copy of the items variable so we can modify this and still reference the original variable.

  const currentlyRefinedByAlgolia = activeItems.filter(ai => ai.isRefined).map(ai => ai.label)

  // Checks if the new set of items is larger than the old set, meaning we need to grow the list of facets and maintain it (so facets never disappear)
  if (activeItems?.length >= oldItems.current?.length) {
    oldItems.current = [...activeItems]
  } else {
    // Copies new properties to the total list of facet
    const propertyMap = new Map(
      activeItems.map((item: FacetItem) => {
        return [item.label, { count: item.count, isRefined: item.isRefined }]
      })
    )

    activeItems = [...oldItems.current]
    activeItems.forEach((item: FacetItem) => {
      const properties = propertyMap.get(item.label) as FacetItem | undefined
      if (properties === undefined) {
        item.count = 0
        item.isRefined = false
      } else {
        item.count = properties!.count
        item.isRefined = properties!.isRefined
      }
    })
  }

  if (sortMethod) activeItems.sort(sortMethod)

  const totalItems = activeItems?.length
  const maxItems = showMore ? activeItems?.length : defaultMaxItems
  const dashedTitle = title.replaceAll(' ', '-')

  useEffect(() => {
    if (!arraysEqual(selections, currentlyRefinedByAlgolia)) {
      setIndexUiState(prevUiState => ({
        ...prevUiState,
        refinementList: {
          ...prevUiState?.refinementList,
          [attribute]: selections,
        },
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections, items?.length, JSON.stringify(currentlyRefinedByAlgolia)])

  return items.length > 0 ? (
    <>
      <Divider className={style.facetDivider} />
      <Accordion
        className={style.facet}
        defaultExpanded={true}
        id={dashedTitle}
        disableGutters
        data-testid={dashedTitle + '-Facet'}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={style.facetHeaderIcon} />}
          id={dashedTitle + '-summary'}
          aria-controls={dashedTitle}
          className={style.facetHeader}
        >
          <Tooltip
            title={attributeHoverMessage[attributeLabelKeys[title]]}
            id={dashedTitle + '-tooltip'}
            placement='top-end'
            describeChild
            arrow
            disableInteractive
          >
            <h4
              className={style.facetTitle}
              aria-describedby={dashedTitle + '-title'}
              data-testid={dashedTitle + '-Facet-title'}
            >
              {title}
            </h4>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails className={style.facetContent}>
          <List dense={true} disablePadding>
            {activeItems.slice(0, maxItems).map((item: FacetItem, index: number) => {
              const label = getItemLabel(title, item.label) ?? item.label

              return (
                <Fragment key={`${title}-fragment-${index}`}>
                  <ListItem className={style.item} disableGutters>
                    <FacetItemComponent
                      label={label}
                      tooltip={
                        item.tooltip ? item.tooltip : tooltipValues ? tooltipValues[item.label] : ''
                      }
                      title={title}
                      isRefined={item.isRefined}
                      count={item.count}
                      onClick={() => {
                        toggleFilter(title, item.label)
                      }}
                    />
                  </ListItem>
                  {activeItems?.length > defaultMaxItems && index === defaultMaxItems - 1 && (
                    <ListItem sx={{ p: 0 }}>
                      <Button
                        aria-label={showMore ? 'Show less' : `Show ${totalItems - maxItems} more`}
                        aria-live='off'
                        className={style.showMore}
                        data-testid={dashedTitle + '-Show-More'}
                        onClick={() => setShowMore(prevShowMore => !prevShowMore)}
                        role='button'
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {showMore ? 'Show less' : `Show ${totalItems - maxItems} more`}
                      </Button>
                    </ListItem>
                  )}
                </Fragment>
              )
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <></>
  )
}

export default function Facet(props: FacetProps) {
  return (
    <ZionRefinementList
      attribute={props.attribute}
      title={props.title}
      limit={500}
      sortMethod={props.sortMethod}
      defaultMaxItems={props.defaultMaxItems}
      tooltipValues={props.tooltipValues}
      itemLabelFilter={props.itemLabelFilter}
    />
  )
}
