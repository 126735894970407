import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import { Checkbox, IconButton, Link, List, Tooltip, Typography } from '@mui/material'
import { GetContentStatus } from 'Api/Content.api'
import { CartAction, useCartState } from 'Store/CartState'
import { GlobalNotificationStateManager, SnackbarSeverity } from 'Store/GlobalNotificationState'
import { ContentStatus } from 'Store/Models/ContentItem'
import { ContentTypes } from 'Types/ContentTypes'
import { AlgoliaHit, ConceptHit, HistoryHit } from 'Types/HitItem'
import { CartItemFromHit, ItemExistsInCart } from 'Utilities/CartUtil'
import classNames from 'classnames'
import { FC } from 'react'
import { Hit } from 'instantsearch.js'
import style from './SearchWithAutocomplete.module.scss'

type Props = {
  results: Hit<AlgoliaHit>[]
  suggestions: ConceptHit[]
  history: HistoryHit[]
  handleListItemClick: (item: any, checked?: any) => void
  removeFromSearchHistory: (item: HistoryHit) => void
  onConfirmSearch: () => void
  searchTerm: string
}

export const AutocompleteResults: FC<Props> = ({
  results,
  suggestions,
  history,
  handleListItemClick,
  removeFromSearchHistory,
  onConfirmSearch,
  searchTerm,
}) => {
  const { cartState, cartStateDispatch } = useCartState()

  function generateHistoryHits() {
    return history.map((item, hx): JSX.Element => {
      return (
        <li
          key={`history-${hx}`}
          data-testid={`history-${hx}`}
          tabIndex={0}
          className={style.listItem}
          onClick={() => (handleListItemClick ? handleListItemClick(item.label) : null)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              if (handleListItemClick) handleListItemClick(item.label)
            }
          }}
        >
          <div className={style.conceptListItemIcon}>
            <Tooltip title='Recent Search'>
              <AccessTimeIcon />
            </Tooltip>
          </div>
          <div className={style.conceptListItemTitle}>
            <Link className={style.autoLink} data-qa='RecentSearchItem'>
              <Typography className={style.itemTitle}>{item.label}</Typography>
            </Link>
          </div>
          <div className={style.contentListItemAction}>
            <Tooltip title='Delete this recent search item'>
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  return removeFromSearchHistory(item)
                }}
                data-qa='DeleteRecentSearch'
                data-testid={`history-remove-${hx}`}
                aria-label='delete history item'
                sx={{
                  padding: 0,
                  '&:focus-visible': { outlineOffset: '7px', outline: '2px dotted #424242' },
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                <CloseRoundedIcon className={style.deleteIcon} />
              </IconButton>
            </Tooltip>
          </div>
        </li>
      )
    })
  }

  function generateSuggestions() {
    return suggestions.map((item, sx) => {
      return (
        <li
          key={`suggestion-${sx}`}
          data-testid={`suggestion-${sx}`}
          tabIndex={0}
          className={style.listItem}
          onClick={() => (handleListItemClick ? handleListItemClick(item.query) : null)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              if (handleListItemClick) handleListItemClick(item.query)
            }
          }}
        >
          <div className={style.conceptListItemIcon}>
            <SearchIcon />
          </div>
          <div className={style.conceptListItemTitle}>
            <Link
              className={style.autoLink}
              data-qa='SuggestedConceptItem'
              aria-label='suggested concept item'
            >
              <Typography className={style.itemTitle}>{item.query}</Typography>
            </Link>
          </div>
        </li>
      )
    })
  }

  const handleCheck = (newChecked: boolean, id: string) => {
    const getResult = results.filter(item => item.content.id === id)
    if (getResult.length > 0) {
      const hit = getResult[0]
      const item = CartItemFromHit(hit)
      cartStateDispatch(
        newChecked
          ? {
              type: CartAction.ADDCARTITEM,
              data: { ...item },
            }
          : {
              type: CartAction.REMOVECARTITEM,
              data: { ...item },
            }
      )

      if (newChecked) {
        GetContentStatus(item.hwid, 'en-us').then(data => {
          if (
            data.status.name.toLowerCase() === ContentStatus.Suppress.toLowerCase() ||
            data.status.name.toLowerCase() === ContentStatus.Deleted.toLowerCase()
          ) {
            cartStateDispatch({
              type: CartAction.REMOVECARTITEM,
              data: { ...item },
            })
            GlobalNotificationStateManager.ShowSnackbarNotification(
              `This title is being ${data.status.name} and is currently unavailable.`,
              SnackbarSeverity.Error
            )
          }
        })
      }
    }
  }

  function getDocIconFromType(type: string): JSX.Element {
    let element = <></>
    switch (type) {
      case ContentTypes.VIDEO:
        element = (
          <Tooltip title='Format: Video'>
            <span aria-label='Format: Video' role='img' data-qa='VideoIcon'>
              <OndemandVideoOutlinedIcon className={style.typeIcon} />
            </span>
          </Tooltip>
        )
        break
      case ContentTypes.DECISION:
      case ContentTypes.SYMPTOM:
      case ContentTypes.INTERACTIVE:
        element = (
          <Tooltip title='Format: Interactive'>
            <span aria-label='Format: Interactive' role='img' data-qa='InteractiveIcon'>
              <TouchAppOutlinedIcon className={style.typeIcon} />
            </span>
          </Tooltip>
        )
        break
      default:
        element = (
          <Tooltip title='Format: Article'>
            <span aria-label='Format: Article' role='img' data-qa='ArticleIcon'>
              <DescriptionOutlinedIcon className={style.typeIcon} />
            </span>
          </Tooltip>
        )
        break
    }
    return <div className='list-item-type-element'>{element}</div>
  }

  function generateResults() {
    return results.map((item, rx) => {
      const existsInCart = ItemExistsInCart(item.content.hwid, cartState.cartItems)
      return (
        <li
          key={`result-${rx}`}
          data-testid={`result-${rx}`}
          tabIndex={0}
          className={style.listItem}
          onClick={() => (handleListItemClick ? handleListItemClick(item.content) : null)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              if (handleListItemClick) handleListItemClick(item.content)
            }
          }}
        >
          <div className={style.contentListItemIcon}>
            <Tooltip title='Add to Basket' disableInteractive>
              <Checkbox
                inputProps={{ 'aria-label': 'article suggestion' }}
                role='checkbox'
                color='primary'
                id={`listItem${item.content.id}`}
                checked={existsInCart}
                className={style.checkbox}
                aria-label={'Add to Basket'}
                onClick={(e: any) => {
                  e.stopPropagation()
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleCheck(e.target.checked, item.content.id)
                }}
                data-qa='SuggestedContentItemCheckbox'
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              />
            </Tooltip>
          </div>
          <div className={style.contentListItemTitle}>
            <Link
              className={style.autoLink}
              data-qa='SuggestedContentItem'
              aria-label='suggested content item'
              data-testid={`result-link-${rx}`}
            >
              <Typography className={style.itemTitle}>{item.content.title}</Typography>
            </Link>
          </div>
          <div className={style.contentListItemAction}>{getDocIconFromType(item.content.type)}</div>
        </li>
      )
    })
  }

  return (
    <List role='list' sx={{ padding: 0 }}>
      {suggestions.length > 0 && (
        <div className={style.autocompleteSection}>
          <div className={style.autocompleteSectionTitle}>Search for</div>
          {generateSuggestions()}
        </div>
      )}
      {results.length > 0 && (
        <div className={style.autocompleteSection}>
          <div className={style.autocompleteSectionTitle}>Recommended</div>
          {generateResults()}
        </div>
      )}
      {history.length > 0 && (
        <div className={style.autocompleteSection}>
          <div className={style.autocompleteSectionTitle}>Recent</div>
          {generateHistoryHits()}
        </div>
      )}
      <div className={style.autocompleteSection}>
        <li key={'perform-search'} tabIndex={-1} className={style.listItem}>
          <div className={style.conceptListItemIcon}>
            <SearchIcon />
          </div>
          <div className={style.conceptListItemTitle}>
            <Link
              className={style.autoLink}
              onClick={onConfirmSearch}
              data-qa='SuggestedConceptItem'
              aria-label='suggested concept item'
            >
              <Typography className={style.itemTitle} sx={{ color: '#262626' }}>
                Search for <strong>"{searchTerm}"</strong>
              </Typography>
            </Link>
          </div>
          <Typography className={classNames(style.contentListItemAction, style.enterNote)}>
            press ENTER
          </Typography>
        </li>
      </div>
    </List>
  )
}
