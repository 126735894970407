import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Typography } from '@mui/material'
import {
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'

import styles from './ContentManagement.module.scss'
import CmxTextField from 'Components/CmxTextField/CmxTextField'
import { useEffect, useState } from 'react'

const pageStyles = {
  color: '#424242',
  '&.Mui-disabled': {
    color: '#d8d8d8',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d8d8d8',
  },
}

export default function DataGridPagination() {
  const apiRef = useGridApiContext()

  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const pageSizeOptions = [10, 20, 50, 100]
  const [rowCount, setRowCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    setRowCount(apiRef.current.getRowsCount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPageCount(Math.max(0, Math.ceil(rowCount / pageSize) - 1))
  }, [rowCount, pageSize])

  const handleFirstPageButtonClick = () => {
    apiRef.current.setPage(0)
  }

  const handleBackButtonClick = () => {
    apiRef.current.setPage(page - 1)
  }

  const handleNextButtonClick = () => {
    apiRef.current.setPage(page + 1)
  }

  const handleLastPageButtonClick = () => {
    apiRef.current.setPage(pageCount)
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5, mr: 2.5 }} className={styles.paginationControls}>
      <InputLabel id='rows-per-page-label' className={styles.rowsPerPageLabel}>
        Rows per page{' '}
      </InputLabel>
      <FormControl>
        <CmxTextField
          value={pageSize}
          className={styles.rowCountSelect}
          onChange={newSelect => apiRef.current.setPageSize(parseInt(newSelect.target.value))}
          SelectProps={{ labelId: 'rows-per-page-label' }}
          mode='light'
          select
        >
          {pageSizeOptions.map(menuItem => {
            return (
              <MenuItem key={`menu-item-${menuItem}`} value={menuItem}>
                {menuItem}
              </MenuItem>
            )
          })}
        </CmxTextField>
      </FormControl>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page <= 0}
        aria-label='first page'
        sx={pageStyles}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page <= 0}
        aria-label='previous page'
        sx={pageStyles}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <Typography display='inline' data-qa='RowCountLabel' mx={3}>{`${Math.min(
        page * pageSize + 1,
        rowCount
      )} - ${Math.min(page * pageSize + pageSize, rowCount)} of ${rowCount}`}</Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= pageCount}
        aria-label='next page'
        sx={pageStyles}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= pageCount}
        aria-label='last page'
        sx={pageStyles}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  )
}
