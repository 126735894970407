import { useAuth0 } from '@auth0/auth0-react'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import { createContext, useContext, useEffect, useReducer, useState } from 'react'

const filterRoutes = [
  App_Routes.HOME,
  App_Routes.HEALTH,
  App_Routes.RELOAD_LOGIN,
  App_Routes.REDIRECT,
  App_Routes.LOGIN,
]

export type Auth0DataProviderConfig = {
  audience: string
  clientId: string
  scope: string
  domain: string
  myHealthwiseUrl: string
  children?: React.ReactNode
  authorizationParams: any
}

export type AuthParams = {
  audience: string
  redirect_uri: string
  organization?: string
}

export type Auth0ConfigState = {
  auth0Config?: Auth0DataProviderConfig
  organizationAlias?: string
  isLoading: boolean
}

const initialState: Auth0ConfigState = {
  isLoading: true,
}

export enum Auth0ConfigAction {
  UPDATEAUTH0DATA,
}

const Auth0ConfigContext = createContext<{
  auth0ConfigState: Auth0ConfigState
  auth0ConfigStateDispatch: React.Dispatch<any>
}>({ auth0ConfigState: initialState, auth0ConfigStateDispatch: () => null })

function reducer(state: Auth0ConfigState, action: any) {
  switch (action.type) {
    case Auth0ConfigAction.UPDATEAUTH0DATA:
      return {
        ...state,
        organizationAlias: action.data?.organizationAlias ?? state.organizationAlias,
        auth0Config: { ...state.auth0Config, ...action.data?.auth0Config },
        isLoading: action.data?.isLoading ?? state.isLoading,
      }
    default:
      return state
  }
}

export type Auth0ConfigStateProviderConfig = {
  domain: string
  clientId: string
  redirectUri: string
  audience: string
  scope: string
  myHealthwiseUrl: string
  children?: JSX.Element
}

export const Auth0ConfigStateProvider = ({
  domain,
  clientId,
  redirectUri,
  audience,
  scope,
  myHealthwiseUrl,
  children,
}: Auth0ConfigStateProviderConfig) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  //Auth0ConfigStateManager.auth0ConfigStateDispatch = dispatch

  const { isAuthenticated } = useAuth0()
  const [organizationAlias, setOrganizationAlias] = useState<string>('')

  /**
   * If there is NO organization alias on the route, we know we are ready to load the configuration for Auth0
   * and present the Enter Organization screen.
   */
  useEffect(() => {
    const getOrgId = async (alias: string) => {
      let res = await fetch(`${myHealthwiseUrl}/organization?organizationName=${alias}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })

      if (!res.ok) {
        throw new Error(res.statusText)
      }
      var data = await res.json()

      if (!data.organizationId) {
        // throw some error here!
      }

      return data.organizationId
    }

    const configureAuth0 = (orgId?: string): Auth0DataProviderConfig => {
      const authParams: AuthParams = {
        audience: audience,
        redirect_uri: redirectUri,
      }

      if (orgId) {
        authParams.organization = orgId
      }

      return {
        audience,
        domain,
        clientId,
        authorizationParams: authParams,
        scope,
        myHealthwiseUrl,
      }
    }

    if (!isAuthenticated) {
      let alias = window.location.pathname
        .split('/')
        .filter(o => o.length) // Filter out empty elements
        .filter(o => !filterRoutes.includes(o))[0] // Filter out any application routes

      /**
       * If there IS a organization alias, we need to look up the organization Id because we
       * are not authenticated AND the user is trying to enter using an organization alias
       */
      if (alias) {
        ;(async () => {
          const orgId = await getOrgId(alias)
          const config = configureAuth0(orgId)
          // This is the main configuration needed to start the authentication process.
          // Here the organizationAlias is null because it will be set after authentication
          // happens
          dispatch({
            type: Auth0ConfigAction.UPDATEAUTH0DATA,
            data: {
              auth0Config: config,
              organizationAlias: null, //alias,
              isLoading: false,
            },
          })
        })()

        setOrganizationAlias(alias)
      } else {
        /**
         * If there is NOT an organization alias, the user should be prompted to enter one.
         */
        const config = configureAuth0()
        dispatch({
          type: Auth0ConfigAction.UPDATEAUTH0DATA,
          data: {
            auth0Config: config,
            organizationAlias: null,
            isLoading: false,
          },
        })
      }
    } else {
      dispatch({
        type: Auth0ConfigAction.UPDATEAUTH0DATA,
        data: { organizationAlias: organizationAlias },
      })
    }
    /* eslint-disable */
  }, [isAuthenticated])
  /* eslint-enable */

  return (
    <Auth0ConfigContext.Provider
      value={{ auth0ConfigState: state, auth0ConfigStateDispatch: dispatch }}
    >
      {children}
    </Auth0ConfigContext.Provider>
  )
}

export function useAuth0ConfigState() {
  const { auth0ConfigState, auth0ConfigStateDispatch } = useContext(Auth0ConfigContext)
  return { auth0ConfigState, auth0ConfigStateDispatch }
}
