export const actionKeys: { [key: string]: number } = {
  Backspace: 8,
  Tab: 9,
  Enter: 13,
  Esc: 27,
  Space: 32,
  PageUp: 33,
  PageDown: 34,
  End: 35,
  Home: 36,
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
  Delete: 46,
}

export const keyCodes: { [key: number]: number } = {
  8: actionKeys.Backspace,
  9: actionKeys.Tab,
  13: actionKeys.Enter,
  27: actionKeys.Esc,
  32: actionKeys.Space,
  33: actionKeys.PageUp,
  34: actionKeys.PageDown,
  35: actionKeys.End,
  36: actionKeys.Home,
  37: actionKeys.ArrowLeft,
  38: actionKeys.ArrowUp,
  39: actionKeys.ArrowRight,
  40: actionKeys.ArrowDown,
  46: actionKeys.Delete,
}

/* Privacy Policy URL */
export const PRIVACY_POLICY_URL = 'https://www.healthwise.org/specialpages/legal/privacy.aspx'

/* Terms of Use Url */
export const TERMS_OF_USE_URL = 'https://www.healthwise.org/specialpages/legal/terms.aspx'

export const MAIN_CONTENT_ID = '#main-content'
