import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AccessToken } from 'Store/AuthState'
const LOG_URL = `${AppSettings.CMXServiceUrl}/api/log`

export interface LogMessage {
  message: string
  logLevel: string
}

export enum LogLevel {
  Info,
  Warning,
  Error,
  Debug,
}

export function Log(message: string, logLevel: LogLevel) {
  fetch(LOG_URL, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
    body: JSON.stringify({
      message: message,
      logLevel: LogLevel[logLevel],
    }),
  })
    .then(response => {
      if (!response.ok) console.log('Error reaching service. Unable to hit logging endpoint.')
    })
    .catch(function () {
      console.log('Error reaching service. Unable to hit logging endpoint.')
    })
}
