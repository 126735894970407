import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'

import { ContentItemMetadata, ContentType, Gender, GetContentType } from 'Store/Models/ContentItem'

import style from './MetadataEdit.module.scss'
import classNames from 'classnames'

interface GenderRadioGroupProps {
  gender: Gender
  hwid: ContentItemMetadata['hwid']
  onGenderRadioChange: RadioGroupProps['onChange']
}

export default function GenderRadioGroup({
  gender,
  hwid,
  onGenderRadioChange,
}: GenderRadioGroupProps) {
  return (
    <>
      <label className={style.metadataEditFieldLabel} id='radio-buttons-assigned-sex-group-label'>
        Assigned Sex
      </label>

      {GetContentType(hwid) === ContentType.Healthwise ? (
        <div className={style.readonlyText}>{gender}</div>
      ) : (
        <RadioGroup
          aria-labelledby='radio-buttons-assigned-sex-group-label'
          name='radio-buttons-group-two'
          onChange={onGenderRadioChange}
          value={gender}
        >
          {[Gender.Any, Gender.Male, Gender.Female].map(gender => (
            <FormControlLabel
              className={classNames(style.radioText, style.genderRadioButton)}
              control={
                <Radio
                  className={style.radio}
                  tabIndex={0}
                  sx={{
                    '&.Mui-focusVisible': {
                      outline: '2px dotted #424242',
                      backgroundColor: '#f4f4f4',
                    },
                  }}
                />
              }
              key={gender}
              label={gender}
              value={gender}
            />
          ))}
        </RadioGroup>
      )}
    </>
  )
}
