import AppSettings from 'ApplicationSettings/ApplicationSettings'
import { AccessToken, OrgName, Subscription, SubscriptionApp } from 'Store/AuthState'
import { ContentItemMetadata, ContentType, GetContentType } from 'Store/Models/ContentItem'
import { DownloadFilePOST } from './DownloadFile'
import { Log, LogLevel } from './Log.api'
const STATUS_URL = `${AppSettings.CMXServiceUrl}/api/status`
const CONTENT_URL = `${AppSettings.CMXServiceUrl}/api/content`
const ECODE_URL = `${AppSettings.CMXServiceUrl}/api/ecode`
const ALLINHOUSECONTENT_URL = `${AppSettings.CMXServiceUrl}/inhousecontent/getAllPaginated`
const ALLHWCONTENT_URL = `${AppSettings.CMXServiceUrl}/hwMetadataAdditions/getAllPaginated`
const METADATA_URL = `${AppSettings.CMXServiceUrl}/metadata/get`
const CONCEPT_URL = `${AppSettings.CMXServiceUrl}/concept/get`
const ALLACTIVITIES_URL = `${AppSettings.CMXServiceUrl}/api/activitiespaginated`
const PDF_URL = `${AppSettings.CMXServiceUrl}/api/pdf`
const PDFZIP_URL = `${AppSettings.CMXServiceUrl}/api/pdfzip`
const ALLLOCALIZATIONS_URL = `${AppSettings.CMXServiceUrl}/api/alllanguages`
const CLUSTER_URL = `${AppSettings.CMXServiceUrl}/api/cluster`

const SAVEINHOUSEMETADATA_URL = `${AppSettings.CMXServiceUrl}/inhousecontent/save`
const SAVEHWMETADATA_URL = `${AppSettings.CMXServiceUrl}/hwMetadataAdditions/save`
const DELETEALLHWMETADATA_URL = `${AppSettings.CMXServiceUrl}/hwMetadataAdditions/delete`
const PUBLISHINHOUSE_URL = `${AppSettings.CMXServiceUrl}/inhousecontent/publish`
const PUBLISHHW_URL = `${AppSettings.CMXServiceUrl}/hwMetadataAdditions/publish`
const SUPPRESSINHOUSE_URL = `${AppSettings.CMXServiceUrl}/inhousecontent/suppress`
const SUPPRESSHW_URL = `${AppSettings.CMXServiceUrl}/api/suppress`
const RESTOREHW_URL = `${AppSettings.CMXServiceUrl}/api/restore`
const RESTOREINHOUSE_URL = `${AppSettings.CMXServiceUrl}/inhousecontent/restore`

export type Size = {
  id: string
  availableLocalizations: string[]
  [key: string]: string | string[]
}

export type AspectSize = {
  short: Size
  medium: Size
  long: Size
  [key: string]: Size // Makes AspectSize indexable; not a new property
}

export type Aspect = {
  label: number
  labelText: string
  uiLabel: string
  uiOrder: number
  sizes: AspectSize
}

export type Cluster = {
  id: string
  title: string
  aspects: Aspect[]
}

export interface PersistedContent {
  id: string
  url: string
}
export interface PdfResult {
  fileUrl: string
  persist: { pdf: PersistedContent[]; html: PersistedContent[] }
}

export interface Localization {
  key: string
  indexKey: string
  name: string
  abbreviation: string
}

export type CartItem = {
  contentId: string
  localization: string
  title: string
}

export interface EventResponse {
  hasError: boolean
  errorCount: number
  successCount: number
  errorMsg: string
}

export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const GetECode = async (subId: string, orgId: string): Promise<any> => {
  let res = await fetch(`${ECODE_URL}/${orgId}/${subId}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return res.text()
}

export const GetContentStatus = async (hwid: string, localization: string): Promise<any> => {
  let res = await fetch(`${STATUS_URL}/${OrgName()}/${hwid}/${localization}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetCluster = async (id: string): Promise<Cluster> => {
  let res = await fetch(`${CLUSTER_URL}/${id}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  const data = await res.json()
  return data.hits[0]
}

export const GetMetadata = async (
  hwid: string,
  localization: string,
  appId: string,
  apiKey: string,
  articlesIndexName: string,
  published: boolean,
  isTopic: boolean
): Promise<any> => {
  let res = await fetch(
    `${METADATA_URL}/${OrgName()}/${hwid}/${localization}/${published}/${isTopic}`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
        appId,
        apiKey,
        articlesIndexName,
      },
    }
  )

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetConcept = async (
  conceptId: string,
  appId: string,
  apiKey: string
): Promise<any> => {
  let res = await fetch(`${CONCEPT_URL}/${conceptId}/${appId}/${apiKey}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetAllInHouseItemsPaginated = async (
  page: number,
  pageSize: number,
  orderByColumn: string,
  orderByDir: string,
  filterString: string
): Promise<any> => {
  let res = await fetch(
    `${ALLINHOUSECONTENT_URL}/${OrgName()}?page=${page}&pageSize=${pageSize}&orderByColumn=${orderByColumn}&orderByDir=${orderByDir}${
      filterString ? `&searchString=${filterString}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
      },
    }
  )

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetAllHWItemsPaginated = async (
  page: number,
  pageSize: number,
  orderByColumn: string,
  orderByDir: string,
  filterString: string
): Promise<any> => {
  let res = await fetch(
    `${ALLHWCONTENT_URL}/${OrgName()}?page=${page}&pageSize=${pageSize}&orderByColumn=${orderByColumn}&orderByDir=${orderByDir}${
      filterString ? `&searchString=${filterString}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
      },
    }
  )

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetAllActivitiesPaginated = async (
  page: number,
  pageSize: number,
  orderByColumn: string,
  orderByDir: string,
  filterString: string
): Promise<any> => {
  let res = await fetch(
    `${ALLACTIVITIES_URL}/${OrgName()}?page=${page}&pageSize=${pageSize}&orderByColumn=${orderByColumn}&orderByDir=${orderByDir}${
      filterString ? `&searchString=${filterString}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
      },
    }
  )

  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export const GetAllLanguages = async (): Promise<any> => {
  let res = await fetch(ALLLOCALIZATIONS_URL, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })

  if (!res.ok) {
    throw new Error(`HTTP Error: ${res.statusText}`)
  }

  const data = await res.json()
  return data
}

export const fetchFormattedContent = async (
  hwid: string,
  lang?: string,
  alpine?: boolean
): Promise<string> => {
  let attempts = 0
  let maxAttempts = 5
  while (attempts < maxAttempts) {
    if (attempts > 0) {
      Log(
        `Retrying to fetch preview for: ${OrgName()} - ${hwid} - ${lang || 'en-us'} - ${
          alpine ? 'true' : 'false'
        }`,
        LogLevel.Warning
      )
      await delay(2000)
    }
    let res = await fetchFormattedContentImpl(hwid, lang, alpine)

    if (res.ok) {
      return await res.text()
    }
    attempts++
  }
  Log(
    `Failed to fetch preview for:  ${OrgName()} - ${hwid} - ${lang || 'en-us'} - ${
      alpine ? 'true' : 'false'
    }`,
    LogLevel.Error
  )
  return 'Cannot Load Preview'
}

export const fetchFormattedContentImpl = async (
  hwid: string,
  lang?: string,
  alpine?: boolean
): Promise<Response> => {
  let q = CONTENT_URL
  q += `?hwid=${hwid}`
  q += lang ? `&lang=${lang}` : ''
  q += alpine ? `&alpine=${alpine}` : ''
  return await fetch(q, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
    },
  })
}

type ContentRequest = {
  hwid: string
  language: string
  title: string
}

function makeRequests(content: CartItem[]): ContentRequest[] {
  const items = content.map(item => ({
    hwid: item.contentId,
    language: item.localization,
    title: item.title,
  }))
  return items
}

export async function fetchPdf(
  selectedContent: CartItem[],
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  await DownloadFilePOST(PDF_URL, { items: makeRequests(selectedContent) }, onComplete, onError)
}

export async function fetchPdfZip(
  selectedContent: CartItem[],
  onComplete: () => void,
  onError: () => void
): Promise<any> {
  await DownloadFilePOST(PDFZIP_URL, { items: makeRequests(selectedContent) }, onComplete, onError)
}

export async function SaveMetadataChanges(contentItems: ContentItemMetadata[]) {
  let hwContentItems: ContentItemMetadata[] = []
  let inHouseContentItems: ContentItemMetadata[] = []

  if (contentItems && contentItems.length > 0) {
    contentItems.forEach((item: ContentItemMetadata) => {
      let type = GetContentType(item.hwid)
      if (type === ContentType.Healthwise) {
        hwContentItems.push(item)
      } else {
        inHouseContentItems.push(item)
      }
    })
  }

  //HW Items
  if (hwContentItems.length > 0) {
    return fetch(`${SAVEHWMETADATA_URL}/${OrgName()}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(contentItems),
    }).then(response => {
      if (!response.ok) {
        throw new Error()
      }
      return
    })
  }

  //InHouse Items
  if (inHouseContentItems.length > 0) {
    return fetch(`${SAVEINHOUSEMETADATA_URL}/${OrgName()}`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + AccessToken(),
        'content-type': 'application/json',
      },
      body: JSON.stringify(contentItems),
    }).then(response => {
      if (!response.ok) {
        throw new Error()
      }
      return
    })
  }
}

export async function PublishItems(items: PublishItem[]) {
  let publishItems = items.map(e => {
    return { hwid: e.hwid, title: e.title, localization: e.localization, status: e.status }
  })
  let hwContentItems: PublishItem[] = []
  let inHouseContentItems: PublishItem[] = []
  var errorCount = 0
  var inhouseErrorCount = 0
  var hwErrorCount = 0
  var formattedString = ''
  var successCount = 0
  let publishResponse = {} as EventResponse

  if (publishItems && publishItems.length > 0) {
    publishItems.forEach((item: PublishItem) => {
      let type = GetContentType(item.hwid)
      if (type === ContentType.Healthwise) {
        hwContentItems.push(item)
      } else {
        inHouseContentItems.push(item)
      }
    })
  }

  //HW Items
  if (hwContentItems.length > 0) {
    let hwRes = await PublishHW(hwContentItems)
    if (hwRes.failedItems) {
      publishResponse.hasError = true
      hwErrorCount = hwRes.failedItems.length
      formattedString += hwRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount += hwContentItems.length - hwErrorCount
  }

  //InHouse Items
  if (inHouseContentItems.length > 0) {
    let inRes = await PublishInhouse(inHouseContentItems)
    if (inRes.failedItems) {
      publishResponse.hasError = true
      inhouseErrorCount = inRes.failedItems.length
      formattedString += inRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount += inRes.successCount
  }

  errorCount = inhouseErrorCount + hwErrorCount
  if (errorCount > 0) {
    publishResponse.errorCount = errorCount
    publishResponse.errorMsg = formattedString
  }
  if (successCount > 0) {
    publishResponse.successCount = successCount
  }
  return publishResponse
}

// Accepts an array of databaseIds that point to content.  Do NOT use hwids
export async function DeleteAllHWMetadata(databaseIds: number[]) {
  return fetch(`${DELETEALLHWMETADATA_URL}/${OrgName()}`, {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(databaseIds),
  })
}

export type SuppressionItem = {
  hwid: string
  title: string
}

export type PublishItem = {
  hwid: string
  title: string
  localization: string
  publishTo?: SubscriptionApp[]
  globalMetadataChanged?: boolean
  status?: string
}

export async function BulkSuppress(items: any[], fromBasket: boolean, subscriptionIds?: string[]) {
  let hwContentItems: SuppressionItem[] = []
  let inHouseContentItems: SuppressionItem[] = []
  var errorCount = 0
  var inhouseErrorCount = 0
  var hwErrorCount = 0
  var formattedString = ''
  var successCount = 0
  let suppressResponse = {} as EventResponse

  suppressResponse.hasError = false

  //turn ContentItems into SuppressionItems
  let suppressItems = items.map(e => {
    return { hwid: e.hwid, title: e.title }
  })

  if (suppressItems && suppressItems.length > 0) {
    suppressItems.forEach((item: SuppressionItem) => {
      let type = GetContentType(item.hwid)
      if (type === ContentType.Healthwise) {
        hwContentItems.push(item)
      } else {
        inHouseContentItems.push(item)
      }
    })
  }

  //HW Items
  if (hwContentItems.length > 0) {
    let hwRes = await SuppressHW(hwContentItems, subscriptionIds ?? [])
    if (hwRes.failedItems) {
      suppressResponse.hasError = true
      hwErrorCount = hwRes.failedItems.length
      formattedString += hwRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount += hwContentItems.length - hwErrorCount
  }

  //InHouse Items
  if (inHouseContentItems.length > 0) {
    let inRes = await SuppressInhouse(inHouseContentItems)
    if (inRes.failedItems) {
      suppressResponse.hasError = true
      inhouseErrorCount = inRes.failedItems.length
      formattedString += inRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount += inRes.successCount
  }

  errorCount = inhouseErrorCount + hwErrorCount
  if (errorCount > 0) {
    suppressResponse.errorCount = errorCount
    suppressResponse.errorMsg = formattedString
  }
  if (successCount > 0) {
    suppressResponse.successCount = successCount
  }
  return suppressResponse
}

export async function PublishInhouse(items: PublishItem[]) {
  let res = await fetch(`${PUBLISHINHOUSE_URL}/${OrgName()}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(items),
  })
  return await res.json()
}

export async function PublishHW(items: PublishItem[]) {
  let res = await fetch(`${PUBLISHHW_URL}/${OrgName()}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(items),
  })
  return await res.json()
}

export async function SuppressInhouse(items: SuppressionItem[]) {
  let res = await fetch(`${SUPPRESSINHOUSE_URL}/${OrgName()}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(items),
  })
  return await res.json()
}

export async function SuppressHW(items: SuppressionItem[], subscriptionIds?: string[]) {
  let res = await fetch(`${SUPPRESSHW_URL}/${OrgName()}`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      items: items,
      subscriptions: subscriptionIds,
    }),
  })
  return await res.json()
}

export async function Restore(items: SuppressionItem[], contentType: ContentType) {
  var errorCount = 0
  var inhouseErrorCount = 0
  var hwErrorCount = 0
  let successCount = 0
  var formattedString = ''
  let suppressResponse = {} as EventResponse
  suppressResponse.hasError = false

  if (contentType === ContentType.Healthwise) {
    let hwRes = await RestoreHW(items)
    //simplifying HW errors, we are not listing titles with errors
    if (hwRes.failedItems) {
      suppressResponse.hasError = true
      hwErrorCount = hwRes.failedItems.length
      formattedString = hwRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount = hwRes.successCount
  } else {
    let inRes = await RestoreInhouse(items)
    if (inRes.failedItems) {
      suppressResponse.hasError = true
      inhouseErrorCount = inRes.failedItems.length
      formattedString = inRes.failedItems.toString().split(',').join('\n\n')
    }
    successCount = inRes.successCount
  }

  errorCount = inhouseErrorCount + hwErrorCount

  if (errorCount > 0) {
    suppressResponse.errorCount = errorCount
    suppressResponse.errorMsg = formattedString
  }

  if (successCount > 0) {
    suppressResponse.successCount = successCount
  }

  return suppressResponse
}

export async function RestoreInhouse(items: SuppressionItem[]) {
  let res = await fetch(`${RESTOREINHOUSE_URL}/${OrgName()}`, {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify(items),
  })
  return await res.json()
}

async function RestoreHW(items: SuppressionItem[]) {
  let res = await fetch(`${RESTOREHW_URL}/${OrgName()}`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + AccessToken(),
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      items: items,
      subscriptions: [],
    }),
  })
  if (!res.ok) {
    throw new Error(res.statusText)
  }
  return await res.json()
}

export function getPublishToValues(subscriptions: Subscription[]): SubscriptionApp[] {
  const adviseSub = subscriptions.find(
    sub =>
      !!sub.applications.find(
        app => app.id.toLocaleLowerCase() === AppSettings.AdviseAppId.toLocaleLowerCase()
      )
  )

  const subApps: SubscriptionApp[] = [
    {
      subscriptionId: adviseSub?.id || '',
      applicationId: AppSettings.AdviseAppId,
    },
  ]

  const managementSub: Subscription | undefined = subscriptions.find(
    sub =>
      !!sub.applications.find(
        app => app.id.toLocaleLowerCase() === AppSettings.CMXAppId.toLocaleLowerCase()
      )
  )
  if (managementSub) {
    subApps.push({
      subscriptionId: managementSub.id,
      applicationId: AppSettings.CMXAppId,
    })
  }
  return subApps
}
