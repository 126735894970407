import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { App_Routes } from 'ApplicationSettings/ApplicationSettings'
import CmxButton from 'Components/CmxButton'
import { PageDropdown } from 'Pages/Browse/Browse'
import { useAuthState } from 'Store/AuthState'
import useSearchStore from 'Store/SearchState'
import { useAlgolia } from 'Store/UseAlgolia'
import { useEffect, useRef, useState } from 'react'
import { Configure, InstantSearch, useRefinementList } from 'react-instantsearch'
import { useNavigate, useLocation } from 'react-router-dom'
import style from './PageRefinement.module.scss'

type RefinementProps = {
  callingPageDropdown: PageDropdown
  enableActionableShowAll?: boolean
  enableJumping?: boolean
  headerOnlyVariant?: boolean
  maxValuesBeforeShowMore?: number
  setActivePage?: (activePage: string | null) => void
  sort: 'alpha' | 'group'
}

export type PageRefinementProps = RefinementProps & {
  filterGroupsToInclude?: string[]
  allowResponsiveness?: boolean
  groupSort?: (a: string, b: string) => number
}

type RawPageRefinementProps = RefinementProps & {
  attribute: string
  limit: number
}

type ValueItemPair = {
  value: string
  item: any
}

type RefinerProps = {
  title: string
  values: ValueItemPair[]
}

type JumpToProps = {
  headers: string[]
}

function RawPageRefinement({
  attribute,
  callingPageDropdown,
  enableJumping = false,
  headerOnlyVariant,
  limit,
  maxValuesBeforeShowMore = 5,
  setActivePage,
  sort,
}: RawPageRefinementProps) {
  const searchTerm = useSearchStore(state => state.searchTerm)
  const updateBrowsePath = useSearchStore(state => state.updateBrowsePath)
  const updateSearchTerm = useSearchStore(state => state.updateSearchTerm)
  const updateSelectedQuickFilters = useSearchStore(state => state.updateSelectedQuickFilters)
  const clearAllFilters = useSearchStore(state => state.clearAllFilters)
  const location = useLocation()
  const navigate = useNavigate()

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const refinementProps = { attribute: attribute, limit: limit }
  const {
    items,
    //@ts-ignore Have to ignore this line b/c the types do not include string[] as a possible type for sortBy
  } = useRefinementList(refinementProps)

  const hasSeenItems = useRef<boolean>(false)
  if (items.length > 0) hasSeenItems.current = true

  // Removes anything that would fall under the 'Medical Specialties' group
  const filteredItems = items.filter(
    (item: { label: string }) => !item.label.includes('Medical Specialties')
  )

  // Transforms 'filteredItems' into a hierarchy-based object (TopLevelConcept -> new Set(SecondLevelConcepts))
  const groupChildPairs: any = {}
  filteredItems.forEach((item: any) => {
    const split = item.label.split(' > ')
    switch (sort) {
      case 'group':
        if (!groupChildPairs[split[1]]) {
          groupChildPairs[split[1]] = []
        }
        groupChildPairs[split[1]].push({ value: split[2], item: item })
        break
      case 'alpha':
        if (!groupChildPairs[split[2].charAt(0)]) {
          groupChildPairs[split[2].charAt(0)] = []
        }
        groupChildPairs[split[2].charAt(0)].push({ value: split[2], item: item })
        break
      default:
        console.error("Sort value must be either 'group' or 'alpha'")
    }
  })

  // Sort each section of our new hierarchy-based object
  function sectionSort(a: { value: string; item: any }, b: { value: string; item: any }) {
    return a.value.localeCompare(b.value)
  }

  Object.keys(groupChildPairs).forEach(key => {
    groupChildPairs[key].sort(sectionSort)
  })

  useEffect(() => {
    setHasScrolled(false)
  }, [location, filteredItems.length])

  useEffect(() => {
    if (
      enableJumping &&
      !hasScrolled &&
      hasSeenItems.current &&
      window.location.href.includes('#')
    ) {
      const scrollToElement = document.getElementById(
        window.location.href.replaceAll('%20', '-').split('#')[1]
      )
      if (scrollToElement?.offsetTop)
        window.scrollTo({
          left: 0,
          top: scrollToElement?.offsetTop - 20,
          behavior: 'smooth',
        })
      setHasScrolled(true)
    }
  }, [hasSeenItems, hasScrolled, filteredItems.length, enableJumping, location])

  function setWhereToScroll(section: string | null, replaceUrl?: boolean) {
    if (setActivePage) setActivePage(null)
    const hopTo = callingPageDropdown.route + (section ? `#${section.replaceAll(' ', '-')}` : '')

    if (replaceUrl) navigate(hopTo, { replace: true })
    else navigate(hopTo)
  }

  function Refiner({ title, values }: RefinerProps) {
    const [showingAll, setShowingAll] = useState<boolean>(false)
    const displayValues = values
      .sort()
      .slice(0, showingAll ? values.length : maxValuesBeforeShowMore)
      // filters out duplicates
      .filter((value, index, self) => index === self.findIndex(t => t.value === value.value))

    return headerOnlyVariant ? (
      <Button
        className={style.showAll}
        onClick={() => setWhereToScroll(title)}
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
      >
        <Typography className={style.showMoreText}>{title}</Typography>
      </Button>
    ) : (
      <Box className={style.group}>
        <div
          className={style.groupHeader}
          id={enableJumping ? title.replaceAll(' ', '-') : undefined}
        >
          <div className={style.groupHeaderTitle}>{title}</div>
          <Box className={style.headerLine} />
        </div>

        <Box className={style.groupChildren}>
          {displayValues.map((displayValue: ValueItemPair) => (
            <Button
              key={displayValue.value}
              className={style.groupChild}
              onClick={() => {
                if (setActivePage) setActivePage(null)
                if (searchTerm.length > 0) updateSearchTerm('')
                if (location.pathname !== App_Routes.BROWSE) navigate(App_Routes.BROWSE)
                updateSelectedQuickFilters([])
                clearAllFilters()
                updateBrowsePath(displayValue.item.label)
              }}
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            >
              {displayValue.value}
            </Button>
          ))}
        </Box>

        {values.length > maxValuesBeforeShowMore && (
          <Grid item xs={12}>
            <CmxButton
              className={style.actionableShowAll}
              onClick={() => setShowingAll(!showingAll)}
              endIcon={
                showingAll ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />
              }
              primary
              variant='text'
            >
              {showingAll ? 'Show Less' : `Show More (${values.length - maxValuesBeforeShowMore})`}
            </CmxButton>
          </Grid>
        )}
      </Box>
    )
  }

  function JumpTo({ headers }: JumpToProps) {
    return (
      <div className={style.jumpTo}>
        <h3 className={style.jumpToHeader}>
          <ArrowDownwardRoundedIcon /> Jump to a Section
        </h3>
        <Box className={sort === 'group' ? style.jumpToSectionsGroup : style.jumpToSectionsAlpha}>
          {headers.sort().map(header => {
            return (
              <Button
                key={`jumpTo-${header}`}
                className={sort === 'group' ? style.jumpToItemGroup : style.jumpToItemAlpha}
                onClick={() => setWhereToScroll(header, true)}
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              >
                {header}
              </Button>
            )
          })}
        </Box>
      </div>
    )
  }

  return (
    <Grid container className={headerOnlyVariant ? style.headers : style.groups}>
      {hasSeenItems.current || items.length > 0 ? (
        <>
          {enableJumping && <JumpTo headers={Object.keys(groupChildPairs)} />}
          {Object.keys(groupChildPairs)
            .sort((a, b) => {
              if (a < b) return -1
              else if (a > b) return 1
              else return 0
            })
            .map(groupTitle => (
              <Refiner key={groupTitle} title={groupTitle} values={groupChildPairs[groupTitle]} />
            ))}
        </>
      ) : (
        <CircularProgress sx={{ my: '20px', mx: 'auto' }} />
      )}
    </Grid>
  )
}

export default function PageRefinement({
  callingPageDropdown,
  sort,
  enableJumping,
  setActivePage,
  maxValuesBeforeShowMore,
  headerOnlyVariant,
}: PageRefinementProps) {
  const { authState } = useAuthState()
  const { algoliaClient } = useAlgolia(authState.accessToken)

  return !algoliaClient?.appId ? (
    <h3>Error Loading Algolia Client</h3>
  ) : !callingPageDropdown.attribute || callingPageDropdown.attribute?.length < 1 ? (
    <h3>Attribute undefined for {callingPageDropdown?.title}</h3>
  ) : (
    <InstantSearch searchClient={algoliaClient} indexName='assets'>
      <Configure
        distinct
        facetingAfterDistinct={false}
        analytics
        clickAnalytics
        enablePersonalization
      />
      <RawPageRefinement
        callingPageDropdown={callingPageDropdown}
        attribute={callingPageDropdown.attribute}
        limit={1000}
        sort={sort}
        enableJumping={enableJumping}
        setActivePage={setActivePage}
        maxValuesBeforeShowMore={maxValuesBeforeShowMore}
        headerOnlyVariant={headerOnlyVariant}
      />
    </InstantSearch>
  )
}
